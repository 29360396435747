import {Injectable} from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {Router} from "@angular/router";


@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private myRoute: Router) {
  }
  public getCookie(name: string) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let credentials = JSON.parse(this.getCookie("credentials"));
    if (credentials != null) {
      return true;
    }
    else {
      this.myRoute.navigate(["user"], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }

}
