import {Component, OnChanges, Input, Output, EventEmitter, ɵEMPTY_ARRAY, OnInit, SimpleChanges} from "@angular/core";
import {animate, group, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: "app-more-filter",
  templateUrl: "./more-filter.component.html",
  styleUrls: ["./more-filter.component.css"],
  // TODO: Change animation milliseconds or delay(if we want to)
  animations: [
    trigger("slideInOut", [
      state("in", style({height: "*", opacity: 0})),
      transition(":leave", [
        style({height: "*", opacity: 1}),

        group([
          animate(300, style({height: 0})),
          animate("200ms ease-in-out", style({"opacity": "0"}))
        ])

      ]),
      transition(":enter", [
        style({height: "0", opacity: 0}),

        group([
          animate(300, style({height: "*"})),
          animate("400ms ease-in-out", style({"opacity": "1"}))
        ])

      ])
    ])
  ]
})
export class MoreFilterComponent implements OnChanges{
  @Input() filter: any;
  @Input() maxFilters = 3;
  @Input() alreadyCheckedFilters: string[];
  @Input() checkedFilters: any;
  lengthOfFilter: number;
  hideToggle = true;
  limit: number;
  public isCollapsed = true;
  @Output() checkChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() {


  }

  toggleView() {
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    this.lengthOfFilter = Object.keys(this.filter).length;
    if (this.lengthOfFilter <= this.maxFilters) {
      this.limit = this.lengthOfFilter;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed === true) {
      this.limit = 3;
    } else if (this.isCollapsed === false) {
      this.limit = this.lengthOfFilter;
    }
  }

  public updateCheckedFilters(filter, event) {
    console.log(this.alreadyCheckedFilters);
    if (this.checkedFilters) {
      const index = this.checkedFilters.findIndex(item => filter.toLowerCase() === item.toLowerCase());
      if (event.target.checked) {
        if (index <= -1) {
          this.checkedFilters.push(filter.toLowerCase());
        }
      } else {
        if (index > -1) {
          this.checkedFilters.splice(index, 1);
        }
      }
      this.checkChanged.emit(this.checkedFilters);
    }
  }

  public caseInsensitiveCheck(alreadyCheckedFilters, name){
    if (alreadyCheckedFilters) {
      let index = alreadyCheckedFilters.findIndex(item => name.toLowerCase() === item.toLowerCase());
      if (index <= -1) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.determineView();
  }
}
