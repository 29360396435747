import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import * as d3 from "d3";


@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.css']
})
export class BarchartComponent implements OnInit, OnChanges {

  defaultValues: any = {
    width: 400,
    height: 400,
    /*legend: {
      rectSize: 25,
      spacing: 6
    },*/
    dataset: [
      {label: 'Assamese', count: 13},
      {label: 'Bengali', count: 83},
      {label: 'Bodo', count: 1.4},
      {label: 'Dogri', count: 2.3},
      {label: 'Gujarati', count: 46},
      {label: 'Hindi', count: 300},
      {label: 'Kannada', count: 38},
      {label: 'Kashmiri', count: 5.5},
      {label: 'Konkani', count: 5},
      {label: 'Maithili', count: 20},
      {label: 'Malayalam', count: 33},
      {label: 'Manipuri', count: 1.5},
      {label: 'Marathi', count: 72},
      {label: 'Nepali', count: 2.9},
      {label: 'Oriya', count: 33},
      {label: 'Punjabi', count: 29},
      {label: 'Sanskrit', count: 0.01},
      {label: 'Santhali', count: 6.5},
      {label: 'Sindhi', count: 2.5},
      {label: 'Tamil', count: 61},
      {label: 'Telugu', count: 74},
      {label: 'Urdu', count: 52}
    ]
  };
  @Input('chartId') public chartId: string;
  @Input('dataset') public dataset: any[];
  @Input('colorCodes') public colorCodes: string[];
  @Input('width') public width: number;
  @Input('height') public height: number;
  @Input('legend') public legend: any;
  @Output() public openMessagesEvent: EventEmitter<any> = new EventEmitter();
  //@Output() public wordClickEvent: EventEmitter<any> = new EventEmitter();

  public color: any = d3.scaleOrdinal(d3.schemeCategory10);
  private tooltip: any;
  private bars: any;
  private myColor: any = {'Positive': "#68a94d", 'Negative':"#dd5026"};

  constructor() {
  }

  private pathAnim(path: any, dir: number, r: number): void {
    switch (dir) {
      case 0: {
        path.transition()
            .duration(500)
            .ease(d3.easeBounce)
            .attr('d', d3.arc()
                .innerRadius(0)
                .outerRadius(r));
        break;
      }

      case 1: {
        path.transition()
            .attr('d', d3.arc()
                .innerRadius(0)
                .outerRadius(r * 1.08));
        break;
      }
    }
  }

  private initChart(): void {
    const chart: any = d3.select('#graph');
    //chart.append('g');
    chart.selectAll('*').remove(); // Clear existing chart contents.

   /* for (let key in this.defaultValues) {
      if (!this[key]) {
        this[key] = this.defaultValues[key];
      }
    }
    if (this.colorCodes && this.colorCodes.length) {
      this.color = d3.scaleOrdinal(this.colorCodes);
    }
    this.width = +this.width;
    this.height = +this.height;*/
  }

  private buildChart(): void {
    //const _this = this;

    //var data = [{"sentiment":"positive","value":28.5},{"sentiment":"negative","value":2.6}]

    var margin = {top: 20, right: 20, bottom: 40, left: 110},
        width = 500 - margin.left - margin.right,
        width = 500 - margin.left - margin.right,
        height = 200 - margin.top - margin.bottom;

    var y = d3.scaleBand()
        .range([height, 0])
        .padding(0.1);

    var x = d3.scaleLinear()
        .range([0, width]);

    var svg = d3.select("#graph").append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    this.dataset.forEach(function(d) {
      d.value = +d.value;
    });

    x.domain([0, 100])
    y.domain(this.dataset.map(function(d) { return d.sentiment; }));

    const _this = this;

    this.bars = svg.selectAll(".bar")
        .data(this.dataset)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("width", function(d) {return x(d.value); } )
        .attr("y", function(d) { return y(d.sentiment) + 15; })
        .attr("height", 50)
        .attr("fill", function(d) {
          if (d.sentiment === "Positive") {
            return _this.myColor.Positive;
          } else {
            return _this.myColor.Negative;
          }
        });


    //this.tooltip = d3.select('.tooltip-scatterplot');
    this.tooltip = d3.select('.tooltip-barchart');
    this.bars.on('mouseover', function (d: any) {
      d3.select(this).style('cursor', 'pointer');
      d3.select(this).attr('fill', '#000000');
      _this.tooltip.select('.name').html(d.sentiment);
      _this.tooltip.select('.freq').html(d.value);
      //_this.tooltip.html('Click to view'+d.sentiment+'messages');
      _this.tooltip.style('display', 'block');
    });

    this.bars.on('mouseout', function () {
      d3.select(this).style('cursor', 'normal');
      d3.select(this).attr("fill", function(d: any) {
        if (d.sentiment === "Positive") {
          return _this.myColor.Positive;
        } else {
          return _this.myColor.Negative;
        }
      });


      _this.tooltip.style('display', 'none');
    });

    this.bars.on('mousemove', function (d: any) {
      const mouse: any = d3.mouse(this);
      _this.tooltip.style('top', (mouse[1] + 0) + 'px')
          .style('left', (mouse[0] + 70) + 'px');
    });

    this.bars.on('click', (d) => {
      this.openMessagesEvent.emit({type:'sentiment', name:d.sentiment});

    });
    svg.selectAll(".images")
        .data(this.dataset)
        .enter().append("svg:image")
        .attr("x", -40 )
        .attr("y", function(d) { return y(d.sentiment) + 10; })
        .attr("width", 30)
        .attr("height", 30)
        .attr("xlink:href", function(d){
          return d.sentiment === 'Positive' ? "../../../assets/images/like.png" :
              "../../../assets/images/unlike.png"
        });

    svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));

    svg.append("g")
        .call(d3.axisLeft(y).tickPadding(60));

    svg.append("text")
        .attr("class", "sentiment-x-label")
        .attr("text-anchor", "end")
        .attr("x", 370)
        .attr("y", height + 40)
        .text("Sentiment (%)");
  }

  ngOnInit(): void {
    this.initChart();
    this.buildChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataset && !changes.dataset.firstChange) {
      this.initChart();
      this.buildChart();
    }
  }

}
