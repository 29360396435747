import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api.service';

const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Injectable({
  providedIn: 'root'
})
export class SubClusterService {

  constructor(private apiService: ApiService) {
  }

  public getSubClusters(parentGroup: number, payload: any): Promise<any> {
    let url = '/clusters/subcluster?workspace=' + workspace + '&parentGroup=' + parentGroup;
    return new Promise((resolve, reject) => {
      this.apiService.put(url, payload).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getSubClusterKeywords(): Promise<any> {
    let url = '/clusters/subcluster?workspace=' + workspace;
    return new Promise((resolve, reject) => {
      this.apiService.get(url).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public cancelSubClustering(subGroups: any[]): Promise<any> {
    let url = '/clusters/subcluster/cancel?workspace=' + workspace;
    return new Promise((resolve, reject) => {
      this.apiService.put(url, subGroups).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public applySubClustering(request: any): Promise<any> {
    let url = '/clusters/subcluster/apply?workspace=' + workspace;
    return new Promise((resolve, reject) => {
      this.apiService.put(url, request).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }
}
