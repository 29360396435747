import {Component, OnInit} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";
import * as $ from "jquery";
import * as moment from "./reusable/header/header.component";
import {AppRoutingModule} from "./app-routing.module";
import {CustomTopicsService} from "./custom-topics/custom-topics.service";
import {SharedServiceService} from "./services/shared-service.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "my-app";
  showHeader = false;
  pagenamefromcustom;
  topicpages:any=[];
  goheader=false;
  predefinedPages=['/home','/accuracy','/diet','/diabetes','/wearable','/supply','/alarm','/digital','/cost','/service','/trend','/twitter','/sentiments','/line-graph','/test-graph','/bar-chart','/messages','/force-directed-graph','/curved-weighted-graph','/word-cloud','/force-directed-rect','/word-cloud-bar-chart','/pack-layout','/pie-chart','/scatterplot','/seek-and-discover','/custom-topics']

  ngOnInit() {

    console.log("inside ng onit in appts")
    this.customtopic.getpagestodisplay().then((resp3)=>{
      console.log(resp3)
      for(let i=0;i<resp3.length;i++){
        this.topicpages.push(resp3[i].page)
      }
      console.log(this.topicpages)

      this.goheader=true;

    })
  }


  constructor(private router: Router, private customtopic:CustomTopicsService ,private sharedser:SharedServiceService) {
    // TODO: temporary solution to hide header from login component.Find new way to hide header
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // TODO: will break if we have another url that contains /user.
        if (event.url.includes("/user")) {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
        if(this.sharedser.fromheader!==1) {

 
          if (this.predefinedPages.includes(event.url)) {
            console.log(event.url)

          } else {
            let m = event.url;
            m = m.slice(1, 100);
            console.log(m);
            let l=m;
            l=l.split("%20").join(' ');
            console.log(l)
            this.router.config[29].path = l;
            console.log(this.router);
            this.sharedser.fromheader = 1;
            this.sharedser.headerval = 1;
            this.sharedser.pagenamefromheader = l;

          }
        }
      }
    });
  }
   public addlinksinHeader2(draft1){
    console.log("in add links ");
    this.pagenamefromcustom = draft1;
     this.customtopic.savepagename(draft1).then((resp)=>{
       console.log("in savepages");
       this.customtopic.getpagestodisplay().then((resp3)=>{
         console.log(resp3)
         for(let i=0;i<resp3.length;i++){
           this.topicpages.push(resp3[i].page)
         }
         console.log(this.topicpages)
         this.goheader=true;
       });


     });
   }

}
