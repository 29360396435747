import {Injectable} from '@angular/core';
import * as d3Cloud from 'd3-cloud';

@Injectable({
  providedIn: 'root'
})
export class WordCloudService {

  constructor() {
  }

  create(data: any[], dimensions: any, listener: any): void {
    d3Cloud().size([dimensions.width, dimensions.height])
        .words(data)
        .rotate(0)
        .padding(dimensions.padding)
        .font('\'Open Sans\', sans-serif')
        .text((d: any) => d.text)
        .fontSize((d: any) => d.rank)
        .on('end', listener)
        .start();
  }
}
