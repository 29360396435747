
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../modal/modal.service';
import {SubClusterService} from './sub-cluster.service';
import {MessageService} from '../message.service';
import {EllipseForceService} from '../../reusable/d3/ellipse-force/ellipse-force.service';
import * as d3 from 'd3';
import {WordCloudService} from '../../reusable/d3/word-cloud/word-cloud.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '../modal/modal.component';
import {ToastrService} from 'ngx-toastr';

const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: 'app-sub-cluster',
  templateUrl: './sub-cluster.component.html',
  styleUrls: ['./sub-cluster.component.css']
})
export class SubClusterComponent implements OnInit {
  showLoader = false;
  nodes: any[] = [];
  links: any[] = [];
  wordCloudNodesMap: any;
  nodeGroups: any;
  nodeLinksMap: any;
  lockedGroups: any = {};
  selectedNodes: any[] = [];
  selectedLinks: any[] = [];
  selectedGroups: number[] = [];
  public selectedGroup: number = 0;
  excludeWords: string[] = [];
  shortListedWords: string[] = [];
  selectedWords: any = {};
  clickedWords: string[] = [];
  public groupLabel: string = '';
  public wordsOccurrence: any[] = [];
  color: any = d3.scaleOrdinal(d3.schemeCategory10);
  clickBox: any;
  max = 40;
  min = 15;
  step = 5;
  slideLimit = 15;
  tickInterval = 5;
  public clickBoxButtonClicked: string;


  @Input('parentGroup') parentGroup: number;
  @Input('rawLinks') rawLinks: any;
  @Input('keywords') keywords: any;
  @Input('nodeTextMap') nodeTextMap: any;
  @Input('subClusterKeywords') subClusterKeywords: any[];
  @Input('groupLabels') groupLabels: any;
  @Input('activeTab') activeTab: any;


  @Output() public updateMainGraphEvent: EventEmitter<any> = new EventEmitter();

  constructor(
      private ellipseForceService: EllipseForceService,
      private messageService: MessageService,
      private modalService: ModalService,
      private ngbModalService: NgbModal,
      private service: SubClusterService,
      private toastr: ToastrService,
      private wordCloudService: WordCloudService) {
  }

  setGroupLabels(labels: any[]): void {
    if (labels && labels.length) {
      labels.forEach((item: any) => {
        this.groupLabels[item.group] = item.label;
      });
    }
  }


  getSliderTickInterval(): number | 'auto' {
    return 0;
  }

  prioritizeKeywords(keywords: any[]): void {
    let excludeWords: string[] = [];
    let shortListedWords: string[] = [];
    keywords.forEach((item: any) => {
      if (item.deletedInSubCluster) {
        excludeWords.push(item.name);
      } else if (item.shortListedInSubCluster) {
        shortListedWords.push(item.name);
      }
    });
    this.excludeWords = excludeWords;
    this.shortListedWords = shortListedWords;
  }

  async fetchKeyWords(): Promise<void> {
    const keywords: any[] = await this.service.getSubClusterKeywords();
    this.subClusterKeywords = keywords;
    this.subClusterKeywords.forEach((item: any) => {
      item.group = item.subGroup;
    });
    this.prioritizeKeywords(keywords);
  }

  public saveLabel(label: any): void {
    // This is duplicate, original implementation is in modal.component, remove it while refactoring.
    /*this.messageService.saveLabel({label: label, group: this.selectedGroup}).then((resp: any) => {
      this.groupLabels[this.selectedGroup] = label;
    }).catch((ex) => console.error(ex));*/
  }

  reloadMainGraphOnSaveWords(): void {
    this.showLoader = true;
    Promise.all([
      this.messageService.getLabels(workspace),
      this.fetchKeyWords()
    ]).then((result: any[]) => {
      this.setGroupLabels(result[0]);
      this.showSubClusterDiagram(this.subClusterKeywords);
      this.showLoader = false;
    });
  }

  expandWordsDiv(targetSVG: any, d: any, links: any[], bBoxList?: any): void {
    const modalRef: NgbModalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.group = d.group;
    modalRef.componentInstance.groupColor = this.color[d.group];
    modalRef.componentInstance.groupLabel = this.groupLabels[d.group] || 'Group - ' + d.group;
    modalRef.componentInstance.nodeGroups = this.nodeGroups;
    modalRef.componentInstance.subCluster = true;
    modalRef.componentInstance.activeTabString = this.clickBoxButtonClicked;
    modalRef.componentInstance.selectedWords = this.clickedWords;
    modalRef.componentInstance.parentWordsOccurrence = this.wordsOccurrence;
    modalRef.result.then((rs: any) => {
      if (rs === 'saveWords') {
        this.reloadMainGraphOnSaveWords();
      }
    }).catch((err: any) => {
      this.showLoader = true;
      Promise.all([
        this.messageService.getLabels(workspace),
        this.service.getSubClusterKeywords()
      ]).then((resp: any[]) => {
        this.setGroupLabels(resp[0]);
        this.removeClickBoxContent();
        this.subClusterKeywords = resp[1];
        this.subClusterKeywords.forEach((item: any) => {
          item.group = item.subGroup;
        });
        //this.appendNodeLabels(targetSVG, '.node-' + d.id, bBoxList, links);
        this.showLoader = false;
      }).catch((ex) => {
        this.showLoader = false;
        this.toastr.error('Error updating label.', 'Error', {timeOut: 3000});
      });
    });
  }

  private appendNodeLabels(targetSVG: any, selector: any, bBoxList: any, links: any[]): void {
    const _this = this;
    targetSVG.select(selector).select('.node-label').remove();
    const label: any = targetSVG.select(selector).append('g').attr('class', 'node-label');
    label.append('text')
        .attr('class', 'label')
        .text((d: any) => this.groupLabels[d.group] || 'Group - ' + d.group)
        .attr('transform', (d: any) => 'translate(' + (bBoxList[d.group].x - 10)
            + ',' + (bBoxList[d.group].y - 20) + ')');
    label.append('text')
        .attr('class', 'fa fa-unlock add-label')
        .text('\uf09c') // Unlock icon
        .attr('transform', (d: any) => 'translate(' + (bBoxList[d.group].x + bBoxList[d.group].width - 100)
            + ',' + (bBoxList[d.group].y - 20) + ')')
        .on('click', function (d: any) {
          if (!_this.lockedGroups[d.group]) {
            _this.lockedGroups[d.group] = 1;
            d3.select(this).classed('fa fa-unlock add-label', false);
            d3.select(this).classed('fa fa-lock add-label', true).text('\uf023'); // Lock icon
            _this.toastr.success('Sub-cluster selected', 'Success', {timeOut: 3000});
          } else {
            delete _this.lockedGroups[d.group];
            d3.select(this).classed('fa fa-lock add-label', false);
            d3.select(this).classed('fa fa-unlock add-label', true).text('\uf09c'); // Unlock icon
            _this.toastr.success('Sub-cluster unselected', 'Success', {timeOut: 3000});
          }
        });
    /*label.append('text')
        .attr('class', 'icon-fullscreen add-label')
        .text('\uf0b2')
        .attr('transform', (d: any) => 'translate(' + (bBoxList[d.group].x + bBoxList[d.group].width - 40)
            + ',' + (bBoxList[d.group].y - 20) + ')')
        .on('click', (d: any) => {
          this.expandWordsDiv(targetSVG, d, links, bBoxList);
        });*/
  }

  private adjustWordCloudBGDimensions(targetSVG: any, bBoxList: any, transform: boolean): void {
    const rectNode: any = targetSVG.selectAll('.rect-node')
        .attr('width', (d: any) => bBoxList[d.group].width + 40)
        .attr('height', (d: any) => bBoxList[d.group].height + 20)
        .attr('rx', (d: any) => ((bBoxList[d.group].height + 20) * 15) / 100)
        .attr('ry', (d: any) => ((bBoxList[d.group].height + 20) * 15) / 100);
    if (transform) {
      rectNode.attr('transform', (d: any) => 'translate(' + (bBoxList[d.group].x - 20)
          + ',' + (bBoxList[d.group].y - 10) + ')');
    }
  }

  private resetWordsSelection(targetSVG: any): void {
    targetSVG.selectAll('.highlighted-text')
        .classed('highlighted-text', false)
        .style('fill', (d: any) => {
          return this.color(this.selectedWords[d.text].group);
        });
    this.selectedWords = {};
  }

  private placeWords(targetSVG: any, selector: any, node: any, words: any[], bBoxList: any, links: any[]): any {
    targetSVG.select(selector).select('.wordcloud').remove();
    const _this = this;
    const wordCloud: any = targetSVG.select(selector)
        .append('g').attr('class', 'wordcloud');
    wordCloud.selectAll('text')
        .data(words)
        .enter().append('text')
        .attr('class', 'wc-txt')
        .attr('text-anchor', 'middle')
        .style('font-size', (d: any) => d.rank)
        .style('fill', this.color(node.group))
        .text((d: any) => d.text)
        .attr('transform', (d: any) => {
          return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
        })
        .on('mouseover', function (d: any) { // mouseover event
          if (!_this.selectedWords[d.text] && _this.selectedNodes.includes(node.id)) {
            d3.select(this).classed('highlight-text', true);
            d3.select(this).style('fill', '#333333');
          }
        })
        .on('mouseout', function (d: any) {
          if (!_this.selectedWords[d.text] && _this.selectedNodes.includes(node.id)) {
            d3.select(this).classed('highlight-text', false);
            d3.select(this).style('fill', _this.color(node.group));
          }
        })
        .on('click', function (d: any) {
          if (_this.selectedNodes.includes(node.id)) {
            _this.resetWordsSelection(targetSVG);
            _this.selectedWords[d.text] = {group: node.group};
            d3.select(this).classed('highlighted-text', true);
            d3.select(this).style('fill', '#333333');
            _this.groupLabel = _this.groupLabels[_this.selectedGroup] || 'Group - ' + _this.selectedGroup;
            _this.clickedWords = [d.text];
            _this.highlightCoOccurredWordNodes(d.text, node.group, targetSVG, links);
          }
        });
    wordCloud.each(function (d: any) {
      bBoxList[d.group] = this.getBBox();
    });
  }

  private highlightCoOccurredWordNodes(text: string, group: number, targetSVG: any, links: any[]): void {
    const textGroupNode: any = this.wordCloudNodesMap[group];
    const linkedWords: string[] = this.nodeLinksMap[text] || [];
    linkedWords.push(text);
    const linkedGroups: number[] = [];
    linkedWords.forEach((word: string) => {
      const wordData: any = this.nodeTextMap[word];
      if (wordData) {
        const wordGroupNode: any = this.wordCloudNodesMap[wordData.subGroup];
        if (wordGroupNode && !linkedGroups.includes(wordGroupNode.id)) {
          linkedGroups.push(wordGroupNode.id);
        }
      }
    });
    const groupLinks: any[] = [];
    if (links) {
      links.forEach((link: any, index: number) => {
        if (link.target.id === textGroupNode.id && linkedGroups.includes(link.source.id)) {
          groupLinks.push(index);
        } else if (link.source.id === textGroupNode.id && linkedGroups.includes(link.target.id)) {
          groupLinks.push(index);
        }
      });
    }
    this.highlightNodes(targetSVG, linkedGroups);
    this.highlightLinks(targetSVG, groupLinks);
  }

  private appendRect(targetSVG: any, selector: any, dimensions: any, datum: any): void {
    targetSVG.select(selector).append('rect')
        .datum(datum)
        .attr('class', 'rect-node')
        .attr('fill', '#fcfcfc')
        .attr('stroke', '#616161')
        .attr('stroke-width', 0.5)
        .attr('width', dimensions.rectWidth)
        .attr('height', dimensions.rectHeight);
  }

  private fillWordCloudInNodes(targetSVG: any, nodes: any[], sizeFactor: number, device: string, links: any[]): any {
    const bBoxList: any = {};

    nodes.forEach((node: any) => {
      const wordCloudData: any = this.messageService.getWordCloudData(node.group, sizeFactor, device,
          {topKeyWords: true, excludeWords: this.excludeWords, shortListedWords: this.shortListedWords},
          this.nodeGroups, this.slideLimit);

      // Append rectangular background for word-cloud.
      this.appendRect(targetSVG, '.node-' + node.id, wordCloudData, node);

      // Append word cloud in each node
      this.wordCloudService.create(wordCloudData.data, {
        width: wordCloudData.rectWidth,
        height: wordCloudData.rectHeight,
        padding: 10
      }, (words: any[]) => {
        this.placeWords(targetSVG, '.node-' + node.id, node, words, bBoxList, links);
      });
    });
    return bBoxList;
  }

  private highlightLinkConnections(targetSVG: any, index: number, links: any[]): void {
    if (!this.selectedNodes.includes(links[index].source.id)) {
      this.selectedNodes.push(links[index].source.id);
    }
    if (!this.selectedNodes.includes(links[index].target.id)) {
      this.selectedNodes.push(links[index].target.id);
    }
    this.highlightNodes(targetSVG, [links[index].source.id, links[index].target.id]);
    this.highlightLinks(targetSVG, [index]);
  }

  private createLinks(targetSVG: any, parentElem: any, links: any[]): any {
    const _this = this;
    const link: any = parentElem.append('g')
        .attr('class', 'links')
        .selectAll('path')
        .data(links)
        .enter().append('svg:path')
        .attr('class', 'link')
        .style('fill', 'none')
        .attr('stroke', '#b7b5b5')
        .attr('stroke-width', (d: any) => Math.sqrt(d.value))
        .on('mouseover', function (d: any, i: number) {
          const elem: any = d3.select(this).style('cursor', 'pointer');
          if (!_this.selectedLinks.includes(i)) {
            elem.attr('stroke', '#71A365');
          }
        })
        .on('mouseout', function (d: any, i: number) {
          const elem: any = d3.select(this).style('cursor', 'normal');
          if (!_this.selectedLinks.includes(i)) {
            elem.attr('stroke', '#b7b5b5');
          }
        })
        .on('click', (d: any, i: number) => {
          this.highlightLinkConnections(targetSVG, i, links);
        });
    link.append('title').text((d: any) => d.value);
    return link;
  }

  private createNodes(targetSVG: any, parentElem: any, nodes: any[], links: any[]): any {
    const _this = this;
    const node: any = parentElem.append('g')
        .attr('class', 'nodes')
        .selectAll('g')
        .data(nodes)
        .enter().append('g')
        .attr('class', (d: any) => {
          return 'node node-' + d.id;
        })
        .on('mouseover', function (d: any) {
          const elem: any = d3.select(this).style('cursor', 'pointer');
          if (!_this.selectedNodes.includes(d.id)) {
            elem.select('rect').attr('stroke-width', 5);
          }
        })
        .on('mouseout', function (d: any) {
          const elem: any = d3.select(this).style('cursor', 'normal');
          if (!_this.selectedNodes.includes(d.id)) {
            elem.select('rect').attr('stroke-width', 0.5);
          }
        })
        .on('click', function (d: any) {
          _this.clickNode(d, this, targetSVG, nodes, links, false);
        });
    node.append('title').text((d: any) => this.nodeGroups[d.group].length + ' words');
    return node;
  }

  private highlightNodes(targetSVG: any, nodesToBeHighlighted: number[]): void {
    targetSVG.selectAll('.node')
        .attr('opacity', (d: any) => {
          return nodesToBeHighlighted.includes(d.id) ? 1 : 0.3;
        });
    targetSVG.selectAll('.rect-node')
        .attr('stroke-width', (d: any) => {
          return this.selectedNodes.includes(d.id) ? 5 : 0.5;
        });
  }

  private highlightLinks(targetSVG: any, linksToBeHighlighted: number[]): void {
    this.selectedLinks = linksToBeHighlighted;
    targetSVG.selectAll('.link')
        .attr('stroke', (d: any, i: number) => {
          return linksToBeHighlighted.includes(i) ? '#71A365' : '#b7b5b5';
        })
        .attr('opacity', (d: any, i: number) => {
          return linksToBeHighlighted.includes(i) ? 1 : 0.3;
        });
  }

  private highlightNodeConnections(targetSVG: any, id: number, links: any[]): void {
    let nodesToBeHighlighted: number[] = [id];
    let linksToBeHighlighted: number[] = [];
    links.forEach((link: any, index: number) => {
      if (link.target.id === id) {
        if (!nodesToBeHighlighted.includes(link.source.id)) {
          nodesToBeHighlighted.push(link.source.id);
        }
        linksToBeHighlighted.push(index);
      } else if (link.source.id === id) {
        if (!nodesToBeHighlighted.includes(link.target.id)) {
          nodesToBeHighlighted.push(link.target.id);
        }
        linksToBeHighlighted.push(index);
      }
    });
    this.selectedNodes = [id];
    this.highlightNodes(targetSVG, nodesToBeHighlighted);
    this.highlightLinks(targetSVG, linksToBeHighlighted);
  }

  private removeClickBoxContent(): void {
    this.clickBox.select("div").remove();
  }

  private clickNode(d: any, elemRef: any, targetSVG: any, nodes: any[], links: any[], addToSelectedGroups?: boolean): void {
    const _this = this;
    this.removeClickBoxContent();
    //const _this = this;
    this.clickBox.transition()
        .duration(100)
        .style('opacity', 1)

    this.clickBox.html(this.createClickBoxHtml(d))
        .style('left', (d3.event.pageX) + 'px')
        .style('top', (d3.event.pageY + 10) + 'px');

    this.selectedNodes.forEach((id: any) => {
      targetSVG.select('.node-' + id).on('click', function (d: any) {
        _this.clickNode(d, this, targetSVG, nodes, links);
      });
    });
    this.selectedNodes.length = 0;
    this.highlightNodeConnections(targetSVG, d.id, links);
    this.selectedGroup = d.group;
    if (!this.selectedGroups.includes(d.group) && addToSelectedGroups) {
      this.selectedGroups.push(d.group);
    }
    //check
    this.wordsOccurrence = this.messageService.getWordCloudData(d.group, 1, null,
        {topKeyWords: false}, this.nodeGroups, this.slideLimit).data;
    const words: string[] = this.wordsOccurrence.map((d: any) => d.text);
    this.groupLabel = this.groupLabels[this.selectedGroup] || 'Group - ' + this.selectedGroup;
    this.clickedWords = words;
    console.log("before click node")
    d3.select('#highlevelstats-clickbox1').on('click', ()  =>{

      console.log("in click  stats")
      this.clickBoxButtonClicked = "statistics";
      this.expandWordsDiv(targetSVG, d, links);

      //this.clickname(d.date,d.id);
    });
    /*d3.select('#subcluster-clickbox').on('click', ()  =>{
      console.log('subcluster');
      //this.clickname(d.date,d.id);
      console.log(d.group);
      this.getSubClusters(d.group);
      //switch tab
      this.isSubClusterTabActive = true;
      //this.showSubClusterView = true;
    });*/
    d3.select('#cloudview-clickbox1').on('click', ()  =>{
      this.clickBoxButtonClicked = "home";
      this.expandWordsDiv(targetSVG, d, links);

      //this.clickname(d.date,d.id);
    });
    d3.select('#listview-clickbox1').on('click', ()  =>{
      this.clickBoxButtonClicked = "menu1";
      this.expandWordsDiv(targetSVG, d, links);
      //this.clickname(d.date,d.id);
    });
    d3.select('#cross-click-box').on('click', ()  =>{
      //this.clickBoxButtonClicked = "menu1";
      //this.expandWordsDiv(targetSVG, d, links);
      //this.clickname(d.date,d.id);
      this.removeClickBoxContent();
    });
    d3.select(elemRef).on('click', null);

  }

  private createClickBoxHtml(d): any {
    let div = '<div class="tooltip-wrapper"> ' +
        '<div class="arrow-tooltip" style="background: ' + this.color(d.group) + '" ></div>' +
        '   <div style="display: flex;justify-content: flex-start;align-items: center;" class="graph-detial-tooltip-header"> ' +
        '   <div class="tool-tip-ctgry-name" style="display: flex;flex-direction: column;width: 90%;text-align: left;"> ' +
        '</div> ' +
        '</div> ' +

        '<div class="row-flex group-row" style="background: ' + this.color(d.group) + '"  > ' +
        '<span>' +
        'Group Name ' + d.group + '<br>' +
        '</span>' +
        '<div>' +
        '<strong>Words : </strong>' + this.nodeGroups[d.group].length +
        '</div>' +
        '</div> ' +
        '<div class="row-flex"> ' +
        '</div> ' +
        '<div class="tooltip-footer"> ' +
        //              '<a style="border: 1px solid #ddd;border-radius: 5px;font-size: 12px;padding: 5px 10px;" (click)="'+ this.clickname(d.date,d.id)+'" >Influencer Map</a> '+
        '<button  id="highlevelstats-clickbox1">' +
        '   <i class="fa fa-bar-chart  " aria-hidden="true"></i>  Statistics' +
        '<span class="btn-tooltip"> View sentiment or device breakup </span>' +
        '</button> ' +
        /*'<button  id="subcluster-clickbox">' +
        '   <i class="fa fa-search-plus" aria-hidden="true"></i> Subclusters' +
        '<span class="btn-tooltip"> View further breakdown of ' + d.group+' </span>' +
        '</button> ' +*/
        '<button  id="listview-clickbox1">' +
        '   <i class="fa fa-list" aria-hidden="true"></i>  List view' +
        '<span class="btn-tooltip"> View prominent phrase/messages </span>' +
        '</button> ' +
        '<button  id="cloudview-clickbox1">' +
        '   <i class="fa fa-cloud" aria-hidden="true"></i>  Cloud view' +
        '<span class="btn-tooltip"> View exact position coordinates </span>' +
        '</button> ' +
        '</div> ' +
        '<span id="cross-click-box">' +
        '<i class="fa fa-times" aria-hidden="true"></i>' +
        '</span>' +
        '</div> ';
    return div;
  }

  public wordSelectedInLeftComponent(word: any): void {
    this.clickedWords = [word];
  }

  private createBackground(targetSVG: any, width: number, height: number, nodes: any[], links: any[]): void {
    const _this = this;
    // For background color and other functionality.
    targetSVG.append('g').append('rect')
        .attr('class', 'graph-transparent')
        .attr('width', '100%')
        .attr('height', height)
        .on('mouseover', function (d: any) {
          d3.select(this).style('cursor', 'all-scroll');
        })
        .on('click', () => {
          // targetSVG.selectAll('.fa-lock').attr('fa-lock', null).classed('fa fa-unlock add-label', true).text('\uf09c');
          targetSVG.selectAll('.node').attr('opacity', 1);
          targetSVG.selectAll('.rect-node').attr('stroke-width', 0.5);
          targetSVG.selectAll('.link').attr('stroke', '#b7b5b5').attr('opacity', 1);

          this.selectedNodes.forEach((id: any) => {
            targetSVG.select('.node-' + id).on('click', function (d: any) {
              _this.clickNode(d, this, targetSVG, nodes, links);
            });
          });
          this.selectedNodes = [];
          this.selectedLinks = [];
          this.selectedGroups = [];
          _this.resetWordsSelection(targetSVG);
          this.clickBox.transition()
              .duration(100)
              .style('opacity', 0);
        });
  }

  buildGraph(targetSVG: any, dimensions: any, nodes: any[], links: any[]): void {
    // Create force simulation.
    const simulation: any = this.ellipseForceService.createForceSimulation({length: 1000, strength: 0.15},
        -6000, {padding: 30, ir: 0.5, or: 700},
        {width: dimensions.width / 2, height: dimensions.height / 4});

    // For background color and other functionality.
    this.createBackground(targetSVG, dimensions.width, dimensions.height, nodes, links);

    // add encompassing group for the zoom
    const g = targetSVG.append('g').attr('class', 'everything');

    // Creating node links and defining their behaviours.
    const link: any = this.createLinks(targetSVG, g, links);
    // Creating nodes and defining their behaviours.
    const node: any = this.createNodes(targetSVG, g, nodes, links);

    // Fill word-cloud in each node and return their html bBox dimensions.
    const bBoxList: any = this.fillWordCloudInNodes(targetSVG, nodes, 1, null, links);
    // Adjust dimensions of word-cloud's background rect.
    this.adjustWordCloudBGDimensions(targetSVG, bBoxList, true);
    // Append node labels based on node dimensions.
    nodes.forEach((d: any) => {
      this.appendNodeLabels(targetSVG, '.node-' + d.id, bBoxList, links);
    });
    //Add tooltip box
    this.clickBox = d3.select('.click-box-subcluster');
    // Define tick behaviour.
    simulation.nodes(nodes).on('tick', () => {
      this.ellipseForceService.tickListener(node, link);
    });
    // Define link force behaviour.
    simulation.force('link').links(links);

    // Add drag capabilities.
    this.ellipseForceService.createDraggableNodes(node, simulation);

    // Add zoom capabilities.
    this.ellipseForceService.createZoomableGraph(targetSVG, [1 / 4, 4], () => {
      g.attr('transform', d3.event.transform);
    });
  }

  initSvg(width: number, height: number): any {
    const svg: any = d3.select('.sub-cluster-svg svg');
    svg.append('g');
    svg.selectAll('*').remove();
    svg.attr('width', '100%').attr('height', height);
    return svg;
  }

  showSubClusterDiagram(subClusters: any[]): void {
    this.showLoader = true;
    subClusters.forEach((item: any) => {
      item.group = item.subGroup;
    });
    const clusterData: any = this.messageService.processClusterData({
      Cluster: subClusters, cooccurence: this.rawLinks
    });
    this.nodes = clusterData.nodes;
    this.links = clusterData.links;
    this.wordCloudNodesMap = clusterData.wordCloudNodesMap;
    this.nodeGroups = clusterData.nodeGroups;
    this.nodeLinksMap = clusterData.nodeLinksMap;
    const width: any = +window.innerWidth;
    const height: any = 600;
    const svg: any = this.initSvg(width, height);
    this.buildGraph(svg, {width: width, height: height}, this.nodes, this.links);
    this.showLoader = false;
  }

  applySubClustering(): void {
    if (Object.keys(this.lockedGroups).length) {
      let keywordsReq: any[] = [];
      this.subClusterKeywords.forEach((keyword: any) => {
        let reqObj: any = {
          name: keyword.name,
          group: keyword.group,
          deleted: keyword.deletedInSubCluster,
          shortListed: keyword.shortListedInSubCluster
        };
        if (!this.lockedGroups[keyword.group]) {
          reqObj.deleted = true;
          reqObj.shortListed = false;
        }
        keywordsReq.push(reqObj);
      });
      this.service.applySubClustering({
        keywordsReq: keywordsReq,
        lockedGroups: Object.keys(this.lockedGroups),
        parentGroup: this.parentGroup
      }).then((resp: any) => {
        this.updateMainGraphEvent.emit();
      }).catch((err: any) => console.error(err));
    }
  }

  cancelSubClustering(): void {
    this.service.cancelSubClustering(Object.keys(this.nodeGroups)).then((resp: any) => {
      this.updateMainGraphEvent.emit();
    }).catch((err: any) => console.error(err));
  }

  ngOnInit(): void {
    this.prioritizeKeywords(this.subClusterKeywords);
    this.showSubClusterDiagram(this.subClusterKeywords);
  }

  /* ngOnChanges(): void {
     //this.showLoader = true;
     console.log('entered in changes');
     console.log(this.parentGroup);
     this.prioritizeKeywords(this.subClusterKeywords);
     this.showSubClusterDiagram(this.subClusterKeywords);
   }*/

}
