import {Component, OnInit} from "@angular/core";
import {HeaderComponent} from "../reusable/header/header.component";
import {SharedServiceService} from "../services/shared-service.service";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {TwitterService} from "./twitter.service";
import {TopicTrendComponent} from "../topic-trend/topic-trend.component";
import {response} from "express";
import {error} from "util";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
    selector: "app-twitter",
    templateUrl: "./twitter.component.html",
    styleUrls: ["./twitter.component.css"]
})
export class TwitterComponent implements OnInit {

    public dates: any;
    public activepage1=false;
    public activepage2=false;
    public activepage3=false;
    public activepage4=false;
    public activepage5=false;
    public activepage6=false;
    public activepage7=false;
    public checkgraphlength:any=0
    public checkgraphlengthkeys:any=0
    public activesection:any="twitter_trends"
    public lineChartOption: any = {};
    public hashChart1: any = {};
    public hashChart2: any = {};
    public activeChartAll: any = {};
    public activeChart1: any = {};
    public activeChart2: any = {};
    public hashStackedBarChart: any = {};
    public showLoader = false;
    public insightsSecond = true;
    public insightsFirst = true;
    public showLoaderHash1 = true;
    public showLoaderHash2 = true;
    public hashTagBarGraphObj: any = {};
    public activeUsersBarGraphObj: any = {};
    public tableresponse: any={};
    public tableres: any = {};
    public graphresp : any = {};
    public graphresp1 : any = {};
    public transcript: any ={};
    public val = false ;

    public showLoaderActiveUser = true;
    public selectedButton: string = "";
    public selectedButton2:string= "";
    public showFilters: boolean = false;

    public isShown : boolean = true;
    public isHidden : boolean = true;

    constructor(public myservice: SharedServiceService,
                public twitterService: TwitterService,
                public header: HeaderComponent,
                public echartGraphs: EchartGraphsService,
                public dateChangedService: HeaderDateChangedService,
                private workspaceService: WorkspaceService) {
        dateChangedService.dateChanged$.subscribe(
            mission => {
                const dates: any[] = this.header.getDateFromLS();
                this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
                    this.showSiteData();
                }).catch((error) => {
                    console.error(error);
                });
            });
    }


    public showSiteData() {
        this.showLoader = true;
        this.dates = this.header.getDateFromLS();
        this.insights();
        this.makeStackedLineChart();
        this.hashTagsFromTweets();
        this.activeUsers();
    }

    public hashTagsFromTweets() {
        this.twitterService.getHashTags(this.dates).then((response: any) => {
            this.hashTagBarGraphObj = response;
            this.hashTagBarGraph2("Abbott");
            this.hashTagBarGraph("Dexcom");
            this.showLoaderHash1 = false;
            this.showLoaderHash2 = false;
        }).catch((error: any) => {
            throw error;
        });
    }
    public activeclass(activename ){
        console.log("inside active class")
        console.log(activename)
        if (activename === "activepage1")
        {
            this.activepage1=true;
            this.activepage2=false;
            this.activepage3=false;
            this.activepage4=false;
            this.activepage5=false;
            this.activepage6=false;
            this.activepage7=false;
        }
        else if (activename === "activepage2")
        {
            this.activepage2=true;
            this.activepage1=false;
            this.activepage3=false;
            this.activepage4=false;
            this.activepage5=false;
            this.activepage6=false;
            this.activepage7=false;
        }
        else if (activename==="activepage3")
        {
            this.activepage3=true;
            this.activepage2=false;
            this.activepage1=false;
            this.activepage4=false;
            this.activepage5=false;
            this.activepage6=false;
            this.activepage7=false;
        }
        else if (activename==="activepage4")
        {
            this.activepage4=true;
            this.activepage2=false;
            this.activepage3=false;
            this.activepage1=false;
            this.activepage5=false;
            this.activepage6=false;
            this.activepage7=false;
        }
        else if (activename==="activepage5")
        {
            this.activepage5=true;
            this.activepage2=false;
            this.activepage3=false;
            this.activepage4=false;
            this.activepage1=false;
            this.activepage6=false;
            this.activepage7=false;
        }  else if (activename==="activepage6")
        {
            this.activepage6=true;
            this.activepage2=false;
            this.activepage3=false;
            this.activepage4=false;
            this.activepage5=false;
            this.activepage1=false;
            this.activepage7=false;
        }
        else if (activename==="activepage7")
        {
            this.activepage7=true;
            this.activepage2=false;
            this.activepage3=false;
            this.activepage4=false;
            this.activepage5=false;
            this.activepage6=false;
            this.activepage1=false;
        }



    }

    public activeUsers() {
        this.twitterService.getUsers(this.dates).then((response: any) => {
            this.activeUsersBarGraphObj = response;
            this.showLoaderActiveUser = false;
            this.activeUserBarGraph("All active users");
            this.activeUserBarGraph("Abbott");
            this.activeUserBarGraph("Dexcom");
            this.hashStackedBarChart = this.echartGraphs.twitterStackedBarChart();
        }).catch((error: any) => {
            throw error;
        });
    }

    public activeUserBarGraph(title: string) {
        const color = this.myservice.siteColor;
        if (title === "Abbott") {
            this.activeChart1 = this.echartGraphs.homeBarGraph(
                this.activeUsersBarGraphObj.active_user[title][0], title,
                this.activeUsersBarGraphObj.active_user[title][1], color[title], "");
        } else if (title === "All active users") {
            this.activeChartAll = this.echartGraphs.homeBarGraph(
                this.activeUsersBarGraphObj.active_user.Twitter_all[0], title,
                this.activeUsersBarGraphObj.active_user.Twitter_all[1], color[title], "");
        } else {
            this.activeChart2 = this.echartGraphs.homeBarGraph(
                this.activeUsersBarGraphObj.active_user[title][0], title,
                this.activeUsersBarGraphObj.active_user[title][1], color[title], "");
        }
    }

    public hashTagBarGraph(title: string) {
        const color = this.myservice.siteColor;
        this.selectedButton2=title
        this.hashChart2 = this.echartGraphs.homeBarGraph(
            this.hashTagBarGraphObj.Hashtag[title].label, title,
            this.hashTagBarGraphObj.Hashtag[title].freq, color[title], "");

    }
    public hashTagBarGraph2(title: string) {
        const color = this.myservice.siteColor;
        this.selectedButton2 = title ;
        this.hashChart1 = this.echartGraphs.homeBarGraph(
            this.hashTagBarGraphObj.Hashtag[title].label, title,
            this.hashTagBarGraphObj.Hashtag[title].freq, color[title], "");

    }

    showTable1 = false;
    showTable2 = false;
    showgraph1=true;
    showgraph2=false
    networkgraph1= false
    networkgraph2 =false
    showleftlabel=false
    showLoaderInner = false;
    public  influencialpost() {
        /* console.log('entered');*/
        this.showLoaderInner = true;
        const color = this.myservice.siteColor;
        this.twitterService.getInfluentialPost(this.dates).then((response:any ) => {
            this.showTable1 = true;
            this.showTable2 = false;
            this.showLoaderInner = false;
            //console.log("in twitter component")
            //console.log(response);
            this.tableresponse = response;
        }).catch((error:any) =>{
            throw error;
        });
    }
    public  influencialuser() {
        /*console.log('entered');*/
        this.showLoaderInner = true;
        const color = this.myservice.siteColor;
        this.twitterService.getInfluentialUser(this.dates).then((response:any ) => {

            this.showLoaderInner = false;
            this.showTable2 = true;
            this.showTable1 = false;
            this.tableres = response;
            //console.log(response)
        }).catch((error:any) =>{
            throw error;
        });
    }
    public secNetworkGraph(){

        this.isShown = false;
        this.isHidden = true;
        this.showgraph1=false;
        this.showgraph2=true
        this.networkgraph1= true;
        this.networkgraph2 =false
        this.showLoaderInner = false;
        this.showFilters=true
        this.val=true
        this.checkgraphlength=2

    }
    public  networkgraph() {

        /*console.log('entered');*/

        this.isShown = false;
        this.isHidden = true;
        this.showgraph1=false;
        this.showgraph2=true
        //const color = this.myservice.siteColor;
        this.twitterService.getNetworkGraph(this.dates).then((response:any ) => {
            this.networkgraph1= true;
            this.networkgraph2 =false
            this.showLoaderInner = false;
            this.showFilters=true
            this.graphresp = response;
            this.val=true
            this.checkgraphlength=this.checkgraphlength+1
            console.log("inside twitter component in netork graph");
            console.log(this.graphresp);
        }).catch((error:any) => {
            throw error;
        });
    }
    public secNetworkGraphkeys(){

        this.isHidden = false;
        this.isShown = true;
        this.showgraph1=true;
        this.showgraph2=false
        this.showleftlabel=true;
        this.checkgraphlengthkeys=2


    }
    public  networkgraphkeys() {
        /*console.log('entered');*/

        this.isHidden = false;
        this.isShown = true;
        this.showgraph1=true;
        this.showgraph2=false
        this.showleftlabel=true;
        this.networkgraph1= false;
        this.networkgraph2 =true
        this.showLoaderInner = false;
        this.showFilters=true;
        this.val=false


        //const color = this.myservice.siteColor;
        this.twitterService.getNetworkGraph2(this.dates).then((response:any ) => {
            this.networkgraph1= false;
            this.networkgraph2 =true
            this.showLoaderInner = false;
            this.showFilters=true;
            this.val=false
            this.checkgraphlengthkeys=this.checkgraphlength+1
            this.graphresp1 = response;
            console.log("inside twitter component in netork graph");
            console.log(this.graphresp1);
        }).catch((error:any) => {
            throw error;
        });
    }
    public  scattergraph() {
        /*console.log('entered');*/

        //const color = this.myservice.siteColor;
        this.twitterService.getscatterGraph(this.dates).then((response:any ) => {
            this.showLoaderInner = false;
            this.showFilters=true;
            this.val=false
            this.graphresp1 = response;
            console.log("inside twitter component in netork graph");
            console.log(this.graphresp1);
        }).catch((error:any) => {
            throw error;
        });
    }
    public  userTranscript() {
        /*console.log('entered');*/

        //const color = this.myservice.siteColor;
        this.twitterService.getuserTranscript(this.dates).then((response:any ) => {
            this.showLoaderInner = false;
            this.showFilters=true;
            this.val=false
            this.transcript = response;
            console.log("inside twitter component in netork graph");

        }).catch((error:any) => {
            throw error;
        });
    }
    public makeStackedLineChart() {
        this.twitterService.getData(this.dates).then((response: any) => {
            this.showLoader = false;
            this.lineGraph(response);
        }).catch((error: any) => {
            throw error;
        });
    }

    public lineGraph(graphObj: any) {
        const obj = graphObj.twitter_main.Twitter;
        const Abbott = obj.Abbott;
        const Dexcom = obj.Dexcom;
        const Medtronic = obj["Medtronic Minimed"];
        const Roche = obj["Roche Accu-Chek"];
        const xdates = obj["x-axis"];
        const legend = ["Abbott FreeStyle", "Dexcom", "Medtronic Minimed", "Roche Accu-Chek"];
        this.lineChartOption = this.echartGraphs.trendsStackedLineChart(legend, xdates, Abbott, Dexcom, Medtronic, Roche);
    }

    public insights() {
        const insightBoolean = this.myservice.insights(this.dates);
        this.insightsFirst = insightBoolean.insFirst;
        this.insightsSecond = insightBoolean.insSecond;
    }

    ngOnInit() {
        this.showSiteData();
        this.influencialpost();
    }
}
