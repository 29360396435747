/**
 * Insight model class.
 */
export class Insight {
  // tslint:disable-next-line:variable-name
  _id: any;
  userId: any;
  orgId: any;
  coordinates: any;
  topic: string;
  subTopic: string;
  device: string;
  description: string;
  category: string;
  insights: any[];
  mentions: any[];
  createdAt: Date;
  modifiedAt: Date;
  channel: string;

  constructor(data: Insight | {} = {}) {
    Object.assign(this, data);
  }
}
