import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-label',
  templateUrl: './edit-label.component.html',
  styleUrls: ['./edit-label.component.css']
})
export class EditLabelComponent implements OnInit {

  @Input() groupLabel;
  toggleEditName: boolean = false;
  newGroupLabel: string;
  constructor(public activeModal: NgbActiveModal) { }
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();

  editLabelName() {
    this.newGroupLabel = this.groupLabel;
    this.toggleEditName = true;
  }

  public saveLabel(): void {
    this.notifyParent.emit(this.newGroupLabel);
    this.activeModal.close('Cross click');
  }

  ngOnInit() {
  }

}
