import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BarChartComponent implements OnInit {

  data = [
    {'salesperson': 'Bob', 'sales': 33},
    {'salesperson': 'Robin', 'sales': 12},
    {'salesperson': 'Anne', 'sales': 41},
    {'salesperson': 'Mark', 'sales': 16},
    {'salesperson': 'Joe', 'sales': 59},
    {'salesperson': 'Eve', 'sales': 38},
    {'salesperson': 'Karen', 'sales': 21},
    {'salesperson': 'Kirsty', 'sales': 25},
    {'salesperson': 'Chris', 'sales': 30},
    {'salesperson': 'Lisa', 'sales': 47},
    {'salesperson': 'Tom', 'sales': 5},
    {'salesperson': 'Stacy', 'sales': 20},
    {'salesperson': 'Charles', 'sales': 13},
    {'salesperson': 'Mary', 'sales': 29}
  ];

  constructor() {
  }

  drawChart(): void {
    // set the dimensions and margins of the graph
    var margin = {top: 20, right: 20, bottom: 30, left: 40},
        width = 960 - margin.left - margin.right,
        height = 600 - margin.top - margin.bottom;

    // set the ranges
    var y = d3.scaleBand()
        .range([height, 0])
        .padding(0.1);

    var x = d3.scaleLinear()
        .range([0, width]);

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    var svg = d3.select('.barChart')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform',
            'translate(' + margin.left + ',' + margin.top + ')');

    // format the data
    this.data.forEach(function (d: any) {
      d.sales = +d.sales;
    });

    // Scale the range of the data in the domains
    x.domain([
      0, d3.max(this.data, function (d: any) {
        return d.sales;
      })
    ]);
    y.domain(this.data.map(function (d) {
      return d.salesperson;
    }));

    console.log(x.domain());
    console.log(y.domain());

    // append the rectangles for the bar chart
    const bar: any = svg.selectAll('.bar')
        .data(this.data)
        .enter().append('g');

    bar.append('rect')
        .attr('class', 'bar')
        .attr('width', (d: any) => x(d.sales))
        .attr('y', (d: any) => y(d.salesperson))
        .attr('height', y.bandwidth());

    bar.append('text')
        .style('fill', 'yellow')
        .style('font', '12px sans-serif')
        .style('text-anchor', 'end')
        .attr('x', (d: any) => x(d.sales))
        .attr('y', (d: any) => y(d.salesperson) + (y.bandwidth() / 2))
        // .attr("dy", ".35em")
        .text((d: any) => d.sales);

    // add the x Axis
    svg.append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // add the y Axis
    svg.append('g')
        .call(d3.axisLeft(y));
  }

  ngOnInit() {
    this.drawChart();
  }

}
