import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from "@angular/core";
import * as d3 from 'd3';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PieChartComponent implements OnInit, OnChanges {

  defaultValues: any = {
    width: 400,
    height: 400,
    /*legend: {
      rectSize: 25,
      spacing: 6
    },*/
    dataset: [
      {label: 'Assamese', count: 13},
      {label: 'Bengali', count: 83},
      {label: 'Bodo', count: 1.4},
      {label: 'Dogri', count: 2.3},
      {label: 'Gujarati', count: 46},
      {label: 'Hindi', count: 300},
      {label: 'Kannada', count: 38},
      {label: 'Kashmiri', count: 5.5},
      {label: 'Konkani', count: 5},
      {label: 'Maithili', count: 20},
      {label: 'Malayalam', count: 33},
      {label: 'Manipuri', count: 1.5},
      {label: 'Marathi', count: 72},
      {label: 'Nepali', count: 2.9},
      {label: 'Oriya', count: 33},
      {label: 'Punjabi', count: 29},
      {label: 'Sanskrit', count: 0.01},
      {label: 'Santhali', count: 6.5},
      {label: 'Sindhi', count: 2.5},
      {label: 'Tamil', count: 61},
      {label: 'Telugu', count: 74},
      {label: 'Urdu', count: 52}
    ]
  };
  @Input('chartId') public chartId: string;
  @Input('dataset') public dataset: any[];
  @Input('colorCodes') public colorCodes: string[];
  @Input('width') public width: number;
  @Input('height') public height: number;
  @Input('legend') public legend: any;
  @Output() public openMessagesEvent: EventEmitter<any> = new EventEmitter();
  //@Output() public wordClickEvent: EventEmitter<any> = new EventEmitter();

  public color: any = d3.scaleOrdinal(d3.schemeCategory10);

  constructor() {
  }

  private pathAnim(path: any, dir: number, r: number): void {
    switch (dir) {
      case 0: {
        path.transition()
            .duration(500)
            .ease(d3.easeBounce)
            .attr('d', d3.arc()
                .innerRadius(0)
                .outerRadius(r));
        break;
      }

      case 1: {
        path.transition()
            .attr('d', d3.arc()
                .innerRadius(0)
                .outerRadius(r * 1.08));
        break;
      }
    }
  }

  private initChart(): void {

    const chart: any = d3.select('#' + this.chartId);
    chart.append('g');
    chart.selectAll('*').remove(); // Clear existing chart contents.

    for (let key in this.defaultValues) {
      if (!this[key]) {
        this[key] = this.defaultValues[key];
      }
    }
    if (this.colorCodes && this.colorCodes.length) {
      this.color = d3.scaleOrdinal(this.colorCodes);
    }
    this.width = +this.width;
    this.height = +this.height;
  }

  private buildChart(dataset: any[]): void {
    console.log(dataset)
    const _this = this;
    // A circular chart needs a radius.
    const radius = (Math.min(this.width, this.height) / 2) * (92 / 100);

    const svg = d3.select('#' + this.chartId)
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height)
        .append('g')
        .attr('transform', 'translate(' + (100) + ',' + (100) + ')');
        // .attr('transform', 'translate(' + (this.width / 2) + ',' + (this.height / 2) + ')');

    const arc = d3.arc()
        .innerRadius(0) // 0 for pie chart, >0 for donut chart.
        .outerRadius(radius); // size of overall chart

    const pie: any = d3.pie()
        .value((d: any) => d.count)
        .sort(null); // by default, data sorts in descending value.
    // this will mess with our animation so we set it to null

    // define tooltip
    const tooltip: any = d3.select('#' + this.chartId)
        .append('div')
        .attr('class', 'tooltipbox');
    tooltip.append('div').attr('class', 'tooltiplabel');
    tooltip.append('div').attr('class', 'count');
    tooltip.append('div').attr('class', 'percent');

    dataset.forEach(function (d: any) {
      d.count = +d.count;
      d.enabled = true;
    });

    // creating the chart
    let path: any = svg.selectAll('path')
        .data(pie(dataset))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d: any, i: number) => this.color(i))
        .each(function (d: any) {
          // @ts-ignore
          return this._current - d;
        }); // creates a smooth animation for each track

    // mouse event handlers are attached to path so they need to come after its definition
    path.on('mouseover', function (d: any) {
      _this.pathAnim(d3.select(this), 1, radius);
      const total: number = d3.sum(dataset.map((d: any) => (d.enabled) ? d.count : 0));
      const percent: number = Math.round(1000 * d.data.count / total) / 10;
      tooltip.select('.tooltiplabel').html(d.data.label);
      tooltip.select('.count').html(d.data.count);
      tooltip.select('.percent').html(percent + '%');
      tooltip.style('display', 'block');
    });

    path.on('mouseout', function () {
      _this.pathAnim(d3.select(this), 0, radius);
      tooltip.style('display', 'none');
    });

    path.on('mousemove', function(d: any) {
      /*tooltip.style('top', (d3.event.layerY + 10) + 'px')
          .style('left', (d3.event.layerX + 10) + 'px');*/
      const mouse: any = d3.mouse(this);


      tooltip.style('top', (mouse[1] + 0) + 'px')
          .style('left', (mouse[0] + 70) + 'px');
    });

    path.on('click', (d) => {
     this.openMessagesEvent.emit({type:'device', name:d.data.label});

    });

    /*// define legend
    const legend: any = svg.selectAll('.legend')
        .data(this.color.domain())
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d: any, i: number) => {
          const height = this.legend.rectSize + this.legend.spacing;
          const offset = height * this.color.domain().length / 2;
          const horz = 18 * this.legend.rectSize;
          const vert = i * height - offset;
          return 'translate(' + horz + ',' + vert + ')';
        });

    // adding colored squares to legend
    legend.append('rect')
        .attr('width', this.legend.rectSize)
        .attr('height', this.legend.rectSize)
        .attr('class', 'legend-rect')
        .style('fill', this.color)
        .style('stroke', this.color)
        .on('click', function (label) {
          const rect: any = d3.select(this);
          const totalEnabled: number = d3.sum(dataset.map((d: any) => (d.enabled) ? 1 : 0));
          let enabled: boolean = true;

          if (rect.attr('class').includes('disabled')) {
            rect.attr('class', 'legend-rect');
          } else {
            if (totalEnabled < 2) {
              return;
            }
            rect.attr('class', 'legend-rect disabled');
            enabled = false;
          }

          pie.value(function (d) {
            if (d.label === label) {
              d.enabled = enabled;
            }
            return (d.enabled) ? d.count : 0;
          });

          path = path.data(pie(dataset));

          path.transition()
              .duration(750) //
              .attrTween('d', function (d) {
                // @ts-ignore
                const interpolate: any = d3.interpolate(this._current, d);
                // @ts-ignore
                this._current = interpolate(0); // interpolate between current value and the new value of 'd'
                return function (t) {
                  return arc(interpolate(t));
                };
              });
        });

    // adding text to legend
    legend.append('text')
        .attr('x', this.legend.rectSize + this.legend.spacing)
        .attr('y', this.legend.rectSize - this.legend.spacing)
        .text((d: any) => d);*/
  }

  ngOnInit(): void {
    console.log("in chartpie")
    this.initChart();
    this.buildChart(this.dataset);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataset && !changes.dataset.firstChange) {
      this.initChart();
      this.buildChart(this.dataset);
    }
  }

}
