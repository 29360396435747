import {Component, OnInit, Input, Output, EventEmitter,} from '@angular/core';
import {CustomTopicsService} from '../custom-topics.service';
import {CdkDragDrop, CdkDragStart, CdkDragEnd, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {response} from 'express';
import {HeaderComponent} from '../../reusable/header/header.component';
import { SwiperOptions } from 'swiper';
import {del} from "selenium-webdriver/http";

@Component({
  selector: 'app-get-user-choice',
  templateUrl: './get-user-choice.component.html',
  styleUrls: ['./get-user-choice.component.css']
})
export class GetUserChoiceComponent implements OnInit {

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30
  };

  constructor( private customservice: CustomTopicsService,
               public header: HeaderComponent,  ) { }
  @Input() selectedkeywords;
  @Input() groupscompile;
  @Input() newsection_name;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  keywords_list = [];
  fianlgroups = [];
  sortingWord = 0;
  smartGroup=1;;
  freqAscending=1;
  multiDragOn=false;
  isRightClicked : boolean = false;
  grp1 = {'name': 'Group1', data: []};
  grp2 = {'name': 'Group2', data: []};
  grp3 = {'name': 'Group3', data: []};
  grp4 = {'name': 'Group4', data: []};
  grp5 = {'name': 'Group5', data: []};
  grp6 = {'name': 'Group6', data: []};
  grp7 = {'name': 'Group7', data: []};
  grp8 = {'name': 'Group8', data: []};
  grp9 = {'name': 'Group9', data: []};
  wordsonly = [];
  searchWord='';
    grpsArray=[];

  no_of_groups = 1;
  wordsleft = 0;
  rightClickedWord='';
  groupLable: any;
  toggleEditName: boolean;
  saveHeading: boolean = true;
  editName: boolean = true;
  showDragDrop : boolean = true;
  selectgrps=false
  selectworkgrps=[];
  newwords={};
  tempkeywordslist=[];
  sendToGrp=false;
  multipleTransferArray=[];

    rightclickclose(){
        console.log("in right clicked");
        this.isRightClicked=false;
    }
    multiSelect = {
        longPressTime: 200, // in ms unit
        verifyLongPress: 0,
        multiSelect: false,
        verifyDragStarted: false,
        ctrlMode: false,
        multiarray: [],
        firstContainer: null as unknown as HTMLElement,


        selectDrag(el: HTMLElement) {
            console.log("in selectdrag")

            while (!el.classList.contains("cdk-drag")) {
                el = el.parentElement as HTMLElement;
            }
            return el;
        },

        mouseDown(e: Event){
            console.log("in mouseDown")
            console.log(e);
            console.log(e['which'])
            if(e['which']===1) {
                console.log("inside if");


                let target = this.selectDrag(e.target as HTMLElement);
                let ctrlKey = (e as KeyboardEvent).ctrlKey;
                let target2;
                target2 = target.innerHTML;

                let newstring = '';
                target2 = target2.split(' ');

                for (let i = 0; i < target2.length - 2; i++) {
                    if(i!==0 && i!==1){
                        newstring = newstring +' '+target2[i];
                        }
                    else {
                        newstring = newstring + target2[i];
                    }
                }
                console.log(newstring)
                if(this.multiarray.includes(newstring)){
                    console.log("empty")
                }
                else {
                    this.multiarray.push(newstring)
                }


                //console.log(target2)
                if (this.multiSelect) { // If multiSelect is enabled

                    /* The responsibility for removing only the first ".selected" has to be with mouseDown and not with mouseUp.
                       if not you can't add the first one */

                    // Remove
                    let allSelected = document.querySelectorAll(".selected").length;
                    if (allSelected == 1 && target.classList.contains("selected") && (this.ctrlMode ? ctrlKey : true)) { // If only have one ".selected" and it was clicked
                        target.classList.remove("selected", "last");  // remove ".selected" and ".last"
                        this.multiSelect = false; // turns off multiSelect
                    }
                } else { // If multiSelect is disabled
                    // Do this
                    let addSelected = () => {
                        this.multiSelect = true; // enable multiSelect
                        this.firstContainer = target.parentElement as HTMLElement; //saves the container of the first selected element
                        target.classList.add("selected", "last"); // and add ".selected" and ".last" to the current element clicked
                    }

                    // If using CTRL
                    if (ctrlKey) {
                        this.ctrlMode = true;
                        addSelected();
                    }
                    ;

                    // If using longPress
                    this.verifyLongPress = <any>setTimeout(() => { // If there is a LongPress
                        this.ctrlMode = false;
                        addSelected();
                    }, this.longPressTime); // after "longPressTime"(ms)
                }
            }
        },

        mouseUp(e: Event) {
            clearTimeout(this.verifyLongPress); // cancel LongPress

            if (this.multiSelect && !this.verifyDragStarted) { // If multiSelect is enabled AND not start DragStarted
                let target = this.selectDrag(e.target as HTMLElement);
                let allSelected = document.querySelectorAll(".selected");
                let ctrlKey = (e as KeyboardEvent).ctrlKey;
                let last = document.querySelector(".last");

                // If use Shift
                if (last && (e as KeyboardEvent).shiftKey) {
                    // take range informations
                    let containerLast = Array.from(last.parentElement!.children);
                    let lastIndex = containerLast.indexOf(last);
                    let currIndex = containerLast.indexOf(target);
                    let max = Math.max(lastIndex, currIndex);
                    let min = Math.min(lastIndex, currIndex);

                    // toggle .selected in the range
                    for (let i = min; i <= max; i++) {
                        if (i != lastIndex) { // Does not toggle the penult element clicked
                            containerLast[i].classList.toggle("selected");
                        }
                    }

                    // put .last if last clicked was selected at end
                    if (target.classList.contains("selected")) {
                        last && last.classList.remove("last"); // remove .last from penult element clicked
                        target.classList.add("last"); // and add ".last" to the current element
                    }
                }

                //If don't use shift
                else {
                    // To remove from selection
                    /* responsibility to remove from selection assigned to mouseUp */
                    if (target.classList.contains("selected") && allSelected.length > 1 && (this.ctrlMode ? ctrlKey : true)) { // If the clicked element already has ".selected" AND If you have more than 1 (not to remove the first one added)
                        target.classList.remove("selected"); // remove ".selected"
                        target.classList.remove("last"); // remove ".last"
                    }

                    // To add to selection
                    else { // if the clicked element does not have the ".selected"
                        if (this.firstContainer == target.parentElement && (this.ctrlMode ? ctrlKey : true)) { //if the item click is made within the same container
                            last && last.classList.remove("last"); // remove .last from penult element clicked
                            target.classList.add("selected", "last"); // add ".selected" and ".last"
                        }
                        else if (this.ctrlMode ? ctrlKey : true) { // if in different container, and with ctrl (if ctrl)
                            allSelected.forEach((el) => { // remove all selected from last container
                                el.classList.remove("selected", "hide", "last");
                            });
                            this.firstContainer = target.parentElement as HTMLElement; //saves the container of the new selected element
                            target.classList.add("selected", "last"); // and add ".selected" to the element clicked in the new container
                        }
                    }

                }
            }
        },

        dragStarted() {
            this.verifyDragStarted = true; // shows to mouseDown and mouseUp that Drag started
            clearTimeout(this.verifyLongPress); // cancel longPress
        },

        dragEnded() {
            this.verifyDragStarted = false; // show mouseDown and mouseUp that Drag is over
        },

        dropListDropped(e: CdkDragDrop<string[]>) {
            let el = e.item.element.nativeElement;
            if (el.classList.contains("selected")) { // the dragged element was of the "selected" class
                this.multiSelect = false; // disable multiSelect
            }
        },

    }

    // Multi Drag
    multiDrag = {
        dragList: [""], // has the value of the selected items in sequence from listData
        dragListCopy: [""], // a copy of the listData, but with the selected elements marked with "DragErase" to delete later
        dragErase: Symbol("DragErase") as any, // a symbol to have unique value when deleting

        dragStarted(e: CdkDragStart) {
            if (e.source.element.nativeElement.classList.contains("selected")) { // If the dragged element has ".selected"
                //prepare
                let listData = e.source.dropContainer.data; // get list data value
                this.dragList = []; // reset the dragList
                this.dragListCopy = [...listData]; // copy listData into variable
                let DOMdragEl = e.source.element.nativeElement; // dragged element
                let DOMcontainer = Array.from(DOMdragEl.parentElement!.children); // container where all draggable elements are
                let DOMdragElIndex = DOMcontainer.indexOf(DOMdragEl); // index of the dragged element
                let allSelected = document.querySelectorAll(".selected"); // get all the ".selected"

                // Goes through all ".selected"
                allSelected.forEach((eli) => {
                    // get index of current element
                    let CurrDOMelIndexi = DOMcontainer.indexOf(eli);

                    // Add listData of current ".selected" to dragList
                    this.dragList.push(listData[CurrDOMelIndexi]);

                    // Replaces current position in dragListCopy with "DragErase" (to erase exact position later)
                    this.dragListCopy[CurrDOMelIndexi] = this.dragErase;

                    // Put opacity effect (by CSS class ".hide") on elements (after starting Drag)
                    if (DOMdragElIndex !== CurrDOMelIndexi) {
                        eli.classList.add("hide");
                    }
                });

            }
        },

        dropListDropped(e: CdkDragDrop<string[]>) {

            if (e.previousContainer === e.container) { // If in the same container

                let posAdjust = e.previousIndex < e.currentIndex ? 1 : 0; // Adjusts the placement position
                this.dragListCopy.splice(e.currentIndex + posAdjust, 0, ...this.dragList); // put elements in dragListCopy
                this.dragListCopy = this.dragListCopy.filter((el) => (el !== this.dragErase)); // remove the "DragErase" from the list

                // Pass item by item to final list
                for (let i = 0; i < e.container.data.length; i++) {
                    e.container.data[i] = this.dragListCopy[i];
                }

            }

            else { // If in different containers

                // remove the "DragErase" from the list
                this.dragListCopy = this.dragListCopy.filter((el) => (el !== this.dragErase));

                // Pass item by item to initial list
                for (let i = 0; i < e.previousContainer.data.length; i++) {
                    e.previousContainer.data[i] = this.dragListCopy[i];
                }
                for (let i = 0; i < this.dragList.length; i++) {
                    e.previousContainer.data.pop();
                }


                let otherListCopy = [...e.container.data]; // list of new container
                otherListCopy.splice(e.currentIndex, 0, ...this.dragList); // put elements in otherListCopy

                // Pass item by item to final list
                for (let i = 0; i < otherListCopy.length; i++) {
                    e.container.data[i] = otherListCopy[i];
                }

            }

            // Remove ".hide"
            let allHidden = document.querySelectorAll(".hide");
            allHidden.forEach((el) => {
                el.classList.remove("hide");
            });
            // Remove ".selected" after 300ms
            setTimeout(() => {
                let allSelected = document.querySelectorAll(".selected");
                allSelected.forEach((el) => {
                    el.classList.remove("selected", "last");
                });
            }, 300);


            this.dragListCopy = []; // reset the dragListCopy
            this.dragList = []; // reset the dragList
        },

    }


    search(){
      console.log("in search");
      console.log(this.tempkeywordslist);
      if(this.searchWord===''){
          this.keywords_list=this.tempkeywordslist;
      }
      else {
          console.log(this.searchWord);
          let m = [];
          for (let h = 0; h < this.keywords_list.length; h++) {

              if (this.keywords_list[h].startsWith(this.searchWord)) {
                  m.push(this.keywords_list[h]);
              }

          }
          console.log(m);
          this.keywords_list = m;
      }
  }

  frequencysort(){
    console.log('in frequency');
    console.log(this.selectedkeywords);
    console.log(this.keywords_list)
    let s=this.keywords_list;
    console.log(s);

    for(let i =0;i<s.length;i++){

      this.newwords[s[i]]=this.selectedkeywords[s[i]];

    }

    console.log(this.newwords);
   let m= this.sort_object(this.newwords);
   console.log(m);
   this.wordsonly=Object.keys(m);
   if(this.freqAscending===0){
       this.wordsonly=this.wordsonly.reverse();
       this.freqAscending=1;

   }
   else{
       this.freqAscending=0
   }
   // let v=[];
   // for(let i in m){
   //     v.push(m[i])
   // }
   // //this.selectedkeywords=m;
   // this.keywords_list=[];
   // this.wordsonly=[];
   //  for (const keys in v) {
   //
   //    this.wordsonly.push(v[keys].name);
   //
   //  }
    this.keywords_list=this.wordsonly;
    console.log(this.keywords_list)
      this.tempkeywordslist=this.keywords_list

  }
  sort_object(obj) {
    let items = Object.keys(obj).map(function(key) {
      return [key, obj[key]];
    });
    items.sort(function(first, second) {
      return second[1]- first[1];
    });
    let use_key
    let use_value
   let  sorted_obj={}
    $.each(items, function(k, v) {
        use_key = v[0]
        use_value = v[1]
      sorted_obj[use_key] = use_value
    })
    return(sorted_obj)
  }
  clearSelectedGrps(){
  let x=[];
  for( let i =0;i<this.no_of_groups;i++){
    x=[];
    if(this.selectworkgrps[i]===true){
      if(i===0) {
        x=this.grp1.data;

        this.keywords_list.push.apply(this.keywords_list,x);
        this.grp1.data = [];

      }
      else if(i===1) {
        x=this.grp2.data;

        this.keywords_list.push.apply(this.keywords_list,x);
        this.grp2.data = [];
      }
      else if(i===2) {
        x=this.grp3.data;

        this.keywords_list.push.apply(this.keywords_list,x);
        this.grp3.data = [];
      }
      else if(i===3) {
        x=this.grp4.data;

        this.keywords_list.push.apply(this.keywords_list,x);
        this.grp4.data = [];
      }
      else if(i===4) {
        x=this.grp5.data;

        this.keywords_list.push.apply(this.keywords_list,x);
        this.grp5.data = [];
      }
      else if(i===5) {
        x=this.grp6.data;

        this.keywords_list.push.apply(this.keywords_list,x);
        this.grp6.data = [];
      }
      else if(i===6) {
        x=this.grp7.data;

        this.keywords_list.push.apply(this.keywords_list,x);
        this.grp7.data = [];
      }
    }
  }
  this.selectworkgrps[0]=false;
  this.selectworkgrps[1]=false;
  this.selectworkgrps[2]=false;
  this.selectworkgrps[3]=false;
  this.selectworkgrps[4]=false;
  this.selectworkgrps[5]=false;
  this.selectworkgrps[6]=false;
  this.selectworkgrps[7]=false;
  this.selectgrps=false;
}
unselectGrps()
{
    this.selectworkgrps[0]=!this.selectworkgrps[0];
    this.selectworkgrps[1]=!this.selectworkgrps[1];
    this.selectworkgrps[2]=!this.selectworkgrps[2];
    this.selectworkgrps[3]=!this.selectworkgrps[3];
    this.selectworkgrps[4]=!this.selectworkgrps[4];
    this.selectworkgrps[5]=!this.selectworkgrps[5];
    this.selectworkgrps[6]=!this.selectworkgrps[6];
    this.selectworkgrps[7]=!this.selectworkgrps[7];
}
    sendKeywordToGroup(grpname){
        //let grpname=this.sendToGroup;


        let targetKeyword=this.rightClickedWord;
        console.log(targetKeyword);
        console.log(grpname);
        if(this.grp1.name===grpname){
            if(Array.isArray(targetKeyword)===false) {
                this.grp1.data.push(targetKeyword)
            }
            else{

                for(let j=0;j<targetKeyword.length;j++){
                    this.grp1.data.push(targetKeyword[j])
                    console.log(this.selectedkeywords[targetKeyword[j]])
                }
            }
            console.log(this.grp1);


        }
        else if(this.grp2.name===grpname){
            if(Array.isArray(targetKeyword)===false) {
                this.grp2.data.push(targetKeyword)
            }
            else{
                for(let j=0;j<targetKeyword.length;j++){
                    this.grp2.data.push(targetKeyword[j])
                }
            }
        }
        else if(this.grp3.name===grpname){
            if(Array.isArray(targetKeyword)===false) {
                this.grp3.data.push(targetKeyword)
            }
            else{
                for(let j=0;j<targetKeyword.length;j++){
                    this.grp3.data.push(targetKeyword[j])
                }
            }
        }
        else if(this.grp4.name===grpname){
            if(Array.isArray(targetKeyword)===false) {
                this.grp4.data.push(targetKeyword)
            }
            else{
                for(let j=0;j<targetKeyword.length;j++){
                    this.grp4.data.push(targetKeyword[j])
                }
            }
        }
        else if(this.grp5.name===grpname){
            if(Array.isArray(targetKeyword)===false) {
                this.grp5.data.push(targetKeyword)
            }
            else{
                for(let j=0;j<targetKeyword.length;j++){
                    this.grp5.data.push(targetKeyword[j])
                }
            }
        }
        else if(this.grp6.name===grpname){
            if(Array.isArray(targetKeyword)===false) {
                this.grp6.data.push(targetKeyword)
            }
            else{
                for(let j=0;j<targetKeyword.length;j++){
                    this.grp6.data.push(targetKeyword[j])
                }
            }
        }
        else if(this.grp7.name===grpname){
            if(Array.isArray(targetKeyword)===false) {
                this.grp7.data.push(targetKeyword)
            }
            else{
                for(let j=0;j<targetKeyword.length;j++){
                    this.grp7.data.push(targetKeyword[j])
                }
            }
        }
        else {
            alert("No Such Group Exists")
        }
console.log(Array.isArray(targetKeyword));
        if(Array.isArray(targetKeyword)===false){
            this.keywords_list = this.keywords_list.filter(e => e !== targetKeyword);

        }
        else {
            for(let j=0;j<targetKeyword.length;j++){
                this.keywords_list = this.keywords_list.filter(e => e !== targetKeyword[j]);

            }
        }
        this.isRightClicked=false;

    }

onRightClick(event,word) {
    this.grpsArray=[];
    console.log(this.multiSelect.multiarray)
    if(this.multiSelect.multiarray.length===0){
        console.log("not multiple ")
    }
    else{
        word=this.multiSelect.multiarray
    }
    console.log(word);
    if(this.grp1.data.length!==0){
        this.grpsArray.push(this.grp1.name)
    }
    if(this.grp2.data.length!==0){
        this.grpsArray.push(this.grp2.name)
    }
    if(this.grp3.data.length!==0){
        this.grpsArray.push(this.grp3.name)
    }
    if(this.grp4.data.length!==0){
        this.grpsArray.push(this.grp4.name)
    }
    if(this.grp5.data.length!==0){
        this.grpsArray.push(this.grp5.name)
    }
    if(this.grp6.data.length!==0){
        this.grpsArray.push(this.grp6.name)
    }
    if(this.grp7.data.length!==0){
        this.grpsArray.push(this.grp7.name)
    }
    console.log(this.grpsArray)
        console.log(event)
    this.rightClickedWord=word;
    this.multiDragOn=true
    // alert('right click activated');
        this.sendToGrp=true;
  this.isRightClicked = true;
        return false;
    }
 deleteSelectedGrps(){
  let deletegrp=[];
   let tempdict=[];
   let tempnamedict=[];

   let x=[];
   for( let i =0;i<this.no_of_groups;i++){
     x=[];
     if(this.selectworkgrps[i]===true){

       if(i===0) {
         x=this.grp1.data;

         this.keywords_list.push.apply(this.keywords_list,x);
         this.grp1.data = [];

       }
       else if(i===1) {
         x=this.grp2.data;

         this.keywords_list.push.apply(this.keywords_list,x);
         this.grp2.data = [];
       }
       else if(i===2) {
         x=this.grp3.data;

         this.keywords_list.push.apply(this.keywords_list,x);
         this.grp3.data = [];
       }
       else if(i===3) {
         x=this.grp4.data;

         this.keywords_list.push.apply(this.keywords_list,x);
         this.grp4.data = [];
       }
       else if(i===4) {
         x=this.grp5.data;

         this.keywords_list.push.apply(this.keywords_list,x);
         this.grp5.data = [];
       }
       else if(i===5) {
         x=this.grp6.data;

         this.keywords_list.push.apply(this.keywords_list,x);
         this.grp6.data = [];
       }
       else if(i===6) {
         x=this.grp7.data;

         this.keywords_list.push.apply(this.keywords_list,x);
         this.grp7.data = [];
       }
     }
     else{
       deletegrp.push(i+1);
       if(i===0) {
         tempdict.push(this.grp1.data) ;
         tempnamedict.push(this.grp1.name);
       }
       else if(i===1) {
         tempdict.push(this.grp2.data) ;
         tempnamedict.push(this.grp2.name);
       }
       else if(i===2) {
         tempdict.push(this.grp3.data) ;
         tempnamedict.push(this.grp3.name);
       }
       else if(i===3) {
         tempdict.push(this.grp4.data) ;
         tempnamedict.push(this.grp4.name);
       }
       else if(i===4) {
         tempdict.push(this.grp5.data);
         tempnamedict.push(this.grp5.name);
       }
       else if(i===5) {
         tempdict.push(this.grp6.data);
         tempnamedict.push(this.grp6.name);
       }
       else if(i===6) {
         tempdict.push(this.grp7.data);
         tempnamedict.push(this.grp7.name);
       }
     }
   }
   this.grp1 = {'name': 'Group1', data: []};
   this.grp2 = {'name': 'Group2', data: []};
   this.grp3 = {'name': 'Group3', data: []};
   this.grp4 = {'name': 'Group4', data: []};
   this.grp5 = {'name': 'Group5', data: []};
   this.grp6 = {'name': 'Group6', data: []};
   this.grp7 = {'name': 'Group7', data: []};
   this.grp8 = {'name': 'Group8', data: []};
   this.grp9 = {'name': 'Group9', data: []};
   console.log(tempdict.length)
   console.log(deletegrp.length);
   for(let j=0;j<deletegrp.length;j++){
   if(j===0){
     this.grp1.data=tempdict[j];
     this.grp1.name=tempnamedict[j];
   }
   else if(j===1){
     this.grp2.data=tempdict[j];
     this.grp2.name=tempnamedict[j];
   }
   else if(j===2){
     this.grp3.data=tempdict[j];
     this.grp3.name=tempnamedict[j];
   }
   else if(j===3){
     this.grp4.data=tempdict[j];
     this.grp4.name=tempnamedict[j];
   }
   else if(j===4){
     this.grp5.data=tempdict[j];
     this.grp5.name=tempnamedict[j];
   }
   else if(j===5){
     this.grp6.data=tempdict[j];
     this.grp6.name=tempnamedict[j];
   }
   else if(j===6){
     this.grp7.data=tempdict[j];
     this.grp7.name=tempnamedict[j];
   }
   }

   if(deletegrp.length===0)
   {
     this.no_of_groups=1;
   }
   else {
     this.no_of_groups=deletegrp.length;
   }

   this.selectworkgrps[0]=false;
   this.selectworkgrps[1]=false;
   this.selectworkgrps[2]=false;
   this.selectworkgrps[3]=false;
   this.selectworkgrps[4]=false;
   this.selectworkgrps[5]=false;
   this.selectworkgrps[6]=false;
   this.selectworkgrps[7]=false;
   this.selectgrps=false;
  // this.no_of_groups=this.no_of_groups-deletegrp.length;
 }


  editSectionName(){
    this.editName = !this.editName;
  }
  selectGroups()
  {
this.selectgrps=!this.selectgrps;
console.log(this.selectworkgrps[0]);
    this.selectworkgrps[0]=!this.selectworkgrps[0];
    this.selectworkgrps[1]=!this.selectworkgrps[1];
    this.selectworkgrps[2]=!this.selectworkgrps[2];
    this.selectworkgrps[3]=!this.selectworkgrps[3];
    this.selectworkgrps[4]=!this.selectworkgrps[4];
    this.selectworkgrps[5]=!this.selectworkgrps[5];
    this.selectworkgrps[6]=!this.selectworkgrps[6];
    this.selectworkgrps[7]=!this.selectworkgrps[7];
  }


  unselectAGrp(grpno){
    console.log("in grping ")
      console.log(grpno);
    let m=grpno-1;
    console.log(m)
    console.log(this.selectworkgrps[m])
    this.selectworkgrps[m]=! this.selectworkgrps[m];
    console.log( this.selectworkgrps[m])
      this.selectgrps=true;
  }
    drop(event: CdkDragDrop<string[]>) {
        console.log("in drop");
        this.multiSelect.multiarray=[];
        console.log(this.multiSelect.multiarray)
        // If current element has ".selected"
        if(event.item.element.nativeElement.classList.contains("selected")){
            this.multiDrag.dropListDropped(event);
        }
        // If dont have ".selected" (normal case)
        else{
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex,  event.currentIndex);
            } else {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
        }
    }



  ngOnInit() {
      console.log(this.groupscompile)
      console.log(this.selectedkeywords)

      let q=Object.keys(this.selectedkeywords);
      let wordsInGrp=[];
      let leftovers=[];


      let l = 1;
    if (this.groupscompile) {

        for(let i=0;i<q.length;i++){
            for ( let j = 0; j < this.groupscompile.length; j++) {
                if(this.groupscompile[j].data.includes(q[i])){

                    wordsInGrp.push(q[i]);
                }
            }

        }
        leftovers =  q.filter( function( el ) {
            return !wordsInGrp.includes( el );
        } );



        // for(let i=0;i<q.length;i++){
        //     if(wordsInGrp.includes(q[i])){
        //         console.log(q[i]);
        //         leftovers.push(q[i]);
        //     }
        // }
        console.log(wordsInGrp)
        console.log(leftovers);




        for ( let i = 0; i < this.groupscompile.length; i++) {
         // console.log(this.groupscompile[i].data.length);
          if (this.groupscompile[i].data.length !== 0) {
              console.log(i)    ;
              if (i === 0) {
                  this.grp1.name = this.groupscompile[i].name;
                  this.grp1.data = this.groupscompile[i].data;
              }

              if (i === 1) {
                  this.grp2.name = this.groupscompile[i].name;
                  this.grp2.data = this.groupscompile[i].data;
              }

              if (i === 2) {
                  this.grp3.name = this.groupscompile[i].name;
                  this.grp3.data = this.groupscompile[i].data;
              }

              if (i === 3) {
                  this.grp4.name = this.groupscompile[i].name;
                  this.grp4.data = this.groupscompile[i].data;
              }

              if (i === 4) {
                  this.grp5.name = this.groupscompile[i].name;
                  this.grp5.data = this.groupscompile[i].data;
              }

              if (i === 5) {
                  this.grp6.name = this.groupscompile[i].name;
                  this.grp6.data = this.groupscompile[i].data;
              }

              if (i === 6) {
                  this.grp7.name = this.groupscompile[i].name;
                  this.grp7.data = this.groupscompile[i].data;
              }
              l = i;
          }
      }

    }
    console.log(this.grp4)
    //console.log(l);
    this.no_of_groups = l+1;
    console.log(this.no_of_groups);
    for (const keys in this.selectedkeywords) {

      this.wordsonly.push(keys);
      this.keywords_list = this.wordsonly;
    }
    if(this.smartGroup===1&&this.groupscompile===undefined) {
        this.smartbucket();
    }
    else{
        console.log('in other ');
    }

    if(this.groupscompile){
      this.keywords_list = leftovers;

    }

    this.tempkeywordslist=this.keywords_list



      this.selectworkgrps[0]=false;
      this.selectworkgrps[1]=false;
      this.selectworkgrps[2]=false;
      this.selectworkgrps[3]=false;
      this.selectworkgrps[4]=false;
      this.selectworkgrps[5]=false;
      this.selectworkgrps[6]=false;
      this.selectworkgrps[7]=false;

  }

  addgroup() {

    const el = document.getElementById('groups-div');
    el.scrollTop = el.scrollHeight;
    this.no_of_groups = this.no_of_groups + 1;

  }

  savegrps() {


    console.log(this.grp1);
    console.log(this.grp2);
    console.log(this.grp3);
    this.fianlgroups.push(this.grp1);
    this.fianlgroups.push(this.grp2);
    this.fianlgroups.push(this.grp3);
    this.fianlgroups.push(this.grp4);
    this.fianlgroups.push(this.grp5);
    this.fianlgroups.push(this.grp6);
    this.fianlgroups.push(this.grp7);
    this.fianlgroups.push(this.grp8);
    this.fianlgroups.push(this.grp9);
    console.log(this.fianlgroups)

    this.passEntry.emit(this.fianlgroups);

  }

  saveLabel() {
    this.saveHeading = true;
    this.toggleEditName =false;
  }

      editLabelName() {
    this.toggleEditName = true;
    this.saveHeading = false;
  }

  customGroup(){
       //this.showDragDrop = !this.showDragDrop;

      this.smartGroup=0;
      this.grp1 = {'name': 'Group1', data: []};
      this.grp2 = {'name': 'Group2', data: []};
      this.grp3 = {'name': 'Group3', data: []};
      this.grp4 = {'name': 'Group4', data: []};
      this.grp5 = {'name': 'Group5', data: []};
      this.grp6 = {'name': 'Group6', data: []};
      this.grp7 = {'name': 'Group7', data: []};
      this.grp8 = {'name': 'Group8', data: []};
      this.grp9 = {'name': 'Group9', data: []};
      this.ngOnInit();
  }
  smartbucket() {
    const dates = this.header.getDateFromLS();
    this.customservice.groupsimilarwords(dates[0], dates[1], this.keywords_list).then((response: any) => {
        let len = 0;
      for (const keys in response.response.clusters) {
        console.log(keys);
        if (keys === '0') {
          this.grp1.data = response.response.clusters[0];
          console.log(response.response.clusters[0]);
        } else if (keys === '1') {
          this.grp2.data = response.response.clusters[1];
        } else if (keys === '2') {
          this.grp3.data = response.response.clusters[2];
        } else if (keys === '3') {
          this.grp4.data = response.response.clusters[3];
        } else if (keys === '4') {
          this.grp5.data = response.response.clusters[4];
        } else if (keys === '5') {
          this.grp6.data = response.response.clusters[5];
        } else if (keys === '6') {
          this.grp7.data = response.response.clusters[6];
        }
        len = len + 1;
      }
      this.no_of_groups = len;
      this.keywords_list.length=0;
    });
  }

  ascendingSort() {
    if (this.sortingWord === 0) {
      this.keywords_list = this.keywords_list.sort((a, b) =>  (a > b ? 1 : -1));
      this.sortingWord = -1;

    } else if (this.sortingWord === -1) {
      this.keywords_list = this.keywords_list.sort((a, b) => (a > b ? -1 : 1));
      this.sortingWord = 0;

    }


  }

  closeDragDrop(){
    console.log(' drag and drop testing');
    this.showDragDrop = false;
  }
}
