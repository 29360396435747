import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import * as d3 from 'd3';
import * as d3Cloud from 'd3-cloud';

@Component({
  selector: 'app-stats-word-cloud',
  templateUrl: './stats-word-cloud.component.html',
  styleUrls: ['./stats-word-cloud.component.css']
})
export class StatsWordCloudComponent implements OnInit, OnChanges {

  @Input() words: any = [];
  @Input() slicedWords: any = [];

  constructor() { }

  buildGraph(): void {
    var fill = d3.scaleOrdinal(d3.schemeCategory10);
    console.log('bdwdbedbhed nwbhqdhbe');
    console.log(this.words);
    console.log(this.slicedWords);




    d3Cloud().size([1200, 500])
        .words(this.slicedWords)
        .padding(5)
        //.rotate(function() { return ~~(Math.random() * 2) * 90; })
        .rotate(function(d) { return 0})
        .text(function(d) { return d.text; }) // THE SOLUTION
        .font("Impact")
        .fontSize(function(d) {
         // else{
            return d.size/2;
          //}
           })
        .on("end", draw)
        .start();

    function draw(words) {
      console.log(d3.select("#mycloud"));
      var firstit = d3.select("#mycloud").append("svg")
          .attr("width", 1100)
          .attr("height", 450)
          var secondit = firstit.append("g")
          .attr("transform", "translate(500,250)")
              var thirdit = secondit
          .selectAll("text")
          .data(words)
          .enter().append("text")
                  var fourthit = thirdit
          .attr("class", "stats-cloud-text")
          .style("font-size", function(d: any) { return d.size + "px"; })
          .style("font-family", "Impact")
          .style("fill", function(d: any, i: any) { return fill(i); })
          .attr("text-anchor", "middle")
          .attr("transform", function(d: any) {

            //return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
            return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";

          })
          .text(function(d:any) { return d.text; });

      fourthit.on('click', (d: any) => {
        console.log(d);
      });

      this.tooltip = d3.select('.tooltip-wordcloud');
      const _this = this;
      console.log(this.tooltip);
      fourthit.on('mouseover', function (d: any) {
        d3.select(this).style('cursor', 'pointer');
        _this.tooltip.select('.name').html(d.text+': '+d.size);
        //_this.tooltip.select('.freq').html(d.value);
        //_this.tooltip.html('Click to view'+d.sentiment+'messages');
        _this.tooltip.style('display', 'block');
      });

      fourthit.on('mouseout', function () {
        d3.select(this).style('cursor', 'normal');
        _this.tooltip.style('display', 'none');
      });

      fourthit.on('mousemove', function (d: any) {
        const mouse: any = d3.mouse(this);
        _this.tooltip.style('top', (mouse[1] + 70) + 'px')
            .style('left', (mouse[0] + 30) + 'px');
      });


    }

  }
  private initChart(): void {
    const chart: any = d3.select('#mycloud');
    chart.append('g');
    chart.selectAll('*').remove(); // Clear existing chart contents.

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.words){
      this.slicedWords = this.words.slice(0,50);

      this.initChart();
      this.buildGraph();
    }
  }

  ngOnInit() {
    console.log('entered in init');
    this.slicedWords = this.words.slice(0,50);
    this.initChart();
    this.buildGraph();


  }

}
