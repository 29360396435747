import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from "@angular/core";
import * as d3 from "d3";

@Component({
  selector: 'app-scatterplot',
  templateUrl: './scatterplot.component.html',
  styleUrls: ['./scatterplot.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ScatterplotComponent implements OnInit, OnChanges {

  @Input() rawData: any;
  @Input() allWordsMap: any;
  @Input() reDraw: boolean;
  @Input() groupLabelName: string;
  @Output() selectionEvent = new EventEmitter();
  @Output() shortlistEvent = new EventEmitter();
  @Output() removeEvent = new EventEmitter();

  margin: any = {top: 20, right: 70, bottom: 30, left: 30};
  totalWidth = 825;
  totalHeight = 330;
  width: number;
  height: number;
  svg: any;
  tooltip: any;
  zoom: any;
  zoomArea: any;
  brush: any;
  selectedWordsBrush: any;
  brushArea: any;
  x: any;
  y: any;
  newX: any;
  newY: any;
  xAxis: any;
  yAxis: any;
  gridX: any;
  gridY: any;
  graph: any;
  scatter: any;
  dot: any;
  minX: any;
  maxX: any;
  minY: any;
  maxY: any;
  selectedWords: any[] = [];
  legendList: any = {};
  colorScheme: any = {
    0: "#1b9e77",
    1: "#7570b3",
    2: "#E6AB02",
    3: "#E7298A",
    4: "#D95F02",
    5: "#66A61E",
    6: "#e41a1c",
    7: "#b15928",
    8: "#843c39",
    9: "#666666",
    10: "#7b4173"
  };
  countBrushCheck: number = 0;
  structuredData: any[] = [];
  activeLegend: string = '';
  masterSelected: boolean = false;
  activeButton: string = 'reset';
  constructor() {
  }

  processData(): any {
    this.structuredData = [];
    for (let keyword in this.rawData) {
      if (!this.allWordsMap[keyword].deleted && !this.allWordsMap[keyword].deletedInSubCluster) {
        this.structuredData.push({
          x: this.rawData[keyword].coordinates[0],
          y: this.rawData[keyword].coordinates[1],
          group: this.rawData[keyword].group,
          keyword: keyword,
          freq: this.allWordsMap[keyword].value,
          shortListed: this.allWordsMap[keyword].shortListed || this.allWordsMap[keyword].shortListedInSubCluster
        });
        if (!this.legendList[this.rawData[keyword].group]) {
          this.legendList[this.rawData[keyword].group] = 1;
        }
      }
    }
    //todo:change legend according to labels.
    return this.structuredData;
  }


  checkUnCheckAll(): void {
    if(this.masterSelected){
      for(var i = 0 ; i < this.selectedWords.length; i++){
        this.selectedWords[i].isSelected = false;
      }
    }
    else{
      for(var i = 0 ; i < this.selectedWords.length; i++){
        this.selectedWords[i].isSelected = true;
      }
    }
  }


  buildGraph(): void {
    let _this = this;
    const data: any[] = this.processData();
    this.maxX = d3.max(data, function (d) {
      return d.x;
    });
    this.minX = d3.min(data, function (d) {
      return d.x;
    });
    this.maxY = d3.max(data, function (d) {
      return d.y;
    });
    this.minY = d3.min(data, function (d) {
      return d.y;
    });
    this.x = this.newX = d3.scaleLinear()
        .domain([this.minX-2, this.maxX+2])
        .range([0, this.width]);
    this.xAxis = this.svg.append("g")
        .attr("transform", "translate(0," + this.height + ")")
        .call(d3.axisBottom(this.x));
    this.y = this.newY =  d3.scaleLinear()
        .domain([this.minY-2, this.maxY+2])
        .range([this.height, 0]);
    this.yAxis = this.svg.append("g")
        .call(d3.axisLeft(this.y));
    this.makeGrid();
    this.addZoomBehavior();
    this.addZoomArea();
    // Add a clipPath: everything out of this area won't be drawn.
    var clip = this.svg.append("defs").append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", this.width)
        .attr("height", this.height)
        .attr("x", 0)
        .attr("y", 0);


    var maxBubble = d3.max(data, function (d) {
      return d.freq;
    });
    var minBubble = d3.min(data, function (d) {
      return d.freq;
    });
    // Add a scale for bubble size
    var z = d3.scaleLinear()
        .domain([minBubble, maxBubble])
        .range([6, 60]);
    this.addBrushBehavior();
    // Create the scatter variable: where both the circles and the brush take place
    this.scatter = this.svg.append('g')
        .attr("clip-path", "url(#clip)");
    this.dot = this.scatter
        .selectAll("dot")
        .data(data)
        .enter()
        .append("g")
        .attr("class", function (d) {
          return "wordInfo group-name" + d.group;
        })
    this.dot.append("circle")
        .attr("class", function (d) {
         return  d.shortListed ? "bubbles bubble-stroke-black" : "bubbles bubble-stroke-white";
        })
        .attr("cx", d => this.x(d.x))
        .attr("cy", d => this.y(d.y))
        .attr("r", function (d) {
          return z(d.freq);
        })
        .style("fill", d => {
          return this.colorScheme[d.group];
        });
    this.addTooltip();
  }

  toggleDisplayNoneToOtherNonZoomedGroups(group, toggle): void {
    //todo:use regex
    for (var i = 0; i <= 9; i++) {
      if (i.toString() !== group.toString()) {
        if (d3.selectAll("g .group-name" + i)) {
          if (toggle) {
            d3.selectAll("g .group-name" + i).attr("display", "none");
          } else {
            d3.selectAll("g .group-name" + i).attr("display", "block");

          }
        }
      }
    }

  }

  calculateLegendZoomCoordinates(group): void {
    var eventCoord = [[Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
      [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]]
    for (var i = 0; i < this.structuredData.length; i++) {
      if (this.structuredData[i].group.toString() === group.toString()) {
        const x = this.structuredData[i].x;
        const y = this.structuredData[i].y;

        //finding x min and max
        if (x < eventCoord[0][0]) {
          eventCoord[0][0] = x;
        }
        if (x > eventCoord[1][0]) {
          eventCoord[1][0] = x;
        }
        //finding y min and max
        if (y < eventCoord[1][1]) {
          eventCoord[1][1] = y;
        }
        if (y > eventCoord[0][1]) {
          eventCoord[0][1] = y;
        }
      }
    }

    var newcood = [[0, 0], [0, 0]];
    newcood[0][0] = this.x(eventCoord[0][0] - 1);
    newcood[1][0] = this.x(eventCoord[1][0] + 1);
    newcood[1][1] = this.y(eventCoord[1][1] - 1);
    newcood[0][1] = this.y(eventCoord[0][1] + 1);
    this.updateChart(false, newcood);
    this.toggleDisplayNoneToOtherNonZoomedGroups(group, true);
  }

  showTextOnCircle(): void {
    var _this = this;
    var x = (this.dot);
    this.dot.append("text")
        .attr("class", "keyword")
        .text((d: any) => d.keyword)
        .style("font-size", 12)
         .style('font-weight', "bold")
        .attr("dy", "0.35em")
        .attr("x", function (d) {
          return _this.x(d.x) - 14;
        })
        .attr("y", function(d) {
          const r = (d3.select(this.previousSibling).attr("r")) + 100;
          // @ts-ignore
          return _this.y(d.y) - (r) - 8;
        });
  }

  removeTextOnCircle(): void {
    this.dot.selectAll("text").remove();
  }

  addTooltip(): void {
    var _this = this;
    this.tooltip = d3.select('.tooltip-scatterplot');
    this.dot.on('mouseover', function (d: any) {
      d3.select(this).style('cursor', 'pointer');
      _this.tooltip.select('.name').html(d.keyword[0].toUpperCase() + d.keyword.slice(1));
      _this.tooltip.select('.freq').html(d.freq);
      _this.tooltip.style('display', 'block');
    });

    this.dot.on('mouseout', function () {
      d3.select(this).style('cursor', 'normal');
      _this.tooltip.style('display', 'none');
    });

    this.dot.on('mousemove', function (d: any) {
      const mouse: any = d3.mouse(this);
      _this.tooltip.style('top', (mouse[1] + 70) + 'px')
          .style('left', (mouse[0] + 30) + 'px');
    });
  }

  makeGrid(): void {
    const axisBottom: any = d3.axisBottom(this.x);
    const axisLeft: any = d3.axisLeft(this.y);
    this.gridX = this.svg.insert('g', '#scatterplot')
        .attr('class', 'grid grid-x')
        .attr('transform', 'translate(0,' + this.height + ')')
        .call(axisBottom
           );

    this.gridY = this.svg.insert('g', '#scatterplot')
        .attr('class', 'grid grid-y')
        .call(axisLeft
          );
  }

  updateGraphOnZoom(): void {
    // recover the new scale
    this.newX = d3.event.transform.rescaleX(this.x);
    this.newY = d3.event.transform.rescaleY(this.y);

    // update axes with these new boundaries
    this.xAxis.call(d3.axisBottom(this.newX));
    this.yAxis.call(d3.axisLeft(this.newY));

    const axisBottom: any = d3.axisBottom(this.newX);
    const axisLeft: any = d3.axisLeft(this.newY);

    this.gridX.call(axisBottom
        /*.tickSize(-this.height)
        .tickFormat('')*/);
    this.gridY.call(axisLeft
       /* .tickSize(-this.width)
        .tickFormat('')*/);

    this.scatter.selectAll('circle')
        .attr('cx', (d: any) => this.newX(d.x))
        .attr('cy', (d: any) => this.newY(d.y));

    this.scatter.selectAll(".keyword")
        .attr('x', (d: any) => this.newX(d.x) - ((d.keyword.length * 4) / 2))
        .attr("y", (d: any) => this.newY(d.y) - 8);
  }

  addZoomArea(): void {
    // This add an invisible rect on top of the chart area. This rect can recover pointer events: necessary to understand when the user zoom
    this.zoomArea = this.svg.append('rect')
        .attr('class', 'zoomArea')
        .attr('width', this.width)
        .attr('height', this.height)
        .style('fill', 'none')
        .style('pointer-events', 'all')
        //.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
        .call(this.zoom)
    this.zoomArea.on("dblclick.zoom", () => this.updateChart(true));

  }

  addZoomBehavior(): void {
    this.zoom = d3.zoom()
        .scaleExtent([1, 15])
        .translateExtent([[0, 0], [this.width, this.height]])
        .on('zoom', () => {
          this.updateGraphOnZoom();
        });
  }

  highlightBrushedCircles(): void {
    const selection: any = d3.event.selection;
    this.selectedWords = [];
    if (selection) {
      let x0 = selection[0][0],
          y0 = selection[0][1],
          dx = selection[1][0] - x0,
          dy = selection[1][1] - y0;

      this.svg.selectAll('.bubbles')
          .style('fill', (d: any) => {
            if (this.newX(d.x) >= x0 && this.newX(d.x) <= x0 + dx && this.newY(d.y) >= y0 && this.newY(d.y) <= y0 + dy) {
              this.selectedWords.push({word: d.keyword, isSelected: true});
              return '#000000';
            } else {
              return this.colorScheme[d.group];
            }
          });
    }
  }

  resetSelection(): void {
    console.log('entered in reset selection');
    console.log(this.dot.selectAll('.bubbles'));
    this.svg.selectAll('.bubbles')
        //.transition()
        //.duration(150)
        //.ease(d3.easeLinear)
        .style('fill', (d: any) => this.colorScheme[d.group]);

  }

  brushended(): void {
    if (!d3.event.selection) {
      this.resetSelection();
    }
  }

  addSelectWordsBrushArea(): void {
    this.brushArea = this.scatter
        .append("g")
        .attr("class", "selectWordsBrushArea")
        .call(this.selectedWordsBrush);

    this.brushArea.on("click", () => {
      this.brushArea.remove();
      this.activeButton = '';
    });

  }

  addBrushBehavior(): void {
    this.selectedWordsBrush = d3.brush()
        .extent([[0, 0], [this.width + this.margin.right, this.height]])
        .on('start', () => {
          this.selectedWords = [];
        })
        .on('brush', () => {
          this.highlightBrushedCircles();
        })
        .on('end', () => {
          this.masterSelected = true;
          this.brushended();
        });

    // Add brushing
    this.brush = d3.brush()                // Add the brush feature using the d3.brush function
        .extent([[0, 0], [this.width, this.height]]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
        .on('end', (event,sel) => {
          this.updateChart(event,sel);
        });
  }

  removeBrushArea(): void {
    if(this.scatter.select('.selectWordsBrushArea')){
      this.scatter.select('.selectWordsBrushArea').remove();
    }
    this.resetSelection();
    this.selectionEvent.emit([]);
    this.selectedWords = [];
  }

  selectWords(): void {
    this.removeBrushArea();
    this.addSelectWordsBrushArea();
  }

  addBrush(): void {
    this.scatter
        .append("g")
        .attr("class", "brush")
        .call(this.brush);
  }

  updateChart(myEvent, legendCoord?): void {
    var _this = this;
    var extent;
    if (myEvent) {
      this.activeLegend = '';
      this.activeButton = 'reset';
      this.removeBrushArea();
      _this.x.domain([_this.minX - 2, _this.maxX + 2]);
      _this.y.domain([_this.minY - 2, _this.maxY + 2]);
      _this.xAxis.transition().duration(1000).call(d3.axisBottom(_this.x))
      _this.yAxis.transition().duration(1000).call(d3.axisLeft(_this.y))
      const axisBottom: any = d3.axisBottom(_this.x);
      const axisLeft: any = d3.axisLeft(_this.y);
      this.scatter.select(".brush").call(this.brush.move, null);
      _this.gridX.call(axisBottom);
      _this.gridY.call(axisLeft);
      _this.scatter
            .selectAll("circle")
            .transition().duration(1000)
          .attr("cx", d => _this.x(d.x))
          .attr("cy", d => _this.y(d.y))
      _this.removeTextOnCircle();
      this.toggleDisplayNoneToOtherNonZoomedGroups("", false);
      this.countBrushCheck = 0;
    } else {
      if (legendCoord) {
        extent = legendCoord;
      } else {
        extent = d3.event.selection;
      }
      if (!extent) {
      } else {
        if(extent!== null){
          this.activeButton = 'zoom';
          this.countBrushCheck += 1;
          _this.x.domain([_this.x.invert(extent[0][0]), _this.x.invert(extent[1][0])]);
          _this.y.domain([_this.y.invert(extent[1][1]), _this.y.invert(extent[0][1])]);
          const axisBottom: any = d3.axisBottom(_this.x);
          const axisLeft: any = d3.axisLeft(_this.y);
          _this.xAxis.transition().duration(500).call(d3.axisBottom(_this.x));
          _this.yAxis.transition().duration(500).call(d3.axisLeft(_this.y));
          this.scatter.select(".brush").call(this.brush.move, null);
          _this.gridX.call(axisBottom);
          _this.gridY.call(axisLeft);
          _this.scatter
              .selectAll("circle")
              .transition().duration(1000)
              .attr("cx", d => _this.x(d.x))
              .attr("cy", d => _this.y(d.y));

          this.scatter.selectAll(".keyword")
              .transition().duration(1000)

        .attr("x", function(d) {
            return _this.x(d.x) - 14;
          })
              .attr("y", function(d) {
                const r = (d3.select(this.previousSibling).attr("r")) + 100;
                // @ts-ignore
                return _this.y(d.y) - (r) - 8;
              });
        if(this.countBrushCheck === 1){
          setTimeout(() => {
            this.showTextOnCircle();
          }, 1000);
        }
        }
      }
    }
    this.removeBrush();

  }

  removeBrush(): void {
    d3.selectAll(".brush").remove();
  }

  initSVG(): void {
    this.selectedWords = [];
    this.activeButton = 'reset';
    d3.select('.scatterplot').html(null);
    this.width = this.totalWidth - this.margin.left - this.margin.right;
    this.height = this.totalHeight - this.margin.top - this.margin.bottom;
    this.svg = d3.select('.scatterplot')
        .attr('width', this.totalWidth)
        .attr('height', this.totalHeight)
        .append("g")
        .attr('transform', 'translate(' + 50 + ',' + this.margin.top + ')');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.reDraw && this.reDraw) || (changes.rawData && this.rawData && Object.keys(this.rawData).length)) {
      this.initSVG();
      this.buildGraph();
    }
  }

  ngOnInit(): void {
    // this.buildGraph();
  }
}
