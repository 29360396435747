import { Injectable } from '@angular/core';
import * as D3 from 'd3';

@Injectable({
  providedIn: 'root'
})
export class ScatterGraphService {

  constructor() { }
  scatter_graph(graphresp1){
    console.log(graphresp1);





  }
}
