import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as d3 from 'd3';
import * as d3Cloud from 'd3-cloud';

// declare let d3: any;

@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WordCloudComponent implements OnInit {

  constructor() {
  }

  buildGraph(): void {
    let data = [
      {'text': 'study', 'size': 40}, {'text': 'motion', 'size': 15}, {
        'text': 'forces',
        'size': 10
      }, {'text': 'electricity', 'size': 15}, {'text': 'movement', 'size': 10}, {'text': 'relation', 'size': 5}, {
        'text': 'things',
        'size': 10
      }, {'text': 'force', 'size': 5}, {'text': 'ad', 'size': 5}, {'text': 'energy', 'size': 85}, {
        'text': 'living',
        'size': 5
      }, {'text': 'nonliving', 'size': 5}, {'text': 'laws', 'size': 15}, {'text': 'speed', 'size': 45}, {
        'text': 'velocity',
        'size': 30
      }, {'text': 'define', 'size': 5}, {'text': 'constraints', 'size': 5}, {'text': 'universe', 'size': 10}, {
        'text': 'physics',
        'size': 120
      }, {'text': 'describing', 'size': 5}, {'text': 'matter', 'size': 90}, {'text': 'physics-the', 'size': 5}, {
        'text': 'world',
        'size': 10
      }, {'text': 'works', 'size': 10}, {'text': 'science', 'size': 70}, {'text': 'interactions', 'size': 30}, {
        'text': 'studies',
        'size': 5
      }, {'text': 'properties', 'size': 45}, {'text': 'nature', 'size': 40}, {'text': 'branch', 'size': 30}, {
        'text': 'concerned',
        'size': 25
      }, {'text': 'source', 'size': 40}, {'text': 'google', 'size': 10}, {'text': 'defintions', 'size': 5}, {
        'text': 'two',
        'size': 15
      }, {'text': 'grouped', 'size': 15}, {'text': 'traditional', 'size': 15}, {'text': 'fields', 'size': 15}, {
        'text': 'acoustics',
        'size': 15
      }, {'text': 'optics', 'size': 15}, {'text': 'mechanics', 'size': 20}, {
        'text': 'thermodynamics',
        'size': 15
      }, {'text': 'electromagnetism', 'size': 15}, {'text': 'modern', 'size': 15}, {
        'text': 'extensions',
        'size': 15
      }, {'text': 'thefreedictionary', 'size': 15}, {'text': 'interaction', 'size': 15}, {'text': 'org', 'size': 25}, {
        'text': 'answers',
        'size': 5
      }, {'text': 'natural', 'size': 15}, {'text': 'objects', 'size': 5}, {'text': 'treats', 'size': 10}, {
        'text': 'acting',
        'size': 5
      }, {'text': 'department', 'size': 5}, {'text': 'gravitation', 'size': 5}, {'text': 'heat', 'size': 10}, {
        'text': 'light',
        'size': 10
      }, {'text': 'magnetism', 'size': 10}, {'text': 'modify', 'size': 5}, {'text': 'general', 'size': 10}, {
        'text': 'bodies',
        'size': 5
      }, {'text': 'philosophy', 'size': 5}, {'text': 'brainyquote', 'size': 5}, {'text': 'words', 'size': 5}, {
        'text': 'ph',
        'size': 5
      }, {'text': 'html', 'size': 5}, {'text': 'lrl', 'size': 5}, {'text': 'zgzmeylfwuy', 'size': 5}, {
        'text': 'subject',
        'size': 5
      }, {'text': 'distinguished', 'size': 5}, {'text': 'chemistry', 'size': 5}, {'text': 'biology', 'size': 5}, {
        'text': 'includes',
        'size': 5
      }, {'text': 'radiation', 'size': 5}, {'text': 'sound', 'size': 5}, {'text': 'structure', 'size': 5}, {
        'text': 'atoms',
        'size': 5
      }, {'text': 'including', 'size': 10}, {'text': 'atomic', 'size': 10}, {'text': 'nuclear', 'size': 10}, {
        'text': 'cryogenics',
        'size': 10
      }, {'text': 'solid-state', 'size': 10}, {'text': 'particle', 'size': 10}, {'text': 'plasma', 'size': 10}, {
        'text': 'deals',
        'size': 5
      }, {'text': 'merriam-webster', 'size': 5}, {'text': 'dictionary', 'size': 10}, {'text': 'analysis', 'size': 5}, {
        'text': 'conducted',
        'size': 5
      }, {'text': 'order', 'size': 5}, {'text': 'understand', 'size': 5}, {'text': 'behaves', 'size': 5}, {
        'text': 'en',
        'size': 5
      }, {'text': 'wikipedia', 'size': 5}, {'text': 'wiki', 'size': 5}, {'text': 'physics-', 'size': 5}, {
        'text': 'physical',
        'size': 5
      }, {'text': 'behaviour', 'size': 5}, {'text': 'collinsdictionary', 'size': 5}, {'text': 'english', 'size': 5}, {
        'text': 'time',
        'size': 35
      }, {'text': 'distance', 'size': 35}, {'text': 'wheels', 'size': 5}, {'text': 'revelations', 'size': 5}, {
        'text': 'minute',
        'size': 5
      }, {'text': 'acceleration', 'size': 20}, {'text': 'torque', 'size': 5}, {'text': 'wheel', 'size': 5}, {
        'text': 'rotations',
        'size': 5
      }, {'text': 'resistance', 'size': 5}, {'text': 'momentum', 'size': 5}, {'text': 'measure', 'size': 10}, {
        'text': 'direction',
        'size': 10
      }, {'text': 'car', 'size': 5}, {'text': 'add', 'size': 5}, {'text': 'traveled', 'size': 5}, {
        'text': 'weight',
        'size': 5
      }, {'text': 'electrical', 'size': 5}, {'text': 'power', 'size': 5}
    ];

    data.sort((a: any, b: any) => {
      return b.size - a.size;
    });
    data = data.slice(0, 20);

    d3Cloud().size([400, 150])
        .words(data)
        .rotate(0)
        .padding(1)
        .font('Impact')
        .fontSize((d: any) => d.size / 2)
        .on('end', draw)
        .start();

    function draw(words) {
      console.log('Words - ', words.length);

      const svg: any = d3.select('svg')
          .attr('width', 850)
          .attr('height', 700);

      svg.on('mouseout', () => {
        svg.select('.tooltip')
            .style('opacity', 0);
      });

      const tooltip = svg.append('g')
          .attr('class', 'tooltip')
          .style('opacity', 0);

      tooltip.append('text')
          .attr('class', 'tooltiptext')
          .attr('transform', 'translate(6,20)')
          .style('fill', 'black')
          .style('font', '14px/21px sans-serif');

      svg.attr('class', 'wordcloud')
          .append('g')
          // without the transform, words words would get cutoff to the left and top, they would
          // appear outside of the SVG area
          .attr('transform', 'translate(200,100)')
          .selectAll('text')
          .data(words)
          .enter().append('text')
          .attr('text-anchor', 'middle')
          .style('font-size', (d: any) => (d.size + 'px'))
          // .attr("filter", "url(#solid)")
          .style('fill', '#000000')
          .attr('font-family', 'Impact')
          .text((d: any) => d.text)
          .attr('transform', (d: any) => {
            return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
          })
          .on('mouseover', (d: any) => {
            svg.select('.tooltip')
                .style('opacity', 1);
            svg.select('.tooltiptext')
                .text(d.size)
                .attr('transform', 'translate(' + d3.event.pageX + ',' + (d3.event.pageY - 100) + ')');
          });
    }
  }

  ngOnInit() {
    this.buildGraph();
  }

}
