import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-panel-stats',
  templateUrl: './side-panel-stats.component.html',
  styleUrls: ['./side-panel-stats.component.css']
})
export class SidePanelStatsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
