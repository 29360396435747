import { Injectable } from '@angular/core';
import {ApiService} from "../services/api.service";
import {SharedServiceService} from "../services/shared-service.service";

@Injectable({
  providedIn: 'root'
})
export class WearableService {

  constructor(private apiService: ApiService,
              private myService: SharedServiceService){
  }
  public getDataWearable(dates: any, filters?: any, relevantMentions?: boolean ) {
    let url = '/wearable'
    let payload = {};
    let promise = new Promise((resolve, reject) =>  {
      if (filters.length === 1 && filters[0] === 'All channels'){
        filters = this.myService.sitesExcludingAllChannels;
      }
      payload['filters'] = filters;
      payload['start'] = dates[0];
      payload['end'] = dates[1];
      if (relevantMentions && relevantMentions === true) {
        url = url + "?relevantmentions=" + relevantMentions;
      }
      this.apiService.post(url, payload).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }

  public getDataWearableSideEffects(dates: any, filters?: any, relevantMentions?: boolean ) {
    let url = '/wearable/wearside'
    let payload = {};
    let promise = new Promise((resolve, reject) =>  {
      if (filters.length === 1 && filters[0] === 'All channels'){
        filters = this.myService.sitesExcludingAllChannels;
      }
      payload['filters'] = filters;
      payload['start'] = dates[0];
      payload['end'] = dates[1];
      if (relevantMentions && relevantMentions === true) {
        url = url + "?relevantmentions=" + relevantMentions;
      }
      this.apiService.post(url, payload).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
}

