import {Component, OnInit, ViewChild} from "@angular/core";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {SharedServiceService} from "../services/shared-service.service";
import {HeaderComponent} from "../reusable/header/header.component";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {SentimentsService} from "./sentiments.service";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: "app-sentiments",
  templateUrl: "./sentiments.component.html",
  styleUrls: ["./sentiments.component.css"]
})
export class SentimentsComponent implements OnInit {

  public dates: any;
  public sentimentAnalysisOption: any;
  public emotionAnalysisOption: any;
  public showLoader = false;
  public showSentimentAnalysisLoader = false;
  public showPositiveSentimentLoader = false;
  public showNegativeSentimentLoader = false;
  public showEmotionLoader = false;
  public msgUrl = "/sentiments/msg";
  public sentColor = {"Positive": "#92c47d", "Negative": "#cd0000", "Neutral": "#D3D3D3"};
  public emotionColor = this.myService.siteColor;
  public pieChart1Option: any;
  public pieChart2Option: any;
  public pieChart3Option: any;
  public pieChart4Option: any;
  public negativeSentimentObj: any;
  public positiveSentimentObj: any;
  public colorPalette = {"working/effective": "#2f4554", "good experience/happy/satisfied": "#68a94d", "helpful": "#d48265", "easy to use": "#91c7ae", "value for money": "#749f83", "good results/reliable": "#ca8622",
    "problems/issues/incompatible/not working": "#c23531", "difficult to use": "#6e7074", "bad experience/unhappy/not satisfied": "#546570", "not helpful": "#2f4554"};
  public checkedSiteFilters: any[] = [];

  @ViewChild(MessageModalComponent) child: MessageModalComponent;

  constructor(public myService: SharedServiceService,
              public sentimentsService: SentimentsService,
              public header: HeaderComponent,
              public eChartGraphs: EchartGraphsService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {
    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.showSiteData(this.checkedSiteFilters);
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public provideGraphOptions(legendData: any, yAxisData: any, graphData: any): any {
    return {
      title: {
        text: ""
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: "shadow"        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      legend: {
        data: legendData,
        textStyle: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: "400",
          color: "#333"
        },
        top: 30,
        left: "center"
      },
      grid: {
        top: 80,
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        },
      },
      yAxis: {
        type: "category",
        data: yAxisData,
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"

          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        },

      },
      series: graphData
    };
  }

  public prepareSentimentAnalysisGraph(data: any): void {
    const label = data.Label;
    const sentLabel = [];
    const graphData = [];
    for (const i in data) {
      if (i !== "Label") {
        if (data[i].length !== 0) {
          sentLabel.push(i);
          graphData.push({
            name: i, type: "bar", stack: "总量",
            label: {
              normal: {
                show: true,
                position: "insideRight",
                formatter: function (params) {
                  let data = parseFloat(params.value);
                  return data.toLocaleString();
                },
              },
            },
            data: data[i], color: this.sentColor[i],
            barMinHeight: 26
          });
        }
      }
    }

    this.sentimentAnalysisOption = this.provideGraphOptions(sentLabel, label, graphData);
  }

  public prepareEmotionAnalysisGraph(data: any): void {
    const label = data.Label;
    const yAxis = data["x-axis"];
    const graphData = [];

    for (const key in data) {
      if (key !== "count" && key !== "label" && key !== "x-axis") {
        const remove0: any[] = data[key].filter((value: any) => {
          return value !== 0;
        });
        if (remove0.length) {
          graphData.push({
            name: key, type: "bar",
            stack: "总量",
            label: {
              normal: {
                show: true,
                position: "insideRight",
                formatter: function (params) {
                  let data = parseFloat(params.value);
                  return data.toLocaleString();
                }
              }
            }, data: remove0, color: this.emotionColor[key],
            barMinHeight: 20
          });
        }
      }
    }

    this.emotionAnalysisOption = this.provideGraphOptions(label, yAxis, graphData);
  }

  public generateNonEmptyLabels(data: any): any {
    const xAxis = data["x-axis"];
    const nonEmptyLabels: any = {};

    for (const key in data) {
      if (key !== "label" && key !== "x-axis") {
        nonEmptyLabels[key] = [];
        nonEmptyLabels["l" + key] = [];
        data[key].forEach((item: number, index: number) => {
          if (item) {
            nonEmptyLabels[key].push(item);
            nonEmptyLabels["l" + key].push(xAxis[index]);
          }
        });
      }
    }
    return nonEmptyLabels;
  }

  public pieChart(title: string, sentiment: string): void {
    const pieData: any[] = [];
    const pieObj: any = (sentiment === "positive") ? this.positiveSentimentObj : this.negativeSentimentObj;
    const tenant = pieObj[title];
    const labelTenant = pieObj["l" + title];

    let total = 0;
    tenant.forEach((item: any) => {
      total += item;
    });

    for (let i = 0; i < tenant.length; i++) {
      const wrapText = labelTenant[i].split("/");
      let newTxt = "";
      for (let k = 0; k < wrapText.length; k++) {
        newTxt += wrapText[k];
        if (k !== (wrapText.length) - 1) {
          newTxt += "/";
        }
        newTxt += "\n";
      }
      const perc = Math.ceil(((tenant[i] / total) * 100) * 100) / 100 + "%";
      pieData.push({value: tenant[i], name: newTxt + perc, itemStyle:{color: this.colorPalette[labelTenant[i]]}});
    }

    // Specify configurations and data graphs
    const option = {
      title: {
        text: title,
        subtext: "",
        bottom: 20,
        left: "center",
        textStyle: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: 20,
          fontStyle: "normal",
          fontWeight: "300",
          color: "#333",
          align: "centre"
        }
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}"
      },
      legend: {
        orient: "vertical",
        left: "left",
        top: 50,
        data: labelTenant
      },
      series: [
        {
          name: title,
          type: "pie",
          avoidLabelOverlap: true,
          radius: "45%",
          center: ["50%", "40%"],
          data: pieData,
          labelLine: {
            length: 20
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0)"
            }
          }
        }
      ]
    };

    if (sentiment === "positive") {
      if (title === "Abbott") {
        this.pieChart1Option = option;
        console.log(this.pieChart1Option);
      } else {
        this.pieChart2Option = option;
      }
    } else {
      if (title === "Abbott") {
        this.pieChart3Option = option;
      } else {
        this.pieChart4Option = option;
      }
    }
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters: [];
    this.showSentimentAnalysisLoader = true;
    this.showPositiveSentimentLoader = true;
    this.dates = this.header.getDateFromLS();
    this.myService.saveSiteInLS(this.checkedSiteFilters);

    Promise.all([
      this.sentimentsService.getData("/sentiments", this.dates, this.checkedSiteFilters),
      this.sentimentsService.getData("/sentiments/positive", this.dates, this.checkedSiteFilters),
      this.sentimentsService.getData("/sentiments/negative", this.dates, this.checkedSiteFilters),
      this.sentimentsService.getData("/sentiments/emotion", this.dates, this.checkedSiteFilters)
    ]).then((results: any[]) => {
      this.showLoader = false;
      this.showSentimentAnalysisLoader = false;
      this.showPositiveSentimentLoader = false;
      this.showNegativeSentimentLoader = false;
      this.showEmotionLoader = false;
      this.prepareSentimentAnalysisGraph(results[0].First);
      this.positiveSentimentObj = this.generateNonEmptyLabels(results[1].positive_sentiment.positive_sentiment);
      this.pieChart("Abbott", "positive");
      this.pieChart("Dexcom", "positive");
      this.negativeSentimentObj = this.generateNonEmptyLabels(results[2].negative_sentiment.negative_sentiment);
      this.pieChart("Abbott", "negative");
      this.pieChart("Dexcom", "negative");
      this.prepareEmotionAnalysisGraph(results[3].emotion.Emotion);
    });
  }

  public openMessageModal(specificUrl?: any, sentiment?:string) {
    specificUrl = specificUrl || "";
    let toCheckObj = {};
    if(sentiment){
      toCheckObj['Sentiment'] = [sentiment];
    }
    if(this.checkedSiteFilters && (this.checkedSiteFilters[0]!=="All channels")){
      toCheckObj['Site_Type'] = this.checkedSiteFilters;
    }
    if(!(Object.keys(toCheckObj).length === 0 && toCheckObj.constructor === Object)) {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, 'false', toCheckObj);
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl,  'false');
    }
  }

  ngOnInit() {
    this.checkedSiteFilters = this.myService.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }

}
