import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HeaderComponent} from '../../reusable/header/header.component';
import {InnerModalMessagesService} from './inner-modal-messages.service';

@Component({
  selector: 'app-inner-message-modal',
  templateUrl: './inner-message-modal.component.html',
  styleUrls: ['./inner-message-modal.component.css']
})
export class InnerMessageModalComponent implements OnInit {
  //messages: any[] = [];
  @Input() phrase;
  loadingData: boolean;
  @Input() messages: any[] = [];
  messagesSliced: any[] = [];
  public msgOffset = 1;


  constructor(
      public activeModal: NgbActiveModal,
      public header: HeaderComponent,
      public innerModalMessagesService: InnerModalMessagesService
  ) {
  }

  paginate(){
    this.msgOffset+=100
    //console.log(index);
    this.messagesSliced = this.messagesSliced.concat(this.messages.slice(this.msgOffset, this.msgOffset+100));
  }

  getMessagesModal(phrase: string): void {
    this.loadingData = true;
    const dates: any[] = this.header.getDateFromLS();
    this.innerModalMessagesService.getMessagesModal(dates[0], dates[1], phrase).then((messages: any[]) => {
      this.messages = Array.from(new Set( messages.map(message => message.Content_clean)));
      this.loadingData = false;
    }).catch((error: any) => {
      console.log(error);
    });
  }

  ngOnInit() {
    console.log(this.messages)
    if(!this.messages.length) {
      this.getMessagesModal(this.phrase);
    }
    else{
      this.messagesSliced = this.messages.slice(0,100);
    }
  }


}
