import {Component, OnInit, ViewChild} from "@angular/core";
import {HeaderComponent} from "../reusable/header/header.component";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {SharedServiceService} from "../services/shared-service.service";
import {HomeService} from "./home.service";
import * as $ from "jquery";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {

  public dates: any;
  public chartOption: any;
  public showLoader = false;
  public wordCloudObj: any;
  public totalMentions: any;
  public relevantMentions: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public resultExists = true;
  public totalInteractions: any;
  public positiveMentions: any;
  public negativeMentions: any;
  public mentionsByCat: any;
  public fontAwesomeIcons: any;
  public colorDict: any;
  public donutChartOption: any;
  public mostActiveSites: any;
  public topics: any;
  public barChartOption: any;
  public barSelectedSite: any;
  public status: any;
  public stackedOption: any;

  constructor(public myservice: SharedServiceService,
              public homeService: HomeService,
              public header: HeaderComponent,
              public echartGraphs: EchartGraphsService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {

    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.showSiteData();
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public showSiteData() {
    this.showLoader = true;
    this.dates = this.header.getDateFromLS();
    this.homeService.getData(this.dates).then((response: any) => {
      this.showLoader = false;
      this.totalMentions = response.Mentions;
      this.totalInteractions = response.Interactions;
      this.positiveMentions = response.Positive;
      this.negativeMentions = response.Negative;
      this.mentionsByCategory(response);
      this.mostActiveSites = response["specific_stes"];
      this.topics = response["Topics"];
      this.makeBarGraph("All");
      this.makeStackedLineChart(response);

    }).catch((error: any) => {
      throw error;
    });
  }

  public makeStackedLineChart(obj: any) {
    let legend = ["All channels", "Positive", "Negative"];
    this.stackedOption = this.echartGraphs.stackedLineChart(legend, obj["x-axis"],
      obj["All channels"]["freq"], obj["positive"]["freq"], obj["negative"]["freq"]);
  }

  public mentionsByCategory(obj: any) {

    this.fontAwesomeIcons = this.myservice.fontAwesomeIcons;
    this.mentionsByCat = obj.Site_Count;
    this.colorDict = this.myservice.colourdict;
    let donuChartObj = [];
    for (const i in this.mentionsByCat) {
      donuChartObj.push({value: this.mentionsByCat[i].value, name: this.mentionsByCat[i].name});
    }
    this.donutChartOption = this.echartGraphs.donutChart(donuChartObj);
  }

  public makeBarGraph(site?: any, clicked?: boolean) {
    if (clicked) {
      this.status = "temp";
    }
    if (site === "All") {
      this.barSelectedSite = "";
    } else {
      this.barSelectedSite = "Of " + site;
    }
    let barGraphObj = [];

    for (const i in this.topics) {
      barGraphObj.push(this.topics[i][site]);
    }
    this.barChartOption = this.echartGraphs.homeBarGraph(Object.keys(this.topics), "", barGraphObj, "#92c47d", 27);
  }

  public insights() {
    const insightBoolean = this.myservice.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  // TODO: no results logic is different for every module. It should be same,
  public isResultExists(label: any) {
    return label.length;
  }


  ngOnInit() {
    this.showSiteData();
  }

}
