import {Component, OnInit, ViewChild} from "@angular/core";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {SharedServiceService} from "../services/shared-service.service";
import {HeaderComponent} from "../reusable/header/header.component";
import {WearableService} from "./wearable.service";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: 'app-wearable',
  templateUrl: './wearable.component.html',
  styleUrls: ['./wearable.component.css']
})
export class WearableComponent implements OnInit {

  public dates: any;
  public barChartOption: any;
  public pieChart1Option: any;
  public pieChart2Option: any;
  public showLoader = false;
  public wordCloudObj1: any;
  public wordCloudObj2: any;
  public totalMentions1: any;
  public relevantMentions1: any;
  public totalMentions2: any;
  public relevantMentions2: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public msgUrl = "/wearable/msg";
  public resultExistsBar: boolean = true;
  public resultExistsPie1: boolean = true;
  public resultExistsPie2: boolean = true;
  public pieObj: any;
  public colorPalette = {"Bleed": "#2f4554", "Adhesive Issue": "#61a0a8", "Adhesive Issues": "#d48265", "Rash/Allergy": "#91c7ae", "Itch": "#749f83"}
  public checkedSiteFilters: any[] = [];
  public activeRadioLabelWearable: boolean = true;
  public activeRadioLabelWearableIssues: boolean = true;
  private totalMentionsObjWearable: any;
  private relevantMentionsObjWearable: any;
  private totalMentionsObjWearableIssues: any;
  private relevantMentionsObjWearableIssues: any;
  private activeRadioButtonNameWearable: string = "relevant";
  private activeRadioButtonNameWearableIssues: string = "relevant";

  @ViewChild(MessageModalComponent) child: MessageModalComponent;

  constructor(private myservice: SharedServiceService,
              private wearableService: WearableService,
              private Header: HeaderComponent,
              private echartGraphs: EchartGraphsService,
              private dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {

    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.Header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.emptyToggleObjects();
            this.showSiteData(this.checkedSiteFilters);
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters: [];
    this.dates = this.Header.getDateFromLS();
    this.insights();
    this.myservice.saveSiteInLS(this.checkedSiteFilters);
    this.wearableDevices();
    this.wearabilityIssues();
  }

  public wearableDevices(){

    let relevantMentionsBoolean = false;
    this.activeRadioButtonNameWearable === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    this.wearableService.getDataWearable(this.dates, this.checkedSiteFilters, relevantMentionsBoolean).then((response: any) => {
      this.showLoader = false;
      this.totalMentions1 = response.wearable.Mention.mentions;
      this.relevantMentions1 = response.wearable.count;
      this.barGraph(response.wearable);
      this.wordCloudObj1 = response.wearable.Mention.freq;
      if (this.activeRadioButtonNameWearable && this.activeRadioButtonNameWearable === "relevant") {
        this.relevantMentionsObjWearable = response;
      }
      else {
        this.totalMentionsObjWearable = response;
      }
    }).catch((error: any) => {
      throw error;
    });

}

  public wearabilityIssues() {

    let relevantMentionsBoolean = false;
    this.activeRadioButtonNameWearableIssues === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    this.wearableService.getDataWearableSideEffects(this.dates, this.checkedSiteFilters, relevantMentionsBoolean).then((response: any) => {
      this.showLoader = false;
      this.totalMentions2 = response.sideffect_breakup.Mention.mentions;
      this.relevantMentions2 = response.sideffect_breakup.count;
      this.pieObj = response.sideffect_breakup;
      this.pieChart("Abbott");
      this.pieChart("Dexcom");
      this.wordCloudObj2 = response.sideffect_breakup.Mention.freq;
      if (this.activeRadioButtonNameWearableIssues && this.activeRadioButtonNameWearableIssues === "relevant") {
        this.relevantMentionsObjWearableIssues = response;
      }
      else {
        this.totalMentionsObjWearableIssues = response;
      }
    }).catch((error: any) => {
      throw error;
    });

}

  public isResultExists(label: any) {
    return label.length;
  }

  public isResultPieExists(label: any, title) {
    if (label === undefined || label.length === 0) {
      title === "Abbott" ? this.resultExistsPie1 = false : this.resultExistsPie2 = false;
    }
    else {
      title === "Abbott" ? this.resultExistsPie1 = true : this.resultExistsPie2 = true;
    }
  }

  private emptyToggleObjects() {
    this.relevantMentionsObjWearable = {};
    this.totalMentionsObjWearable = {};
  }

  public toggleRelevantMentionsWearable(event: any, radioName: string) {
    // Checks added to make sure api call is not made every time. Only made when date or site filter is changed
    if (event.target.checked === true ) {
      if (radioName === "total") {
        this.activeRadioButtonNameWearable = radioName;
        if (!this.totalMentionsObjWearable || (Object.entries(this.totalMentionsObjWearable).length === 0 && this.totalMentionsObjWearable.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.barGraph(this.totalMentionsObjWearable.wearable);
          this.wordCloudObj1 = this.totalMentionsObjWearable.wearable.Mention.freq;
        }
      }
      else {
        this.activeRadioButtonNameWearable = radioName;
        if (!this.relevantMentionsObjWearable || (Object.entries(this.relevantMentionsObjWearable).length === 0 && this.relevantMentionsObjWearable.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.barGraph(this.relevantMentionsObjWearable.wearable);
          this.wordCloudObj1 = this.relevantMentionsObjWearable.wearable.Mention.freq;
        }
      }
    }
  }


  public toggleRelevantMentionsWearableIssues(event: any, radioName: string) {
    // Checks added to make sure api call is not made every time. Only made when date or site filter is changed
    if (event.target.checked === true) {
      if (radioName === "total") {
        this.activeRadioButtonNameWearableIssues = radioName;
        if (!this.totalMentionsObjWearableIssues || (Object.entries(this.totalMentionsObjWearableIssues).length === 0 && this.totalMentionsObjWearableIssues.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.pieObj = this.totalMentionsObjWearableIssues.sideffect_breakup;
          this.pieChart("Abbott");
          this.pieChart("Dexcom");
          this.wordCloudObj2 = this.totalMentionsObjWearableIssues.sideffect_breakup.Mention.freq;
        }
      }
      else {
        this.activeRadioButtonNameWearableIssues = radioName;
        if (!this.relevantMentionsObjWearableIssues || (Object.entries(this.relevantMentionsObjWearableIssues).length === 0 && this.relevantMentionsObjWearableIssues.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.pieObj = this.relevantMentionsObjWearableIssues.sideffect_breakup;
          this.pieChart("Abbott");
          this.pieChart("Dexcom");
          this.wordCloudObj2 = this.relevantMentionsObjWearableIssues.sideffect_breakup.Mention.freq;
        }
      }
    }
  }

  public barGraph(obj: any) {
    let barGraphObj = [];
    const color = this.myservice.siteColor;
    const legend = [];
    const yAxis = obj.label;
    this.resultExistsBar = this.isResultExists(yAxis);
    if (this.resultExistsBar) {
      for (const i in obj) {
        if (!(i === "Mention") && !(i === "count") && !(i === "label")) {
          legend.push(i);
          barGraphObj.push({name: i, type: "bar", data: obj[i], barGap: 0, color: color[i]});
        }
      }
      barGraphObj = this.myservice.sortByName(barGraphObj);
      legend.sort();
      this.barChartOption = this.echartGraphs.barGraph("", legend, {"type": "value", "data": {}, "light": true}, {
        "type": "category",
        "data": yAxis,
        "light": true
      }, barGraphObj);
    }
  }


  public pieChart(title: string) {
    let total = this.myservice.pieComparisonLegend(this.pieObj, title);
    let legend = [];
    let pieData = [];
    this.isResultPieExists(this.pieObj[title], title);
    if (title === "Abbott" ? this.resultExistsPie1 : this.resultExistsPie2) {
      for (let i = 0; i < this.pieObj[title].length; i++) {
        if (this.pieObj[title][i] !== 0) {
          let perc = Math.ceil(((this.pieObj[title][i] / total) * 100) * 100) / 100 + "%";
          pieData.push({value: this.pieObj[title][i], name: (this.pieObj.label)[i] + "\n" + perc, itemStyle:{color: this.colorPalette[(this.pieObj.label)[i]]}});
          legend.push(this.pieObj.label[i]);
        }
      }
      if (title === "Abbott") {
        this.pieChart1Option = this.echartGraphs.pieChart(title, legend, pieData);
      }
      else {
        this.pieChart2Option = this.echartGraphs.pieChart(title, legend, pieData);
      }

    }
  }

  public insights() {
    const insightBoolean = this.myservice.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  public openMessageModal(specificUrl?: any) {
    let activeRadioName: string;
    if (specificUrl === "/wearside") {
      activeRadioName = this.activeRadioButtonNameWearableIssues;
    }
    else {
      activeRadioName = this.activeRadioButtonNameWearable;
    }
    specificUrl = specificUrl || "";
    if (this.checkedSiteFilters.length && this.checkedSiteFilters[0] === "All channels") {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, activeRadioName);
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, activeRadioName, {Site_Type: this.checkedSiteFilters});
    }
  }

  ngOnInit() {
    this.checkedSiteFilters = this.myservice.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }

}
