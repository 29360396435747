import {Component, Input, OnInit} from '@angular/core';
import{MessageService} from "../../messages/message.service";
import {HeaderComponent} from "../header/header.component";
import {ModalService} from "../../messages/modal/modal.service";
import * as d3 from "d3";
import * as c3 from "c3";


const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.css']
})
export class SidePanelComponent implements OnInit {
  loadingData: boolean;
  filterWords: any[] = [];
  chartpie={};

  @Input() isOpen;
  @Input() previousPage;

  @Input() selectedWords;
  @Input() frequencySelectedwords;
  @Input() groupLabel="group 5";
  @Input() group=5;
  @Input() subCluster;
  @Input() parentWordsOccurrence= [ {text: "sensor", size: 194, rank: 70},
 {text: "pump", size: 152, rank: 60},
{text: "device", size: 120, rank: 51},
{text: "visit website", size: 75, rank: 39},
{text: "phone", size: 73, rank: 39},
 {text: "problem", size: 67, rank: 37},
 {text: "doctor", size: 63, rank: 36}]
  activeTabString = 'menu1';
tempselectwords=[];
  apiCalled = false;
  searchWord: string;
  allWords: any[] = [];
  deletedWords: string[] = [];
  words: any[] = [];
  keywords: any = {};
  selectedkeywords:any={};
  wordsWithCoordinates: any;
  phrases: any[] = [];
  toggleNameSort = false;
  masterSelected: boolean = false;
  isSelected: boolean;
  toggleEditName: boolean = false;
  selectedItem = null;
  newGroupLabel: string;
  phraseListHighlight: boolean = false;
  shortListedWords: number = 0;
  reDraw = false;
  statsHeader: string ;

  constructor(private messageService: MessageService,
              public header: HeaderComponent,      private modalService: ModalService,
  ) { }

  openList(){
    this.isOpen = !this.isOpen;
  }
  editLabelName() {
    this.newGroupLabel = this.groupLabel;
    this.toggleEditName = true;
  }

  public saveLabel(): void {
    const labelReq: any = {label: this.newGroupLabel, group: this.group, workspace: workspace};
    this.messageService.saveLabel(labelReq).then((resp: any) => {
      this.groupLabel = this.newGroupLabel;
      this.toggleEditName = false;

    }).catch((ex) => console.error(ex));
  }

  checkUncheckAll(): void {
      if(this.previousPage ==="seek&discover") {
          for (let i = 0; i < this.words.length; i++) {
              if (!this.keywords[this.words[i]].shortListedInSubCluster && !this.keywords[this.words[i]].shortListed) {
                  this.words[i].isSelected = this.masterSelected;
              }
          }
          this.filterWords = [];

          if (this.masterSelected) {
              for (let i = 0; i < this.words.length; i++) {
                  if (!this.keywords[this.words[i]].shortListedInSubCluster && !this.keywords[this.words[i]].shortListed) {
                      this.filterWords.push(this.words[i]);
                  }
              }
          }
          console.log(this.filterWords)
      }
      else{ for (let i = 0; i < this.words.length; i++) {
          if (!this.keywords[this.words[i].name].shortListedInSubCluster && !this.keywords[this.words[i].name].shortListed) {
              this.words[i].isSelected = this.masterSelected;
          }
      }
          this.filterWords = [];

          if (this.masterSelected) {
              for (let i = 0; i < this.words.length; i++) {
                  if (!this.keywords[this.words[i].name].shortListedInSubCluster && !this.keywords[this.words[i].name].shortListed) {
                      this.filterWords.push(this.words[i].name);
                  }
              }
          }
      }
  }
  isItemShortListed(d: any): boolean {
    return ((this.keywords[d.name] && this.keywords[d.name].shortListed) ||
        (this.keywords[d.name] && this.keywords[d.name].shortListedInSubCluster));
  }
  splitAndSortSLWords(words: any[]): any[] {
    let slWords: any[] = [], nonSLWords: any[] = [];
    words.forEach((d: any) => {
      if (this.isItemShortListed(d)) {
        slWords.push(d);
      } else {
        nonSLWords.push(d);
      }
    });
    slWords.sort((a, b) => b.value - a.value);
    nonSLWords.sort((a, b) => b.value - a.value);
    return slWords.concat(nonSLWords);
  }


  ascendingSort(): void {
    this.toggleNameSort = !this.toggleNameSort;
    console.log(this.words)
      let m=[];
      if(this.previousPage==="seek&discover") {
          console.log(this.frequencySelectedwords);
         for(let z=0;z<this.frequencySelectedwords.length;z++){
             m.push(this.frequencySelectedwords[z].name)
         }

          if (this.toggleNameSort) {
              m.sort((a, b) => a.localeCompare(b.name));
              this.allWords.sort((a, b) => a.localeCompare(b.name));
          } else {
              m = this.splitAndSortSLWords(m);
              this.words = this.splitAndSortSLWords(this.words);
              this.allWords = this.splitAndSortSLWords(this.allWords);
          }
          let c=[];

          for(let i=0;i<m.length;i++){
              for(let z=0;z<m.length;z++) {
                  console.log(m[i]);
                  console.log(this.frequencySelectedwords[z].name)
                  if (this.frequencySelectedwords[z].name === m[i]) {


                      c.push({name: m[i], value: this.frequencySelectedwords[z].value});

                  }
              }
          }
          console.log(c);
          this.frequencySelectedwords=[];
           this.frequencySelectedwords=c;
      }
      else {

          if (this.toggleNameSort) {
              this.words.sort((a, b) => a.name.localeCompare(b.name));
              this.allWords.sort((a, b) => a.name.localeCompare(b.name));
          } else {
              this.words = this.splitAndSortSLWords(this.words);
              this.allWords = this.splitAndSortSLWords(this.allWords);
          }
      }

  }
  search(): void {
      console.log('in search')

      if(this.searchWord===''){
          this.frequencySelectedwords=this.tempselectwords;
      }
      let d=[];
      if(this.previousPage==="seek&discover") {
          let m=[];
          if(this.previousPage==="seek&discover") {
              console.log(this.frequencySelectedwords);
              for (let z = 0; z < this.frequencySelectedwords.length; z++) {
                  m.push(this.frequencySelectedwords[z].name)
              }
          }

          //      d= m.filter((item: any) => {
          //     return item.startsWith(this.searchWord) ;
          console.log(m);
          console.log(d);
          // });
          for(let h=0;h<m.length;h++){
              console.log(this.searchWord);
              if(m[h].startsWith(this.searchWord)){
                  d.push(m[h]);
              }
          }
          let c=[];
          console.log(d);
          console.log(this.frequencySelectedwords)

          for(let i=0;i<d.length;i++){
              for(let z=0;z<m.length;z++) {
                  console.log(d[i]);
                  console.log(this.frequencySelectedwords[z].name)
                  if (this.frequencySelectedwords[z].name === d[i]) {


                      c.push({name: d[i], value: this.frequencySelectedwords[z].value});

                  }
              }
          }
          console.log(c);
          this.frequencySelectedwords=[];
          this.frequencySelectedwords=c;


      }
      else {

          this.words = this.allWords.filter((item: any) => {
              return item.name.startsWith(this.searchWord) && !this.deletedWords.includes(item.name);
          });
      }

  }
  removeShortListedWords(text: string): any {
    if (this.subCluster) {
      this.keywords[text].shortListedInSubCluster = false;
    } else {
      this.keywords[text].shortListed = false;
    }
    this.shortListedWords--;
    if (!this.toggleNameSort) {
      this.words = this.splitAndSortSLWords(this.words);


          this.allWords = this.splitAndSortSLWords(this.allWords);

    }
  }

  public updateWordsFilters(word: any) {
    if (word.isSelected) {
      if (!this.filterWords.includes(word.name)) {
        this.filterWords.push(word.name);
      }
    } else {
      const index = this.filterWords.indexOf(word.name);
      if (index > -1) {
        this.filterWords.splice(index, 1);
      }
      this.masterSelected = false;
    }
  }
  getPhrases(keyWord: string): void {
    console.log("in get phrases")
      console.log(keyWord)

    this.phraseListHighlight = true;
    this.selectedItem = keyWord;
    const dates: any[] = this.header.getDateFromLS();
    this.loadingData = true;

    /*this.toggleHighlight = !this.toggleHighlight;*/
    this.modalService.getPhrases(dates[0], dates[1], keyWord).then((phrases: any[]) => {
      this.phrases = phrases;
      this.loadingData = false;
    }).catch((error: any) => {
      console.error(error);
    });
  }
  filterDeletedWords(): void {


          this.words = this.allWords.filter((item: any) => {
              return !this.deletedWords.includes(item.name);
          });

  }
  recoverRemovedWords(word: string): any {
    const index = this.deletedWords.indexOf(word);
    if (index > -1) {
      this.deletedWords.splice(index, 1);
    }
    if (this.subCluster) {
      this.keywords[word].deletedInSubCluster = false;
    } else {
      this.keywords[word].deleted = false;
    }
    this.filterDeletedWords();
  }
  removeWords(event): any {
    if(event){
      this.selectionEventHandler(event);
    }
    this.filterWords.forEach((item: string) => {
      if (this.subCluster) {
        this.keywords[item].deletedInSubCluster = true;
      } else {
        this.keywords[item].deleted = true;
      }
    });
    this.deletedWords = this.deletedWords.concat(this.filterWords);
    this.filterWords.length = 0;
    this.filterDeletedWords();
    this.masterSelected = false;
    this.allWords.forEach((word: any) => {
      word.isSelected = false;
    });
    this.reDraw = true;
  }
  selectionEventHandler(selectedWords: any): void {
    this.reDraw = false;
    this.filterWords.length = 0;
    if (selectedWords && selectedWords.length) {
      selectedWords.forEach((obj: any) => {
        if (obj.isSelected === true) {
          this.filterWords.push(obj.word);
        }
      });
    }
  }

  shortListWords(event?: any): any {
      console.log(event)
    if(event){
      this.selectionEventHandler(event);
    }
    console.log(this.filterWords)
    this.filterWords.forEach((item: string) => {
        this.keywords[item].shortListed = true;

      this.shortListedWords++;
    });
    this.filterWords.length = 0;
    this.masterSelected = false;
    this.allWords.forEach((word: any) => {
        console.log(word)
      word.isSelected = false;
    });
    this.reDraw = true;
    if (!this.toggleNameSort) {
      this.words = this.splitAndSortSLWords(this.words);
      this.allWords = this.splitAndSortSLWords(this.allWords);
    }
  }
  callApi(): void{
    if(this.activeTabString === 'home' || this.activeTabString === 'menu1'){
      if(!this.apiCalled){
        this.prepareGraphData();
      }
    }
    else{
      this.statics();
    }
  }

  prepareGraphData(): void {
      console.log(this.selectedWords)
      console.log(this.previousPage)

    this.apiCalled = true;
    this.loadingData = true;
    let options: any = {};
    console.log(this.loadingData)
    console.log(this.group)

      options.group = this.group;

    console.log("the keyword is ")
    console.log(options)
      if(this.previousPage ==="seek&discover"){
          this.processKeywords(this.selectedWords);
          console.log("afterprocesskeywords")
          this.allWords = this.splitAndSortSLWords(this.selectedWords);
          this.filterDeletedWords();
          //this.wordsWithCoordinates = resp;
          this.loadingData = false;
          console.log(this.words)

      }
      else {
          this.modalService.getKeywords(options).then((keywords: any[]) => {
              console.log("afte getKeywords")
              console.log(keywords)
              const words: string[] = keywords.map((d: any) => d.name.trim());
              console.log(words)
              this.selectedkeywords = words

              console.log("after getcoordinates")
              const extent: number[] = d3.extent(keywords, (d: any) => d.value || 0);
              this.allWords = keywords.filter((d: any) => {
                  d.name = d.name.trim();
                  if (d.name) {
                      d.rank = this.messageService.calculateSizeRank(d.value, extent[1], extent[0], 30) + 10;
                      return true;
                  }
                  return false;
              });

              this.processKeywords(this.allWords);
              console.log("afterprocesskeywords")
              this.allWords = this.splitAndSortSLWords(this.allWords);
              this.filterDeletedWords();
              //this.wordsWithCoordinates = resp;
              this.loadingData = false;
              console.log(this.words)


          }).catch((error: any) => {
              console.error(error);
          });
      }
  }

  processKeywords(keywords: any[]): void {

    keywords.forEach((item: any) => {
      this.keywords[item.name] = item;
      if (item.deletedInSubCluster || item.deleted) {
        this.deletedWords.push(item.name);
      }
      if (item.shortListed || item.shortListedInSubCluster) {
        this.shortListedWords++;
      }
    });


  }
  statics(){
    this.statsHeader='stats'

    // console.log("the keywords for statsics are")
    // console.log(this.selectedkeywords)
    // this.selectedWords=this.selectedkeywords

  }

  openMessage(){
   console.log('test is done!');
  }

  ngOnInit():void {
    console.log(this.activeTabString)

    if(this.activeTabString === 'home' || this.activeTabString === 'menu1'){
      this.prepareGraphData();
      this.tempselectwords=this.frequencySelectedwords;




    }

  }

}
