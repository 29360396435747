import {Component, OnInit, ViewChild} from "@angular/core";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {SharedServiceService} from "../services/shared-service.service";
import {HeaderComponent} from "../reusable/header/header.component";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {CustomerServiceService} from "./customer-service.service";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: "app-customer-service",
  templateUrl: "./customer-service.component.html",
  styleUrls: ["./customer-service.component.css"]
})
export class CustomerServiceComponent implements OnInit {

  public showLoader = false;
  public dates: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public totalMentions: number;
  public labels: string[];
  public feedbackData:any ={};
  public feedbackDataKeys: string[];
  public wordCloudObj: any;
  public msgUrl = "/service/msg";
  public resultExists = true;
  public sentiments: any = {
    "Satisfactory": "Good customer service, Good/Quick replacement, Good refund policy",
    "Average": "Suggestion to contact customer care, Would contact later",
    "Poor": "No response/Bad customer service, Bad/Delayed replacement/refund, Bad refund"
  };
  public checkedSiteFilters: any[] = [];
  @ViewChild(MessageModalComponent) public child: MessageModalComponent;

  constructor(public myService: SharedServiceService,
              public header: HeaderComponent,
              public customerService: CustomerServiceService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {
    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.showSiteData(this.checkedSiteFilters);
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public insights() {
    const insightBoolean = this.myService.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  public isResultExists(label: any) {
    return label.length;
  }

  public openMessageModal(specificUrl?: any) {
    specificUrl = specificUrl || "";
    if (this.checkedSiteFilters.length && this.checkedSiteFilters[0] === "All channels") {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, 'false');
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, 'false', {Site_Type: this.checkedSiteFilters});
    }
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters: [];
    this.dates = this.header.getDateFromLS();
    this.feedbackData = {};
    this.insights();
    this.myService.saveSiteInLS(this.checkedSiteFilters);
    this.customerService.getData(this.dates, this.checkedSiteFilters).then((response: any) => {
      this.showLoader = false;
      this.totalMentions = response.count;
      this.labels = response.label;
      this.resultExists = this.isResultExists(this.labels);
      if(this.resultExists){
        let orderOfDisplay = ['Satisfactory', 'Average', 'Poor'];
        for(const i in orderOfDisplay){
          if(response[orderOfDisplay[i]]){
            this.feedbackData[orderOfDisplay[i]]=response[orderOfDisplay[i]];
          }
        }
        this.feedbackDataKeys = Object.keys(this.feedbackData);
      }
      this.wordCloudObj = response.Mention.freq;
    }).catch((error: any) => {
      throw error;
    });
  }

  ngOnInit() {
    this.checkedSiteFilters = this.myService.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }

}
