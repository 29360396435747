import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from '../message.service';
import {WordCloudService} from '../../reusable/d3/word-cloud/word-cloud.service';
import * as d3 from 'd3';
import {ModalService} from './modal.service';
import {ToastrService} from 'ngx-toastr';
import {HeaderComponent} from '../../reusable/header/header.component';
import {InnerMessageModalComponent} from '../inner-message-modal/inner-message-modal.component';
import {EditLabelComponent} from '../edit-label/edit-label.component';

const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  loadingData: boolean;
  filterWords: any[] = [];

  @Input() group;
  @Input() groupColor;
  @Input() groupLabel;
  @Input() nodeGroups;
  @Input() subCluster;
  @Input() activeTabString = 'statistics';
  @Input() selectedWords;
  @Input() parentWordsOccurrence;

  apiCalled = false;
  searchWord: string;
  allWords: any[] = [];
  deletedWords: string[] = [];
  words: any[] = [];
  keywords: any = {};
  wordsWithCoordinates: any;
  phrases: any[] = [];
  toggleNameSort = false;
  masterSelected: boolean = false;
  isSelected: boolean;
  toggleEditName: boolean = false;
  selectedItem = null;
  newGroupLabel: string;
  phraseListHighlight: boolean = false;
  shortListedWords: number = 0;
  reDraw = false;
  statsHeader: string = 'stats';

  constructor(
      public activeModal: NgbActiveModal,
      private messageService: MessageService,
      private wordCloudService: WordCloudService,
      private toastr: ToastrService,
      public header: HeaderComponent,
      private modalService: ModalService,
      private ngbModalService: NgbModal
  ) {
  }

  openWindowCustomClass(phrase: string): void {
    const modalRef: NgbModalRef = this.ngbModalService.open(InnerMessageModalComponent, {windowClass: 'innerMsgModal'});
    modalRef.componentInstance.phrase = phrase;
  }

  openEditLabelModalAndSaveWords(): void {
    if (!(this.groupLabel === 'Group - ' + this.group)) {
      this.saveWords();
    } else {
      const editModalRef: NgbModalRef = this.ngbModalService.open(EditLabelComponent, {windowClass: 'editMsgModal'});
      editModalRef.componentInstance.groupLabel = this.groupLabel;
      editModalRef.componentInstance.notifyParent.subscribe(result => {
        this.newGroupLabel = result;
        this.saveLabel();
      });
      editModalRef.result.then((dismissReason: any) => {
        if (dismissReason === 'Cross click') {
          this.saveWords();
        }
      }).catch((err: any) => {
        console.error('Error while saving words:' + err);
        this.saveWords();
      });
    }
  }

  editLabelName() {
    this.newGroupLabel = this.groupLabel;
    this.toggleEditName = true;
  }

  public saveLabel(): void {
    console.log("in savelabel")
    const labelReq: any = {label: this.newGroupLabel, group: this.group, workspace: workspace};
    this.messageService.saveLabel(labelReq).then((resp: any) => {
      this.groupLabel = this.newGroupLabel;
      this.toggleEditName = false;
      this.toastr.success('Label updated.', 'Success', {timeOut: 3000});
    }).catch((ex) => console.error(ex));
  }

  search(): void {
    this.words = this.allWords.filter((item: any) => {
      return item.name.startsWith(this.searchWord) && !this.deletedWords.includes(item.name);
    });
  }

  public updateWordsFilters(word: any) {
    if (word.isSelected) {
      if (!this.filterWords.includes(word.name)) {
        this.filterWords.push(word.name);
      }
    } else {
      const index = this.filterWords.indexOf(word.name);
      if (index > -1) {
        this.filterWords.splice(index, 1);
      }
      this.masterSelected = false;
    }
  }

  checkUncheckAll(): void {
    for (let i = 0; i < this.words.length; i++) {
      if (!this.keywords[this.words[i].name].shortListedInSubCluster && !this.keywords[this.words[i].name].shortListed) {
        this.words[i].isSelected = this.masterSelected;
      }
    }
    this.filterWords = [];
    if (this.masterSelected) {
      for (let i = 0; i < this.words.length; i++) {
        if (!this.keywords[this.words[i].name].shortListedInSubCluster && !this.keywords[this.words[i].name].shortListed) {
          this.filterWords.push(this.words[i].name);
        }
      }
    }
  }

  filterDeletedWords(): void {
    this.words = this.allWords.filter((item: any) => {
      return !this.deletedWords.includes(item.name);
    });
  }

  removeWords(event): any {
    if(event){
      this.selectionEventHandler(event);
    }
    this.filterWords.forEach((item: string) => {
      if (this.subCluster) {
        this.keywords[item].deletedInSubCluster = true;
      } else {
        this.keywords[item].deleted = true;
      }
    });
    this.deletedWords = this.deletedWords.concat(this.filterWords);
    this.filterWords.length = 0;
    this.filterDeletedWords();
    this.masterSelected = false;
    this.allWords.forEach((word: any) => {
      word.isSelected = false;
    });
    this.reDraw = true;
  }

  shortListWords(event?: any): any {
    if(event){
      this.selectionEventHandler(event);
    }
    this.filterWords.forEach((item: string) => {
      if (this.subCluster) {
        this.keywords[item].shortListedInSubCluster = true;
      } else {
        this.keywords[item].shortListed = true;
      }
      this.shortListedWords++;
    });
    this.filterWords.length = 0;
    this.masterSelected = false;
    this.allWords.forEach((word: any) => {
      word.isSelected = false;
    });
    this.reDraw = true;
    if (!this.toggleNameSort) {
      this.words = this.splitAndSortSLWords(this.words);
      this.allWords = this.splitAndSortSLWords(this.allWords);
    }
  }

  removeShortListedWords(text: string): any {
    if (this.subCluster) {
      this.keywords[text].shortListedInSubCluster = false;
    } else {
      this.keywords[text].shortListed = false;
    }
    this.shortListedWords--;
    if (!this.toggleNameSort) {
      this.words = this.splitAndSortSLWords(this.words);
      this.allWords = this.splitAndSortSLWords(this.allWords);
    }
  }

  recoverRemovedWords(word: string): any {
    const index = this.deletedWords.indexOf(word);
    if (index > -1) {
      this.deletedWords.splice(index, 1);
    }
    if (this.subCluster) {
      this.keywords[word].deletedInSubCluster = false;
    } else {
      this.keywords[word].deleted = false;
    }
    this.filterDeletedWords();
  }

  saveWords(): void {
    this.loadingData = true;
    const keywordsReq: any[] = Object.values(this.keywords).map((d: any) => {
      if (this.subCluster) {
        return {
          name: d.name,
          deletedInSubCluster: d.deletedInSubCluster,
          shortListedInSubCluster: d.shortListedInSubCluster
        };
      } else {
        return {
          name: d.name,
          deleted: d.deleted,
          shortListed: d.shortListed
        };
      }
    });
    this.modalService.upsertKeywords(keywordsReq).then((resp: any[]) => {
      this.loadingData = false;
      this.toastr.success('Changes saved.', 'Success', {timeOut: 3000});
      this.activeModal.close('saveWords');
    }).catch((error: any) => {
      console.error(error);
    });
  }

  processKeywords(keywords: any[]): void {
    console.log(this.keywords)
    keywords.forEach((item: any) => {
      this.keywords[item.name] = item;
      if (item.deletedInSubCluster || item.deleted) {
        this.deletedWords.push(item.name);
      }
      if (item.shortListed || item.shortListedInSubCluster) {
        this.shortListedWords++;
      }
    });
    console.log(this.keywords)
  }

  isItemShortListed(d: any): boolean {
    return ((this.keywords[d.name] && this.keywords[d.name].shortListed) ||
        (this.keywords[d.name] && this.keywords[d.name].shortListedInSubCluster));
  }

  splitAndSortSLWords(words: any[]): any[] {
    let slWords: any[] = [], nonSLWords: any[] = [];
    words.forEach((d: any) => {
      if (this.isItemShortListed(d)) {
        slWords.push(d);
      } else {
        nonSLWords.push(d);
      }
    });
    slWords.sort((a, b) => b.value - a.value);
    nonSLWords.sort((a, b) => b.value - a.value);
    return slWords.concat(nonSLWords);
  }

  ascendingSort(): void {
    this.toggleNameSort = !this.toggleNameSort;

    if (this.toggleNameSort) {
      this.words.sort((a, b) => a.name.localeCompare(b.name));
      this.allWords.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.words = this.splitAndSortSLWords(this.words);
      this.allWords = this.splitAndSortSLWords(this.allWords);
    }
  }

  getPhrases(keyWord: string): void {

    this.phraseListHighlight = true;
    this.selectedItem = keyWord;
    const dates: any[] = this.header.getDateFromLS();
    this.loadingData = true;

    /*this.toggleHighlight = !this.toggleHighlight;*/
    this.modalService.getPhrases(dates[0], dates[1], keyWord).then((phrases: any[]) => {
      this.phrases = phrases;
      this.loadingData = false;
    }).catch((error: any) => {
      console.error(error);
    });
  }

  selectionEventHandler(selectedWords: any): void {
    this.reDraw = false;
    this.filterWords.length = 0;
    if (selectedWords && selectedWords.length) {
      selectedWords.forEach((obj: any) => {
        if (obj.isSelected === true) {
          this.filterWords.push(obj.word);
        }
      });
    }
  }

  prepareGraphData(): void {
    this.apiCalled = true;
    this.loadingData = true;
    let options: any = {};
    if (this.subCluster) {
      options.subGroup = this.group;
    } else {
      options.group = this.group;
    }


    this.modalService.getKeywords(options).then((keywords: any[]) => {
      const words: string[] = keywords.map((d: any) => d.name.trim());
      this.modalService.getCoordinates(words).then((resp: any) => {
        const extent: number[] = d3.extent(keywords, (d: any) => d.value || 0);
        this.allWords = keywords.filter((d: any) => {
          d.name = d.name.trim();
          if (d.name) {
            d.rank = this.messageService.calculateSizeRank(d.value, extent[1], extent[0], 30) + 10;
            return true;
          }
          return false;
        });
        console.log(this.keywords)
        console.log(this.allWords)

        this.processKeywords(this.allWords);
        this.allWords = this.splitAndSortSLWords(this.allWords);
        this.filterDeletedWords();
        this.wordsWithCoordinates = resp;
        this.loadingData = false;
      }).catch((error: any) => {
        console.error(error);
      });
    }).catch((error: any) => {
      console.error(error);
    });
  }

  callApi(): void{
    if(this.activeTabString === 'home' || this.activeTabString === 'menu1'){
      if(!this.apiCalled){
        this.prepareGraphData();
      }
    }
  }

  ngOnInit(): void {
    if(this.activeTabString === 'home' || this.activeTabString === 'menu1'){
      this.prepareGraphData();


    }
  }
}
