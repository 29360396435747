import {Component, Input, OnInit} from '@angular/core';
import {SeekDiscoverService} from './seek-discover.service';
import {HeaderComponent} from '../reusable/header/header.component';
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ModalComponent} from "../messages/modal/modal.component";
import {ModalService} from "../messages/modal/modal.service";
import {WorkspaceService} from '../workspace/workspace.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from "../messages/message.service";
import {SharedServiceService} from "../services/shared-service.service";
import { Router} from '@angular/router';




const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';
@Component({
  selector: 'app-seek-discover',
  templateUrl: './seek-discover.component.html',
  styleUrls: ['./seek-discover.component.css']
})
export class SeekDiscoverComponent implements OnInit {

  @Input() groupLabel="Group 1";
  @Input() group=1;
  isOpen = true
  selectedall=false;
  apiCalled = false;
  searchWord: string;
  allWords: any[] = [];
  deletedWords: string[] = [];
  words: any[] = [];
  keywords: any = {};
  wordsWithCoordinates: any;
  phrases: any[] = [];
  toggleNameSort = false;
  masterSelected: boolean = false;
  isSelected: boolean;
  toggleEditName: boolean = false;
  selectedItem = null;
  newGroupLabel: string;
  phraseListHighlight: boolean = false;
  shortListedWords: number = 0;
  reDraw = false;
  Finish=false;
  finalisedic={}

  statsHeader: string = 'stats';

  constructor(private seekDiscoverService: SeekDiscoverService,
              private messageService: MessageService,
              public header: HeaderComponent,
              public sharedservice:SharedServiceService,
              private router:Router) { }



  public show:boolean = false;
  public buttonName:any = 'Show';
  public selectedKeywords: string[] = [];
  private similarKeywords: any[] = [];
  private clickedWords: any[] = [];
  private showLoader: boolean = false;
  private isApiResponseAwaited = false;
  private tabStatus = 'enter';
  private showPanel=false;
  private currentpage="seek&discover"
  groupedwords: any=[];
finalisedWords: any=[];
leftpannelarr=[];
  newval=[]
  ngOnInit () {

  }

  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.show)
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }

  toggle2() {

    $('body').addClass('blur');

    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.show)
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }

  leftpannel(arr){
    console.log(arr)
    this.showPanel =!this.showPanel


    this.isOpen = false;
    this.leftpannelarr.length=0;
    this.newval=[];


    for( let x=0;x<Object.keys(arr).length;x++){
      this.leftpannelarr.push(arr[x].word.keyword)
      this.newval.push({name:arr[x].word.keyword ,value:arr[x].word.count.total});
    }

    console.log(this.leftpannelarr)
    this.isOpen = true;

    console.log(this.showPanel)
}

  addKeywords(event): void {
    const keyword = event.target.value;
    if(keyword){
      this.selectedKeywords.push(event.target.value);
    }
  };

  removeKeywords(index): void {
    this.selectedKeywords.splice(index,1);
  }

  expandWordsDiv(): void {
    console.log(this.selectedKeywords)

  }

  getSimilarKeywords(): void {
    if (!this.isApiResponseAwaited && this.selectedKeywords.length) {
      this.isApiResponseAwaited = true;
      this.showLoader = true;
      const dates = this.header.getDateFromLS();
      console.log(this.selectedKeywords)
      let temparrsimilar=[];
      this.seekDiscoverService.getSimilarKeywords(dates[0], dates[1], this.selectedKeywords).then((response: any) => {
        if (response.error){
          throw response.error;
        } else {
          console.log(response)

          this.similarKeywords = response.response;

          for (var key in this.similarKeywords) {
            if(this.similarKeywords[key].length!==0){
              temparrsimilar[key]=this.similarKeywords[key];
            }
          }
          this.similarKeywords=temparrsimilar;
          this.groupedwords= Object.keys(this.similarKeywords)

            for (var key in this.similarKeywords) {

            if (this.similarKeywords.hasOwnProperty(key)) {
              this.similarKeywords[key] = this.similarKeywords[key].map(arr => {
                return ({word: arr, isSelected: false});
              });
            }
          }
          this.tabStatus = 'select';
        }
        this.showLoader = false;
        this.isApiResponseAwaited = false;
      }).catch((error: any) => {
        this.showLoader = false;
        this.isApiResponseAwaited = false;
        throw error;
      });
    }
  }

  checkedkeywords(word: any,count:any) {


    if(this.finalisedWords.includes(word)){
      let q= this.finalisedWords.indexOf(word)
           console.log(q)
      this.finalisedWords.splice(this.finalisedWords.indexOf(word),1)
      this.finalisedWords.splice(q,1)

    }
    else{
      this.finalisedWords.push(word)
      this.finalisedWords.push(count)
    }

  }

  nextpannel() {
    console.log(this.finalisedWords.length);
    for(let i=0;i<this.finalisedWords.length;i=i+2){
      this.finalisedic[this.finalisedWords[i]]=this.finalisedWords[i+1]
    }

    this.Finish=true;

    // this.sharedservice.keywordsTopics1((this.finalisedic) => {
    //   console.log(response)
    //
    // }).catch((error: any) => {
    //   this.showLoader = false;
    //   this.isApiResponseAwaited = false;
    //   throw error;
    // });
    let dictvardex={}
    let dictvarabbott={}
    let dictvarroche={}
    let dictvarmedt={}
    this.sharedservice.keywordsTopics=this.finalisedic


    for(let keys in this.similarKeywords){
      for(let i=0;i<this.similarKeywords[keys].length;i++){
          if(this.similarKeywords[keys][i].word.count.Dexcom!==undefined){
          //  console.log(this.similarKeywords[keys][i].word.count.Dexcom)
            dictvardex[this.similarKeywords[keys][i].word.keyword]=this.similarKeywords[keys][i].word.count.Dexcom
          }
          else if(this.similarKeywords[keys][i].word.count['Abbott FreeStyle']!==undefined){
            //  console.log(this.similarKeywords[keys][i].word.count.Dexcom)
            dictvarabbott[this.similarKeywords[keys][i].word.keyword]=this.similarKeywords[keys][i].word.count['Abbott FreeStyle']
          }
          else if(this.similarKeywords[keys][i].word.count['Roche Accu-Chek']!==undefined){
            //  console.log(this.similarKeywords[keys][i].word.count.Dexcom)
            dictvarroche[this.similarKeywords[keys][i].word.keyword]=this.similarKeywords[keys][i].word.count['Roche Accu-Chek']
          }
          else if(this.similarKeywords[keys][i].word.count['Medtronic Minimed']!==undefined){
            //  console.log(this.similarKeywords[keys][i].word.count.Dexcom)
            dictvarmedt[this.similarKeywords[keys][i].word.keyword]=this.similarKeywords[keys][i].word.count['Medtronic Minimed']
          }

      }
    }
    let all=[];
    all.push(dictvardex)
    all.push(dictvarabbott)
    all.push(dictvarroche)
    all.push(dictvarmedt)

    this.sharedservice.typeKeywords = all;
    console.log(dictvardex);

    this.router.navigate(['/custom-topics']);





  }

  clearfield() {
    this.selectedKeywords=[];
  }

  reset() {
    this.tabStatus='enter';
  }

  selectgrp() {
    console.log(this.similarKeywords);
    for (let i in this.similarKeywords) {
      for (let p = 0; p < this.similarKeywords[i].length;p++) {
        console.log(this.similarKeywords[i])
        console.log(this.similarKeywords[i][p])
        console.log(this.similarKeywords[i][p].word)

 console.log(this.similarKeywords[i][p].word.keyword)
        if (this.finalisedWords.includes(this.similarKeywords[i][p].word.keyword)) {
          let q = this.finalisedWords.indexOf(this.similarKeywords[i][p].word.keyword)
          console.log(q);

        } else {
          this.finalisedWords.push(this.similarKeywords[i][p].word.keyword);
          this.finalisedWords.push(this.similarKeywords[i][p].word.count.total);
        }
      }
    }
    console.log(this.finalisedWords)
    this. selectedall=true;
  }

  unselectgp(){
    this.finalisedWords.length=0;
    console.log(this.finalisedWords);
    this.selectedall=false;

  }
}
