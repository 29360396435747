

import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HeaderComponent} from '../reusable/header/header.component';
import {SharedServiceService} from '../services/shared-service.service';
import {HeaderDateChangedService} from '../reusable/header/header-date-changed.service';
import {TrendsService} from './trends.service';
import * as d3 from 'd3';
import {D3Service} from '../reusable/d3/d3.service';
import {Insight} from '../insights/insight.model';
import {InsightsService} from '../insights/insights.service';
import {ToastrService} from 'ngx-toastr';
import {UtilsService} from '../services/utils.service';
import {element} from "protractor";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';


@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.css']
})

export class TrendsComponent implements OnInit {

  siteSelected = 'trends';
  margin: any = {top: 20, right: 30, bottom: 110, left: 70};
  contextMargin: any = {top: 410, right: 30, bottom: 30, left: 70};
  aggregation = 'daily';
  aggregations: any[] = [{value: 'daily', key: 'Daily'}, {value: 'weekly', key: 'Weekly'}];
  annotationData: Insight[] = [];
  sites: any;
  dates: any;
  showLoader = false;
  insightsSecond = true;
  insightsFirst = true;
  checkedSiteFilters: any[] = [];
  svg: any;
  annotations: any;
  legendfilter=[];
   tempinsights  = [];
  draggableRect: any;
  x: any;
  y: any;
  x2: any;
  y2: any;
  flagtemp=0;
  width: any;
  height: any;
  height2: any;
  legends: string[] = ['Abbott FreeStyle', 'Dexcom', 'Medtronic Minimed', 'Roche Accu-Chek'];
  legendDataMap: any = {
    'Abbott FreeStyle': 'Abbott',
    'Dexcom': 'Dexcom',
    'Medtronic Minimed': 'Medtronic Minimed',
    'Roche Accu-Chek': 'Roche Accu-Chek'
  };
  color: any = {
    'Abbott FreeStyle': '#ed7c2f',
    'Dexcom': '#00b050',
    'Medtronic Minimed': '#006bbe',
    'Roche Accu-Chek': '#7f7f7f'
  };
  trendsData: any;
  sideBarData: any = {
    currentElement: null,
    state: 'close',
    createMode: false,
    editMode: false
  };
  checkBoxValue: string[] = [];

  channels: any = [
    {
      name: 'facebook',
      value: 'facebook',
      selected: false
    },
    {
      name: 'twitter',
      value: 'twitter',
      selected: false
    },
    {
      name: 'forum',
      value: 'forum',
      selected: false
    },
    {
      name: 'video',
      value: 'video',
      selected: false
    },
    {
      name: 'blog',
      value: 'blog'
    },
    {
      name: 'comments',
      value: 'comments',
      selected: false
    },
    {
      name: 'consumer',
      value: 'consumer',
      selected: false
    },
    {
      name: 'option',
      value: 'option',
      selected: false
    }
  ];

  selectedChannels: [string];

  createInsightForm: FormGroup = new FormGroup({
    device: new FormControl('', [Validators.required, Validators.nullValidator]),
    insights: new FormArray([]),
    channels: new FormControl([])
  });

  editInsightForm: FormGroup = new FormGroup({
    device: new FormControl('', [Validators.required]),
    insights: new FormArray([]),
    channels: new FormArray([])
  });
  isFormSubmitted = false;

  insight: Insight = new Insight({});
  showInsights = true;
  showLegendLine: any = {
    'Abbott FreeStyle': true,
    'Dexcom': true,
    'Medtronic Minimed': true,
    'Roche Accu-Chek': true
  };
  graphDrawn = false;


  expanded = false;

  showCheckboxes() {
    var checkboxes = document.getElementById('checkboxes');
    if (!this.expanded) {
      window.addEventListener('click', (e: any) => {
        if (document.getElementById('multiselect').contains(e.target) === true) {
          this.expanded = true;
          checkboxes.classList.add('showSelect');
        } else {
          this.expanded = false;
          checkboxes.classList.remove('showSelect');
        }
      });
    }
  }

  checkboxStatus(val): any {
    if (this.checkBoxValue.indexOf(val) < 0) {
      return this.checkBoxValue.push(val);
    } else {
      return this.checkBoxValue.splice(this.checkBoxValue.indexOf(val), 1);
    }
    console.log(this.checkBoxValue);
  }


  constructor(
      private d3Service: D3Service,
      private insightsService: InsightsService,
      private trendsService: TrendsService,
      private fb: FormBuilder,
      private toastr: ToastrService,
      public myService: SharedServiceService,
      public header: HeaderComponent,
      public dateChangedService: HeaderDateChangedService,
      private workspaceService: WorkspaceService) {
    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.showSiteData(this.checkedSiteFilters);
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  // Getter method to access form controls.
  get deviceControl() {
    if (this.sideBarData.createMode) {
      return this.createInsightForm.get('device');
    } else {
      return this.editInsightForm.get('device');
    }
  }

  getLinkControl(index: number) {
    let control: FormArray;
    if (this.sideBarData.editMode) {
      control = this.editInsightForm.controls.mentions as FormArray;
    } else {
      control = this.createInsightForm.controls.mentions as FormArray;
    }
    return control.controls[index]['controls'].link;
  }


  initInsightTextarea(text: string): FormGroup {
    // initialize insight textarea
    return this.fb.group({
      description: [text]
    });
  }

  toggle(index) {
    const toggleMenu = document.getElementById('toggleMenu' + index);
    if (toggleMenu.classList.contains('showDiv')) {
      toggleMenu.classList.remove('showDiv');
    } else {
      toggleMenu.className += ' showDiv';
    }

    const toggleMention = document.getElementById('toggleMention' + index);
    if (toggleMention.classList.contains('rotate-180')) {
      toggleMention.classList.remove('rotate-180');
    } else {
      toggleMention.className += ' rotate-180';
    }
  }

  checkActiveClass() {
    const getSliderId = document.getElementById('quick-div');
    const getAggregations = document.getElementById('aggregations');
    const getFilters = document.getElementById('filterby1');
    if (getSliderId.classList.contains('mystyle')) {
      getAggregations.classList.add('mooveToRight');
      getFilters.classList.add('mooveToRight');
      /*      alert("mystyle Class present");*/
    } else {
      getAggregations.classList.remove('mooveToRight');
      getFilters.classList.add('mooveToRight');
    }
  }

  sticky() {
    const el = document.getElementById('quick-div');
    window.addEventListener('scroll', function () {
      if (document.documentElement.scrollTop > 90) {
        /*el.style.position = "fixed";*/
        el.style.top = '0px';
      } else {
        /*el.style.position = "absolute";*/
        el.style.top = '95px';
      }
    });
  }

  initMentionInputFields(excerpt: string, link: string): FormGroup {
    // initialize mention input fields
    const reg = '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$';
    return this.fb.group({
      excerpt: [excerpt],
      link: [link, [Validators.pattern(reg)]]
    });
  }

  initInsightForm(element: any): FormGroup {
    return this.fb.group({
      device: [element.device || ''],
      insights: this.fb.array([]),
      mentions: this.fb.array([]),
      channels: this.fb.array([])
    });
  }

  initChannelCheckBox(arrayControl: FormArray, channels: any[]): void {
    channels.forEach(channel => {
      arrayControl.push(this.fb.group({
        name: [channel.name],
        selected: [channel.selected || false]
      }));
    });
  }

  buildCreateInsightFormData(element: any): void {
    const newForm = this.initInsightForm(element);
    const arrayControl1: FormArray = newForm.controls.insights as FormArray;
    arrayControl1.push(this.initInsightTextarea(''));
    const arrayControl3: FormArray = newForm.controls.channels as FormArray;
    this.initChannelCheckBox(arrayControl3, this.channels);
    const arrayControl2: FormArray = newForm.controls.mentions as FormArray;
    arrayControl2.push(this.initMentionInputFields('', ''));
    this.createInsightForm = newForm;
  }

  buildEditInsightFormData(element: any): void {
    const newForm = this.initInsightForm(element);

    const arrayControl1: FormArray = newForm.controls.insights as FormArray;
    if (element.insights && element.insights.length) {
      element.insights.forEach(item => {
        arrayControl1.push(this.initInsightTextarea(item.description));
      });
    } else {
      arrayControl1.push(this.initInsightTextarea(''));
    }

    const arrayControl2: FormArray = newForm.controls.mentions as FormArray;
    if (element.mentions && element.mentions.length) {
      element.mentions.forEach(item => {
        arrayControl2.push(this.initMentionInputFields(item.excerpt, item.link));
      });
    } else {
      arrayControl2.push(this.initMentionInputFields('', ''));
    }

    const arrayControl3: FormArray = newForm.controls.channels as FormArray;
    this.initChannelCheckBox(arrayControl3, element.channels);

    /*const arrayControl3: FormArray = newForm.controls.channels as FormArray;
    const newForm = this.initChannelCheckBox(element.channels);
    if (element.channels && element.channels.length) {
      element.channels.forEach(item => {
        //noinspection TypeScriptValidateTypes
        arrayControl3.push(this.initChannelCheckBox(channels.name));
      });
    } else {
      arrayControl3.push(this.initChannelCheckBox(""));
    }*/

    this.editInsightForm = newForm;
  }


  addInsightTextArea(): void {
    let control: FormArray;
    if (this.sideBarData.editMode) {
      control = this.editInsightForm.controls.insights as FormArray;
    } else {
      control = this.createInsightForm.controls.insights as FormArray;
    }
    control.push(this.initInsightTextarea(''));
  }

  removeInsightTextArea(index: number): void {
    let control: FormArray;
    if (this.sideBarData.editMode) {
      control = this.editInsightForm.controls.insights as FormArray;
    } else {
      control = this.createInsightForm.controls.insights as FormArray;
    }
    control.removeAt(index);
  }

  addMentionInputFields(): void {
    let control: FormArray;
    if (this.sideBarData.editMode) {
      control = this.editInsightForm.controls.mentions as FormArray;
    } else {
      control = this.createInsightForm.controls.mentions as FormArray;
    }
    control.push(this.initMentionInputFields('', ''));
  }

  removeMentionInputFields(index: number): void {
    let control: FormArray;
    if (this.sideBarData.editMode) {
      control = this.editInsightForm.controls.mentions as FormArray;
    } else {
      control = this.createInsightForm.controls.mentions as FormArray;
    }
    control.removeAt(index);
  }

  showSiteData(checkedSiteFilters: any[]) {
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters : [];
    this.showLoader = true;
    this.dates = this.header.getDateFromLS();
    this.insights();
    this.myService.saveSiteInLS(this.checkedSiteFilters);
    Promise.all([
      this.trendsService.getData(this.dates, this.checkedSiteFilters),
      this.insightsService.getInsightsForTopic('trends')
    ]).then((result: any[]) => {
      this.showLoader = false;
      this.trendsData = result[0];
      this.annotationData = this.sortInsights(result[1]);
     // this.tempinsights=this.annotationData;
      this.drawLineGraph(this.margin, this.contextMargin);
    }).catch((ex: any) => {
      console.error('Error while fetching trend data - ', ex);
      throw ex;
    });
  }

  insights() {
    const insightBoolean = this.myService.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  public getDateInHumanReadableForm(date1: Date, date2: Date): any {
    const strDate1: string = UtilsService.getDateInHumanReadableForm(date1);
    const strDate2: string = UtilsService.getDateInHumanReadableForm(date2);
    return (strDate1 === strDate2) ? strDate1 : (strDate1 + ' - ' + strDate2);
  }

  public showAnnotations(): void {
    this.showInsights = true;
    this.generateAnnotations(this.x, this.width, this.height);
  }

  public hideAnnotations(): void {
    this.showInsights = false;
    this.annotations.selectAll('.annotation').remove();
  }


  public aggregateData(aggregation: string): void {
    this.aggregation = aggregation;
    this.drawLineGraph(this.margin, this.contextMargin);
  }


  public toggleLegendLine(legendName: string): void {
    console.log(legendName)
    //console.log(this.tempinsights);
    console.log(this.flagtemp)
 if(this.flagtemp==0){
   console.log("in if ")
   let m=this.annotationData.length
   console.log(this.annotationData[0])
   for(let x=0;x<this.annotationData.length;x++){
     this.tempinsights.push(this.annotationData[x]);
   }

   console.log(this.tempinsights)
 }
 else {
   this.annotationData=[];
   console.log(this.tempinsights)
   //this.annotationData = this.tempinsights;
   for(let x=0;x<this.tempinsights.length;x++){
     this.annotationData.push(this.tempinsights[x]);
   }

 }

    if(this.legendfilter.includes(legendName)){
      var i = this.legendfilter.indexOf(legendName);
      this.legendfilter.splice(i,1);
    }
    else{
      this.legendfilter.push(legendName)
    }
    for(let m=this.annotationData.length-1;m>=0;m--){

      if(this.legendfilter.includes(this.annotationData[m].device)){
        this.annotationData.splice(m,1)

      }
    }
    console.log(this.annotationData)
    this.flagtemp = this.flagtemp+1;

    this.showLegendLine[legendName] = !this.showLegendLine[legendName];
    this.drawLineGraph(this.margin, this.contextMargin);
  }

  private initSvg(): void {
    d3.select('.trend-line-chart')
        .select('svg').remove();
    this.svg = d3.select('.trend-line-chart').append('svg')
        .attr('width', window.innerWidth * .81)
        .attr('height', 480);
  }

  private generateSideBarData(element: Insight, index?: number): void {
    this.sideBarData.currentElement = element;
    if (this.sideBarData.state === 'close') {
      this.toggleRightSlider();
    }
    if (typeof index === 'number') {
      this.highlightAnnotation(index);
    }
  }

  private unHighlightExistingHighlightedInsights(index?: number): boolean {
    const sideBarElems: HTMLCollectionOf<Element> = document.getElementsByClassName('active');
    if (sideBarElems && sideBarElems.length) {
      if (sideBarElems[0].id === ('insight' + index)) { // If selected insight is already active.
        return false;
      }
      sideBarElems[0].classList.remove('active');
      return true;
    }
    return true;
  }

  private highlightSideBarInsight(index: number): void {
    if (this.unHighlightExistingHighlightedInsights(index)) {
      const sideBarElem: Element = document.getElementById('insight' + index);
      if (sideBarElem) {
        sideBarElem.className += ' active';
        sideBarElem.scrollIntoView();
      }
    }
  }

  private highlightAnnotation(index, highlightSideBarElem?: boolean): void {
    if (!this.showInsights) {
      this.showAnnotations();
    }
    this.svg.selectAll('.annotation')
        .data(this.annotationData)
        .style('stroke-width', (d: any, i: number) => {
          if (i === index) {
            return 3;
          } else {
            return 0;
          }
        })
        .style('stroke', (d: any, i: number) => {
          if (i === index) {
            return '#04b0fd';
          } else {
            return '';
          }
        })
        .style('opacity', (d: any, i: number) => {
          if (i === index) {
            return 0.6;
          } else {
            return 0.4;
          }
        });
    if (highlightSideBarElem) {
      this.highlightSideBarInsight(index);
    }
  }

  private generateAnnotations(xAxis: any, width: number, height: number): void {
    this.annotations.selectAll('.annotation')
        .data(this.annotationData)
        .enter().append('rect')
        .attr('class', 'annotation')
        .attr('id', (element: any, index: number) => {
          return ('annotation' + index);
        })
        .style('fill', 'black')
        .style('opacity', 0.4)
        .attr('pointer-events', 'all')
        .attr('width', (element: any) => {
          const mousePointers: any = this.d3Service.findXAxisMousePointers(xAxis.domain(), width, {
            start: new Date(element.coordinates.graph.start.x),
            end: new Date(element.coordinates.graph.end.x)
          });
          element.coordinates.svg.start.x = mousePointers.start;
          return (mousePointers.end - mousePointers.start);
        })
        .attr('height', (element: any) => {
          return (height - element.coordinates.svg.start.y);
        })
        .attr('transform', (element: any) => {
          return ('translate(' + element.coordinates.svg.start.x + ',' + element.coordinates.svg.start.y + ')');
        })
        .on('click', (element: Insight, index: number) => {
          this.highlightSideBarInsight(index);
          this.generateSideBarData(element, index);
        });
  }

  public sortInsights(insights: Insight[]): Insight[] {
    return insights.sort((a: Insight, b: Insight) => {
      return (new Date(a.coordinates.graph.start.x).getTime()) - (new Date(b.coordinates.graph.start.x).getTime());
    });
  }

  private storeInsightInSortedOrder(newInsight: Insight): number {
    let insertionIndex: number;
    for (let index = 0; index < this.annotationData.length; index++) {
      const insight: Insight = this.annotationData[index];
      if (new Date(newInsight.coordinates.graph.start.x) < new Date(insight.coordinates.graph.start.x)) {
        insertionIndex = index;
        break;
      }
    }
    if (insertionIndex !== undefined) {
      this.annotationData.splice(insertionIndex, 0, newInsight);
    } else {
      insertionIndex = this.annotationData.length;
      this.annotationData.push(newInsight);
    }
    return insertionIndex;
  }

  private removeTemporarilyStoredInsight(): Insight[] {
    return this.annotationData.filter((insight: Insight) => {
      return (insight._id);
    });
  }

  public saveAnnotation(): any {
    this.isFormSubmitted = true;
    if (this.sideBarData.createMode) {
      if (!this.createInsightForm.valid) {
        return false;
      }
      Object.assign(this.insight, this.createInsightForm.value);
      this.insight.insights = this.insight.insights.filter((data: any) => {
        return (data.description);
      });
      this.insight.mentions = this.insight.mentions.filter((data: any) => {
        return (data.excerpt || data.link);
      });
      this.insightsService.saveInsights([this.insight]).then((insights: Insight[]) => {
        this.toastr.success(
            'Insight successfully created.',
            'Success',
            {
              timeOut: 3000
            });
        this.isFormSubmitted = false;
        this.annotationData = this.removeTemporarilyStoredInsight();
        this.storeInsightInSortedOrder(insights[0]);
        this.hideAnnotations();
        this.showAnnotations();
        this.sideBarData.createMode = false;
        this.generateSideBarData(null);
      }).catch((ex: Error) => {
        this.toastr.error(
            'Error while creating insights.',
            'Error',
            {
              timeOut: 3000
            });
        console.error('Error while saving insights - ', ex);
      });
    } else {
      if (!this.editInsightForm.valid) {
        return false;
      }
      Object.assign(this.insight, this.editInsightForm.value);
      this.insight.insights = this.insight.insights.filter((data: any) => {
        return (data.description);
      });
      this.insight.mentions = this.insight.mentions.filter((data: any) => {
        return (data.excerpt || data.link);
      });
      this.insightsService.editInsight(this.insight).then((resp: any) => {
        this.toastr.success(
            'Insight successfully updated.',
            'Success',
            {
              timeOut: 3000
            });
        this.isFormSubmitted = false;
        this.annotationData.forEach((insight: Insight) => {
          if (insight._id === this.insight._id) {
            insight.device = this.insight.device;
            insight.description = this.insight.description;
          }
        });
        this.sideBarData.editMode = false;
        this.generateSideBarData(null);
      }).catch((ex: Error) => {
        this.toastr.error(
            'Error while updating insights.',
            'Error',
            {
              timeOut: 3000
            });
        console.error('Error while updating insights - ', ex);
      });
    }
  }

  public cancelFormSubmission(): void {
    this.annotationData = this.removeTemporarilyStoredInsight();
    this.unHighlightExistingHighlightedInsights();
    this.highlightAnnotation(null);
    if (this.sideBarData.createMode) {
      this.hideAnnotations();
      this.showAnnotations();
    }
    this.sideBarData.createMode = false;
    this.sideBarData.editMode = false;
    this.isFormSubmitted = false;
  }

  public editInsight(element: any): void {
    this.sideBarData.editMode = true;
    this.sideBarData.currentElement = null;
    this.insight = element;
    this.buildEditInsightFormData(element);
  }

  public deleteAnnotation(id: string): void {
    this.insightsService.deleteInsight(id).then((resp: any) => {
      this.toastr.success(
          'Insight successfully deleted.',
          'Success',
          {
            timeOut: 3000
          });
      this.annotationData = this.annotationData.filter((element: Insight) => {
        return (element._id !== id);
      });
      this.sideBarData.currentElement = null;
      this.hideAnnotations();
      this.showAnnotations();
    }).catch((error: Error) => {
      this.toastr.error(
          'Error while deleting insights.',
          'Error',
          {
            timeOut: 3000
          });
      console.error('Error while deleting annotation - ', error);
    });
  }

  private createSelectedInsight(tagCoordinates: any): void {
    if (this.sideBarData.createMode) {
      this.toastr.error(
          'Cannot create multiple insights together.',
          'Error',
          {
            timeOut: 3000
          });
    } else if (this.sideBarData.editMode) {
      this.toastr.error(
          'Cannot edit and create insights together.',
          'Error',
          {
            timeOut: 3000
          });
    } else if (this.showInsights) {
      this.insight = new Insight({
        coordinates: tagCoordinates,
        topic: 'trends',
        subTopic: 'trends',
        category: 'range',
        userId: 'dynamic',
        orgId: 'dynamic'
      });

      this.buildCreateInsightFormData({});
      this.unHighlightExistingHighlightedInsights();
      this.sideBarData.createMode = true;
      const storedIndex = this.storeInsightInSortedOrder(this.insight);
      this.hideAnnotations();
      this.showAnnotations();
      this.generateSideBarData(null);
      setTimeout(() => {
        this.highlightAnnotation(storedIndex, true);
      }, 100);
    } else {
      this.toastr.error(
          'Insights are disabled. Please enable insights to create a new one.',
          'Error',
          {
            timeOut: 3000
          });
    }
  }

  private generateRectStartCoordinates(mousePointer: number[]): any {
    const x0: any = this.x.invert(mousePointer[0]);
    const y0: any = this.y.invert(mousePointer[1]);
    return {
      graph: {
        start: {
          x: x0,
          y: y0
        }
      },
      svg: {
        start: {
          x: mousePointer[0],
          y: mousePointer[1]
        }
      }
    };
  }

  private generateRectCoordinates(mousePointer: number[], tagCoordinates: any): void {
    const x0: any = this.x.invert(mousePointer[0]);
    const y0: any = this.y.invert(mousePointer[1]);
    tagCoordinates.graph.end = {
      x: x0,
      y: y0
    };
    tagCoordinates.svg.end = {
      x: mousePointer[0],
      y: mousePointer[1]
    };
    tagCoordinates.svg.start.y = tagCoordinates.svg.end.y;
    tagCoordinates.graph.start.y = tagCoordinates.graph.end.y;
  }

  private initDraggableRect(mouseG: any): void {
    this.draggableRect.attr('x', 0).attr('y', 0).attr('width', 0).attr('height', 0);
    mouseG.on('mousemove', null);
  }

  private drawLineGraph(margin: any, contextMargin: any): void {
    this.graphDrawn = false;
    const _this = this;
    const rawData: any = this.trendsData[this.siteSelected];
    const data: any[] = this.trendsService.formatData(rawData, this.legends, this.showLegendLine, this.legendDataMap, this.aggregation);
    this.initSvg();
    const [width, height, height2] = this.d3Service.calculateSvgDimensions(this.svg, margin, contextMargin);
    this.width = width;
    this.height = height;
    this.height2 = height2;
    const [x, y, x2, y2] = this.d3Service.drawAxises(
        {xAxisScale: this.trendsService.reScaleXAxisData(rawData['x-axis'], this.aggregation), data: data},
        {width: this.width, height1: this.height, height2: this.height2},
        {y: 'trend', x: 'date'});
    this.x = x;
    this.y = y;
    this.x2 = x2;
    this.y2 = y2;

    const line: any = this.d3Service.drawLine(this.x, this.y, {y: 'trend', x: 'date'});
    const line2: any = this.d3Service.drawLine(this.x2, this.y2, {y: 'trend', x: 'date'});

    this.d3Service.appendClipPath(this.svg, this.width, this.height);
    /*    this.createLegendGroup(margin, this.width);*/
    const focus: any = this.d3Service.createFocusableAxises(this.svg, margin, this.height, this.x, this.y);
    const legendDataLine: any = this.d3Service.createLegendDataLine(this.svg, data, margin, line, this.color);
    const mouseG: any = this.d3Service.createMouseOverEffects(this.svg, margin);
    mouseG.on('mousedown', startDragging)
        .on('mouseup', endDragging);
    this.d3Service.generateStatsOnMouseOver(mouseG, data, this.color);

    const brush = d3.brushX()
        .extent([[0, 0], [this.width, this.height2]])
        .on('brush end', () => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') {
            return;
          } // ignore brush-by-zoom
          const s = d3.event.selection || this.x2.range();
          this.x.domain(s.map(this.x2.invert, this.x2));
          legendDataLine.selectAll('.line').attr('d', (d: any) => line(d.values));
          focus.select('.axis--x').call(d3.axisBottom(this.x));
          this.svg.select('.zoom').call(zoom.transform, d3.zoomIdentity
              .scale(this.width / (s[1] - s[0]))
              .translate(-s[0], 0));
          this.d3Service.reDrawRectOnZoom(this.annotations, this.annotationData, this.x, this.width);
        });

    const zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [this.width, this.height]])
        .extent([[0, 0], [this.width, this.height]])
        .on('zoom', () => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') {
            return;
          } // ignore zoom-by-brush
          const t = d3.event.transform;
          if (this.graphDrawn) {
            this.trendsService.setZoomFactor(t.k, t.x);
          }
          this.x.domain(t.rescaleX(this.x2).domain());
          legendDataLine.selectAll('.line').attr('d', (d: any) => line(d.values));
          focus.select('.axis--x').call(d3.axisBottom(this.x));
          context.select('.brush').call(brush.move, this.x.range().map(t.invertX, t));
          this.d3Service.reDrawRectOnZoom(this.annotations, this.annotationData, this.x, this.width);
        });

    let tagCoordinates: any = {
      graph: {},
      svg: {}
    };
    const rect: any = this.d3Service.createRectForMouseEvents(mouseG, this.width, this.height, zoom);
    rect.on('mousemove', mousemoveListener);

    // Generate annotations.
    this.annotations = mouseG.append('g').attr('class', 'annotations');
    this.draggableRect = mouseG.append('rect').attr('class', 'draggableRect');
    this.initDraggableRect(mouseG);
    if (this.showInsights) {
      this.generateAnnotations(this.x, this.width, this.height);
    }

    // Create brush-able context.
    const context = this.d3Service.createContext(this.svg, contextMargin, data, line2);
    this.d3Service.createBrushBehaviorOnContext(context, this.height2, this.x, this.x2, brush);

    function startDragging() {
      // recover coordinate we need
      const mousePointer: any = d3.mouse(this);
      tagCoordinates = _this.generateRectStartCoordinates(mousePointer);
      _this.draggableRect
          .attr('x', tagCoordinates.svg.start.x)
          .attr('y', tagCoordinates.svg.start.y)
          .attr('height', 0)
          .attr('width', 0)
          .style('opacity', 0.4);
      mouseG.on('mousemove', dragging);
    }

    function dragging() {
      const mousePointer: any = d3.mouse(this);
      _this.generateRectCoordinates(mousePointer, tagCoordinates);
      _this.draggableRect
          .attr('y', tagCoordinates.svg.start.y)
          .attr('width', Math.max(0, mousePointer[0] - tagCoordinates.svg.start.x))
          .attr('height', _this.height - tagCoordinates.svg.start.y);
    }

    function endDragging() {
      _this.initDraggableRect(mouseG);
      // recover coordinate we need
      const mousePointer: any = d3.mouse(this);
      _this.generateRectCoordinates(mousePointer, tagCoordinates);

      const rectWidth: number = tagCoordinates.svg.end.x - tagCoordinates.svg.start.x;
      if (rectWidth > 0) {
        _this.createSelectedInsight(tagCoordinates);
      }
    }

    function mousemoveListener() { // mouse moving over canvas
      const mouse: any = d3.mouse(this);
      d3.select('.mouse-line')
          .attr('d', () => {
            let d = 'M' + mouse[0] + ',' + _this.height;
            d += ' ' + mouse[0] + ',' + 0;
            return d;
          });

      const xDate = _this.x.invert(mouse[0]);
      const bisect = d3.bisector((c: any) => c.date).left;
      let xId: number = bisect(data[0].values, xDate) || 1;
      const dateStats: string = data[0].values[xId - 1].date.toDateString();
      const trendStats: string[] = [];

      data.forEach((item: any) => {
        xId = bisect(item.values, xDate) || 1;
        trendStats.push(item.name + ': ' + item.values[xId - 1].trend);
      });

      d3.select('.dateStats').text(dateStats);
      d3.selectAll('.trendStats')
          .data(trendStats)
          .text((d: any) => d);

      const lineStatsCoordinates = {
        x: mouse[0] + 20,
        y: mouse[1] + 20
      };
      if ((_this.width - lineStatsCoordinates.x) < 170) {
        lineStatsCoordinates.x = mouse[0] - 170 - 20;
      }
      if ((_this.height - lineStatsCoordinates.y) < 110) {
        lineStatsCoordinates.y = mouse[1] - 110 - 20;
      }
      d3.select('.line-stats')
          .attr('transform', 'translate(' + lineStatsCoordinates.x + ',' + lineStatsCoordinates.y + ')');
    }

    const zoomFactor = this.trendsService.getZoomFactor();
    this.svg.select('.zoom').call(zoom.transform, d3.zoomIdentity.translate(+zoomFactor.x, 0).scale(+zoomFactor.k));

    this.graphDrawn = true;
  }

  ngOnInit() {
    this.checkedSiteFilters = this.myService.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
    window.addEventListener('resize', () => {
      this.drawLineGraph(this.margin, this.contextMargin);
    });
    this.sticky();
  }

  public toggleRightSlider(): void {
    /* const element = document.getElementById("quick-div");
     element.classList.toggle("mystyle");*/

    if (this.sideBarData.state === 'close') {
      this.margin.right = 310;
      this.contextMargin.right = 310;
      this.sideBarData.state = 'open';
    } else {
      this.margin.right = 30;
      this.contextMargin.right = 30;
      this.sideBarData.state = 'close';
      this.sideBarData.currentElement = null;
      this.cancelFormSubmission();
    }
    this.drawLineGraph(this.margin, this.contextMargin);
  }
}
