import { Injectable } from '@angular/core';
import {ApiService} from '../services/api.service';


@Injectable({
  providedIn: 'root'
})
export class SeekDiscoverService {

  constructor(private apiService: ApiService)
  { }
  public getSimilarKeywords(start: any, end: any, words: string[]): Promise<any> {
    const url = '/keywords/getSimilarKeywords';
    return new Promise((resolve, reject) => {
      this.apiService.put(url, {start_date: start, end_date: end, keywords: words}).subscribe((response) => {
        console.log(response)
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }
}
