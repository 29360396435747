import {Component, OnInit, ChangeDetectionStrategy, ViewChild, Input} from "@angular/core";
import {MessageModalServiceService} from "./message-modal-service.service";
import * as moment from "moment";
import {DaterangePickerComponent} from "ng2-daterangepicker";
import {SharedServiceService} from "../../services/shared-service.service";
import {HeaderDateChangedService} from "../header/header-date-changed.service";
import {WorkspaceService} from "../../workspace/workspace.service";
import {HeaderComponent} from "../header/header.component";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: "app-message-modal",
  templateUrl: "./message-modal.component.html",
  styleUrls: ["./message-modal.component.css"],
  changeDetection: ChangeDetectionStrategy.Default
})

export class MessageModalComponent implements OnInit {
  @Input() keywords=[];
  @Input() datesrange=[];
  @Input() forTopics=false

  // Todo: date sent as moment object and sometimes as string.Should be consistent
  public modal: any;
  public dates: any;
  public loading = true;
  public msgObj: any = [];
  public filters: any;
  public fontAwesomeNames = {
    "Twitter": "fa fa-twitter",
    "Forum": "fa fa-wpforms",
    "Video and Photo": "fa fa-youtube",
    "Blog": "fa fa-rss",
    "Social network": "fa fa-facebook",
    "Consumer opinions": "fa fa-users"
  };

  public checkedFilters: any = {};
  public url: string;
  public date:any=[]
  public totalMessageCount: number;
  public msgOffset = 1;
  public verifyOffset: number;
  public showNewMessages = false;
  public range: any = {};
  public dateDisplay: string;
  public minDate: any;
  public maxDate: any;
  public startDate: any;
  public endDate: any;
  public dateFilterString: string;
  public activeRadioButtonName: string;

  public showRadioButtons: boolean = true;

  constructor(private msgService: MessageModalServiceService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService,
              public Header: HeaderComponent,

  private myService: SharedServiceService) {
    dateChangedService.dateChanged$.subscribe(
        mission => {
          this.date= this.Header.getDateFromLS();
          console.log(this.date)

        });
  }

  @ViewChild(DaterangePickerComponent)
  private picker: DaterangePickerComponent;

  public msgOptions: any = {
    startDate: this.startDate,
    endDate: this.endDate,
    locale: {format: "YYYY-MM-DD"},
    alwaysShowCalendars: false,
    showCustomRangeLabel: true,
    minDate: this.minDate,
    maxDate: this.maxDate,
  };

  public initModal(dates: any, url: any, activeRadioButtonName?: any, filters?: any) {
    console.log("in initModal")
    filters = filters || {};
    this.activeRadioButtonName = activeRadioButtonName;
    if(this.activeRadioButtonName === 'false'){
      this.showRadioButtons = false;
    }
    this.msgObj = [];
    this.totalMessageCount = 0;
    this.loading = true;
    this.checkedFilters = {};
    this.dates = dates;
    this.url = url;
    this.initDateObject();
    for (const key in filters) {
      this.filterObject(key, filters[key]);
    }
    this.openModal();
    this.fetchMessages(true);
  }

  public initDateObject() {
    console.log("in initDateObject")
    // TODO: setting start date and end date twice seems unnecesary but functionality not working without it. Explore library more.
    this.dateFilterString = "";
    this.dateDisplay = moment(this.dates[0]).format("Do MMM YY") + " - " + moment(this.dates[1]).format("Do MMM YY");
    this.range = {};
    this.msgOptions.minDate = moment(this.dates[0]).format("YYYY-MM-DD");
    this.msgOptions.maxDate = moment(this.dates[1]).format("YYYY-MM-DD");
    this.picker.datePicker.setStartDate(this.dates[0]);
    this.picker.datePicker.setEndDate(this.dates[1]);
    this.msgOptions.startDate = this.dates[0];
    this.msgOptions.endDate = this.dates[1];
  }

  // Todo: move date range functionalities to separate component.
  public selectedDate(value: any, datepicker?: any) {

    console.log("in selectedDate")
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.dateDisplay = value.start.format("Do MMM YY") + " - " + value.end.format("Do MMM YY");
    this.checkedFilters.Date = [value.start.format("YYYY-MM-DD"), value.end.format("YYYY-MM-DD")];
    this.dateFilterString = value.start.format("Do MMM YY") + " - " + value.end.format("Do MMM YY");
    this.fetchMessages(false);
  }

  public initFilterObject(filterNames: string[]) {
    console.log("in initFilterObject")
    filterNames.forEach((filter: string) => {
      if (!this.checkedFilters.hasOwnProperty(filter)) {
        this.checkedFilters[filter] = [];
      }
    });
  }
  applyFilters(){
    if(this.forTopics==true){
      //this.fetchMessages2(this.datesrange,false);
      this.newfilters();
    }
    else{
      this.fetchMessages(false);
    }

  }
  newfilters(){
    console.log(this.msgObj);
    let newmsgobj=[];
    console.log(this.checkedFilters);
    if(this.checkedFilters.Country.length!==0) {
      console.log('in first if')
      for(let j=0;j<this.checkedFilters.Country.length;j++){
      for (let i = 0; i < this.msgObj.length; i++) {
        this.msgObj[i].Country=this.msgObj[i].Country.toLowerCase();
      console.log(this.checkedFilters.Country[j])
      if(this.msgObj[i].Country.includes(this.checkedFilters.Country[j].toLowerCase())){
        console.log('in others if')
        newmsgobj.push(this.msgObj[i])
      }

      }
      }
    }
    console.log(newmsgobj);

    // if( newmsgobj.length!==0){
    //   console.log("output length not zero")
    //   this.msgObj = newmsgobj;
    // }
   // console.log(this.msgObj)
    if(this.checkedFilters.Device.length!==0) {
      console.log('in first if')
      for(let j=0;j<this.checkedFilters.Device.length;j++){
        for (let i = 0; i < this.msgObj.length; i++) {
          this.msgObj[i].Topics_Subtopics=this.msgObj[i].Topics_Subtopics.toLowerCase();

          if(this.msgObj[i].Topics_Subtopics.includes(this.checkedFilters.Device[j].toLowerCase())){
            console.log('in others if')
            newmsgobj.push(this.msgObj[i])
          }

        }
      }

    }
    // if( newmsgobj.length!==0){
    //   console.log("output length not zero")
    //   this.msgObj= newmsgobj
    // }
    if(this.checkedFilters.SiteType.length!==0) {
      for(let j=0;j<this.checkedFilters.SiteType.length;j++){
        for (let i = 0; i < this.msgObj.length; i++) {
          this.msgObj[i].Site_Type=this.msgObj[i].Site_Type.toLowerCase();
          if(this.msgObj[i].Site_Type.includes(this.checkedFilters.SiteType[j].toLowerCase())){
            newmsgobj.push(this.msgObj[i])
          }

        }
      }

    }
    // if( newmsgobj.length!==0){
    //   console.log("output length not zero")
    //   this.msgObj= newmsgobj
    // }

    if(this.checkedFilters.Language.length!==0) {
      for(let j=0;j<this.checkedFilters.Language.length;j++){
        for (let i = 0; i < this.msgObj.length; i++) {
          this.msgObj[i].Languagee=this.msgObj[i].Language.toLowerCase();

          if(this.msgObj[i].Language.includes(this.checkedFilters.Language[j].toLowerCase())){
            console.log('in others if')
            newmsgobj.push(this.msgObj[i])
          }

        }
      }
    }

    if(newmsgobj.length===0){
      this.fetchMessages2(this.datesrange,false);
    }

    var flags = [], output = [], l = newmsgobj.length;
    for(  let i=0; i<l; i++) {
      if( flags[newmsgobj[i].Id]) continue;
      flags[newmsgobj[i].Id] = true;
      output.push(newmsgobj[i]);
    }

    console.log(newmsgobj);
    console.log(output)
    this.msgObj=[];
    this.msgObj=output
    this.totalMessageCount = this.msgObj.length;


  }

  public fetchMessages(callinitObject: any, retainCheckboxesState?: boolean) {

    console.log(callinitObject)
    console.log(retainCheckboxesState)
    console.log("in fetchmessages")
    this.loading = true;
    let relevantMentionsBoolean = true;
    this.activeRadioButtonName === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    console.log(this.keywords)
    this.msgService.getMessages(this.applyDateFilter(this.url), this.checkedFilters, relevantMentionsBoolean)
      .then((response: any) => {
        console.log(response);
        this.loading = false;
        this.msgObj = (response.data);
        this.totalMessageCount = response.total;
        this.verifyOffset = Math.ceil(this.totalMessageCount) / 100;
        this.msgOffset = 1;
        if (callinitObject) {
          if (!retainCheckboxesState){
            this.initFilterObject(Object.keys(response.filters));
          }
          this.filters = response.filters;

        }
        console.log(this.filters)
      }).catch((error: any) => {
      throw error;

    });
  }

  public fetchMessages2(date2:any ,callinitObject: any) {

    let retainCheckboxesState
    console.log("in fetchmessages")
    this.loading = true;
    let relevantMentionsBoolean = true;
    this.activeRadioButtonName === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    console.log(this.keywords)
console.log(this.date)
    this.date.startDate='2019-02-02';
    this.date.endDate='2019-02-14';
    console.log(date2);


    this.msgService.getnewMessages(date2[0] , date2[1], this.keywords).then((response:any)=>{
      //this.filters=response[1];
      console.log(this.filters)

          this.loading = false;
          this.msgObj = (response[0]);
          this.totalMessageCount = response[0].length;
          this.verifyOffset = Math.ceil(this.totalMessageCount) / 100;
          this.msgOffset = 1;
      //
      if (callinitObject) {
        if (!retainCheckboxesState){
          this.initFilterObject(Object.keys(response[1]));
        }
        this.filters = response[1];

      }
          this.openModal()

        }).catch((error: any) => {
      throw error;

    });
  }


   public toggleRelevantMentions(event: any, radioName: string) {

    console.log("in toggleRelevantMentions")
    if (event.target.checked === true ) {
      if (radioName === "total" ) {
        this.activeRadioButtonName = radioName;
        this.fetchMessages(true, true);
      }
      else {
        this.activeRadioButtonName = radioName;
        this.fetchMessages(true, true);
      }
    }
  }

  public filterObject(filterName, event) {
    console.log("in filterObject")
    this.checkedFilters[filterName] = event;
    console.log(this.checkedFilters);
  }

  public openModal() {
    console.log("in openModal")
    this.modal = document.getElementById("myModal");
    this.modal.style.display = "block";
  }

  public closeModal() {
    console.log("in closeModal")
    this.modal.style.display = "none";
  }

  private applyDateFilter(url: string): string {
    console.log("in applyDateFilter")
    console.log(this.msgOptions.startDate)
    if (url.includes("?")) {
      return url + "&start=" + this.msgOptions.startDate + "&end=" + this.msgOptions.endDate;
    } else {
      return url + "?start=" + this.msgOptions.startDate + "&end=" + this.msgOptions.endDate;
    }
  }

  public paginate() {
    console.log("in paginate")

    this.msgOffset++;
    this.showNewMessages = true;
    let relevantMentionsBoolean = false;
    this.activeRadioButtonName === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    (this.msgService.getMessages(this.applyDateFilter(this.url + "?pg=" + this.msgOffset), this.checkedFilters, relevantMentionsBoolean))
      .then((response: any) => {
        this.loading = false;
        this.msgObj = this.msgObj.concat(response.data);
      }).catch((error: any) => {
      throw error;
    });
  }

  // TODO: this function is exactly copied from previous project. Should be modified acc to Angular standards
  public saveData = (function () {
    console.log("in savedata")

    let a = document.createElement("a");
    document.body.appendChild(a);
    return (data: any, fileName: any) => {
      const blob = new Blob([data], {type: "text/csv"}),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  }());

  public download() {
    console.log("in Download")

    let relevantMentionsBoolean = false;
    this.activeRadioButtonName === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    const url = this.url + "/download?relevantmentions=" + relevantMentionsBoolean;
    (this.msgService.downloadMessages(this.applyDateFilter(url), this.checkedFilters, {
      headers: {"Content-Type": "application/json"},
      responseType: "text"
    }))
      .then((response) => {
        this.saveData(response, "file.csv");
      }).catch((error: any) => {
      throw error;
    });

  }

  ngOnInit() {
    console.log("in messages")
    // if(this.activeRadioButtonName===undefined){
    //   console.log("for the other part")
    //   this.fetchMessages2()
    if(this.forTopics===true){
      console.log('inside true');
      this.fetchMessages2(this.datesrange,true);
    }
    //
    //
    // }

  }

}
