import {Component, Input, OnChanges, OnInit, ViewChild} from "@angular/core";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {PageEvent} from "@angular/material/paginator";
import {max} from "rxjs/operators";
// import {ProgressBarModule} from "angular-progress-bar";
import {animate, state, style, transition, trigger} from "@angular/animations";
export interface UserData {
  post: string;
//  Id: number;
  author: string;
  no_ofPost:number;
  Followers:number;
  reach: number;
  likes: number;
  retweets: number;
  hashtags: string;
  ScreenName:string;
  //sentiment: s:tring;
  totalInteraction: number;
  Location: string;
  Profession: string;

}

@Component({
  selector: 'app-influentialuser',
  templateUrl: './influentialuser.component.html',
  styleUrls: ['./influentialuser.component.css']
})
export class InfluentialuserComponent implements OnInit,OnChanges {

  constructor() { }
  @Input() tableres: any = [];

  displayedColumns: string[] = [ "author", "no_ofPost" , "totalInteraction", "Profession", "Followers"];

  dataSource: any;

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20];

  // MatPaginator Inputs
  // length = this.tableres.length;
  // pageSize = 10;
  // pageSizeOptions: number[] = [5, 10, 20];
  //
  // // MatPaginator Output
  pageEvent: PageEvent;
  // Rate = 2.5;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }








  addDataToTable() {
    /*this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }*/
    this.dataSource = new MatTableDataSource(this.tableres);

    // console.log(this.tableresponse.Potential_Reach);
  }

  ngOnChanges() {
    this.addDataToTable();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.determineView();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;



  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // addDataToTable() {
  //     /*this.dataSource.filter = filterValue.trim().toLowerCase();
  //
  //     if (this.dataSource.paginator) {
  //         this.dataSource.paginator.firstPage();
  //     }*/
  //
  //     this.dataSource = new MatTableDataSource(this.tableresponse);
  //
  //     // console.log(this.tableresponse.Potential_Reach);
  // }
  expandtable(){

  }

  // ngOnChanges() {
  //   this.addDataToTable();
  //   // this.determineView();
  // }





}

/** Builds and returns a new User. */
// function createNewUser(Id: number): UserData {
//   const name = author[Math.round(Math.random() * (author.length - 1))] + ' ' +
//       author[Math.round(Math.random() * (author.length - 1))].charAt(0) + '.';
//
//   let x = {
//       post:Math.round(Math.random() * 10).toString(),
//       Id:  Math.round(Math.random() * 10),
//       author: name,
//       reach:  Math.round(Math.random() * 10),
//       likes:  Math.round(Math.random() * 10),
//       retweets:  Math.round(Math.random() * 10),
//       hashtags: Math.round(Math.random() * 10).toString(),
//       sentiment: sentiment[Math.round(Math.random() * (sentiment.length - 1))]
//
//
//   };
//   console.log('x is');
//   //console.log(x);
//
//   return x;
//
//   //return
// }
//
