import {Component, OnInit, Input, Output, EventEmitter,} from '@angular/core';

@Component({
  selector: 'app-graph-options',
  templateUrl: './graph-options.component.html',
  styleUrls: ['./graph-options.component.css']
})
export class GraphOptionsComponent implements OnInit {

  constructor() { }

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  }

}
