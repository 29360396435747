import { Component, OnInit } from "@angular/core";
import * as d3 from "d3";
import {ForceLink, Link} from "d3";
import {NetworkGraphService} from "./network-graph.service";
let node;
let link;
@Component({
  selector: "app-network-graph",
  templateUrl: "./network-graph.component.html",
  styleUrls: ["./network-graph.component.css"]
})
export class NetworkGraphComponent implements OnInit {
    svg:any;
    simulation:any;
    colors:any;
    constructor( private networkGraphService: NetworkGraphService) {

    }

  ngOnInit() {
       console.log("inside onit")


     this.colors = d3.scaleOrdinal(d3.schemeCategory10);

      // tslint:disable-next-line:prefer-const
     /*svg = d3.select("svg"),
        width = +svg.attr("width"),
        height = +svg.attr("height"),*/
    let node;
    let link;

    this.svg = d3.select(".network-graph").append("svg")
        .attr("width", 1100)
        .attr("height", 480);

     console.log(this.svg);

    this.svg.append("defs").append("marker")
        .attr("id", "arrowhead")
            .attr("viewBox", "-0 -5 10 10")
            .attr("refX", 13)
            .attr( "refY", 0)
            .attr("orient", "auto")
            .attr("markerWidth", 13)
            .attr("markerHeight",  13)
            .attr("xoverflow", "visible")
        .append("svg:path")
        .attr("d", "M 0,-5 L 10 ,0 L 0,5")
        .attr("fill", "#999")
        .style("stroke", "none");
    console.log('before simulation');
    console.log(this.svg);

     this.simulation = d3.forceSimulation()
    // tslint:disable-next-line:only-arrow-functions
          .force("link", d3.forceLink().id(function(d: any) {return d.id; }).distance(100).strength(1))
          .force("charge", d3.forceManyBody())
          .force("center", d3.forceCenter(500, 200));
    console.log("after sim");
        this.update(this.networkGraphService.Links, this.networkGraphService.Nodes)
        console.log( this.update(this.networkGraphService.Links, this.networkGraphService.Nodes))


    // function ticked() {
    //       link
    //           .attr("x1", function(d) {return d.source.x; })
    //           .attr("y1", function(d) {return d.source.y; })
    //           .attr("x2", function(d) {return d.target.x; })
    //           .attr("y2", function(d) {return d.target.y; });
    //
    //       node
    //           .attr("transform", function(d) {return "translate(" + d.x + ", " + d.y + ")"; });
    //
    //        let edgepaths.attr ("d", function(d) {
    //           return " M " + d.source.x + " " + d.source.y + " L " + d.target.x + " " + d.target.y;
    //       });
    //
    //        let edgelabels.attr("transform", function(d) {
    //           if (d.target.x < d.source.x) {
    //               const bbox = this.getBBox();
    //
    //             let   rx = bbox.x + bbox.width / 2;
    //             let  ry = bbox.y + bbox.height / 2;
    //               return "rotate(180 " + rx + " " + ry + ")";
    //           } else {
    //               return "rotate(0)";
    //           }
    //       });
    //   }





  }

    public update(links, nodes) {
        console.log("inside update")

        link = this.svg.selectAll(".link")
            .data(links)
            .enter()
            .append("line")
            .attr("class", "link")
            .attr("marker-end", "url(#arrowhead)");

        link.append("title")
            .text(function(d) {return d.type; });

        let edgepaths = this.svg.selectAll(".edgepath")
            .data(links)
            .enter()
            .append("path")
            .attr("class", "edgepath")
            .attr("fill-opacity", 0)
            .attr("stroke-opacity", 0)
            .attr("id",function(d, i) {return "edgepath" + i})
            .style("pointer-events", "none");

        let edgelabels = this.svg.selectAll(".edgelabel")
            .data(links)
            .enter()
            .append("text")
            .style("pointer-events", "none")
            .attr(
                "class", "edgelabel")
            .attr("id",function(d, i) {return "edgelabel" + i; })
            .attr("font-size", 10)
            .attr("fill", "#aaa");

        edgelabels.append("textPath")
            .attr("xlink:href", function(d, i) {return "#edgepath" + i; })
            .style("text-anchor", "middle")
            .style("pointer-events", "none")
            .attr("startOffset", "50%")
            .text(function(d) {return typeof(d); });

        node = this.svg.selectAll(".node")
            .data(nodes)
            .enter()
            .append("g")
            .attr("class", "node")
            .call(d3.drag()
                    .on("start", this.dragstarted)
                    .on("drag", this.dragged)
                // .on("end", dragended)
            );

        node.append("circle")
            .attr("r", 5)
            .style("fill", function(d, i) {return this.colors(i); });

        node.append("title")
            .text(function(d) {return d.id; });

        node.append("text")
            .attr("dy", -3)
            .text(function(d) {return d.name + ":" + d.label; });

        this.simulation
            .nodes(nodes)
        //            .on("tick", ticked);

        //this.simulation.force<ForceLink<any,any>>("link")
            .links(links);
        console.log("at the end of update")
        console.log(this.svg)
        return [links, nodes];
    }
    public dragstarted(d) {
        console.log("inside dragstart")
        if (!d3.event.active) { this.simulation.alphaTarget(0.3).restart(); }
        d.fx = d.x;
        d.fy = d.y;
    }

    public dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
    }

}
