import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HeaderDateChangedService {

  constructor() { }
  private dateChangedSource = new Subject<String>();

  // Observable string streams
  dateChanged$ = this.dateChangedSource.asObservable();

  // Service message commands
  announceDate() {
    this.dateChangedSource.next();
  }

}
