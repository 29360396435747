import {Component, OnInit, ViewChild} from "@angular/core";
import {SharedServiceService} from "../services/shared-service.service";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {AccuracyService} from "./accuracy.service";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {HeaderComponent} from "../reusable/header/header.component";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: "app-accuracy",
  templateUrl: "./accuracy.component.html",
  styleUrls: ["./accuracy.component.css"],
})
export class AccuracyComponent implements OnInit {

  public sites: any;
  public dates: any;
  public chartOption: any;
  public showLoader = false;
  public wordCloudObj: any;
  public totalMentions: any;
  public relevantMentions: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public msgUrl = "/accuracy/msg";
  public resultExists = true;
  public checkedSiteFilters: any[] = [];
  private activeRadioLabel: boolean =  true;
  private totalMentionsObj: any;
  private relevantMentionsObj: any;
  private activeRadioButtonName: string = "relevant";

  @ViewChild(MessageModalComponent) child: MessageModalComponent;

  constructor(public myservice: SharedServiceService,
              public accuracyService: AccuracyService,
              public header: HeaderComponent,
              public echartGraphs: EchartGraphsService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {

    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.emptyToggleObjects();
            this.showSiteData(this.checkedSiteFilters);
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters: [];
    this.dates = this.header.getDateFromLS();
    this.insights();
    this.myservice.saveSiteInLS(this.checkedSiteFilters);
    let relevantMentionsBoolean = false;
    this.activeRadioButtonName === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    this.accuracyService.getData(this.dates, this.checkedSiteFilters, relevantMentionsBoolean).then((response: any) => {
      this.showLoader = false;
      this.totalMentions = response.Mention.mentions;
      this.relevantMentions = response.stats.count;
      this.barGraph(response.stats);
      this.wordCloudObj = response.Mention.freq;
      if (this.activeRadioButtonName && this.activeRadioButtonName === "relevant") {
        this.relevantMentionsObj = response;
      }
      else {
        this.totalMentionsObj = response;
      }
    }).catch((error: any) => {
      throw error;
    });
  }

  private emptyToggleObjects() {
    this.relevantMentionsObj = {};
    this.totalMentionsObj = {};
  }

  public toggleRelevantMentions(event: any, radioName: string) {
    if (event.target.checked === true ) {
      if (radioName === "total") {
        this.activeRadioButtonName = radioName;
        if (!this.totalMentionsObj || (Object.entries(this.totalMentionsObj).length === 0 && this.totalMentionsObj.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.barGraph(this.totalMentionsObj.stats);
          this.wordCloudObj = this.totalMentionsObj.Mention.freq;
        }
      }
      else {
        this.activeRadioButtonName = radioName;
        if (!this.relevantMentionsObj || (Object.entries(this.relevantMentionsObj).length === 0 && this.relevantMentionsObj.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.barGraph(this.relevantMentionsObj.stats);
          this.wordCloudObj = this.relevantMentionsObj.Mention.freq;
        }
      }
    }
  }

  public insights() {
    const insightBoolean = this.myservice.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  // TODO: no results logic is different for every module. It should be same,
  public isResultExists(label: any) {
    return label.length;
  }

  public barGraph(obj: any) {
    let barGraphObj = [];
    const color = this.myservice.siteColor;
    const legend = [];
    const xAxis = obj.label;
    this.resultExists = this.isResultExists(xAxis);
    if (this.resultExists) {
      for (const i in obj) {
        if (!(i === "count") && !(i === "label")) {
          legend.push(i);
          barGraphObj.push({name: i, type: "bar", data: obj[i], barGap: 0, color: color[i]});
        }
      }
      barGraphObj = this.myservice.sortByName(barGraphObj);
      legend.sort();
      console.log(barGraphObj)
      this.chartOption = this.echartGraphs.barGraph("", legend,
        {"type": "category", "data": xAxis, "light": false},
        {"type": "value", "data": {}, "light": false}, barGraphObj);
    }
  }

  public openMessageModal(specificUrl?: any) {
    specificUrl = specificUrl || "";
    if (this.checkedSiteFilters.length && this.checkedSiteFilters[0] === "All channels") {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, this.activeRadioButtonName );
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, this.activeRadioButtonName, {Site_Type: this.checkedSiteFilters});
    }
  }

  ngOnInit() {
    this.showLoader = true;
    this.checkedSiteFilters = this.myservice.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }
}
