import {Component, OnInit, ViewChild} from "@angular/core";
import {SharedServiceService} from "../services/shared-service.service";
import {HeaderComponent} from "../reusable/header/header.component";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {SupplyService} from "./supply.service";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: "app-supply",
  templateUrl: "./supply.component.html",
  styleUrls: ["./supply.component.css"]
})
export class SupplyComponent implements OnInit {

  public showLoader = false;
  public dates: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public wordCloudObj: any;
  public totalMentions: any;
  public relevantMentions: any;
  public pieOption: any;
  public msgUrl = "/supply/msg";
  public checkedSiteFilters: any[] = [];
  @ViewChild(MessageModalComponent) public child: MessageModalComponent;
  private activeRadioLabel: boolean =  true;
  private totalMentionsObj: any;
  private relevantMentionsObj: any;
  private activeRadioButtonName: string = "relevant";

  constructor(public myService: SharedServiceService,
              public header: HeaderComponent,
              public supplyService: SupplyService,
              public eChartGraphs: EchartGraphsService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {

    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.emptyToggleObjects();
            this.showSiteData(this.checkedSiteFilters);          }).catch((error) => {
            console.error(error);
          });
        });

  }

  public insights() {
    const insightBoolean = this.myService.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  private emptyToggleObjects() {
    this.relevantMentionsObj = {};
    this.totalMentionsObj = {};
  }

  public openMessageModal(specificUrl?: any) {
    specificUrl = specificUrl || "";
    if (this.checkedSiteFilters.length && this.checkedSiteFilters[0] === "All channels") {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, this.activeRadioButtonName );
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, this.activeRadioButtonName, {Site_Type: this.checkedSiteFilters});
    }
  }

  public pieChart(freq: any) {
    console.log(freq);
    const color = this.myService.siteColor;
    let total = 0;
    for (const key in freq) {
      if (freq.hasOwnProperty(key)) {
        total += freq[key];
      }
    }
    const pieData = [];
    const pieColor = [];
    for (const key in freq) {
      if (freq.hasOwnProperty(key)) {
        const percTotal = Math.ceil(((freq[key] / total) * 100) * 10) / 10 + "%";
        pieData.push({value: freq[key], name: key + " " + "-" + " " + percTotal, color: color[key]});
        pieColor.push(color[key]);
      }
    }
    console.log(pieData);
    console.log(pieColor);
    this.pieOption = this.eChartGraphs.getNoComparisonPieChart('', pieColor, pieData, null);
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters: [];
    this.dates = this.header.getDateFromLS();
    this.insights();
    this.myService.saveSiteInLS(this.checkedSiteFilters);
    let relevantMentionsBoolean = false;
    this.activeRadioButtonName === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    this.supplyService.getData(this.dates, this.checkedSiteFilters, relevantMentionsBoolean).then((response: any) => {
      this.showLoader = false;
      this.totalMentions = response.mentions_count;
      this.relevantMentions = response.count;
      this.pieChart(response.freq);
      this.wordCloudObj = response.mention_freq;
      if (this.activeRadioButtonName && this.activeRadioButtonName === "relevant") {
        this.relevantMentionsObj = response;
      }
      else {
        this.totalMentionsObj = response;
      }
    }).catch((error: any) => {
      throw error;
    });
  }

  public toggleRelevantMentions(event: any, radioName: string) {
    if (event.target.checked === true ) {
      if (radioName === "total") {
        this.activeRadioButtonName = radioName;
        if (!this.totalMentionsObj || (Object.entries(this.totalMentionsObj).length === 0 && this.totalMentionsObj.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.pieChart(this.totalMentionsObj.freq);
          this.wordCloudObj = this.totalMentionsObj.mention_freq;
          /*this.barGraph(this.totalMentionsObj.stats);
          this.wordCloudObj = this.totalMentionsObj.Mention.freq;*/
        }
      }
      else {
        this.activeRadioButtonName = radioName;
        if (!this.relevantMentionsObj || (Object.entries(this.relevantMentionsObj).length === 0 && this.relevantMentionsObj.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          /*this.barGraph(this.relevantMentionsObj.stats);
          this.wordCloudObj = this.relevantMentionsObj.Mention.freq;*/
          this.pieChart(this.relevantMentionsObj.freq);
          this.wordCloudObj = this.relevantMentionsObj.mention_freq;
        }
      }
    }
  }

  ngOnInit() {
    this.checkedSiteFilters = this.myService.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }
}
