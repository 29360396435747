import {Injectable} from "@angular/core";
import {ApiService} from "../services/api.service";

@Injectable({
  providedIn: "root"
})
export class TwitterService {

  constructor(private apiService: ApiService) {
  }

  public getData(dates: any) {

    let promise = new Promise((resolve, reject) => {
      this.apiService.get("/twitter?start=" + dates[0] + "&end=" + dates[1]).subscribe((response) => {
        resolve(response);
      })
    }).catch((error: any) => {
      throw error;
    });
    return promise;

  }

  public getUsers(dates: any) {

    let promise = new Promise((resolve, reject) => {
      this.apiService.get("/twitter/user?start=" + dates[0] + "&end=" + dates[1]).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;

  }

  public getHashTags(dates: any) {

    let promise = new Promise((resolve, reject) => {
      this.apiService.get("/twitter/hash?start=" + dates[0] + "&end=" + dates[1]).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;

  }

  public getInfluentialPost(dates: any, filters?: any) {

    let promise = new Promise((resolve, reject) => {
      this.apiService.get("/twitter/influential?start=" + dates[0] + "&end=" + dates[1]).subscribe((response) => {
        console.log("  inside twitter service")
        // console.log(response)
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
  public getInfluentialUser(dates: any, filters?: any) {

    let promise = new Promise((resolve, reject) => {
      this.apiService.get("/twitter/influentialuser?start=" + dates[0] + "&end=" + dates[1]).subscribe((response) => {
        console.log("  inside twitter user (service)")
        //  console.log(response)
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
  public getNetworkGraph(dates: any, getFilters?: any) {
    console.log('entered');
    console.log(dates);
    console.log(getFilters)

    let promise = new Promise((resolve, reject) => {

      this.apiService.post("/twitter/networkgraph?start=" + dates[0] + "&end=" + dates[1],{getFilters} ).subscribe((response) => {

        console.log(response);
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
  public getNetworkGraph2(dates: any, getFilters?: any) {
    console.log('entered');
    console.log(dates);
    console.log(getFilters)

    let promise = new Promise((resolve, reject) => {

      this.apiService.post("/twitter/networkgraph2?start=" + dates[0] + "&end=" + dates[1],{ getFilters} ).subscribe((response) => {

        console.log(response);
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
  public gettopicGraph(dates:any, getFilters?: any, username?: any){
    console.log('entered scatter graph');
 //   console.log(getFilters);
    getFilters["username"]=username
    console.log("the getfilters is "+ getFilters)
    //console.log(getFilters)

    let promise = new Promise((resolve, reject) => {

      this.apiService.post("/twitter/topicscatter?start=" + dates[0] + "&end=" + dates[1], getFilters  ).subscribe((response) => {

        console.log(response);
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }


  public getscatterGraph(dates: any, getFilters?: any) {
    console.log('entered scatter graph');
    console.log(getFilters);
    //console.log(getFilters)

    let promise = new Promise((resolve, reject) => {

      this.apiService.post("/twitter/scattergraph?start=" + dates[0] + "&end=" + dates[1], getFilters  ).subscribe((response) => {

        console.log(response);
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
  public getuserTranscript(dates: any, getFilters?: any) {
    console.log('userTranscript');
    console.log(getFilters);
    //console.log(getFilters)

    let promise = new Promise((resolve, reject) => {

      this.apiService.post("/twitter/userTranscript?start=" + dates[0] + "&end=" + dates[1], getFilters  ).subscribe((response) => {

        console.log(response);
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }



}

