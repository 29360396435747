import {Component, OnInit, ViewChild} from "@angular/core";
import {SharedServiceService} from "../services/shared-service.service";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {DietService} from "./diet.service";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {HeaderComponent} from "../reusable/header/header.component";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: "app-diet",
  templateUrl: "./diet.component.html",
  styleUrls: ["./diet.component.css"]
})
export class DietComponent implements OnInit {

  public sites: any;
  public selectedSite: any;
  public dates: any;
  public barChartOption: any;
  public pieChart1Option: any;
  public pieChart2Option: any;
  public showLoader = false;
  public wordCloudObj: any;
  public totalMentions: any;
  public relevantMentions: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public msgUrl = "/diet/msg";
  public resultExistsBar = true;
  public resultExistsPie1 = true;
  public resultExistsPie2 = true;
  public pieObj: any;
  public colorPalette = {"no sugar": "#ca8622", "gluten": "#61a0a8", "vegan": "#2f4554", "low carb": "#d48265", "keto": "#91c7ae", "protein": "#749f83"};
  public checkedSiteFilters: any[] = [];
  public activeRadioLabel: boolean =  true;
  private totalMentionsObj: any;
  private relevantMentionsObj: any;
  private activeRadioButtonName: string = "relevant";

  @ViewChild(MessageModalComponent) child: MessageModalComponent;

  constructor(public myservice: SharedServiceService,
              public dietService: DietService,
              public Header: HeaderComponent,
              public eChartGraphs: EchartGraphsService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {

    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.Header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.emptyToggleObjects();
            this.showSiteData(this.checkedSiteFilters);          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters: [];
    this.dates = this.Header.getDateFromLS();
    this.insights();
    this.myservice.saveSiteInLS(this.checkedSiteFilters);
    let relevantMentionsBoolean = false;
    this.activeRadioButtonName === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    this.dietService.getData(this.dates, this.checkedSiteFilters, relevantMentionsBoolean).then((response: any) => {
      this.showLoader = false;
      this.totalMentions = response.Mention.mentions;
      this.relevantMentions = response.diet.count;
      this.barGraph(response.diet);
      this.pieObj = response.diet_breakup;
      this.pieChart("Abbott");
      this.pieChart("Dexcom");
      this.wordCloudObj = response.Mention.freq;
      if (this.activeRadioButtonName && this.activeRadioButtonName === "relevant") {
        this.relevantMentionsObj = response;
      }
      else {
        this.totalMentionsObj = response;
      }
    }).catch((error: any) => {
      throw error;
    });
  }

  private emptyToggleObjects() {
    this.relevantMentionsObj = {};
    this.totalMentionsObj = {};
  }

  public toggleRelevantMentions(event: any, radioName: string) {
    this.showLoader = true;
    if (event.target.checked === true ) {
      if (radioName === "total") {
        this.activeRadioButtonName = radioName;
        if (!this.totalMentionsObj || (Object.entries(this.totalMentionsObj).length === 0 && this.totalMentionsObj.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.barGraph(this.totalMentionsObj.diet);
          this.pieObj = this.totalMentionsObj.diet_breakup;
          this.pieChart("Abbott");
          this.pieChart("Dexcom");
          this.wordCloudObj = this.totalMentionsObj.Mention.freq;
          this.showLoader = false;
        }
      }
      else {
        this.activeRadioButtonName = radioName;
        if (!this.relevantMentionsObj || (Object.entries(this.relevantMentionsObj).length === 0 && this.relevantMentionsObj.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.barGraph(this.relevantMentionsObj.diet);
          this.pieObj = this.relevantMentionsObj.diet_breakup;
          this.pieChart("Abbott");
          this.pieChart("Dexcom");
          this.wordCloudObj = this.relevantMentionsObj.Mention.freq;
          this.showLoader = false;
        }
      }
    }
  }

  public isResultExists(label: any) {
    return label.length;
  }

  public isResultPieExists(label: any, title) {
    if (label === undefined || label.length === 0) {
      title === "Abbott" ? this.resultExistsPie1 = false : this.resultExistsPie2 = false;
    } else {
      title === "Abbott" ? this.resultExistsPie1 = true : this.resultExistsPie2 = true;
    }
  }

  public barGraph(obj: any) {
    let barGraphObj = [];
    const color = this.myservice.siteColor;
    const legend = [];
    const yAxis = obj.label;
    this.resultExistsBar = this.isResultExists(yAxis);
    if (this.resultExistsBar) {
      for (const i in obj) {
        if (!(i === "count") && !(i === "label")) {
          legend.push(i);
          barGraphObj.push({name: i, type: "bar", data: obj[i], barGap: 0, color: color[i]});
        }
      }
      barGraphObj = this.myservice.sortByName(barGraphObj);
      console.log(barGraphObj)
      legend.sort();
      this.barChartOption = this.eChartGraphs.barGraph("", legend, {"type": "value", "data": {}, "light": true}, {
        "type": "category",
        "data": yAxis,
        "light": true
      }, barGraphObj);
    }
  }

  public pieChart(title: string) {
    const total = this.myservice.pieComparisonLegend(this.pieObj, title);
    const legend = [];
    const pieData = [];
    this.isResultPieExists(this.pieObj[title], title);
    if (title === "Abbott" ? this.resultExistsPie1 : this.resultExistsPie2) {
      for (let i = 0; i < this.pieObj[title].length; i++) {
        if (this.pieObj[title][i] !== 0) {
          const perc = Math.ceil(((this.pieObj[title][i] / total) * 100) * 100) / 100 + "%";
          pieData.push({value: this.pieObj[title][i], name: (this.pieObj.label)[i] + "\n" + perc, itemStyle:{color: this.colorPalette[(this.pieObj.label)[i]]}});
          legend.push(this.pieObj.label[i]);
        }
      }
      if (title === "Abbott") {
        console.log(pieData);
        console.log(legend);
        this.pieChart1Option = this.eChartGraphs.pieChart(title, legend, pieData);
      } else {
        this.pieChart2Option = this.eChartGraphs.pieChart(title, legend, pieData);
      }
    }

  }

  public insights() {
    const insightBoolean = this.myservice.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  public openMessageModal(specificUrl?: any) {
    specificUrl = specificUrl || "";
    if (this.checkedSiteFilters.length && this.checkedSiteFilters[0] === "All channels") {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, this.activeRadioButtonName );
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, this.activeRadioButtonName, {Site_Type: this.checkedSiteFilters});
    }
  }

  ngOnInit() {
    this.checkedSiteFilters = this.myservice.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }

}
