import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class UtilsService {
  public static getDateInHumanReadableForm(date: Date): any {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if (typeof date === "string") {
      date = new Date(date);
    }
    const day: number = date.getDate();
    let postfix = "th";
    if (day <= 3 || day >= 21) {
      switch (day % 10) {
        case 1: {
          postfix = "st";
          break;
        }
        case 2: {
          postfix = "nd";
          break;
        }
        case 3: {
          postfix = "rd";
          break;
        }
        default: {
          postfix = "th";
        }
      }
    }
    const year = date.getFullYear().toString(10).substr(-2);
    return "" + day + postfix + " " + months[date.getMonth()] + " " + year;
  }

  public static getDateDiffInDays(date1: Date, date2: Date): number {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return (diffTime / (1000 * 60 * 60 * 24));
  }
}
