import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  constructor(private apiService: ApiService) {
  }

  public resetWorkspace(workspace: string, payload: any): Promise<any> {
    let url = '/workspaces/reset/' + workspace;
    return new Promise((resolve, reject) => {
      this.apiService.put(url, payload).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getWorkspaces(): Promise<any[]> {
    let url = '/workspaces';
    return new Promise((resolve, reject) => {
      this.apiService.get(url).subscribe((response: any[]) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public async getActiveWorkspace(): Promise<any> {
    const workspaces: any[] = await this.getWorkspaces();
    return workspaces.find((d: any) => d.active);
  }
}
