import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import * as d3 from "d3";

@Component({
  selector: "app-test-graph",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./test-graph.component.html",
  styleUrls: ["./test-graph.component.css"]
})
export class TestGraphComponent implements OnInit {

  lineGraphRawData: any = {
    "bpi": {
      "2017-12-31": 13860.1363,
      "2018-01-01": 13412.44,
      "2018-01-02": 14740.7563,
      "2018-01-03": 15134.6513,
      "2018-01-04": 15155.2263,
      "2018-01-05": 16937.1738,
      "2018-01-06": 17135.8363,
      "2018-01-07": 16178.495,
      "2018-01-08": 14970.3575,
      "2018-01-09": 14439.4738,
      "2018-01-10": 14890.7225,
      "2018-01-11": 13287.26,
      "2018-01-12": 13812.715,
      "2018-01-13": 14188.785,
      "2018-01-14": 13619.0288,
      "2018-01-15": 13585.9013,
      "2018-01-16": 11348.02,
      "2018-01-17": 11141.2488,
      "2018-01-18": 11250.6475,
      "2018-01-19": 11514.925,
      "2018-01-20": 12759.6413,
      "2018-01-21": 11522.8588,
      "2018-01-22": 10772.15,
      "2018-01-23": 10839.8263,
      "2018-01-24": 11399.52,
      "2018-01-25": 11137.2375,
      "2018-01-26": 11090.0638,
      "2018-01-27": 11407.1538,
      "2018-01-28": 11694.4675,
      "2018-01-29": 11158.3938,
      "2018-01-30": 10034.9975,
      "2018-01-31": 10166.5063,
      "2018-02-01": 9052.5763,
      "2018-02-02": 8827.63,
      "2018-02-03": 9224.3913,
      "2018-02-04": 8186.6488,
      "2018-02-05": 6914.26,
      "2018-02-06": 7700.3863,
      "2018-02-07": 7581.8038,
      "2018-02-08": 8237.2363,
      "2018-02-09": 8689.8388,
      "2018-02-10": 8556.6125,
      "2018-02-11": 8070.7963,
      "2018-02-12": 8891.2125,
      "2018-02-13": 8516.2438,
      "2018-02-14": 9477.84,
      "2018-02-15": 10016.4888,
      "2018-02-16": 10178.7125,
      "2018-02-17": 11092.1475,
      "2018-02-18": 10396.63,
      "2018-02-19": 11159.7238,
      "2018-02-20": 11228.2413,
      "2018-02-21": 10456.1725,
      "2018-02-22": 9830.4263,
      "2018-02-23": 10149.4625,
      "2018-02-24": 9682.3825,
      "2018-02-25": 9586.46,
      "2018-02-26": 10313.0825,
      "2018-02-27": 10564.4188,
      "2018-02-28": 10309.6413,
      "2018-03-01": 10907.59,
      "2018-03-02": 11019.5213,
      "2018-03-03": 11438.6513,
      "2018-03-04": 11479.7313,
      "2018-03-05": 11432.9825,
      "2018-03-06": 10709.5275,
      "2018-03-07": 9906.8,
      "2018-03-08": 9299.2838,
      "2018-03-09": 9237.05,
      "2018-03-10": 8787.1638,
      "2018-03-11": 9532.7413,
      "2018-03-12": 9118.2713,
      "2018-03-13": 9144.1475,
      "2018-03-14": 8196.8975,
      "2018-03-15": 8256.9938,
      "2018-03-16": 8269.3275,
      "2018-03-17": 7862.1088,
      "2018-03-18": 8196.0225,
      "2018-03-19": 8594.1913,
      "2018-03-20": 8915.9038,
      "2018-03-21": 8895.4,
      "2018-03-22": 8712.8913,
      "2018-03-23": 8918.7438,
      "2018-03-24": 8535.8938,
      "2018-03-25": 8449.835,
      "2018-03-26": 8138.3363,
      "2018-03-27": 7790.1575,
      "2018-03-28": 7937.205,
      "2018-03-29": 7086.4875,
      "2018-03-30": 6844.3213,
      "2018-03-31": 6926.0175,
      "2018-04-01": 6816.74
    },
    "disclaimer": "This data was produced from the CoinDesk Bitcoin Price Index. BPI value data returned as USD.",
    "time": {
      "updated": "Apr 2, 2018 00:03:00 UTC",
      "updatedISO": "2018-04-02T00:03:00+00:00"
    }
  };
  lineGraphActualData: any[];

  measurement: any = {
    margin: {top: 20, right: 20, bottom: 30, left: 50},
    svgWidth: 600,
    svgHeight: 400
  };

  @ViewChild("chart")
  private chartContainer: ElementRef;

  private numList: number[] = [10, 20, 30, 25, 15];

  constructor() {
    this.lineGraphActualData = this.parseData(this.lineGraphRawData);
  }

  private parseData(data: any) {
    const bpiList: any[] = [];
    // tslint:disable-next-line:forin
    for (const date in data.bpi) {
      bpiList.push(
          {
            date: new Date(date), // date
            value: +data.bpi[date] // convert string to number
          });
    }
    return bpiList;
  }

  private drawChart(data: any[], element: any, measurement: any): void {
    measurement = measurement || {};
    d3.select("svg").remove();
    const svgWidth = measurement.svgWidth;
    const svgHeight = measurement.svgHeight;
    const margin = measurement.margin;

    const svg = d3.select(element).append("svg")
        .attr("width", svgWidth)
        .attr("height", svgHeight);

    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;
    const g = svg.append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")"
        );
    const x = d3.scaleTime().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([height, 0]);
    const line = d3.line()
        .x((d: any) => x(d.date))
        .y((d: any) => y(d.value));
    x.domain(d3.extent(data, (d: any) => d.date));
    y.domain(d3.extent(data, (d: any) => d.value));

    g.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x))
        .select(".domain")
        .remove();

    g.append("g")
        .call(d3.axisLeft(y))
        .append("text")
        .attr("fill", "#000")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", "0.71em")
        .attr("text-anchor", "end")
        .text("Price ($)");

    g.append("path")
        .datum(this.lineGraphActualData)
        .attr("fill", "none")
        .attr("stroke", "steelBlue")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", 1.5)
        .attr("d", line)
        // .on("mousedown", this.mouseDown)
        // .on("mouseup", this.mouseUp)
        .on("click", mouseClick);

    function mouseClick() {
      console.log("mouseClick");
      g.attr("fill", "red");
    }
  }

  private mouseDown(): void {
    console.log("mouseDown");
  }

  private mouseUp(): void {
    console.log("mouseUp");
  }

  private demo(): void {
    d3.select("div.myclass").append("span").text("from D3.js");
    d3.select(".myclass").style("color", "red");
    d3.select("#list").selectAll("li")
        .data(this.numList)
        .text((d: any) => "This is pre-existing element and the value is " + d)
        .enter()
        .append("li")
        .text((d: any) => "This is dynamically created element and the value is " + d);

    d3.select("p.paragraph")
        .datum(50)
        .text((d: any) => "Used existing paragraph element and the data " + d + " is assigned.");

    d3.select("div.paragraph")
        .datum(100)
        .append("p")
        .text((d: any) => "Created new paragraph element and the data " + d + " is assigned.");

    // Create SVG element
    const svg = d3.select("#svgcontainer")
        .append("svg")
        .attr("width", 300)
        .attr("height", 150);
    // Create and append rectangle element
    svg.append("rect")
        .attr("x", 20)
        .attr("y", 20)
        .attr("width", 200)
        .attr("height", 100)
        .attr("fill", "green");
    // Append circle
    svg.append("circle")
        .attr("cx", 200)
        .attr("cy", 50)
        .attr("r", 20)
        .attr("fill", "blue");
    svg.append("ellipse")
        .attr("cx", 100)
        .attr("cy", 50)
        .attr("rx", 50)
        .attr("ry", 25)
        .attr("fill", "orange");

    /*const t = d3.transition()
        .duration(4000);*/
    const t = d3.transition()
        .delay(200)
        .duration(4000);

    d3.select("#transitions")
        .style("background-color", "lightblue")
        .transition(t)
        .style("background-color", "red");
    d3.selectAll("h1").transition().style("color","rgb(0,150,120)").duration(5000);
  }

  private remove() {
    this.numList.splice(3, 1);
    d3.select("#list").selectAll("li")
        .data(this.numList)
        .text((d: any) => "This is pre-existing element and the value is " + d)
        .exit()
        .remove();
  }

  onResize(): void {
    console.log("Window resize called !!!");
    this.drawChart(this.lineGraphActualData, this.chartContainer.nativeElement, this.measurement);
  }

  ngOnInit(): void {
    console.log("Component initialized !!!");
    // this.drawChart(this.lineGraphActualData, this.chartContainer.nativeElement, this.measurement);
    this.demo();
  }
}
