import { Component, OnInit } from '@angular/core';
import {TwitterService} from "../twitter/twitter.service";

@Component({
  selector: 'app-topic-trend',
  templateUrl: './topic-trend.component.html',
  styleUrls: ['./topic-trend.component.css']
})
export class TopicTrendComponent implements OnInit {

  constructor() {
    console.log("inside topic trend")
  }

  ngOnInit() {
  }

}
