import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as d3 from 'd3';
import {CurvedWeightedService} from '../curved-weighted-force-graph/curved-weighted.service';

@Component({
  selector: 'app-force-directed-rect',
  templateUrl: './force-directed-rect.component.html',
  styleUrls: ['./force-directed-rect.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForceDirectedRectComponent implements OnInit {

  constructor(private service: CurvedWeightedService) {
  }

  buildGraph(): void {
    const svg: any = d3.select('svg');
    const width: any = +svg.attr('width');
    const height: any = +svg.attr('height');
    const color: string[] = this.service.nodeColors;
    const xCenter = width / 2;
    const yCenter = height / 2;

    let nodes: any[] = this.service.nodes;
    let links: any[] = this.service.links;

    nodes.forEach((node: any) => {
      links.forEach((link: any) => {
        if (link.target === node.id || link.source === node.id) {
          link.value = link.value ? (link.value + (+node.value || 0)) : +node.value || 0;
          if (link.source === node.id) {
            link.group = node.group;
          }
        }
      });
    });

    let simulation: any = d3.forceSimulation()
        .force('link', d3.forceLink().id((d: any) => d.id)
            .distance((d: any) => {
              return d.groupLink ? 200 : 100;
            }).strength(0.35)
        )
        .force('charge', d3.forceManyBody().strength((d: any) => {
          return -5000;
        }))
        .force('collide', d3.forceCollide().radius((d: any) => {
          return +d.value + 100;
        }))
        .force('center', d3.forceCenter(xCenter, yCenter));

    // For background color and other functionality.
    svg.append('g').append('rect')
        .attr('width', width)
        .attr('height', height)
        .attr('fill', '#000000')
        .on('click', () => {
          svg.selectAll('.label')
              .data(nodes)
              .attr('opacity', 1);
          svg.selectAll('.node')
              .data(nodes)
              .attr('opacity', 1);
          svg.selectAll('.link')
              .data(links)
              .attr('opacity', 1);
        });

    // add encompassing group for the zoom
    let g = svg.append('g')
        .attr('class', 'everything');

    let link: any = g.append('g')
        .attr('class', 'links')
        .selectAll('path')
        .data(links)
        .enter().append('svg:path')
        .attr('class', 'link')
        .style('fill', 'none')
        .attr('opacity', (d: any) => {
          return d.groupLink ? 1 : 0;
        })
        .on('mouseover', function () {
          d3.select(this).style('cursor', 'pointer');
        })
        .on('mouseout', function () {
          d3.select(this).style('cursor', 'normal');
        })
        .attr('stroke', (d: any) => {
          return (d.groupLink) ? '#999999' : color[d.group];
        })
        .attr('stroke-width', (d: any) => {
          return d.groupLink ? Math.sqrt(d.value) : 2;
        })
        .on('click', (d: any, i: number) => {
          highlightLinkConnections(i);
        });

    let node: any = g.append('g')
        .attr('class', 'nodes')
        .selectAll('g')
        .data(nodes)
        .enter().append('g')
        .on('mouseover', function () {
          d3.select(this).style('cursor', 'pointer');
        })
        .on('mouseout', function () {
          d3.select(this).style('cursor', 'normal');
        })
        .on('click', (d: any) => {
          highlightNodeConnections(d.id);
        });

    let rect: any = node.append('rect')
        .attr('class', 'node')
        .attr('fill', '#171717');

    const bBoxList = [];

    let label: any = node.append('text')
        .attr('class', (d: any) => {
          return d.name ? 'label' : 'add-label';
        })
        .text((d: any) => {
          return d.name ? d.name : '\uf067' + ' Add Label';
        })
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .style('font-size', (d: any) => {
          return (d.root) ? '80px' : (50 + (+d.value || 0)) + 'px';
        })
        .style('font-weight', (d: any) => {
          return (d.root) ? 'bold' : 'normal';
        })
        .attr('fill', (d: any) => {
          return (d.root) ? '#FFFFFF' : color[+d.group];
        })
        .each(function () {
          const bBox = this.getBBox();
          bBoxList.push(bBox);
        })
        .on('click', (d: any) => {
          if (!d.name) {
            console.log('Add the damn label');
          }
        });

    function highlightLinkConnections(index: number) {
      // Hello
      const link: any = links[index];
      svg.selectAll('.label')
          .data(nodes)
          .attr('opacity', (d: any) => {
            return (link.source.id === d.id || link.target.id === d.id) ? 1 : 0.3;
          });
      svg.selectAll('.node')
          .data(nodes)
          .attr('opacity', (d: any) => {
            return (link.source.id === d.id || link.target.id === d.id) ? 1 : 0.3;
          });
      svg.selectAll('.link')
          .data(links)
          .attr('opacity', (d: any, i: number) => {
            return i === index ? 1 : 0.3;
          });
    }

    function highlightNodeConnections(id: number) {
      let nodesToBeHighlighted: number[] = [id];
      let linksToBeHighlighted: number[] = [];
      links.forEach((link: any, index: number) => {
        if (link.target.id === id) {
          if (!nodesToBeHighlighted.includes(link.source.id)) {
            nodesToBeHighlighted.push(link.source.id);
          }
          linksToBeHighlighted.push(index);
        } else if (link.source.id === id) {
          if (!nodesToBeHighlighted.includes(link.target.id)) {
            nodesToBeHighlighted.push(link.target.id);
          }
          linksToBeHighlighted.push(index);
        }
      });
      svg.selectAll('.label')
          .data(nodes)
          .attr('opacity', (d: any) => {
            return nodesToBeHighlighted.includes(d.id) ? 1 : 0.3;
          });
      svg.selectAll('.node')
          .data(nodes)
          .attr('opacity', (d: any) => {
            return nodesToBeHighlighted.includes(d.id) ? 1 : 0.3;
          });
      svg.selectAll('.link')
          .data(links)
          .attr('opacity', (d: any, i: number) => {
            return linksToBeHighlighted.includes(i) ? 1 : 0.3;
          });
    }

    svg.selectAll('.node')
        .data(nodes)
        .attr('width', (d: any, i: number) => {
          return bBoxList[i].width + 60;
        })
        .attr('height', (d: any, i: number) => {
          return bBoxList[i].height + 20;
        })
        .attr('rx', (d: any, i: number) => {
          return ((bBoxList[i].height + 20) * 14) / 100;
        })
        .attr('ry', (d: any, i: number) => {
          return ((bBoxList[i].height + 20) * 14) / 100;
        })
        .attr('transform', (d: any, i: number) => {
          return 'translate(-' + ((bBoxList[i].width + 60) / 2) + ',-' + ((bBoxList[i].height + 20) / 2) + ')';
        });

    node.append('title')
        .text((d: any) => d.value);

    simulation
        .nodes(nodes)
        .on('tick', ticked);

    function ticked() {
      link.attr('d', (d: any) => {
        let dx = d.target.x - d.source.x,
            dy = d.target.y - d.source.y,
            dr = Math.sqrt(dx * dx + dy * dy);
        return 'M' +
            d.source.x + ',' +
            d.source.y + 'A' +
            dr + ',' + dr + ' 0 0,1 ' +
            d.target.x + ',' +
            d.target.y;
      });

      node.attr('transform', (d: any) => {
        return 'translate(' + d.x + ',' + d.y + ')';
      });
    }

    simulation.force('link')
        .links(links);

    //add drag capabilities
    const dragHandler = d3.drag()
        .on('start', dragStarted)
        .on('drag', dragged)
        .on('end', dragEnded);

    dragHandler(node);

    function dragStarted(d: any) {
      if (!d3.event.active) {
        simulation.alphaTarget(0.3).restart();
      }
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d: any) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragEnded(d: any) {
      /*if (!d.root) {
        if (!d3.event.active) {
          simulation.alphaTarget(0);
        }
        d.fx = null;
        d.fy = null;
      }*/
      simulation.alphaTarget(0);
    }

    //add zoom capabilities
    const zoomHandler = d3.zoom()
        .scaleExtent([1 / 8, 1])
        .on('zoom', zoomActions);
    svg.call(zoomHandler).on('dblclick.zoom', null);
    svg.call(zoomHandler.transform, d3.zoomIdentity);
    zoomHandler.scaleTo(svg.transition(), 0);

    //Zoom functions
    function zoomActions() {
      g.attr('transform', d3.event.transform);
    }
  }

  ngOnInit() {
    this.buildGraph();
  }

}
