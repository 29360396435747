import {Component, OnInit} from '@angular/core';
import {PackLayoutService} from './pack-layout.service';
import * as d3 from 'd3';

@Component({
  selector: 'app-pack-layout',
  templateUrl: './pack-layout.component.html',
  styleUrls: ['./pack-layout.component.css']
})
export class PackLayoutComponent implements OnInit {

  constructor(private service: PackLayoutService) {
  }

  drawLayout(): void {
    const color: any = d3.scaleOrdinal(d3.schemeCategory10);
    const width = window.innerWidth, height = window.innerHeight;

    const chart = d3.select('.svgContainer').append('svg')
        .attr('width', width).attr('height', height)
        .append('g')
        .attr('transform', 'translate(50,50)');

    const root: any = d3.hierarchy(this.service.data);
    const nodes: any = root.descendants();
    const pack: any = d3.pack()
        .size([width, height - 200])
        .padding(10);
    pack(root);

    const node = chart.selectAll('.node')
        .data(nodes).enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', (d: any) => {
          return 'translate(' + d.x + ',' + d.y + ')';
        });

    node.append('circle')
        .attr('r', (d: any) => {
          return d.r;
        })
        .attr('fill', (d: any) => color(d.depth))
        .attr('opacity', 0.25)
        .attr('stroke', '#ADADAD')
        .attr('stroke-width', 2);

    node.append('text')
        .text((d: any) => {
          return d.children ? '' : d.name;
        });
  }

  ngOnInit() {
    this.drawLayout();
  }

}
