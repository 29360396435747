import {Injectable} from "@angular/core";
import {ApiService} from "../services/api.service";

@Injectable({
  providedIn: "root"
})
export class HomeService {

  constructor(private apiService: ApiService) {
  }

  public getData(dates: any) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get("/home?start=" + dates[0] + "&end=" + dates[1]).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }

}

