import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurvedWeightedService {

  nodes = [
    {
      'id': 0,
      'name': 'regering',
      'value': '237',
      'group': '0'
    }, {
      'id': 1,
      'name': 'soldier',
      'value': '259',
      'group': '2'
    }, {
      'id': 2,
      'name': 'film',
      'value': '179',
      'group': '4'
    }, {
      'id': 3,
      'name': 'army',
      'value': '179',
      'group': '2'
    }, {
      'id': 4,
      'name': 'woman',
      'value': '156',
      'group': '3'
    }, {
      'id': 5,
      'name': 'department',
      'value': '120',
      'group': '0'
    }, {
      'id': 6,
      'name': 'magazines',
      'value': '116',
      'group': '4'
    }, {
      'id': 7,
      'name': 'embassy',
      'value': '111',
      'group': '0'
    }, {
      'id': 8,
      'name': 'people',
      'value': '109',
      'group': '1'
    }, {
      'id': 9,
      'name': 'lady',
      'value': '106',
      'group': '3'
    }, {
      'id': 10,
      'name': 'dollar',
      'value': '103',
      'group': '5'
    }, {
      'id': 11,
      'name': 'troops',
      'value': '98',
      'group': '2'
    }, {
      'id': 12,
      'name': 'soldiers',
      'value': '90',
      'group': '2'
    }, {
      'id': 13,
      'name': 'report',
      'value': '80',
      'group': '6'
    }, {
      'id': 14,
      'name': 'CULTURE',
      'value': '200',
      'group': '1',
      'root': true
    }, {
      'id': 15,
      'name': 'secretary',
      'value': '77',
      'group': '0'
    }, {
      'id': 16,
      'name': 'market',
      'value': '71',
      'group': '5'
    }, {
      'id': 17,
      'name': 'authorities',
      'value': '66',
      'group': '0'
    }, {
      'id': 18,
      'name': 'air force',
      'value': '66',
      'group': '2'
    }, {
      'id': 19,
      'name': 'navy',
      'value': '63',
      'group': '2'
    }, {
      'id': 20,
      'name': 'research',
      'value': '56',
      'group': '6'
    }, {
      'id': 21,
      'name': 'congress',
      'value': '52',
      'group': '0'
    }, {
      'id': 22,
      'name': 'tourists',
      'value': '51',
      'group': '1'
    }, {
      'id': 23,
      'name': 'show',
      'value': '50',
      'group': '4'
    }, {
      'id': 24,
      'name': 'envoy',
      'value': '47',
      'group': '2'
    }, {
      'id': 25,
      'name': 'public',
      'value': '46',
      'group': '1'
    }, {
      'id': 26,
      'name': 'senate',
      'value': '42',
      'group': '0'
    }, {
      'id': 27,
      'name': 'president',
      'value': '41',
      'group': '0'
    }, {
      'id': 28,
      'name': 'zone',
      'value': '40',
      'group': '2'
    }, {
      'id': 29,
      'name': 'auto',
      'value': '39',
      'group': '5'
    }, {
      'id': 30,
      'name': 'pilots',
      'value': '39',
      'group': '2'
    }, {
      'id': 31,
      'name': 'trusts',
      'value': '37',
      'group': '5'
    }, {
      'id': 32,
      'name': 'flag',
      'value': '33',
      'group': '1'
    }, {
      'id': 33,
      'name': 'press',
      'value': '31',
      'group': '4'
    }, {
      'id': 34,
      'name': 'petrol',
      'value': '30',
      'group': '5'
    }, {
      'id': 35,
      'name': 'way',
      'value': '28',
      'group': '1'
    }, {
      'id': 36,
      'name': 'television',
      'value': '27',
      'group': '4'
    }, {
      'id': 37,
      'name': 'men',
      'value': '26',
      'group': '3'
    }, {
      'id': 38,
      'name': 'concern',
      'value': '25',
      'group': '5'
    }, {
      'id': 39,
      'name': 'newspapers',
      'value': '25',
      'group': '4'
    }, {
      'id': 40,
      'name': 'novel',
      'value': '25',
      'group': '1'
    }, {
      'id': 41,
      'name': 'fleet',
      'value': '25',
      'group': '2'
    }, {
      'id': 42,
      'name': 'consul',
      'value': '24',
      'group': '0'
    }, {
      'id': 43,
      'name': 'officers',
      'value': '24',
      'group': '2'
    }, {
      'id': 44,
      'name': 'author',
      'value': '24',
      'group': '4'
    }, {
      'id': 45,
      'name': 'railroads',
      'value': '24',
      'group': '5'
    }, {
      'id': 46,
      'name': 'tobacco industry',
      'value': '24',
      'group': '5'
    }, {
      'id': 47,
      'name': 'side',
      'value': '24',
      'group': '2'
    }, {
      'id': 48,
      'name': 'scientists',
      'value': '22',
      'group': '6'
    }, {
      'id': 49,
      'name': 'firm',
      'value': '21',
      'group': '5'
    }, {
      'id': 50,
      'name': 'industry',
      'value': '21',
      'group': '5'
    }, {
      'id': 51,
      'name': 'corporation',
      'value': '21',
      'group': '5'
    }, {
      'id': 52,
      'name': 'origin',
      'value': '21',
      'group': '1'
    }, {
      'id': 53,
      'name': 'tanks',
      'value': '21',
      'group': '2'
    }, {
      'id': 54,
      'name': 'doctor',
      'value': '20',
      'group': '6'
    }, {
      'id': 55,
      'name': 'banks',
      'value': '20',
      'group': '5'
    }, {
      'id': 56,
      'name': 'citizens',
      'value': '20',
      'group': '1'
    }, {
      'id': 57,
      'name': 'girl',
      'value': '20',
      'group': '3'
    }, {
      'id': 58,
      'name': 'American',
      'value': '',
      'group': '-1',
      'root': true
    }, {
      'id': 59,
      'name': 'prisoners of war',
      'value': '20',
      'group': '2'
    }, {
      'id': 60,
      'name': 'medical',
      'value': '20',
      'group': '6'
    }, {
      'id': 61,
      'name': 'treasury',
      'value': '20',
      'group': '5'
    }, {
      'id': 62,
      'name': 'tobacco trust',
      'value': '20',
      'group': '5'
    }, {
      'id': 63,
      'name': 'MILITARY',
      'value': '200',
      'group': '2',
      'root': true
    }, {
      'id': 64,
      'name': 'GENDER',
      'value': '200',
      'group': '3',
      'root': true
    }, {
      'id': 65,
      'name': 'MEDIA',
      'value': '200',
      'group': '4',
      'root': true
    }, {
      'id': 66,
      'name': 'TRADE',
      'value': '200',
      'group': '5',
      'root': true
    }, {
      'id': 67,
      'name': 'GOVERNMENT',
      'value': '200',
      'group': '0',
      'root': true
    }, {
      'id': 68,
      'name': 'SCIENCE',
      'value': '200',
      'group': '6',
      'root': true
    }
  ];

  links = [
    {
      'source': 14,
      'target': 58,
      'groupLink': true
    }, {
      'source': 63,
      'target': 58,
      'groupLink': true
    }, {
      'source': 64,
      'target': 58,
      'groupLink': true
    }, {
      'source': 65,
      'target': 58,
      'groupLink': true
    }, {
      'source': 66,
      'target': 58,
      'groupLink': true
    }, {
      'source': 67,
      'target': 58,
      'groupLink': true
    }, {
      'source': 68,
      'target': 58,
      'groupLink': true
    }, {
      'source': 67,
      'target': 0
    }, {
      'source': 63,
      'target': 1
    }, {
      'source': 65,
      'target': 2
    }, {
      'source': 63,
      'target': 3
    }, {
      'source': 64,
      'target': 4
    }, {
      'source': 67,
      'target': 5
    }, {
      'source': 65,
      'target': 6
    }, {
      'source': 67,
      'target': 7
    }, {
      'source': 14,
      'target': 8
    }, {
      'source': 64,
      'target': 9
    }, {
      'source': 66,
      'target': 10
    }, {
      'source': 63,
      'target': 11
    }, {
      'source': 63,
      'target': 12
    }, {
      'source': 68,
      'target': 13
    }, {
      'source': 67,
      'target': 15
    }, {
      'source': 66,
      'target': 16
    }, {
      'source': 67,
      'target': 17
    }, {
      'source': 63,
      'target': 18
    }, {
      'source': 63,
      'target': 19
    }, {
      'source': 68,
      'target': 20
    }, {
      'source': 67,
      'target': 21
    }, {
      'source': 14,
      'target': 22
    }, {
      'source': 65,
      'target': 23
    }, {
      'source': 63,
      'target': 24
    }, {
      'source': 14,
      'target': 25
    }, {
      'source': 67,
      'target': 26
    }, {
      'source': 67,
      'target': 27
    }, {
      'source': 63,
      'target': 28
    }, {
      'source': 66,
      'target': 29
    }, {
      'source': 63,
      'target': 30
    }, {
      'source': 66,
      'target': 31
    }, {
      'source': 14,
      'target': 32
    }, {
      'source': 65,
      'target': 33
    }, {
      'source': 66,
      'target': 34
    }, {
      'source': 14,
      'target': 35
    }, {
      'source': 65,
      'target': 36
    }, {
      'source': 64,
      'target': 37
    }, {
      'source': 66,
      'target': 38
    }, {
      'source': 65,
      'target': 39
    }, {
      'source': 14,
      'target': 40
    }, {
      'source': 63,
      'target': 41
    }, {
      'source': 67,
      'target': 42
    }, {
      'source': 63,
      'target': 43
    }, {
      'source': 65,
      'target': 44
    }, {
      'source': 66,
      'target': 45
    }, {
      'source': 66,
      'target': 46
    }, {
      'source': 63,
      'target': 47
    }, {
      'source': 68,
      'target': 48
    }, {
      'source': 66,
      'target': 49
    }, {
      'source': 66,
      'target': 50
    }, {
      'source': 66,
      'target': 51
    }, {
      'source': 14,
      'target': 52
    }, {
      'source': 63,
      'target': 53
    }, {
      'source': 68,
      'target': 54
    }, {
      'source': 66,
      'target': 55
    }, {
      'source': 14,
      'target': 56
    }, {
      'source': 64,
      'target': 57
    }, {
      'source': 63,
      'target': 59
    }, {
      'source': 68,
      'target': 60
    }, {
      'source': 66,
      'target': 61
    }, {
      'source': 66,
      'target': 62
    }
  ];

  nodeColors: string[] = [
    '#9D7C10',
    '#1ec1c7',
    '#468f61',
    '#009975',
    '#de752a',
    '#8f33b9',
    '#332fa3',
    '#fd6683',
    '#e04d2f',
    '#fd6683',
    '#1f67db'
  ];

  constructor() {
  }
}
