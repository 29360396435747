import {Component, OnInit} from "@angular/core";

@Component({
  selector: "app-word-cloud-bar-chart",
  templateUrl: "./word-cloud-bar-chart.component.html",
  styleUrls: ["./word-cloud-bar-chart.component.css"]
})
export class WordCloudBarChartComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
