import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-words-filter',
  templateUrl: './words-filter.component.html',
  styleUrls: ['./words-filter.component.css']
})
export class WordsFilterComponent implements OnInit {
  constructor() {
  }

  @Input('parentData') public brands: string[];
  @Output() public brandsEvent = new EventEmitter();
  @Output() public sentimentsEvent = new EventEmitter();

  compare1: string = '';
  compare2: string = '';

  public compareBrands(): void {
    this.brandsEvent.emit([this.compare1, this.compare2]);
  }

  public combineBrands(): void {
    this.brandsEvent.emit(null);
  }

  public showSentiments(): void {
    this.sentimentsEvent.emit(true);
  }

  public hideSentiments(): void {
    this.sentimentsEvent.emit(false);
  }

  public show: any[] = [];

  // Toggle collaspe.
  toggle(str: string, open: boolean) {
    const wordsIcons = document.getElementById('wordsFilterIcons');

    const toggleCollasple = this.show.indexOf(str);
    if (toggleCollasple > -1) {
      this.show.splice(toggleCollasple, 1);
    } else {
      this.show.push(str);
    }

    switch (str) {
      case 'Brands': {
        if (open) {
          this.compareBrands();
        } else {
          this.combineBrands();
        }
        break;
      }
      case 'Sentiments': {
        if (open) {
          this.showSentiments();
        } else {
          this.hideSentiments();
        }
        break;
      }
    }
  }

  sideBarScroll() {
    //noinspection TypeScriptUnresolvedFunction
    $(window).scroll(function () {

      let wordsFilterPannelHeight: any = $('.wordsFilterPannel').height() + 104;
      let windowHeight: any = $(window).height();
      let remainingHeight: any = wordsFilterPannelHeight - windowHeight;
      let docHeight: any = $(document).height();
      let docHeightCalc: any = docHeight - wordsFilterPannelHeight;
      let scrollHeight: any = $(document).scrollTop();
      let calcScrollTop: any = scrollHeight + windowHeight;

      if (wordsFilterPannelHeight > windowHeight) {
        $('.wordsFilterPannel').removeClass('position-normal');

        if (calcScrollTop > wordsFilterPannelHeight) {
          $('.wordsFilterPannel').addClass('sticky-fixed');
        } else {
          $('.wordsFilterPannel').removeClass('sticky-fixed');
        }
      }
    });
  }

  ngOnInit() {
    this.sideBarScroll();
    const docColor: any = document.getElementsByTagName('html')[0];
    docColor.style.backgroundColor = 'black';
    this.compare1 = this.brands[0];
    this.compare2 = this.brands[1];
  }


}
