import { Component, OnInit } from "@angular/core";
import {LoginService} from "./login.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {

  public username: "string";
  public password: "string";
  public rememberMe = false;
  public invalidCredentials : boolean = false;
  public returnUrl: string;

  constructor(public loginService: LoginService,
              public route: ActivatedRoute,
              public router: Router) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  public setCookie(payload){
    if (this.rememberMe) {
      document.cookie = "credentials=" + JSON.stringify(payload)
          //+ ";expires=Thu, 26 Dec 2019 00:00:01 GMT";
    } else {
      document.cookie = "credentials=" + JSON.stringify(payload);
    }
  }
  onSubmit() {
    const payload = {
      username: this.username,
      password: this.password
    };
    this.loginService.isAuthenticated(payload, {headers: {"Content-Type": "application/json"}, responseType: "boolean"}).then((response: any) => {
      // Todo: Boolean should be checked without quotes but responsetype set as text
      if(response === "true"){
        this.setCookie(payload);
        this.router.navigateByUrl(this.returnUrl);
      }
      else{
        this.invalidCredentials = true;
      }
    }).catch((error: any) => {
      this.invalidCredentials = true;
      throw error;
    });
  }

}
