import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'app-pie-comparison-radio',
  templateUrl: './pie-comparison-radio.component.html',
  styleUrls: ['./pie-comparison-radio.component.css']
})
export class PieComparisonRadioComponent implements OnInit {

  @Input() name: string = 'site';
  @Input() default: string;
  @Input() selectedbutton:string;
  // @Input() selectedbutton2:string;
  @Output()
  radioChanged: EventEmitter<any> = new EventEmitter<any>(); //creating an output event

  constructor() { }

  ngOnInit() {
    console.log(this.selectedbutton);
  }

  public siteChanged(site:any, $event){
    this.radioChanged.emit(site);
  }
}
