import {Injectable} from '@angular/core';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root'
})
export class EllipseForceService {

  constructor() {
  }

  createForceSimulation(link: any, forceStrength: number, ellipse: any, forceCenter: any): any {
    return d3.forceSimulation()
        .force('link', d3.forceLink().id((d: any) => d.id).distance(link.length).strength(link.strength))
        .force('charge', d3.forceManyBody().strength(forceStrength))
        .force('collide', this.ellipseForce(ellipse.padding, ellipse.ir, ellipse.or))
        .force('center', d3.forceCenter(forceCenter.width, forceCenter.height));
  }

  // Closure
  constant(x: any): any {
    return function () {
      return x;
    };
  }

  ellipseForce(padding: any, innerRepulsion: any, outerRepulsion: any): any {
    let _this = this;
    let nodes;

    if (typeof padding !== 'function') {
      padding = this.constant(padding == null ? 4 : +padding);
    }
    innerRepulsion = innerRepulsion == null ? 0.5 : +innerRepulsion;
    outerRepulsion = outerRepulsion == null ? 0.5 : +outerRepulsion;

    function force(alpha) {
      let i, j, n = nodes.length,
          // dimensions of this node
          // tslint:disable-next-line:variable-name
          node, my_padding, my_w, my_h, my_x, my_y,
          // often used multiples
          // tslint:disable-next-line:variable-name
          my_w2, my_h2, my_wh,
          // dimensions of the other node
          // tslint:disable-next-line:variable-name
          other, other_padding, other_w, other_h, other_x, other_y,
          // distance between nodes
          // tslint:disable-next-line:variable-name
          dist_x, dist_y,
          // components for the overall result
          // tslint:disable-next-line:variable-name
          force_ratio, dist, gap, repulsion, x_component, y_component,
          // computing elliptical force
          g, g2, x1, y1, x2, y2, d1, d2,
          // tslint:disable-next-line:variable-name
          force_ratio1, force_ratio2,
          // parameters
          myOuterRepulsion = outerRepulsion * 16;

      for (i = 0; i < n; ++i) {
        node = nodes[i];
        my_padding = +padding(node, i, nodes);
        my_w = node.rx + my_padding;
        my_h = node.ry + my_padding;
        my_w2 = my_w * my_w;
        my_h2 = my_h * my_h;
        my_wh = my_w * my_h;
        my_x = node.x + node.vx;
        my_y = node.y + node.vy;

        for (j = 0; j < n; ++j) {
          if (j === i) {
            continue;
          }
          other = nodes[j];
          other_padding = +padding(other, j, nodes);
          other_w = other.rx + other_padding;
          other_h = other.ry + other_padding;
          other_x = other.x + other.vx;
          other_y = other.y + other.vy;
          dist_x = my_x - other_x;
          dist_y = my_y - other_y;
          if (dist_x === 0 && dist_y === 0) {
            node.vx += (Math.random() * 4) - 2;
            node.vy += (Math.random() * 4) - 2;
            continue;
          } else if (dist_x === 0) {
            force_ratio = (my_h / my_w + other_h / other_w) / 2;
            dist = Math.abs(dist_y);
            gap = dist - my_h - other_h;
          } else if (dist_y === 0) {
            force_ratio = 1;
            dist = Math.abs(dist_x);
            gap = dist - my_w - other_w;
          } else {
            // ellipse is defined as  x^2   y^2
            //                        --- + --- = 1
            //                        w^2   h^2
            // here x,y are points on ellipse's arc.
            // we have a line going between center points of two ellipses and we want to know
            // the point where it crosses the ellipse's arc. Because we know the line, we
            // know that y = g * x, where
            g = dist_y / dist_x;
            // now the only unknown in ellipse above is x, and thus we can find it by
            // moving pieces around (pen and paper work). equation becomes:
            //             w * h
            // x = ---------------------
            //     sqrt(h^2 + g^2 * w^2)

            g2 = g * g;
            x1 = my_wh / Math.sqrt(my_h2 + g2 * my_w2);
            y1 = g * x1;
            // the length of the little bit from the center of ellipse to its margin.
            // For circle it would be 'r', but for ellipse it varies.
            d1 = Math.sqrt(x1 * x1 + y1 * y1);
            // Strength of force that this ellipse eminates is modified by ratio of this bit
            // to the ellipse's width. (It doesn't matter if we use width or height as reference
            // point)
            force_ratio1 = d1 / my_w;
            // And same for the other ellipse:
            x2 = (other_w * other_h) / Math.sqrt(other_h * other_h + g2 * other_w * other_w);
            y2 = g * x2;
            d2 = Math.sqrt(x2 * x2 + y2 * y2);
            force_ratio2 = d2 / other_w;
            // now we can calculate the gap or overlap between two ellipses, and force ratio on
            // how strongly they should push as average of their force_ratios
            dist = Math.sqrt(dist_x * dist_x + dist_y * dist_y);
            gap = dist - d2 - d1;
            force_ratio = (force_ratio1 + force_ratio2) / 2;
          }
          x_component = dist_x / dist;
          y_component = dist_y / dist;
          if (gap < 0) { // force GROWS as gap goes further into negative
            repulsion = Math.min(Math.max(1.0, innerRepulsion * force_ratio * -gap), 5.0);
            node.vx += repulsion * x_component;
            node.vy += repulsion * y_component;
          } else { // force DIMINISHES as gap becomes larger
            repulsion = Math.min(20.0, (force_ratio * myOuterRepulsion * alpha) / gap);
            node.vx += repulsion * x_component;
            node.vy += repulsion * y_component;
          }
        }
      }
    }

    // tslint:disable-next-line:variable-name
    force.initialize = function (my_nodes) {
      nodes = my_nodes;
    };

    // tslint:disable-next-line:variable-name
    force.outerRepulsion = function (my_outerRepulsion) {
      if (arguments.length) {
        outerRepulsion = +my_outerRepulsion;
        return force;
      } else {
        return outerRepulsion;
      }
    };

    // tslint:disable-next-line:variable-name
    force.innerRepulsion = function (my_innerRepulsion) {
      if (arguments.length) {
        innerRepulsion = +my_innerRepulsion;
        return force;
      } else {
        return innerRepulsion;
      }
    };

    // tslint:disable-next-line:variable-name
    force.padding = function (my_padding) {
      if (arguments.length) {
        if (typeof my_padding === 'function') {
          padding = my_padding;
        } else {
          padding = _this.constant(+my_padding);
        }
        return force;
      } else {
        return padding;
      }
    };
    return force;
  }

  tickListener(node: any, link: any): void {
    link.attr('d', (d: any) => {
      let dx = d.target.x - d.source.x,
          dy = d.target.y - d.source.y,
          dr = Math.sqrt(dx * dx + dy * dy);
      return 'M' +
          d.source.x + ',' +
          d.source.y + 'A' +
          dr + ',' + dr + ' 0 0,1 ' +
          d.target.x + ',' +
          d.target.y;
    });

    node.attr('transform', (d: any) => {
      return 'translate(' + d.x + ',' + d.y + ')';
    });
  }

  createDraggableNodes(node: any, simulation: any): void {
    const dragHandler: any = d3.drag()
        .on('start', dragStarted)
        .on('drag', dragged)
        .on('end', dragEnded);

    dragHandler(node);

    function dragStarted(d: any) {
      if (!d3.event.active) {
        simulation.alphaTarget(0.3).restart();
      }
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d: any) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragEnded(d: any) {
      /*if (!d.root) {
        if (!d3.event.active) {
          simulation.alphaTarget(0);
        }
        d.fx = null;
        d.fy = null;
      }*/
      simulation.alphaTarget(0);
    }
  }

  createZoomableGraph(targetSVG: any, scaleExtent: [number, number], zoomActions: any): void {

    const zoomHandler = d3.zoom().scaleExtent(scaleExtent).on('zoom', zoomActions);
    targetSVG.call(zoomHandler).on('dblclick.zoom', null);
    targetSVG.call(zoomHandler.transform, d3.zoomIdentity);
    zoomHandler.scaleTo(targetSVG.transition(), 0);
  }
}
