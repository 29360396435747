import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AccuracyComponent} from './accuracy/accuracy.component';
import {HttpClientModule} from '@angular/common/http';
import {SitefiltersComponent} from './reusable/sitefilters/sitefilters.component';
import {HeaderComponent} from './reusable/header/header.component';
import {Daterangepicker} from 'ng2-daterangepicker';
import {DaterangepickerComponent} from './reusable/daterangepicker/daterangepicker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule, MatInputModule, MatPaginatorModule, MatProgressBarModule, MatSortModule} from '@angular/material';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {MatTableModule} from '@angular/material/table';
import {AutosizeModule} from 'ngx-autosize';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSliderModule} from '@angular/material/slider';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ChartsModule } from 'ng2-charts';

import {SharedServiceService} from './services/shared-service.service';
import {EchartGraphsService} from './services/echart-graphs.service';
import {AccuracyService} from './accuracy/accuracy.service';
import {ApiService} from './services/api.service';
import {NgxEchartsModule} from 'ngx-echarts';
import {LoaderComponent} from './reusable/loader/loader.component';
import {WordcloudComponent} from './reusable/wordcloud/wordcloud.component';
import {MessageModalComponent} from './reusable/message-modal/message-modal.component';
import {ClickOutsideDirective} from './directives/click-outside.directive';
import {ReadMoreComponent} from './reusable/read-more/read-more.component';
import {MoreFilterComponent} from './reusable/more-filter/more-filter.component';
import {SupplyComponent} from './supply/supply.component';
import {DietComponent} from './diet/diet.component';
import {PieComparisonRadioComponent} from './reusable/pie-comparison-radio/pie-comparison-radio.component';
import {HeaderDateChangedService} from './reusable/header/header-date-changed.service';
import {DiabetesComponent} from './diabetes/diabetes.component';
import {DigitalComponent} from './digital/digital.component';
import {WearableComponent} from './wearable/wearable.component';
import {AlertsComponent} from './alerts/alerts.component';
import {CostComponent} from './cost/cost.component';
import {HomeComponent} from './home/home.component';
import {CustomerServiceComponent} from './customer-service/customer-service.component';
import {SentimentsComponent} from './sentiments/sentiments.component';
import {TrendsComponent} from './trends/trends.component';
import {TwitterComponent} from './twitter/twitter.component';
import {LoginComponent} from './login/login.component';

import {LineGraphComponent} from './reusable/d3/line-graph/line-graph.component';
import {TestGraphComponent} from './reusable/d3/test-graph/test-graph.component';
import {BarChartComponent} from './reusable/d3/bar-chart/bar-chart.component';
import {InfluncerComponent} from './twitter/influncer/influncer.component';
import {BarRatingModule} from 'ngx-bar-rating';
import {ProgressBarModule} from 'angular-progress-bar';
import {RatingBarComponent} from './twitter/influncer/rating-bar/rating-bar.component';
import {NgbModule, NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import {InfluentialuserComponent} from './twitter/influentialuser/influentialuser.component';
import {NetworkGraphComponent} from './twitter/network-graph/network-graph.component';
import {WordsFilterComponent} from './reusable/words-filter/words-filter.component';
import {MessagesComponent} from './messages/messages.component';
import {ForceDirectedGraphComponent} from './reusable/d3/force-directed-graph/force-directed-graph.component';
import {CurvedWeightedForceGraphComponent} from './reusable/d3/curved-weighted-force-graph/curved-weighted-force-graph.component';
import {WordCloudComponent} from './reusable/d3/word-cloud/word-cloud.component';
import {ForceDirectedRectComponent} from './reusable/d3/force-directed-rect/force-directed-rect.component';
import {WordCloudBarChartComponent} from './reusable/d3/word-cloud-bar-chart/word-cloud-bar-chart.component';
import {PackLayoutComponent} from './reusable/d3/pack-layout/pack-layout.component';
import {LeftPannelComponent} from './reusable/left-pannel/left-pannel.component';
import {MessageService} from './messages/message.service';
import {EllipsisPipe} from './reusable/elipsis.pipe';
import {PieChartComponent} from './reusable/d3/pie-chart/pie-chart.component';
import {ModalComponent} from './messages/modal/modal.component';
import {InnerMessageModalComponent} from './messages/inner-message-modal/inner-message-modal.component';
import {HighlightPipeComponent} from './highlight-pipe/highlight-pipe.component';
import {SubClusterComponent} from './messages/sub-cluster/sub-cluster.component';
import {EditLabelComponent } from './messages/edit-label/edit-label.component';
import {ScatterplotComponent } from './reusable/d3/scatterplot/scatterplot.component';
import {ScatterGraphComponent} from './twitter/scatter-graph/scatter-graph.component';
import {ScatterGraphService} from './twitter/scatter-graph/scatter-graph.service';
import {MatCardModule} from "@angular/material/card";
import { BarchartComponent } from './reusable/d3/barchart/barchart.component';
import { StatsWordCloudComponent } from './reusable/d3/stats-word-cloud/stats-word-cloud.component';
import { UserTranscriptComponent } from './twitter/user-transcript/user-transcript.component';
import { TopicTrendComponent } from './topic-trend/topic-trend.component';
import { SeekDiscoverComponent } from './seek-discover/seek-discover.component';
import { SidePanelComponent } from './reusable/side-panel/side-panel.component';
import { SidePanelMessagesComponent } from './reusable/side-panel-messages/side-panel-messages.component';
import { SidePanelStatsComponent } from './reusable/side-panel-stats/side-panel-stats.component';
import { CustomTopicsComponent } from './custom-topics/custom-topics.component';
import { GetUserChoiceComponent } from './custom-topics/get-user-choice/get-user-choice.component';
import { GraphOptionsComponent } from './custom-topics/graph-options/graph-options.component';


@NgModule({
  declarations: [
    AppComponent,
    AccuracyComponent,
    SitefiltersComponent,
    HeaderComponent,
    DaterangepickerComponent,
    LoaderComponent,
    WordcloudComponent,
    MessageModalComponent,
    ClickOutsideDirective,
    ReadMoreComponent,
    MoreFilterComponent,
    SupplyComponent,
    DietComponent,
    PieComparisonRadioComponent,
    DiabetesComponent,
    WearableComponent,
    AlertsComponent,
    DigitalComponent,
    WearableComponent,
    CostComponent,
    HomeComponent,
    CustomerServiceComponent,
    TrendsComponent,
    TwitterComponent,
    SentimentsComponent,
    LoginComponent,
    LineGraphComponent,
    TestGraphComponent,
    BarChartComponent,
    WordsFilterComponent,
    MessagesComponent,
    LeftPannelComponent,
    ForceDirectedGraphComponent,
    CurvedWeightedForceGraphComponent,
    WordCloudComponent,
    ForceDirectedRectComponent,
    WordCloudBarChartComponent,
    PackLayoutComponent,
    EllipsisPipe,
    PieChartComponent,
    InfluncerComponent,
    RatingBarComponent,
    InfluentialuserComponent,
    NetworkGraphComponent,
    ModalComponent,
    InnerMessageModalComponent,
    HighlightPipeComponent,
    SubClusterComponent,
    EditLabelComponent,
    ScatterplotComponent,
    ScatterGraphComponent,
    UserTranscriptComponent,
    UserTranscriptComponent,
    TopicTrendComponent,
    BarchartComponent,
    StatsWordCloudComponent,
    SeekDiscoverComponent,
    SidePanelComponent,
    SidePanelMessagesComponent,
    SidePanelStatsComponent,
    CustomTopicsComponent,
    GetUserChoiceComponent,
    GraphOptionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatFormFieldModule,
    Daterangepicker,
    NgxEchartsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatInputModule,
    MatTabsModule,
    MatSliderModule,
    DragDropModule,
    NgxUsefulSwiperModule,

    MatPaginatorModule,
    MatProgressBarModule,
    MatSortModule,
    NgbRatingModule,
    AutosizeModule,
    InfiniteScrollModule,
    MatTableModule,
    BarRatingModule,
    ProgressBarModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      closeButton: true
    }),
    MatCardModule,
    ChartsModule,
  ],
  providers: [
    SharedServiceService, AccuracyService, ApiService, DaterangepickerComponent, EchartGraphsService,
    MessageModalComponent, HeaderComponent, HeaderDateChangedService, MessageService, ScatterGraphService, ScatterGraphComponent
  ],
  entryComponents: [
    ModalComponent, InnerMessageModalComponent, EditLabelComponent, GetUserChoiceComponent, GraphOptionsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
