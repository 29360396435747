import {Injectable} from "@angular/core";
import {ApiService} from "../services/api.service";

@Injectable({
  providedIn: "root"
})
export class LoginService {

  constructor(private apiService: ApiService) {
  }

  public isAuthenticated(filters: any, options?: any) {

    const promise = new Promise((resolve, reject) => {
      this.apiService.post("/user/login", filters, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;

  }
}
