import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MessageService} from '../../messages/message.service';
import {SharedServiceService} from '../../services/shared-service.service';
import {FormControl, FormGroup} from '@angular/forms';
import {HeaderComponent} from '../header/header.component';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {InnerMessageModalComponent} from '../../messages/inner-message-modal/inner-message-modal.component';




@Component({
  selector: 'app-left-pannel',
  templateUrl: './left-pannel.component.html',
  styleUrls: ['./left-pannel.component.css']
})
export class LeftPannelComponent implements OnInit, OnChanges {
  limit: number = 8;
  msglimit: number = 3;
  lastMsgId: string;
  public toggle = true;
  public toggleHeight: boolean = true;
  public initialShow: boolean = true;
  public getH: any;
  public calcHeight: any = 0;
  public screentHeight: any;
  public calcHeightNew: any;
  public topScreentHeight: any;
  public topCalcHeightNew: any;
  public totalInteractions = 0;
  public pieOption: any;
  showLess = true;
  public brandMap: any = {
    abbottfreestyle: 'Abbott FreeStyle',
    dexcom: 'Dexcom',
    medtronicminimed: 'Medtronic Minimed',
    rocheaccuchek: 'Roche Accu-Chek'
  };
  public barChartData = [];


  public brandMessages: any = {
    'abbottfreestyle': 0,
    'dexcom': 0,
    'medtronicminimed': 0,
    'rocheaccuchek': 0
  };
  public brandMessagesContent: any = {
    'abbottfreestyle': [],
    'dexcom': [],
    'medtronicminimed': [],
    'rocheaccuchek': []
  };
  public sentimentsMessageContent: any = {
    positive: [],
    negative: [],
    neutral: []
  };

  public sentiments: any = {
    positive: 0,
    negative: 0,
    neutral: 0
  };
  public totalSentiments: number = 0;
  public categoryMessages: any = {};
  public totalMessages = 0;
  public totalCategoryMessages = 0;
  public brandPieChartDataSet = [];
  public pieChartDataSet = [];
  public authorMessagesContent = {
    "patient_advocate": [],
    'researcher': [],
    'journalist/media': [],
    'organization/ngo': [],
    'others': []

  };

  public fontAwesomeNames: any = {
    'Twitter': 'fa fa-twitter',
    'Forum': 'fa fa-wpforms',
    'Video and Photo': 'fa fa-youtube',
    'Blog': 'fa fa-rss',
    'Social network': 'fa fa-facebook',
    'Consumer opinions': 'fa fa-users'
  };
  /*  public deviceColorCodes:string[] = ['#41a6d3','#68a94d','#dd5026','#f2bb1f'];*/
  public colorCodes: string[] = ['#41a6d3', '#68a94d', '#dd5026', '#f2bb1f', '#654d46'];

  labelForm: FormGroup = new FormGroup({
    label: new FormControl([])
  });

  public messagesTopic: string;
  public showLoaderChild: boolean = true;
  public messages: any[] = [];
  @Input('selectedWords') public selectedWords: string[];
  @Input('parentWordsOccurrence') public wordsOccurrence: any [];
  @Input('groupLabel') public groupLabel: string;

  @Output() public saveLabelEvent: EventEmitter<any> = new EventEmitter();
  @Output() public wordClickEvent: EventEmitter<any> = new EventEmitter();


  constructor(public messageService: MessageService,
              public myService: SharedServiceService,
              public header: HeaderComponent,
              public ngbModalService: NgbModal) {
    // Bello, I initiate things.
  }

  //List collapse with limit
  public toggleView(): void {
    this.toggle = !this.toggle;
    if (this.toggle === true) {
      this.limit = 8;
      this.msglimit = 3;
    } else if (this.toggle === false) {
      this.limit = this.wordsOccurrence.length;
      this.msglimit = this.messages.length;
    }
  }

  openWindowCustomClass(messagesArr: any[]): void {
    const modalRef: NgbModalRef = this.ngbModalService.open(InnerMessageModalComponent, {windowClass: 'innerMsgModal'});
    modalRef.componentInstance.messages = messagesArr;
  }

  saveLabel(): void {
    this.saveLabelEvent.emit(this.labelForm.value.label);
  }

  clickEvent1(div): void {
    console.log(div)
    if (div === 'div1') {
      this.initialShow = !this.initialShow;
    } else if (div === 'div2') {
      this.toggleHeight = !this.toggleHeight;
    }
  }

  onMessageScroll() {
    this.fetchMessages();
  }

  showWordInfo(word: string): void {
    this.wordClickEvent.emit(word);
  }

  getPercent(value: number, baseValue: number) {
    return Math.round(1000 * value / baseValue) / 10;
  }

  processMsgStats(messages: any[]): void {
    console.log(this.totalMessages)
    this.totalInteractions = 0;
    this.totalMessages = messages.length;
    this.totalCategoryMessages = 0;
    this.categoryMessages = {};
    this.brandMessages = {
      'abbottfreestyle': 0,
      'dexcom': 0,
      'medtronicminimed': 0,
      'rocheaccuchek': 0
    };
    this.brandMessagesContent = {
      'abbottfreestyle': [],
      'dexcom': [],
      'medtronicminimed': [],
      'rocheaccuchek': []
    };
    this.sentiments = {
      positive: 0,
      negative: 0,
      neutral: 0
    };
    this.totalSentiments = 0;
    messages.forEach((item: any) => {
      // Calculate respective messages from brands/devices.
      const devices: string[] = item.Topics_Subtopics ? item.Topics_Subtopics.split(',') : [];
      devices.forEach((deviceItem: string) => {
        const device: string = this.messageService.formatDeviceName(deviceItem);
        if (this.brandMessages.hasOwnProperty(device)) {
          this.brandMessages[device]++;
          this.brandMessages[device]++;
          this.brandMessagesContent[device].push({Content: item.Content, Author_name: item.Author_name,  Site: item.Site, Site_Type: item.Site_Type, Date: item.Date, URL: item.URL});

        }
      });
      // Calculate total interactions.
      if (item['Total Interactions']) {
        this.totalInteractions += item['Total Interactions'];
      }

      if (item.profession && item.profession.trim()) {
        const categories: string[] = item.profession.trim().split(':');
        categories.forEach((category: string) => {
          category = category.toLowerCase();
          if (!this.categoryMessages[category]) {
            this.categoryMessages[category] = 0;
          }
          this.categoryMessages[category]++;
          this.totalCategoryMessages++;
        });
      } else {
        if (!this.categoryMessages['others']) {
          this.categoryMessages['others'] = 0;
        }
        this.categoryMessages['others']++;
        this.totalCategoryMessages++;
      }

      // Bello Sentiment totalSentiments
      item.Sentiment_general = item.Sentiment_general && item.Sentiment_general.toLowerCase().trim();
      if (item.Sentiment_general === 'positive' || item.Sentiment_general === 'negative') {
         this.sentiments[item.Sentiment_general]++;
        this.sentimentsMessageContent[item.Sentiment_general].push({Content: item.Content, Author_name: item.Author_name,  Site: item.Site, Site_Type: item.Site_Type, Date: item.Date, URL: item.URL});
      } else {
        this.sentiments['neutral']++;
      }
      this.totalSentiments++;
    });

    const newBrandDataSet: any[] = [];
    // Calculating brand/device messages percent.
    if (this.totalMessages) {
      for (let device in this.brandMessages) {
        newBrandDataSet.push({label: device, count: this.brandMessages[device]});
      }
    }
    this.brandPieChartDataSet = newBrandDataSet;

    const newCatDataSet: any[] = [];
    if (this.totalCategoryMessages) {
      let categoriesSorted: string[] = Object.keys(this.categoryMessages).sort((a: string, b: string) => {
        return this.categoryMessages[a] - this.categoryMessages[b];
      });
      categoriesSorted = categoriesSorted.slice(0, 5); // Top 5 categories.
      if (categoriesSorted.includes('others')) {
        categoriesSorted = categoriesSorted.filter((cat: string) => {
          return (cat !== 'others');
        });
      } else {
        categoriesSorted.pop();
      }
      let catCount = 0;
      categoriesSorted.forEach((cat: string) => {
        newCatDataSet.push({label: cat, count: this.categoryMessages[cat]});
        catCount += this.categoryMessages[cat];
      });
      newCatDataSet.push({label: 'others', count: (this.totalCategoryMessages - catCount)});
    }
    this.pieChartDataSet = newCatDataSet;
    this.barChartData = [{"sentiment":"Positive","value":this.getPercent(this.sentiments.positive, this.totalSentiments)},{"sentiment":"Negative","value":this.getPercent(this.sentiments.negative, this.totalSentiments)}];
    //this.barChartData.push({"sentiment":"Positive","value":this.getPercent(this.sentiments.positive, this.totalSentiments)});
    //this.barChartData.push({"sentiment":"Negative","value":this.getPercent(this.sentiments.negative, this.totalSentiments)});

  }

  filterMessages(object){
    const type = object.type;
    const name = object.name;
    if(type === 'device'){
      this.openWindowCustomClass(this.brandMessagesContent[name]);
    }
    else if(type === 'sentiment'){
      this.openWindowCustomClass(this.sentimentsMessageContent[name.toLowerCase()]);
    }
  }

  ngOnInit(): void {
    console.log("in left pannel ngonit")
    this.getH = $(window).height();
    this.calcHeight = $(window).height() - 180;
    this.screentHeight = $(window).height() - 20 + 'px';
    this.calcHeightNew = (this.calcHeight) + 'px';
    this.topScreentHeight = (this.calcHeight) + 220 + 'px';

    this.clickEvent1('div');
  }

  showPanel() {
    let hidepannel = document.getElementById('hide-left-pannel');
    let msgDiv = document.getElementById('messages-div');
    let getDiv = document.getElementById('l-p-sec');
    if (!getDiv.classList.contains('show-panel')) {
      getDiv.classList.add('show-panel');
      msgDiv.classList.add('p-r-390');
      hidepannel.classList.add('rotate-360');
    }
  }

  hidePanel() {
    let hidepannel = document.getElementById('hide-left-pannel');
    let msgDiv = document.getElementById('messages-div');
    let getDiv = document.getElementById('l-p-sec');
    if (getDiv.classList.contains('show-panel')) {
      getDiv.classList.remove('show-panel');
      msgDiv.classList.remove('p-r-390');
      hidepannel.classList.remove('rotate-360');
    } else {
      hidepannel.classList.add('rotate-360');
      getDiv.classList.add('show-panel');
      msgDiv.classList.add('p-r-390');
    }
  }

  private fetchMessages(): void {
    console.log("in fetchmessages")
    console.log(this.selectedWords)
    console.log(this.selectedWords.length)
    if (this.selectedWords && this.selectedWords.length) {
      const dates: any[] = this.header.getDateFromLS();
      this.showLoaderChild = false;
      console.log("the dates are")
      console.log(dates[0])
      let promises: any[] = [this.messageService.getMessages(dates[0], dates[1], this.selectedWords, this.lastMsgId)];
      console.log("after get messages")
      if (!this.lastMsgId) {
        promises.push(this.messageService.getMsgStats(dates[0], dates[1], this.selectedWords));
      }
      console.log("after getmsgstats")

      Promise.all(promises).then((resp: any[]) => {
        if (!this.lastMsgId) {
          this.processMsgStats(resp[1] || []);
        }
        if (resp[0] && resp[0].length) {
          this.messages = this.messages.concat(resp[0]);
          this.lastMsgId = this.messages[this.messages.length - 1]._id;
        }
        this.messagesTopic = this.selectedWords.length > 1 ? this.groupLabel : this.selectedWords[0];
        console.log("after msg stats")
        this.showLoaderChild = false;
      }).catch((err) => {
        console.error('Got error');
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {


    if (changes.selectedWords) {
      this.lastMsgId = null;
      this.messages = [];
      //this.showPanel();
      this.fetchMessages();
    }
  }
}
