import {NgModule} from '@angular/core';
import {Component, OnInit, Input} from '@angular/core';

import {RouterModule, Routes} from '@angular/router';
import {AccuracyComponent} from './accuracy/accuracy.component';
import {DietComponent} from './diet/diet.component';
import {DiabetesComponent} from './diabetes/diabetes.component';
import {SupplyComponent} from './supply/supply.component';
import {WearableComponent} from './wearable/wearable.component';
import {AlertsComponent} from './alerts/alerts.component';
import {DigitalComponent} from './digital/digital.component';
import {CostComponent} from './cost/cost.component';
import {HomeComponent} from './home/home.component';
import {CustomerServiceComponent} from './customer-service/customer-service.component';
import {TrendsComponent} from './trends/trends.component';
import {TwitterComponent} from './twitter/twitter.component';
import {SentimentsComponent} from './sentiments/sentiments.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './auth.guard';
import {LineGraphComponent} from './reusable/d3/line-graph/line-graph.component';
import {TestGraphComponent} from './reusable/d3/test-graph/test-graph.component';
import {BarChartComponent} from './reusable/d3/bar-chart/bar-chart.component';
import {MessagesComponent} from './messages/messages.component';
import {ForceDirectedGraphComponent} from './reusable/d3/force-directed-graph/force-directed-graph.component';
import {CurvedWeightedForceGraphComponent} from './reusable/d3/curved-weighted-force-graph/curved-weighted-force-graph.component';
import {WordCloudComponent} from './reusable/d3/word-cloud/word-cloud.component';
import {ForceDirectedRectComponent} from './reusable/d3/force-directed-rect/force-directed-rect.component';
import {WordCloudBarChartComponent} from './reusable/d3/word-cloud-bar-chart/word-cloud-bar-chart.component';
import {PackLayoutComponent} from './reusable/d3/pack-layout/pack-layout.component';
import {PieChartComponent} from './reusable/d3/pie-chart/pie-chart.component';
import {ScatterplotComponent} from './reusable/d3/scatterplot/scatterplot.component';
import {SeekDiscoverComponent} from './seek-discover/seek-discover.component';
import {CustomTopicsComponent} from './custom-topics/custom-topics.component';
import {HeaderComponent} from "./reusable/header/header.component";

const routes: Routes = [
  {path: 'user', component: LoginComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'accuracy', component: AccuracyComponent, canActivate: [AuthGuard]},
  {path: 'diet', component: DietComponent, canActivate: [AuthGuard]},
  {path: 'diabetes', component: DiabetesComponent, canActivate: [AuthGuard]},
  {path: 'wearable', component: WearableComponent, canActivate: [AuthGuard]},
  {path: 'supply', component: SupplyComponent, canActivate: [AuthGuard]},
  {path: 'alarm', component: AlertsComponent, canActivate: [AuthGuard]},
  {path: 'digital', component: DigitalComponent, canActivate: [AuthGuard]},
  {path: 'cost', component: CostComponent, canActivate: [AuthGuard]},
  {path: 'service', component: CustomerServiceComponent, canActivate: [AuthGuard]},
  {path: 'trend', component: TrendsComponent, canActivate: [AuthGuard]},
  {path: 'twitter', component: TwitterComponent, canActivate: [AuthGuard]},
  {path: 'sentiments', component: SentimentsComponent, canActivate: [AuthGuard]},
  {path: 'line-graph', component: LineGraphComponent},
  {path: 'test-graph', component: TestGraphComponent},
  {path: 'bar-chart', component: BarChartComponent},
  {path: 'messages', component: MessagesComponent},
  {path: 'force-directed-graph', component: ForceDirectedGraphComponent},
  {path: 'curved-weighted-graph', component: CurvedWeightedForceGraphComponent},
  {path: 'word-cloud', component: WordCloudComponent},
  {path: 'force-directed-rect', component: ForceDirectedRectComponent},
  {path: 'word-cloud-bar-chart', component: WordCloudBarChartComponent},
  {path: 'pack-layout', component: PackLayoutComponent},
  {path: 'pie-chart', component: PieChartComponent},
  {path: 'scatterplot', component: ScatterplotComponent},
  {path: 'seek-and-discover', component: SeekDiscoverComponent},
  {path: 'custom-topics', component: CustomTopicsComponent},
  {path: 'custom2', component: CustomTopicsComponent},
  {path: 'custom3', component: CustomTopicsComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule {
  @Input() newRoute
  @Input() isRoute=false;
  constructor(public header: HeaderComponent){}
  ngOnit(){
    console.log("in ngonit")
    console.log(routes)


  }
  public addToRoutes(){
console.log("add to Routes")
    console.log(this.isRoute)
  }
}
