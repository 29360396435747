import {Injectable, NgModule} from "@angular/core";
import {ApiService} from "../../services/api.service";
import {MessageModalComponent} from "./message-modal.component";

@Injectable({
  providedIn: "root"
})
export class MessageModalServiceService {

  constructor(private apiService: ApiService) {
  }

  public getMessages(url: any, filters: any, relevantMentions: boolean) {
    filters = filters || {};
    if (relevantMentions && relevantMentions === true) {
      if (url.includes("?")) {
        url = url + "&relevantmentions=" + relevantMentions;
      } else {
        url = url + "?relevantmentions=" + relevantMentions;
      }
    }
    console.log(url);
    let promise = new Promise((resolve, reject) => {
      this.apiService.post(url, filters).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }

  public getnewMessages(startdate,enddate,keyword) {
   let url='/keywords/getmessages';
    let promise = new Promise((resolve, reject) => {
      this.apiService.put(url, {startdate:startdate,enddate:enddate,keywords:keyword}).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
public tofetchser(dates){
    console.log(dates);
   // this.msgcomp.fetchMessages2(dates);

}
  public downloadMessages(url: any, filters: any, options: any) {

    filters = filters || {};
    /*if (relevantMentions && relevantMentions === true) {
      if (url.includes("?")) {
        url = url + "&relevantmentions=" + relevantMentions;
      } else {
        return url + "?relevantmentions=" + relevantMentions;
      }
    }*/
    const promise = new Promise((resolve, reject) => {
      this.apiService.post(url, filters, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
}
