import {Injectable} from "@angular/core";
import {ApiService} from "../services/api.service";
import {Insight} from "./insight.model";

@Injectable({
  providedIn: "root"
})
export class InsightsService {

  constructor(private apiService: ApiService) {
  }

  public getInsightsForTopic(topic: string): Promise<any> {
    const url: string = "/insights?topic=" + topic;
    return new Promise((resolve: any, reject: any) => {
      this.apiService.get(url).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public saveInsights(insights: Insight[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post("/insights", insights).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public editInsight(insight: Insight): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.put("/insights", insight).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public deleteInsight(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.delete("/insights?id=" + id).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }
}
