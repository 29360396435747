import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {SharedServiceService} from "../../services/shared-service.service";


@Component({
  selector: 'app-wordcloud',
  templateUrl: './wordcloud.component.html',
  styleUrls: ['./wordcloud.component.css']
})
export class WordcloudComponent implements OnInit {

  @Input() wordCloudObj:any;
  public topWords: any;
  public rangeOffset: any;
  constructor(private myservice: SharedServiceService) { }

  public wordCloud(obj: any){
    console.log(this.wordCloudObj)
    console.log(obj)
    let finalobj = this.myservice.wordCloud(obj);
    console.log(finalobj)
    this.topWords = finalobj['arr'];
    this.rangeOffset = finalobj['rangeOffset'];
  }

  ngOnChanges(changes: SimpleChanges) {
    let cur:any;
    for (let propName in changes) {
      let chng = changes[propName];
      cur  = (chng.currentValue);
    }
    this.wordCloudObj = cur;
    this.wordCloud(this.wordCloudObj);

  }

  ngOnInit() {
    console.log(this.wordCloudObj)
    this.wordCloud(this.wordCloudObj);
  }

}
