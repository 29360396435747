import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

const SERVER_URL = environment.serverUrl;
const apiVersion1 = "/api/v1";

@Injectable({
  providedIn: "root"
})
export class ApiService {

  constructor(private http: HttpClient) {
  }


  get(urlAddress: string, options?: any) {
    options = options || {};
    return this.http
      .get<any>(SERVER_URL + apiVersion1 + urlAddress)
      .pipe(map(data => data));
  }

  post(urlAddress: string, payload: any, options?: any) {
    options = options || {};
    return this.http
      .post<any>(SERVER_URL + apiVersion1 + urlAddress, payload, options)
      .pipe(map(data => data));
  }

  put(urlAddress: string, payload: any, options?: any) {
    options = options || {};
    return this.http
        .put<any>(SERVER_URL + apiVersion1 + urlAddress, payload, options)
        .pipe(map(data => data));
  }

  delete(urlAddress: string, options?: any) {
    options = options || {};
    return this.http
        .delete<any>(SERVER_URL + apiVersion1 + urlAddress)
        .pipe(map(data => data));
  }
}
