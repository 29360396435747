import * as moment from "moment";
import {Injectable} from "@angular/core";
import {ApiService} from "../services/api.service";
import {SharedServiceService} from "../services/shared-service.service";

@Injectable({
  providedIn: "root"
})
export class TrendsService {

  constructor(private apiService: ApiService,
              private myService: SharedServiceService) {
  }

  public getData(dates: any, filters?: any): Promise<any> {
    const payload: any = {};
    return new Promise((resolve: any, reject: any) => {
      if (filters.length === 1 && filters[0] === "All channels") {
        filters = this.myService.sitesExcludingAllChannels;
      }
      payload.filters = filters;
      payload.start = dates[0];
      payload.end = dates[1];
      this.apiService.post("/trend", payload).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  private weeklyAggregationData(rawData: any, legend: string, legendDataMap: any): any {
    const legendData: any = {
      name: legend,
      values: []
    };
    let weeklyTrend: any = {};
    rawData["x-axis"].forEach((date: string, index: number) => {
      const startOfWeek: Date = moment(date, "YYYY-MM-DD").startOf("isoWeek").toDate();
      if (index === 0) {
        weeklyTrend = {date: startOfWeek, trend: 0};
      }
      if (weeklyTrend.date.getTime() !== startOfWeek.getTime()) {
        legendData.values.push(weeklyTrend);
        weeklyTrend = {date: startOfWeek, trend: 0};
      } else {
        weeklyTrend.trend = weeklyTrend.trend + +rawData[legendDataMap[legend]][index];
      }
    });
    legendData.values.push(weeklyTrend);
    return legendData;
  }

  private dailyAggregationData(rawData: any, legend: string, legendDataMap: any): any {
    const legendData: any = {
      name: legend,
      values: []
    };
    rawData["x-axis"].forEach((date: string, index: number) => {
      legendData.values.push({
        "date": new Date(new Date(date).setHours(0, 0, 0)),
        "trend": +rawData[legendDataMap[legend]][index]
      });
    });
    return legendData;
  }

  public formatData(rawData: any, legends: any, showLegendLine: any, legendDataMap: any, aggregation: string): any[] {
    const data: any[] = [];
    legends.forEach((legend: string) => {
      if (showLegendLine[legend]) {
        switch (aggregation) {
          case "weekly": {
            data.push(this.weeklyAggregationData(rawData, legend, legendDataMap));
            break;
          }
          default: {
            data.push(this.dailyAggregationData(rawData, legend, legendDataMap));
            break;
          }
        }
      }
    });
    return data;
  }

  public reScaleXAxisData(xAxisScale: string[], aggregation: string): any[] {
    switch (aggregation) {
      case "weekly": {
        const reScaleData: any[] = [];
        let weekDay: Date = null;
        xAxisScale.forEach((date: string, index: number) => {
          const startOfWeek: Date = moment(date, "YYYY-MM-DD").startOf("isoWeek").toDate();
          if (index === 0) {
            weekDay = startOfWeek;
          }
          if (weekDay.getTime() !== startOfWeek.getTime()) {
            reScaleData.push(weekDay);
            weekDay = startOfWeek;
          }
        });
        reScaleData.push(weekDay);
        return reScaleData;
      }
      default: {
        return xAxisScale.map((date: string) => {
          return new Date(new Date(date).setHours(0, 0, 0));
        });
      }
    }
  }

  getZoomFactor(): any {
    const zf = localStorage.getItem("zoomFactor");
    return zf ? JSON.parse(zf) : {k: 1, x: 0};
  }

  setZoomFactor(k: any, x: any): void {
    localStorage.setItem("zoomFactor", JSON.stringify({k: k, x: x}));
  }
}
