import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.css']
})
export class DaterangepickerComponent implements OnInit {

  @Output()
  authorClick: EventEmitter<any> = new EventEmitter<any>(); //creating an output event

  constructor() { }


  public daterange: any = {};
  public minDate: any = moment("2018-09-01",'YYYY-MM-DD');
  public maxDate: any = moment("2019-02-07",'YYYY-MM-DD');

  public dateDisplay: any;

  // see original project for full list of options
  // can also be setup using the config service to apply to multiple pickers
  public options: any = {
    startDate: this.minDate,
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    showCustomRangeLabel:true,
    minDate: this.minDate,
    maxDate: this.maxDate
  };
  //private JSON: any;

  public selectedDate(value: any, datepicker?: any) {
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.dateDisplay = value.start.format('Do MMM YY') + ' - ' + value.end.format('Do MMM YY');
    this.storeDateInLS(datepicker.start, datepicker.end);
    this.authorClick.emit('yay'); //emmiting the event.
  }


  /*
      Storing date in localstorage
  */
  public storeDateInLS(start: any, end:any){
    let datearr = [];
    datearr.push(start.format('YYYY-MM-DD'));
    datearr.push(end.format('YYYY-MM-DD'));
    localStorage.setItem("dates", JSON.stringify(datearr));


  }

  public checkIfDateIsInLS(){
    if(!localStorage.getItem("dates")){
      return false;
    }
    else{
      return true;
    }
  }

  public getDateFromLS(){
    let start = JSON.parse(localStorage.getItem("dates"))[0];
    let end = JSON.parse(localStorage.getItem("dates"))[1];
    return [start, end]
  }

  ngOnInit() {
    //this.startdate = moment("2018-09-01",'YYYY-MM-DD');
    if(this.checkIfDateIsInLS()){
      let date = this.getDateFromLS();
      this.dateDisplay = moment(date[0]).format('Do MMM YY') + ' - ' + moment(date[1]).format('Do MMM YY');
    }
    else{
      this.dateDisplay = this.minDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
    }



    //Todo: Add tag if date contains 'st' or 'th'
    /*if(this.dateDisplay.includes('st') || this.dateDisplay.includes('th') ){
      console.log('entered in st');
      $('.applied-date').html.replace(/(st)/g, '<sup>$1</sup>');
      //console.log()
      //this.dateDisplay = this.dateDisplay.replace('st', '<sup>$1</sup>');

    }

    if($(".applied-date:contains('st')").html(function(_, html) {
      //console.log('entered in st');
      return  html.replace(/(st)/g, '<sup>$1</sup>')
    })){
    }
    if($(".applied-date:contains('th')").html(function(_, html) {
      return  html.replace(/(th)/g, '<sup>$1</sup>')
    })){
    }
    if($(".applied-date:contains('nd')").html(function(_, html) {
      return  html.replace(/(nd)/g, '<sup>$1</sup>')
    })){
    }
    if($(".applied-date:contains('rd')").html(function(_, html) {
      return  html.replace(/(rd)/g, '<sup>$1</sup>')
    })){
    }
    console.log(moment("2018-09-01",'YYYY-MM-DD'));*/

    //$('#reportrange span').html(moment(JSON.parse(localStorage.getItem("dates"))[0]).format('Do MMM YY') + ' - ' + moment(JSON.parse(localStorage.getItem("dates"))[1]).format('Do MMM YY'));


  }

}
