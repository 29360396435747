import { Injectable } from '@angular/core';
import { Component, OnInit,Input } from '@angular/core';
import {ApiService} from "../services/api.service";

@Injectable({
  providedIn: 'root'
})
export class CustomTopicsService {
public usersgrps={}
  constructor( private apiService: ApiService) { }
  public groupsimilarwords(start: any, end: any, words: string[]): Promise<any> {
    const url = '/keywords/groupsimilarwords';
    console.log(words)
    return new Promise((resolve, reject) => {
      this.apiService.put(url, {start_date: start, end_date: end, keywords: words}).subscribe((response) => {
        console.log(response)
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }
  public newfrequency(start: any, end: any, words: string[]): Promise<any> {
    const url = '/keywords/newfrequency';
    console.log(words)
    return new Promise((resolve, reject) => {
      this.apiService.put(url, {start_date: start, end_date: end, keywords: words}).subscribe((response) => {
        console.log(response)
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public Mentions(MentionsArray): Promise<any> {
    const url = '/keywords/mentions';
console.log(MentionsArray);
    return new Promise((resolve, reject) => {
      this.apiService.put(url, {MentionsArray}).subscribe((response) => {
        console.log(response)
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }





  public savepage(  words:any): Promise<any> {
    const url = '/keywords/savepage';
    console.log(words)

    return new Promise((resolve, reject) => {
      this.apiService.put(url, { keywords: words}).subscribe((response) => {
        console.log(response)
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }
  public deletepage(pagename) : Promise<any> {
    console.log(pagename);
    const url = '/custom-topic/deletepages';
    return new Promise((resolve, reject) => {
      this.apiService.put(url, { page: pagename}).subscribe((response) => {
        console.log(response)
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });

  }
  public savepagename(pagename): Promise<any> {
    const url = '/custom-topic/savenewpages';
    console.log(pagename)
    console.log("in save newpages")

    return new Promise((resolve, reject) => {
      this.apiService.put(url, { page: pagename}).subscribe((response) => {
        console.log(response)
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getdata(pagename:any): Promise<any> {
  console.log('in getdata');

   const url = '/custom-topic/getdata';

  return new Promise((resolve, reject) => {
  this.apiService.put(url, {pagename:pagename}).subscribe((response) => {
  console.log(response)
  resolve(response);
}, (error: any) => {
  reject(error);
});
});
}
  public getpagestodisplay(): Promise<any> {
  console.log("getpagestodisplay")

    const url = '/custom-topic/getpagenames';

    return new Promise((resolve, reject) => {
      this.apiService.put(url,{}).subscribe((response) => {
        console.log(response)
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }


}
