import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {MessageModalServiceService} from "../reusable/message-modal/message-modal-service.service";
import {AppRoutingModule} from "../app-routing.module";

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  constructor(public msgservice:MessageModalServiceService) {
  }
  public keywordsTopics: any={};
  public typeKeywords=[];
  public pagenamefromheader;
  public fromheader=0;
  public dates=[];
  public headerval=0;

  public sites: string[] = ['All channels', 'Twitter', 'Forum', 'Blog', 'Social network', 'Video and Photo'];
  public sitesExcludingAllChannels: string[] = ['Twitter', 'Forum', 'Blog', 'Social network', 'Video and Photo', 'Consumer Opinions', 'Comments'];
  public donutChartColor: any = {'Twitter': '#91c7ae', 'Forum': '#d48165', 'Blog': '#749f83', 'Social network': '#ca8622', 'Video and Photo': '#619fa8', 'Consumer opinions': '#2f4554', 'Comments': '#ce6958'};

  public siteColor: any = {'Abbott': '#41a6d3', 'Dexcom': '#68a94d', 'Medtronic Minimed': '#dd5026', 'Roche Accu-Chek': '#f2bb1f', 'All active users': '#e69138'};
  public siteColorMessages: any = {'abbottfreestyle': '#41a6d3', 'dexcom': '#68a94d', 'medtronicminimed': '#dd5026', 'rocheaccuchek': '#f2bb1f', 'All active users': '#e69138'};

  public midDate: Moment = moment('2018-12-31');
  public pieChartRadioLabels = ['Dexcom', 'Medtronic Minimed', 'Roche'];
  public diabetesSatDissat = {'Satisfaction': '#92c47d', 'Disatisfaction': '#cd0000'};
  public fontAwesomeIcons = {'Twitter': 'fa fa-twitter', 'Forum': 'fa fa-wpforms', 'Video and Photo': 'fa fa-youtube', 'Blog': 'fa fa-rss', 'Social network': 'fa fa-facebook', 'Comments': 'fa fa-comments', 'Consumer opinions': 'fa fa-users'};
  public colourdict = {'Comments': 'col-comments', 'Consumer opinions': 'col-consumer', 'Video and Photo': 'col-video', 'Forum': 'col-form', 'Social network': 'col-social', 'Blog': 'col-blog', 'Twitter': 'col-twitter'};
  public hoverTextTotalMentions = 'Mentions are just that - a mention of relevant keywords, Irrespective of the context. e.g. these include mentions from news, researcher blogs, tagged for attention, questions and hearsay etc.  These are good to measure the buzz of a topic.';
  public hoverTextRelevantMentions = 'Relevant and First-Hand Mentions are usually instances of someone using the product and sharing their direct experience. This is a subset of the overall mentions. These take context into account and represent the most relevant feedback.';

  public insights(dates) {
    let insightsSecond = true;
    let insightsFirst = true;
    if (moment(dates[1]).isSameOrBefore(this.midDate)) {
      insightsSecond = false;
    } else if (moment(dates[0]).isAfter(this.midDate)) {
      insightsFirst = false;
    }
    return {
      insFirst: insightsFirst,
      insSecond: insightsSecond
    };
  }
  public tofetchmessages2(dates){
    console.log(dates)
    console.log('in tofetch');
    this.dates=dates;
    this.msgservice.tofetchser(this.dates);

  }
  public approut2(){
    console.log('in servie for approut')
  //  this.approu.addToRoutes();
  }

  public wordCloud(obj: any) {
    const ranges = 10;
    // let max : any;
    const sortedwc = Object.keys(obj).sort((a: any, b: any) => {
      return obj[b] - obj[a];
    });
    let top30 = sortedwc;
     console.log(Object.keys(top30).length)
    top30 = this.removeWordsWithLessFreq(obj, top30);
    const random = top30.sort(() => Math.random() - 0.5);

    // Changing fonts depending on freq
    // let valuesObj = Object.values(obj)
    const values: number[] = Object.values(obj);
    const max = Math.max(...values);
    const rangeOff = (max) / ranges;
    return {
      arr: random,
      rangeOffset: rangeOff
    };
  }

  public saveSiteInLS(siteFilters: any[]) {
    if (siteFilters === undefined || siteFilters.length === 0) {
      localStorage.setItem('source' + window.location.pathname.split('/')[1] + '', JSON.stringify(['All channels']));
    } else {
      localStorage.setItem('source' + window.location.pathname.split('/')[1] + '', JSON.stringify(siteFilters));
    }
  }

  public getSiteInLS() {
    if (localStorage.getItem('source' + window.location.pathname.split('/')[1] + '')) {
      const localStorageData = localStorage.getItem('source' + window.location.pathname.split('/')[1] + '');
      if (localStorageData.includes('[')) {
        if ((JSON.parse(localStorageData)).length === 0) {
          return ['All channels'];
        } else {
          return JSON.parse(localStorageData);
        }
      } else {
        return ['All channels'];
      }
    } else {
      return ['All channels'];
    }
  }

  public sortByName(obj: any[]) {
    const sortedObject: any = obj.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return sortedObject;
  }

  public pieComparisonLegend(obj: any, site: string) {
    let total: number = 0;
    for (const i in obj[site]) {
      if (obj[site][i] !== 0) {
        total = total + obj[site][i];
      }
    }
    return total;
  }

  // TODO: Delete logic can be improved using splice or some other inbuilt method. Can also be added in html using pipe
  public removeWordsWithLessFreq(obj: any, top30: any) {
    for (const i in top30) {
      if (top30.hasOwnProperty(i) && obj[top30[i]] === 1) {
        delete top30[i];
      }
    }
    return top30;
  }

  public showTotalMentionsToggle(date: string) {
    const aprilEndDate = moment('2019-04-30');
    const aprilStartDate = moment('2019-04-01');
    if (moment(date).isSameOrBefore(aprilEndDate) && moment(date).isSameOrAfter(aprilStartDate)) {
      return true;
    } else {
      return false;
    }
  }
  public keywordsTopics1(){
    return this.keywordsTopics;

  }


}
