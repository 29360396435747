import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {PageEvent} from '@angular/material/paginator';
import {MatTabGroup} from '@angular/material';

import {animate, state, style, transition, trigger} from "@angular/animations";


export interface UserData {
    post: string;
//  Id: number;
    author: string;
    reach: number;
    likes: number;
    retweets: number;
    hashtags: string;

    authorfollowers:number,
    //sentiment: s:tring;
    Interaction:number,
    Location:string,
    Profession:string,
    Rank:number,
    Mentions:string,
    Verified:boolean,
    date:Date

}

@Component({
    selector: 'app-user-transcript',
    templateUrl: './user-transcript.component.html',
    styleUrls: ['./user-transcript.component.css'],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({height: '0px', minHeight: '0'})),
            state("expanded", style({height: '*'})),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ],

    styles: [`
        .star {
            font-size: 1.5rem; 
            background: #f2f5ef;
        }
        .filled {
            background: #ffb5b5;
        }
       
    `]
})
export class UserTranscriptComponent implements OnInit, OnChanges {
    public initialShow = true;
    public catfilters: any = [];
    public  index=0;
    constructor() { }

    @Input() transcript: any = [];

    @Input() screen_name: any;
    public duplitranscript: any = this.transcript
    public follsort=0;
    public datesort=0;
    public intersort=0
    public catkeys: any = ['Doctor', 'Patient', 'Bloggers', 'Caregiver', 'HCP', 'reseracher', 'Patient_advocate', 'journalist/media', ' Org Med Devices', 'organization/NGO', 'others'];

    public  status1 = false;
    public  status2: boolean = false;
    public  status3:boolean = false;
    public  status4: boolean = false;
    public  status5 : boolean= false;
    public  status6: boolean = false;
    public  status7 :boolean= false;
    public  status8:boolean = false;
    public  status9:boolean = false;
    public  status10:boolean = false;
    public  status11:boolean = false;
    public activeTab: any;



    dataSource: any;
    expandedrow: UserData | null;



    // @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // MatPaginator Inputs
    length = 100;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20];

    // MatPaginator Output
    pageEvent: PageEvent;
    Rate = 2.5;

    table: UserData[] = [
        {
            Interaction: 24,
            post: this.transcript.Content,
            author: this.transcript.Author_Name,
            date: this.transcript.postDate,
            reach: this.transcript.Potential_Reach,
            hashtags: this.transcript.hashtag,
            likes: this.transcript.Likes,
            retweets: this.transcript.Retweets,
            authorfollowers: this.transcript.Follower_Count,
            Location:this.transcript.Location,
            Profession:this.transcript.Profession,
            Rank:this.transcript.Rank,
            Mentions:this.transcript.Mentions,
            Verified:this.transcript.Verified

        },

    ];



    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
    }

    public colour_code( prof) {
        // console.log("the profession is ")
        //console.log(prof)
        if (prof === 'Doctor') {
            return '#E82D4F'; }
        else if (prof === 'Patient') {
            return '#52AA8E';
        } else if (prof === 'Bloggers') {
            return '#759ECC';
        } else if (prof === 'Caregiver') {
            return '#CA7FA8';
        } else if (prof === 'HCP') {
            return  '#CEC07A';
        } else if (prof === 'researcher') {
            return '#D14FC6';
        } else if (prof === 'Patient_advocate') {
            return '#6A9C9C';
        } else if (prof === 'journalist/media') {
            return '#63B142';
        } else if (prof === 'Org Med Devices') {
            return  '#465E73';
        } else if (prof === 'organisation/NGO') {
            return '#823E45';
        } else if (prof === 'others') {
            return '#682DE6';
        }
    }


    sortTable(type) {
        console.log(type);
        if(type==="Follower"){
            if(this.follsort===0) {

                this.transcript.sort(function (a, b) {
                    return (a.Follower_Count - b.Follower_Count)
                })
                this.follsort=1;
            }
            else if(this.follsort===1) {

                this.transcript.sort(function (a, b) {
                    return (b.Follower_Count - a.Follower_Count)
                })
                this.follsort=0;
            }


        }
        else if(type==="Date") {
            if(this.datesort===0) {
                this.transcript.sort(function (a, b) {
                    return (a.postDate - b.postDate)

                })
                this.datesort=1
            }
            else if(this.datesort===1){

                this.transcript.sort(function (a, b) {
                    return (b.postDate - a.postDate)

                })
                this.datesort=0
            }

        }
        else if (type==="Interaction"){
            if(this.intersort===0) {
                this.transcript.sort(function (a, b) {
                    return (a.Likes - b.Likes)

                })
                this.intersort=1;
            }
            else if(this.intersort===1) {
                this.transcript.sort(function (a, b) {
                    return (b.Likes - a.Likes)

                })
                this.intersort=0;
            }

        }

    }


    addDataToTable(filters?) {
        /*this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }*/
        this.dataSource = new MatTableDataSource(this.transcript);
        console.log(this.dataSource)
        console.log(this.transcript.length)
        let m=0
        console.log(filters)

        for (let i = 0; i < this.transcript.length; i++) {
            console.log("inside for loop")
            if(filters==="All"||(!filters)) {
                //   this.transcript=this.duplitranscript
                continue;
            }
            else if(filters==="Tweets") {
                console.log("inside second if ")
                if(this.transcript[i].Screen_Name!==this.screen_name) {
                    this.transcript.splice(i,1);

                }
            }

            else if(filters==="Mentions") {
                console.log("inside 2nd if ")
                if(!this.transcript[i].Mentions.includes(this.screen_name)) {
                    this.transcript.splice(i,1);
                }
            }

        }
        console.log(this.dataSource);
    }
    public catfunction(){
        let flag=0
        console.log(this.catfilters.length);
        for(let i=0;i<this.transcript.length;i++){
            for (let x=0;x<this.transcript[i].Profession.length;x++) {
                if (this.catfilters.includes(this.transcript[i].Profession[x])) {
                    flag = 1;
                    continue;
                }
            }
            if(flag===0)
            {
                this.transcript.splice(i,1);
            }
        }

    }
    public clearfunc(){
        this.transcript=this.duplitranscript;
        this.activeTab=false
    }
    public checkarraycat(filtername1: any ) {
        // console.log(this.catkeys);
        console.log('in checkarray');
        console.log(this.catfilters);
        console.log(filtername1)
        if(this.catfilters.includes(filtername1)){
            this.catfilters.splice(this.catfilters.indexOf(filtername1), 1);
        }
        else{


            this.catfilters.push(filtername1);
        }
        console.log('the added filters is');
        console.log(this.catfilters);
        // console.log(this.catkeys);

        // console.log(this.catkeys);
    }

    ngOnChanges() {
        this.addDataToTable();

        // this.determineView();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }


    @ViewChild(MatPaginator) paginator: MatPaginator;


    ngOnInit() {
        this.transcript.paginator = this.paginator;
        this.dataSource.sort = this.sort;

    }
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    // addDataToTable() {
    //     /*this.dataSource.filter = filterValue.trim().toLowerCase();
    //
    //     if (this.dataSource.paginator) {
    //         this.dataSource.paginator.firstPage();
    //     }*/
    //
    //     this.dataSource = new MatTableDataSource(this.transcript);
    //
    //     // console.log(this.transcript.Potential_Reach);
    // }
    expandtable(){

    }



    reach(potentialReach: any) {
        // console.log(potentialReach)
        const x = (potentialReach / 16896125 ) * 100;
        // console.log(x)
        return x ;
    }

    potential_graph(potentialReach: number) {
        let y;
        if (potentialReach===0){
            y=0
        }
        else if  (potentialReach >0 && potentialReach < 500 ) {
            y=10

        } else if (potentialReach > 500 && potentialReach<1000) {
            y=20;
        }
        else if(potentialReach > 1000 && potentialReach<3000){
            y=30;
        }
        else if(potentialReach > 3000 && potentialReach<6000){
            y=40;
        }
        else if(potentialReach > 6000 && potentialReach<10000){
            y=50;
        }
        else if(potentialReach > 10000 && potentialReach<100000){
            y=60;
        }
        else if(potentialReach > 100000 && potentialReach<500000){
            y=70;
        }
        else if(potentialReach > 500000 && potentialReach<1000000){
            y=80;
        }
        else if(potentialReach > 1000000 && potentialReach<4000000){
            y=90;
        }
        else
            y=100;


        return y;

    }
    filters(type:any) {
    }


    hidePanel() {
        const hidepannel = document.getElementById('hide-left-pannel');
        const msgDiv = document.getElementById('messages-div');
        const getDiv = document.getElementById('l-p-sec');
        if (getDiv.classList.contains('show-panel')) {
            getDiv.classList.remove('show-panel');
            msgDiv.classList.remove('p-r-390');
            hidepannel.classList.remove('rotate-360');
        } else {
            hidepannel.classList.add('rotate-360');
            getDiv.classList.add('show-panel');
            // msgDiv.classList.add('p-r-390');
        }
    }



}