import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {PageEvent} from '@angular/material/paginator';
import {max} from "rxjs/operators";
// import {ProgressBarModule} from "angular-progress-bar";
import {animate, state, style, transition, trigger} from "@angular/animations";


export interface UserData {
    post: string;
//  Id: number;
    author: string;
    reach: number;
    likes: number;
    retweets: number;
    hashtags: string;
    authorfollowers:number,
    //sentiment: s:tring;
    Interaction:number,
    Location:string,
    Profession:string,
    Rank:number,
    Mentions:string,
    Verified:boolean

}



@Component({
    selector: "app-influncer",
    templateUrl: "./influncer.component.html",
    styleUrls: ["./influncer.component.css"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({height: '0px', minHeight: '0'})),
            state("expanded", style({height: '*'})),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ],
    styles: [`
        .star {
            font-size: 1.5rem;
            background: #f2f5ef;
        }
        .filled {
            background: #ffb5b5;
        }
       
    `]
})

export class InfluncerComponent implements OnInit, OnChanges {

    constructor() {

        // const users =Array.from({length: 100}, (_, k) => createNewUser(k + 1));

    }
    @Input() tableresponse: any = []
    displayedColumns: string[] = [ "post","Rank", "author", "reach" ,  "Interaction", "Score","hashtags"];

    dataSource: any;
    expandedrow: UserData | null;



    // @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // MatPaginator Inputs
    length = 100;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20];

    // MatPaginator Output
    pageEvent: PageEvent;
    Rate = 2.5;

    table: UserData[] = [
        {
            Interaction: 24,
            post: this.tableresponse.Content,
            author: this.tableresponse.Author_Name,
            reach: this.tableresponse.Potential_Reach,
            hashtags: this.tableresponse.hashtag,
            likes: this.tableresponse.Likes,
            retweets: this.tableresponse.Retweets,
            authorfollowers: this.tableresponse.Follower_Count,
            Location:this.tableresponse.Location,
            Profession:this.tableresponse.Profession,
            Rank:this.tableresponse.Rank,
            Mentions:this.tableresponse.Mentions,
            Verified:this.tableresponse.Verified

        },

    ];



    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
    }


    addDataToTable() {
        /*this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }*/
        this.dataSource = new MatTableDataSource(this.tableresponse);
        console.log(this.dataSource);
    }
    ngOnChanges() {
        this.addDataToTable();
        // this.determineView();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }


    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngOnInit() {

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }


    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    // addDataToTable() {
    //     /*this.dataSource.filter = filterValue.trim().toLowerCase();
    //
    //     if (this.dataSource.paginator) {
    //         this.dataSource.paginator.firstPage();
    //     }*/
    //
    //     this.dataSource = new MatTableDataSource(this.tableresponse);
    //
    //     // console.log(this.tableresponse.Potential_Reach);
    // }
    expandtable(){

    }



    reach(potentialReach: any) {
        // console.log(potentialReach)
        const x = (potentialReach / 16896125 ) * 100;
        // console.log(x)
        return x ;
    }
    interaction(intera: number ) {
        let m;
        if (intera == 0) {
            m=0;

        }
        else if (intera>0 && intera < 30 ) {
            m=1;

        } else if (intera > 30 && intera<70) {
            m=2;
        }
        else if (intera > 70 && intera<120) {
            m=3;
        }
        else if (intera > 120 && intera<250) {
            m=4;
        }  else if (intera > 250 && intera<500) {
            m=5;
        }

        else if (intera > 500 && intera<1200) {
            m=5.5;
        }
        else  {
            m=6;
        }




        return m;

    }
    potential_graph(potentialReach: number) {
        let y;
        if (potentialReach===0){
            y=0
        }
        else if  (potentialReach >0 && potentialReach < 500 ) {
            y=10

        } else if (potentialReach > 500 && potentialReach<1000) {
            y=20;
        }
        else if(potentialReach > 1000 && potentialReach<3000){
            y=30;
        }
        else if(potentialReach > 3000 && potentialReach<6000){
            y=40;
        }
        else if(potentialReach > 6000 && potentialReach<10000){
            y=50;
        }
        else if(potentialReach > 10000 && potentialReach<100000){
            y=60;
        }
        else if(potentialReach > 100000 && potentialReach<500000){
            y=70;
        }
        else if(potentialReach > 500000 && potentialReach<1000000){
            y=80;
        }
        else if(potentialReach > 1000000 && potentialReach<4000000){
            y=90;
        }
        else
            y=100;


        return y;

    }





}

/** Builds and returns a new User. */
// function createNewUser(Id: number): UserData {
//   const name = author[Math.round(Math.random() * (author.length - 1))] + ' ' +
//       author[Math.round(Math.random() * (author.length - 1))].charAt(0) + '.';
//
//   let x = {
//       post:Math.round(Math.random() * 10).toString(),
//       Id:  Math.round(Math.random() * 10),
//       author: name,
//       reach:  Math.round(Math.random() * 10),
//       likes:  Math.round(Math.random() * 10),
//       retweets:  Math.round(Math.random() * 10),
//       hashtags: Math.round(Math.random() * 10).toString(),
//       sentiment: sentiment[Math.round(Math.random() * (sentiment.length - 1))]
//
//
//   };
//   console.log('x is');
//   //console.log(x);
//
//   return x;
//
//   //return
// }
//