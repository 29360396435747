import {Component, OnInit, ViewChild} from "@angular/core";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {HeaderComponent} from "../reusable/header/header.component";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {SharedServiceService} from "../services/shared-service.service";
import {DiabetesService} from "./diabetes.service";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: "app-diabetes",
  templateUrl: "./diabetes.component.html",
  styleUrls: ["./diabetes.component.css"]
})
export class DiabetesComponent implements OnInit {

  public sites: any;
  public dates: any;
  public barChart1Option: any;
  public barChart2Option: any;
  public showLoader = false;
  public wordCloudObj: any;
  public totalMentions: any;
  public relevantMentions: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public msgUrl = "/diabetes/msg";
  public resultExistsBar1 = true;
  public resultExistsBar2 = true;
  public barObj: any;
  public checkedSiteFilters: any[] = [];
  private activeRadioLabel: boolean =  true;
  private totalMentionsObj: any;
  private relevantMentionsObj: any;
  private activeRadioButtonName: string = "relevant";
  public resultExistsBar = true;
  public barChartOption: any;

  @ViewChild(MessageModalComponent) child: MessageModalComponent;

  constructor(public myservice: SharedServiceService,
              public diabetesService: DiabetesService,
              public Header: HeaderComponent,
              public echartGraphs: EchartGraphsService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {

    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.Header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.emptyToggleObjects();
            this.showSiteData(this.checkedSiteFilters);
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters : [];
    this.dates = this.Header.getDateFromLS();
    this.insights();
    this.myservice.saveSiteInLS(checkedSiteFilters);
    let relevantMentionsBoolean = false;
    this.activeRadioButtonName === "relevant" ? relevantMentionsBoolean = true : relevantMentionsBoolean = false;
    this.diabetesService.getData(this.dates, this.checkedSiteFilters, relevantMentionsBoolean).then((response: any) => {
      this.showLoader = false;
      this.totalMentions = response.Mention.mentions;
      this.relevantMentions = response.stat.count;
      this.wordCloudObj = response.Mention.freq;
      if (this.activeRadioButtonName && this.activeRadioButtonName === "relevant") {
        this.barObj = response.stat;
        this.barGraph("Abbott");
        this.barGraph("Dexcom");
        this.relevantMentionsObj = response;
      }
      else {
        this.totalMentionsObj = response;
        this.barGraphTotal(response.stat);

      }
    }).catch((error: any) => {
      throw error;
    });
  }

  private emptyToggleObjects() {
    this.relevantMentionsObj = {};
    this.totalMentionsObj = {};
  }

  public toggleRelevantMentions(event: any, radioName: string) {
    if (event.target.checked === true ) {
      if (radioName === "total") {
        this.activeRadioButtonName = radioName;
        if (!this.totalMentionsObj || (Object.entries(this.totalMentionsObj).length === 0 && this.totalMentionsObj.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.wordCloudObj = this.totalMentionsObj.Mention.freq;
          this.barGraphTotal(this.totalMentionsObj.stat);
        }
      }
      else {
        this.activeRadioButtonName = radioName;
        if (!this.relevantMentionsObj || (Object.entries(this.relevantMentionsObj).length === 0 && this.relevantMentionsObj.constructor === Object)) {
          this.showSiteData(this.checkedSiteFilters);
        }
        else {
          this.barObj = this.relevantMentionsObj.stat;
          this.barGraph("Abbott");
          this.barGraph("Dexcom");
          this.wordCloudObj = this.relevantMentionsObj.Mention.freq;
        }
      }
    }
  }

  public barGraphTotal(obj: any) {
    let barGraphObj = [];
    const color = this.myservice.siteColor;
    const legend = [];
    const yAxis = obj.label;
    this.resultExistsBar = this.isResultExists(yAxis);
    if (this.resultExistsBar) {
      for (const i in obj) {
        if (!(i === "count") && !(i === "label")) {
          legend.push(i);
          barGraphObj.push({name: i, type: "bar", data: obj[i], barGap: 0, color: color[i]});
        }
      }
      barGraphObj = this.myservice.sortByName(barGraphObj);
      legend.sort();
      this.barChartOption = this.echartGraphs.barGraph("", legend, {"type": "value", "data": {}, "light": true}, {
        "type": "category",
        "data": yAxis,
        "light": true
      }, barGraphObj);
    }
  }

  public isResultExists(label: any) {
    return label.length;
  }

  public isResultPieExists(obj: any, title) {

    if (obj.label === undefined || obj.label.length === 0) {
      title === "Abbott" ? this.resultExistsBar1 = false : this.resultExistsBar2 = false;
    } else {
      title === "Abbott" ? this.resultExistsBar1 = true : this.resultExistsBar2 = true;
    }

  }

  public barGraph(title: any) {
    console.log(this.barObj);
    const legend = [];
    const color = this.myservice.diabetesSatDissat;
    this.isResultPieExists(this.barObj[title], title);
    const barGraphObj = [];
    const correctOrder = ["Satisfaction", "Dissatisfaction"];
    if (title === "Abbott" ? this.resultExistsBar1 : this.resultExistsBar2) {

      for (const i in correctOrder) {
        if (this.barObj[title][correctOrder[i]]) {
          barGraphObj.push({name: correctOrder[i], type: "bar", data: this.barObj[title][correctOrder[i]], color: color[correctOrder[i]]});
          legend.push(correctOrder[i]);
        }
      }
      legend.sort().reverse();
      if (title === "Abbott") {
        this.barChart1Option = this.echartGraphs.barGraph(title, legend, {"type": "value", "data": {}, "light": true}, {
          "type": "category",
          "data": this.barObj[title].label,
          "light": true
        }, barGraphObj);
      } else {
        this.barChart2Option = this.echartGraphs.barGraph(title, legend, {"type": "value", "data": {}, "light": true}, {
          "type": "category",
          "data": this.barObj[title].label,
          "light": true
        }, barGraphObj);
      }
    }
  }

  public insights() {
    const insightBoolean = this.myservice.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  public openMessageModal(specificUrl?: any) {
    specificUrl = specificUrl || "";
    if (this.checkedSiteFilters.length && this.checkedSiteFilters[0] === "All channels") {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, this.activeRadioButtonName );
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, this.activeRadioButtonName, {Site_Type: this.checkedSiteFilters});
    }
  }

  ngOnInit() {
    this.checkedSiteFilters = this.myservice.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }
}
