import {Component, OnInit, ViewChild} from "@angular/core";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {SharedServiceService} from "../services/shared-service.service";
import {HeaderComponent} from "../reusable/header/header.component";
import {DigitalService} from "./digital.service";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: "app-digital",
  templateUrl: "./digital.component.html",
  styleUrls: ["./digital.component.css"]
})
export class DigitalComponent implements OnInit {

  public showLoader = false;
  public dates: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public totalMentions: number;
  public digitalData: any;
  public msgUrl = "/digital/msg";
  public checkedSiteFilters: any[] = [];
  @ViewChild(MessageModalComponent) public child: MessageModalComponent;

  constructor(public myService: SharedServiceService,
              public header: HeaderComponent,
              public digitalService: DigitalService,
              public dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {
    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.showSiteData(this.checkedSiteFilters);
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public insights() {
    const insightBoolean = this.myService.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  public openMessageModal(specificUrl?: any) {
    specificUrl = specificUrl || "";
    if (this.checkedSiteFilters.length && this.checkedSiteFilters[0] === "All channels") {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, 'false');
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, 'false', {Site_Type: this.checkedSiteFilters});
    }
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters: [];
    this.dates = this.header.getDateFromLS();
    this.insights();
    this.myService.saveSiteInLS(checkedSiteFilters);
    this.digitalService.getData(this.dates, this.checkedSiteFilters).then((response: any) => {
      this.showLoader = false;
      this.totalMentions = response.count;
      this.digitalData = response;
    }).catch((error: any) => {
      throw error;
    });
  }

  ngOnInit() {
    this.checkedSiteFilters = this.myService.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }

}
