import {Injectable} from '@angular/core';
import {UtilsService} from '../../services/utils.service';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root'
})
export class D3Service {
  public drawAxises(content: any, dimensions: any, dataAccessors: any): any[] {
    const svgMetadata: any = {
      x: {
        min: new Date(content.xAxisScale[0]),
        max: new Date(content.xAxisScale[content.xAxisScale.length - 1])
      },
      y: {
        min: d3.min(content.data, (c: any) => d3.min(c.values, (d: any) => d[dataAccessors.y])),
        max: d3.max(content.data, (c: any) => d3.max(c.values, (d: any) => d[dataAccessors.y]))
      }
    };

    const x1: any = d3.scaleTime().range([0, dimensions.width]).domain([svgMetadata.x.min, svgMetadata.x.max]);
    const y1: any = d3.scaleLinear().range([dimensions.height1, 0]).domain([svgMetadata.y.min, svgMetadata.y.max]);
    const x2: any = d3.scaleTime().range([0, dimensions.width]).domain(x1.domain());
    const y2: any = d3.scaleLinear().range([dimensions.height2, 0]).domain(y1.domain());

    return [x1, y1, x2, y2];
  }

  public drawLine(xAxis: any, yAxis: any, dataAccessors: any): any {
    return d3.line()
        .x((d: any) => xAxis(d[dataAccessors.x]))
        .y((d: any) => yAxis(d[dataAccessors.y]));
  }

  public calculateSvgDimensions(svg: any, margin: any, contextMargin: any): any[] {
    const svgWidth: number = +svg.attr('width');
    const svgHeight: number = +svg.attr('height');

    const width: number = svgWidth - margin.left - margin.right;
    const height: number = svgHeight - margin.top - margin.bottom;
    const height2: number = svgHeight - contextMargin.top - contextMargin.bottom;
    return [width, height, height2];
  }

  public appendClipPath(svg: any, width: number, height: number): void {
    svg.append('defs').append('svg:clipPath')
        .attr('id', 'clip')
        .append('svg:rect')
        .attr('width', width)
        .attr('height', height)
        .attr('x', 0)
        .attr('y', 0);
  }

  public createFocusableAxises(svg: any, margin: any, height: number, xAxis: any, yAxis: any): any {
    const focus = svg.append('g')
        .attr('class', 'focus')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    focus.append('g')
        .attr('class', 'x axis axis--x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(xAxis));

    focus.append('g')
        .attr('class', 'y axis axis--y')
        .call(d3.axisLeft(yAxis));
    return focus;
  }

  public createLegendDataLine(svg: any, data: any[], margin: any, line: any, color: any): any {
    const legendDataLine = svg.selectAll('.legendDataLine')
        .data(data)
        .enter().append('g')
        .attr('class', 'legendDataLine')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .attr('clip-path', 'url(#clip)');

    legendDataLine.append('path')
        .attr('class', 'line')
        .attr('d', (d: any) => line(d.values))
        .style('stroke', (d: any) => color[d.name])
        .style('stroke-width', 2);
    return legendDataLine;
  }

  public createMouseOverEffects(svg: any, margin: any): any {
    const mouseG = svg.append('g')
        .attr('class', 'mouse-over-effects')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    mouseG.append('path') // this is the black vertical line to follow mouse
        .attr('class', 'mouse-line')
        .style('stroke', 'black')
        .style('stroke-width', 0.7)
        .style('opacity', '0');
    return mouseG;
  }

  public generateStatsOnMouseOver(mouseG: any, data: any[], color: any): void {
    const lineStats = mouseG.append('g')
        .attr('class', 'line-stats')
        .style('opacity', '0');

    lineStats.append('rect')
        .attr('class', 'line-stats-view')
        .attr('fill', 'black')
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('width', 170)
        .attr('height', 110)
        .style('opacity', '0.7');

    lineStats.append('text')
        .attr('class', 'dateStats')
        .attr('transform', 'translate(6,20)')
        .style('fill', 'white')
        .style('font', '14px/21px sans-serif');
    lineStats.selectAll('.statsCircles')
        .data(data)
        .enter().append('circle')
        .attr('r', 5)
        .attr('class', 'statsCircles')
        .attr('fill', (d: any) => color[d.name])
        .attr('transform', (d: any, i: number) => {
          return 'translate(9,' + ((20 * (i + 2)) - 4) + ')';
        });
    lineStats.selectAll('.trendStats')
        .data(data)
        .enter().append('text')
        .attr('class', 'trendStats')
        .style('fill', 'white')
        .style('font', '14px/21px sans-serif')
        .attr('transform', (d: any, i: number) => {
          return 'translate(18,' + (20 * (i + 2)) + ')';
        });
  }

  public findYAxisMousePointers(domain: any, axisLength: number, coordinates: any): any {
    const mousePointers: any = {};
    const noOfUnits: number = domain[1] - 0;
    const widthPerUnit: number = axisLength / noOfUnits;
    const startCoordinates = coordinates.end > coordinates.start ? coordinates.end : coordinates.start;
    mousePointers.start = Math.ceil(axisLength - (widthPerUnit * startCoordinates));
    mousePointers.end = mousePointers.start;
    return mousePointers;
  }

  public findXAxisMousePointers(domain: any, axisLength: number, coordinates: any): any {
    const mousePointers: any = {};
    const noOfUnits: number = UtilsService.getDateDiffInDays(domain[1], domain[0]);
    const widthPerUnit: number = axisLength / noOfUnits;
    // 1. start coordinates -
    // Condition checks whether the start coordinate is in right of the origin.
    if (coordinates.start > domain[0]) {
      if (coordinates.start > domain[1]) { // If it goes beyond axis end.
        mousePointers.start = axisLength;
      } else {
        mousePointers.start = widthPerUnit * UtilsService.getDateDiffInDays(coordinates.start, domain[0]);
      }
    } else { // If start coordinate is behind the origin.
      mousePointers.start = 0;
    }

    // 2. end coordinates -
    // Condition checks whether the end coordinate is in left of the axis end.
    if (coordinates.end < domain[1]) {
      if (coordinates.end < domain[0]) { // If it goes behind the origin.
        mousePointers.end = 0;
      } else {
        mousePointers.end = widthPerUnit * UtilsService.getDateDiffInDays(coordinates.end, domain[0]);
      }
    } else { // If end coordinate is beyond the axis end.
      mousePointers.end = axisLength;
    }
    return mousePointers;
  }

  public reDrawRectOnZoom(annotations: any, annotationData: any[], xAxis: any, width: number): void {
    annotations.selectAll('.annotation')
        .data(annotationData)
        .attr('width', (element: any) => {
          const mousePointers: any = this.findXAxisMousePointers(xAxis.domain(), width, {
            start: new Date(element.coordinates.graph.start.x),
            end: new Date(element.coordinates.graph.end.x)
          });
          element.coordinates.svg.start.x = mousePointers.start;
          return (mousePointers.end - mousePointers.start);
        })
        .attr('transform', (element: any) => {
          return ('translate(' + element.coordinates.svg.start.x + ',' + element.coordinates.svg.start.y + ')');
        });
  }

  public createRectForMouseEvents(mouseG: any, width: number, height: number, zoom: any): any {
    return mouseG.append('svg:rect') // append a rect to catch mouse movements on canvas
        .attr('width', width) // can't catch mouse events on a g element
        .attr('height', height)
        .attr('class', 'zoom')
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .call(zoom)
        .on('mousedown.zoom', null)
        .on('touchstart.zoom', null)
        .on('touchmove.zoom', null)
        .on('touchend.zoom', null)
        .on('mouseout', () => { // on mouse out hide line, circles and text
          d3.select('.mouse-line')
              .style('opacity', '0');
          d3.select('.line-stats')
              .style('opacity', '0');
        })
        .on('mouseover', () => { // on mouse in show line, circles and text
          d3.select('.mouse-line')
              .style('opacity', '1');
          d3.select('.line-stats')
              .style('opacity', '1');
        });
  }

  public createContext(svg: any, contextMargin: any, data: any[], line: any): any {
    const context = svg.append('g')
        .attr('class', 'context')
        .attr('transform', 'translate(' + contextMargin.left + ',' + contextMargin.top + ')');

    context.selectAll('.legendDataLine2')
        .data(data)
        .enter().append('path')
        .attr('class', 'legendDataLine2')
        .attr('class', 'line')
        .attr('d', (d: any) => line(d.values))
        .style('stroke', 'black')
        .style('stroke-width', 0.5);
    return context;
  }

  public createBrushBehaviorOnContext(context: any, height: number, xAxis1: any, xAxis2: any, brush: any): void {
    context.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(xAxis2));

    context.append('g')
        .attr('class', 'brush')
        .call(brush)
        .call(brush.move, xAxis1.range());
  }
}
