import { Component, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';


  @Pipe({
    name: 'highlightFilter'
  })

  export class HighlightPipeComponent implements PipeTransform {
    transform(str: string, phrase: string): any {
      str = str.toLowerCase();
      let regex = '';
      if ( str.indexOf('/') > -1) {
        str = str.replace(/\//g, '');
      }
      str = str.replace(/’/g, '\'');
      str = str.replace(/([a-z0-9])-([a-z0-9])/g, '$1$2');
      let phraseArr = phrase.split(' ');
      for(var i = 0; i < phraseArr.length; i++){
        if(i === phraseArr.length - 1){
          regex = regex + phraseArr[i];
        }
        else{
          regex = regex + phraseArr[i] + '[^a-z^0-9]+';
        }
      }
      let matchObj = str.match(new RegExp(regex));
      if(matchObj){
        let startIndex = matchObj.index;
        let endIndex = matchObj.index + matchObj[0].length;
        let numSpaces = 0;
        let startBreakIndex = 0;
        for(var i= startIndex-1 ; i > 0; i--){
          if(str.charAt(i) === ' '){
            numSpaces++;
            if(numSpaces === 3){
              startBreakIndex = i;
              break;
            }
          }
        }
        numSpaces = 0;
        var endBreakIndex = str.length - 1;
        for(var i= endIndex+1 ; i < str.length; i++){
          if(str.charAt(i) === ' '){
            numSpaces++;
            if(numSpaces === 3){
              endBreakIndex = i;
              break;
            }
          }
        }
        var finalString = str.substring(startBreakIndex,endBreakIndex);
        finalString = finalString.replace(matchObj[0],'<span class="highlightText">'+matchObj[0]+'</span>')

        return '...'+finalString+'...';
      }
      else{
        return '...'+str.substring(5,50)+'...';
      }
    }

}
