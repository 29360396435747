import {Component, OnInit, ViewChild} from "@angular/core";
import {MessageModalComponent} from "../reusable/message-modal/message-modal.component";
import {EchartGraphsService} from "../services/echart-graphs.service";
import {SharedServiceService} from "../services/shared-service.service";
import {HeaderComponent} from "../reusable/header/header.component";
import {AlertsService} from "./alerts.service";
import {HeaderDateChangedService} from "../reusable/header/header-date-changed.service";
import {ArrayType} from "@angular/compiler";
import {WorkspaceService} from "../workspace/workspace.service";
const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  public showLoader = false;
  public dates: any;
  public insightsSecond = true;
  public insightsFirst = true;
  public wordCloudObj: any;
  public totalMentions: any;
  public alertsObj : any;
  public alertsArr : any =[];
  public readingArr : any =[];
  public alertsColspan :number;
  public readingObj : any;
  public msgUrl = "/alarm/msg";
  public resultExistsTable1: boolean = true;
  public resultExistsTable2: boolean = true;
  public checkedSiteFilters: any[] = [];
  @ViewChild(MessageModalComponent) child: MessageModalComponent;

  constructor(private myService: SharedServiceService,
              private header: HeaderComponent,
              private alertsService: AlertsService,
              private eChartGraphs: EchartGraphsService,
              private dateChangedService: HeaderDateChangedService,
              private workspaceService: WorkspaceService) {

    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            this.showSiteData(this.checkedSiteFilters);
          }).catch((error) => {
            console.error(error);
          });
        });
  }

  public insights() {
    const insightBoolean = this.myService.insights(this.dates);
    this.insightsFirst = insightBoolean.insFirst;
    this.insightsSecond = insightBoolean.insSecond;
  }

  public isResultExists(label: any) {
    return label.length;
  }

  public openMessageModal(specificUrl?: any) {
    specificUrl = specificUrl || "";
    if (this.checkedSiteFilters.length && this.checkedSiteFilters[0] === "All channels") {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, 'false');
    }
    else {
      this.child.initModal(this.dates, this.msgUrl + specificUrl, 'false',{Site_Type: this.checkedSiteFilters});
    }
  }

  public showSiteData(checkedSiteFilters: any[]) {
    this.showLoader = true;
    this.checkedSiteFilters = checkedSiteFilters ? checkedSiteFilters: [];
    this.dates = this.header.getDateFromLS();
    this.insights();
    this.myService.saveSiteInLS(this.checkedSiteFilters);
    this.alertsService.getData(this.dates, this.checkedSiteFilters).then((response: any) => {
      this.showLoader = false;
      this.totalMentions = response.alert_alarm_mentions;
      this.makeTables(response);
      this.wordCloudObj = response.alarm_freq;
    }).catch((error: any) => {
      throw error;
    });
  }

  public makeTables(obj:any){
    this.alertsArr = [];
    this.readingArr = [];
    this.alertsObj = obj.alarm;
    this.resultExistsTable1 = this.isResultExists(this.alertsObj.label);
    if(this.resultExistsTable1){
      for(const i in this.alertsObj){
        if(i!=="label"){
          this.alertsArr.push(i);
        }
      }
      this.alertsArr.sort();
      this.alertsColspan = this.alertsArr.length;
    }
    this.readingObj = obj.scan_auto;
    this.resultExistsTable2 = this.isResultExists(this.readingObj.label);
    if(this.resultExistsTable2){
      for(const i in this.readingObj){
        if(i!=="label"){
          this.readingArr.push(i);
        }
      }
      this.readingArr.sort();
    }

  }

  ngOnInit() {
    this.checkedSiteFilters = this.myService.getSiteInLS();
    this.showSiteData(this.checkedSiteFilters);
  }

}
