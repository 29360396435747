import {Injectable, Input} from "@angular/core";


@Injectable({
  providedIn: "root"
})
export class ForceDirectedGraphService {
  public nodes: any = [];
  public links: any = [];

  public nodes2: any = [];
  public links2: any = [];


  constructor() {

  }

  graph(graphresp) {

    this.nodes2.length = 0;
    this.links2.length = 0;
    let  cordinatesfixedx=[-628.09,-530.07,-332.73,-620.53,-9.96,-207.17,619.318,-441.75,-86.86,-591.99,610.98,-324.55,299.644,-267.06,-335.26,550.79,254.48,-34.65,765.266,37.62];
    let cordinatesfixedy=[-173.79,-501.35,-548.04,-520.63,81.634,619.31,-441.75,-566.76,166.232,210.922,320.72,-26.74,292.65,-350.66,2.58,-114.80,-244.74,-230.7,403.98,403.98];



    console.log(graphresp);

    let x
    let q = 35;
    let r = 1
    let l = 0
    for (x = 0; x < 30; x++) {
      let m =[]

      this.nodes2[x] = {}
      if (graphresp[x]["Profession"][0] === "Doctor") {
        m.push(1);
      } else if (graphresp[x]["Profession"][0] === "Patient") {
        m.push(2);
      } else if (graphresp[x]["Profession"][0] === "Bloggers") {
        m.push(3);
      } else if (graphresp[x]["Profession"][0] === "Caregiver") {
        m.push(4);
      } else if (graphresp[x]["Profession"][0] === "HCP") {
        m.push(5);
      } else if (graphresp[x]["Profession"][0] === "reseacher") {
        m.push(6);
      } else if (graphresp[x]["Profession"][0] === "Patient_advocate") {
        m.push(7);
      } else if (graphresp[x]["Profession"][0] === "journalist/media") {
        m.push(8);
      } else if (graphresp[x]["Profession"][0] === "Org Med Devices") {
        m.push(9);
      } else if (graphresp[x]["Profession"][0] === "organization/NGO") {
        m.push(10);
      } else if (graphresp[x]["Profession"][0] === "others") {
        m.push(11);
      }
      if (graphresp[x]["Profession"][1] === "Doctor") {
        m.push(1);
      } else if (graphresp[x]["Profession"][1] === "Patient") {
        m.push(2);
      } else if (graphresp[x]["Profession"][1] === "Bloggers") {
        m.push(3);
      } else if (graphresp[x]["Profession"][1] === "Caregiver") {
        m.push(4);
      } else if (graphresp[x]["Profession"][1] === "HCP") {
        m.push(5);
      } else if (graphresp[x]["Profession"][1] === "reseacher") {
        m.push(6);
      } else if (graphresp[x]["Profession"][1] === "Patient_advocate") {
        m.push(7);
      } else if (graphresp[x]["Profession"][1] === "journalist/media") {
        m.push(8);
      } else if (graphresp[x]["Profession"][1] === "Org Med Devices") {
        m.push(9);
      } else if (graphresp[x]["Profession"][1] === "organization/NGO") {
        m.push(10);
      } else if (graphresp[x]["Profession"][1] === "others") {
        m.push(11);
      }
      if (graphresp[x]["Profession"][2] === "Doctor") {
        m.push(1);
      } else if (graphresp[x]["Profession"][2] === "Patient") {
        m.push(2);
      } else if (graphresp[x]["Profession"][2] === "Bloggers") {
        m.push(3);
      } else if (graphresp[x]["Profession"][2] === "Caregiver") {
        m.push(4);
      } else if (graphresp[x]["Profession"][2] === "HCP") {
        m.push(5);
      } else if (graphresp[x]["Profession"][2] === "reseacher") {
        m.push(6);
      } else if (graphresp[x]["Profession"][2] === "Patient_advocate") {
        m.push(7);
      } else if (graphresp[x]["Profession"][2] === "journalist/media") {
        m.push(8);
      } else if (graphresp[x]["Profession"][2] === "Org Med Devices") {
        m.push(9);
      } else if (graphresp[x]["Profession"][2] === "organization/NGO") {
        m.push(10);
      } else if (graphresp[x]["Profession"][2] === "others") {
        m.push(11);
      }



      let v = graphresp[x]["Follower_Count"]

      let leng = graphresp[x]["Profession"].length



      this.nodes2[x] = {
        "id": graphresp[x]["Screen_Name"],
        "Author_Name":graphresp[x]["Author_Name"],
        Location:graphresp[x]["Location"],
        "group": m,
        "value": q,
        "ranks": r,
        "follower": v,
        "Profession": graphresp[x]["Profession"],
        "Score": graphresp[x]["userScore"], "x": cordinatesfixedx[x],
        "Nopost":graphresp[x]["Numpost"],
        "Interaction":graphresp[x]["Interaction"],


        "y": cordinatesfixedy[x],
        "fixed":true,
        "rx" : 150,// Default values
        "ry" : 120



      };
      q = q - 1;
      r = r + 1;
    }
    let z = 0;
    for (let x = 0; x < 30; x++) {

      for (var g of  Object.keys(graphresp[x].Mentions)) {

        let valuel = graphresp[x].Mentions[g];
        let m = g.replace('@', '');

        for (let z = 0; z < 30; z++) {

          if (m === graphresp[z]["Screen_Name"]) {

            this.links2[l] = {"source": graphresp[x]["Screen_Name"], "target": m, "value": valuel}
            l = l + 1;
          }
        }
      }
    }
    console.log(this.links2);
  }

  graph2(graphresp1) {

    let  cordinatesfixedx=[-628.09,-530.07,-332.73,-620.53,-9.96,-207.17,619.318,-441.75,-86.86,-591.99,610.98,-324.55,299.644,-267.06,-335.26,550.79,254.48,-34.65,765.266,37.62];
    let cordinatesfixedy=[-173.79,-501.35,-548.04,-520.63,81.634,619.31,-441.75,-566.76,166.232,210.922,320.72,-26.74,292.65,-350.66,2.58,-114.80,-244.74,-230.7,403.98,403.98];

    this.nodes.length = 0;
    this.links.length = 0;

    console.log(graphresp1);

    let x
    let q = 25;
    let len;
    let r = 1
    let l = 0
    if(graphresp1.length<20){
      len=graphresp1.length
    }
    else {
      len=20
    }
    console.log(len)
    for (x = 0; x < len; x++) {
      let m =[]

      this.nodes2[x] = {}
      if (graphresp1[x]["Profession"][0] === "Doctor") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "Patient") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "Bloggers") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "Caregiver") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "HCP") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "reseacher") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "Patient_advocate") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "journalist/media") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "Org Med Devices") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "organization/NGO") {
        m.push(graphresp1[x]["Profession"][0]);
      } else if (graphresp1[x]["Profession"][0] === "others") {
        m.push(graphresp1[x]["Profession"][0]);
      }
      if (graphresp1[x]["Profession"][1] === "Doctor") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "Patient") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "Bloggers") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "Caregiver") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "HCP") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "reseacher") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "Patient_advocate") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "journalist/media") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "Org Med Devices") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "organization/NGO") {
        m.push(graphresp1[x]["Profession"][1]);
      } else if (graphresp1[x]["Profession"][1] === "others") {
        m.push(graphresp1[x]["Profession"][1]);
      }
      if (graphresp1[x]["Profession"][2] === "Doctor") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "Patient") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "Bloggers") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "Caregiver") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "HCP") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "reseacher") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "Patient_advocate") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "journalist/media") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "Org Med Devices") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "organization/NGO") {
        m.push(graphresp1[x]["Profession"][2]);
      } else if (graphresp1[x]["Profession"][2] === "others") {
        m.push(graphresp1[x]["Profession"][2]);
      }

      let v = graphresp1[x]["Follower_Count"]

      let leng = graphresp1[x]["Profession"].length


      this.nodes[x] = {
        "id": graphresp1[x]["Screen_Name"],
        "group": m,
        "value": q,
        "ranks": r,
        "follower": v,
        "Profession": graphresp1[x]["Profession"],
        "Score": graphresp1[x]["userScore"],
        "date": graphresp1[x]["t_created"],
        "Author_Name":graphresp1[x]["Author_Name"],
        "Location":graphresp1[x]["Location"],
        "Nopost":graphresp1[x]["Numpost"],
        "Interaction":graphresp1[x]["Interaction"],

        "hashtags":graphresp1[x]["hashtag"].slice(0, 3),
        "Keywords":graphresp1[x]["clean_keywords_array"].slice(0, 4),
        "x": cordinatesfixedx[x],
        "y": cordinatesfixedy[x],
        "fixed":true,
        "rx" : 150,// Default values
        "ry" : 120
       // console.log(cordinatesfixedx[x])

      };
      //this.nodes.fixed();



      q = q - 1;
      r = r + 1;
    }

    let f=0;

    let z = 0;
    let flag=0

    let linkMap: any = {};
    console.log(len)
    for (let g = 0; g < len; g++) {
      let linkstrength=0

      for (let m = 0; m < graphresp1[g].finalGroup.length; m++) {
        let q = graphresp1[g].finalGroup[m];

        for (let j = g+1; j < len; j++) {
          flag=0

          if (graphresp1[j].finalGroup.includes(q, 0)) {
            if(this.links.length===0) {
              this.links.push({
                "source": graphresp1[g]["Screen_Name"],
                "target": graphresp1[j]["Screen_Name"],
                "value": 1,
                "link_strength":1
              })
            }
            else {
              flag=1
              for(let i=0; i <this.links.length; i++){


                if(graphresp1[g].Screen_Name === this.links[i].source && graphresp1[j].Screen_Name === this.links[i].target) {
                  //console.log("inside if links")
                  flag = 0;
                  linkstrength = linkstrength+1

                  //console.log("the link strength is ")
                  //console.log(linkstrength)
                  this.links[i].link_strength=linkstrength

                }
              }
              if (flag!==0)
              {

                this.links.push( {
                  "source": graphresp1[g]["Screen_Name"],
                  "target": graphresp1[j]["Screen_Name"],
                  "value": 1,
                  "link_strength":1

                      })
                flag=1;

              }
            }
          }
        }
      }
    }



    console.log("the links are ")
    console.log(this.links)
    console.log(this.nodes)

  }
}
