import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {CurvedWeightedService} from "./curved-weighted.service";
import * as d3 from "d3";

@Component({
  selector: "app-curved-weighted-force-graph",
  templateUrl: "./curved-weighted-force-graph.component.html",
  styleUrls: ["./curved-weighted-force-graph.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class CurvedWeightedForceGraphComponent implements OnInit {

  constructor(private service: CurvedWeightedService) {
  }

  buildGraph(): void {
    let svg: any = d3.select("svg");
    let width: any = +svg.attr("width");
    let height: any = +svg.attr("height");
    let color: any = d3.scaleOrdinal(d3.schemeCategory10);

    let radius = d3.scaleSqrt()
        .range([0, 6]);

    let simulation: any = d3.forceSimulation()
        .force("link",
            d3.forceLink().id((d: any) => d.id)
                .distance((d: any) => radius(d.source.value / 2) + radius(d.target.value / 2))
                .strength((d: any) => 0.75)
        )
        .force("charge", d3.forceManyBody().strength(-300))
        .force("collide", d3.forceCollide().radius((d: any) => radius(d.value / 2) + 2))
        .force("center", d3.forceCenter(width / 2, height / 2));

    // add encompassing group for the zoom
    let g = svg.append("g")
        .attr("class", "everything");

    let link: any = g.append("g")
        .attr("class", "links")
        .selectAll("path")
        .data(this.service.links)
        .enter().append("svg:path")
        .attr("stroke-width", (d: any) => 1);

    link.style("fill", "none")
        .style("stroke", "black")
        .style("stroke-width", "2px");

    let node: any = g.append("g")
        .attr("class", "nodes")
        .selectAll("g")
        .data(this.service.nodes)
        .enter().append("g")
        .style("transform-origin", "50% 50%");

    node.append("circle")
        .attr("r", (d: any) => radius(d.value / 2))
        .attr("fill", (d: any) => color(d.group));

    node.append("text")
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text((d: any) => d.name);

    simulation
        .nodes(this.service.nodes)
        .on("tick", ticked);

    function ticked() {
      link.attr("d", (d: any) => {
        let dx = d.target.x - d.source.x,
            dy = d.target.y - d.source.y,
            dr = Math.sqrt(dx * dx + dy * dy);
        return "M" +
            d.source.x + "," +
            d.source.y + "A" +
            dr + "," + dr + " 0 0,1 " +
            d.target.x + "," +
            d.target.y;
      });

      node.attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      });
    }

    simulation.force("link")
        .links(this.service.links);

    //add drag capabilities
    const dragHandler = d3.drag()
        .on("start", dragStarted)
        .on("drag", dragged)
        .on("end", dragEnded);

    dragHandler(node);

    function dragStarted(d) {
      if (!d3.event.active) {
        simulation.alphaTarget(0.3).restart();
      }
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragEnded(d) {
      /*if (!d3.event.active) {
        simulation.alphaTarget(0);
      }
      d.fx = null;
      d.fy = null;*/
    }

    //add zoom capabilities
    const zoomHandler = d3.zoom()
        .on("zoom", zoomActions);

    zoomHandler(svg);

    //Zoom functions
    function zoomActions() {
      g.attr("transform", d3.event.transform);
    }
  }

  ngOnInit() {
    this.buildGraph();
  }

}
