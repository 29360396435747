import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {ForceDirectedGraphService} from '../../reusable/d3/force-directed-graph/force-directed-graph.service';
import {TwitterService} from '../twitter.service';
import {HeaderComponent} from '../../reusable/header/header.component';


import {ScatterGraphService} from './scatter-graph.service';
import * as D3 from 'd3';
import {main} from "@angular/compiler-cli/src/main";
// import {Input} from "@angular/core";

@Component({
    selector: 'app-scatter-graph',
    templateUrl: './scatter-graph.component.html',
    styleUrls: ['./scatter-graph.component.css']
})
export class ScatterGraphComponent implements OnInit {
    // @Input() () graphresp: any = [];
    @Input () graphresp1: any = [];
    @Input () screen_name: any;

    public toggleHeight = true;
    public initialShow = true;
    public usertopics: any ;
    public graphresp2: any = [];
    public compare_val: any=[];
    public showMyContainer: boolean = true;
    public exitval: any=1;
    public infobox1: any;
    public globei=0;
    public commontop: any=[];



    constructor(private service: ScatterGraphService ,
                private  twitterService: TwitterService,
                private  header: HeaderComponent) {

    }
    hidePanel() {
        const hidepannel = document.getElementById('hide-left-pannel');
        const msgDiv = document.getElementById('messages-div');
        const getDiv = document.getElementById('l-p-sec');
        if (getDiv.classList.contains('show-panel')) {
            getDiv.classList.remove('show-panel');
            msgDiv.classList.remove('p-r-390');
            hidepannel.classList.remove('rotate-360');
        } else {
            hidepannel.classList.add('rotate-360');
            getDiv.classList.add('show-panel');
            // msgDiv.classList.add('p-r-390');
        }
    }
    clickEvent1(div): void {
        if (div === 'div1') {
            this.initialShow = !this.initialShow;
        } else if (div === 'div2') {
            this.toggleHeight = !this.toggleHeight;
        }
    }
    clickTopic(topicname) {
        console.log(topicname);
        if(topicname==='new'){
            const headerDates = this.header.getDateFromLS();
            this.build_scatterGraph(this.graphresp1, this.screen_name);

        }
        else {

            const headerDates = this.header.getDateFromLS();
            this.build_scatterGraph2(topicname);
        }


        // this.twitterService.gettopicGraph(headerDates, {Topicname: topicname},this.screen_name).then((response: any) => {
        //
        //     this.graphresp2 = response;
        //     console.log(this.graphresp2)
        //     this.build_scatterGraph2(this.graphresp2)
        //
        // }).catch((error: any) => {
        //     throw error;
        // });
    }
    //
    //
    public closeinfo_box(){
        console.log("inside ingo box")
        this.infobox1.select("div").remove();


    }


    build_scatterGraph(input, screen_name): void {
        console.log('building the scatter graph');
        console.log('the this.graphresp1 is ');
        console.log(this.screen_name);

        let main_user_array=[]
        console.log(this.graphresp1);
        const d3: any = D3;

        const svg1 = d3.select('.scatter-graph');
        svg1.selectAll('svg').remove();

        const coloursd = ['#E82D4F', '#52AA8E', '#759ECC', '#CA7FA8', '#CEC07A', '#D14FC6', '#6A9C9C', '#63B142', '#465E73', '#823E45', '#682DE6'];
        // const svg: any = d3.select('.scatter-graph').append('svg');
        this.infobox1=d3.select('.tooltop');

        const margin = {
                top: 20,
                right: 80,
                bottom: 30,
                left: 50
            },
            width = 960 - margin.left - margin.right,
            height = 500 - margin.top - margin.bottom;
        console.log("the height is ")
        console.log(height)

        //  var k = height / width
        // x0 = [-4.5, 4.5],
        // y0 = [-4.5 * k, 4.5 * k]
        // x = d3.scaleLinear().domain(x0).range([0, width]),
        // y = d3.scaleLinear().domain(y0).range([height, 0]),
        // z = d3.scaleOrdinal(d3.schemeCategory10);
        // let brush = d3.brush().on('end',
        //
        //
        //
        //       brushended),
        //       idleTimeout,
        //       idleDelay = 350;

        //

        // var parseDate = d3.time.fromat('%Y-%m-%dT%H:%M:%S%Z');
        const  xValue = function(d) {
            return  new Date(d.t_created).getTime(); }; // data -> value
        let xScale = d3.scaleLinear().range([0, width]); // value -> display
        const xMap = function(d) { return xScale(xValue(d)); }, // data -> display
            xAxis = d3.axisBottom().scale(xScale);

        // console.log('scale is');
        // console.log(xAxis);
        // console.log(xValue)


        // let yValue:any=[];
        const yValue = (d) => {
            console.log(d["Screen_Name"])
            console.log(this.screen_name)
            if (d.Screen_Name === this.screen_name) {
                main_user_array[0]=d.Screen_Name;
                this.compare_val[0]=main_user_array[0]
                main_user_array[1]=d.Location;
                this.compare_val[1]=main_user_array[1]
                main_user_array[2]=d.Category;
                this.compare_val[2]=main_user_array[2]
                main_user_array[3]=d.Numpost
                this.compare_val[3]=main_user_array[3]
                main_user_array[4]=d.Interaction
                this.compare_val[4]=main_user_array[4]
                main_user_array[5]=d.hashtag[0]
                this.compare_val[5]=main_user_array[5]
                main_user_array[6]=d.Potential_Reach
                this.compare_val[6]=main_user_array[6]
                main_user_array[7]=d.topicGroup
                this.compare_val[7]=main_user_array[7]
                console.log("in if")
                return 10;
            }
            else{
                console.log("in else")
                return d.topicGroup.length;} };
        //console.log(this.graphresp1.similarityscore);
        // data -> value

        let yScale = d3.scaleLinear().range([height, 0]),
            yMap = function(d) { return yScale(yValue(d)); }, // data -> display
            yAxis = d3.axisLeft().scale(yScale);


        const cValue = function(d) { return d.Category; },
            color: any = d3.scaleOrdinal(coloursd);
        const svg = d3.select('.scatter-graph').append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
        const tooltip = d3.select('.scatter-graph').append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);
        xScale.domain([d3.min(this.graphresp1, xValue) - 1, d3.max(this.graphresp1, xValue) + 1]);
        yScale.domain([d3.min(this.graphresp1, yValue) - 1, d3.max(this.graphresp1, yValue) + 1]);
        // console.log("the y scale is ")


        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', 'translate(0,' + height + ')')
            .attr('id', 'axis-axis--x')
            .call(xAxis)
            .append('text')
            .attr('class', 'label')
            .attr('x', width)
            .attr('y', 0)
            .style('text-anchor', 'end')


        // y-axis
        svg.append('g')
            .attr('class', 'y-axis')
            .attr('id', ' axis-axis--y')
            .call(yAxis)
            .append('text')
            .attr('class', 'label')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0)
            .attr('dy', '.71em')
            .style('text-anchor', 'end');



        // svg.selectAll("circle")
        //     .data(this.graphresp1)
        //     .enter().append("circle")
        //     .attr("cx", function(d) { return x(d[0]); })
        //     .attr("cy", function(d) { return y(d[1]); })
        //     .attr("r", 2.5)
        //     .attr("fill", function(d) { return z(d[2]); });
        console.log('before svg .dot');
        let mycontainer=this.showMyContainer

        // console.log(this.screen_name)
    //   var smallDiv = '<div class="smallDiv"> </div>'
    //     function addDiv ( => {
    //         console.log(d3.select('.comtop'))
    //         d3.select('.comtop').append("span").attr("class","col-flex tags")
    //             .html(this.commontop[this.globei])
    //     })
        svg.selectAll('.dot')
            .data(this.graphresp1)
            .enter().append('circle')
            .attr('class', 'dot')
            .attr('r', (d)  => {   // console.log(this.screen_name);
                // console.log(d['Screen_Name']);

                if (d.Screen_Name === this.screen_name) {
                    console.log(d.topicGroup)
                    this.usertopics = d.topicGroup;


                    //console.log('the user topics are ');
                    //console.log(this.usertopics);
                    // console.log("true") ;
                    return 0.5; } else { // console.log(d.Screen_Name);
                    return nodesize(d); }})
            .attr('cx', xMap)
            .attr('opacity', 0.9)
            .attr('cy', yMap)
            .style('fill', function(d) {  return color(cValue(d)); })
            .on('click', (d)=> {
                console.log(mycontainer)
                mycontainer = !mycontainer  ;
                this.infobox1.transition()
                    .duration(200)
                    .style('opacity', 1);
                let commontopics=[];
                for(this.globei=0;this.globei<main_user_array[7].length;this.globei++){
                    if(d.topicGroup.includes(main_user_array[7][this.globei]))
                    {
                        commontopics.push(main_user_array[7][this.globei])
                    }
                }
                console.log(commontopics)
                this.commontop=commontopics

                // tooltip.html("User Screen Name:      "+      d.Screen_Name +"     " +main_user_array[0] + '<br/> ' +"   "+ d.Location + "     "+main_user_array[1] + '<br/> ' + "Category:     "+  d.Category +"    "+main_user_array[2]
                //      + '<br/> ' + "no of posts:      "+ d.Numpost  + "      "+   main_user_array[3]+'<br/> ' + "Total Interactions:    "+ d.Interaction  +"    " +main_user_array[4] +'<br/> '+ "hashtags used:    " + d.hashtag[0]+ "      "+main_user_array[5])
                //     .style('left', (d3.event.pageX ) + 'px')
                //     .style('top', (d3.event.pageY+50) + 'px');
                this.infobox1.html(
                    '<div style="background:#fff;position:absolute;top:54%;left:50%;transform:translate(-50%, -90%);width:auto;border:1px solid #eee">'+
                    '          <div class="close-tooltip" style="position: absolute;right: -20px;top: -20px;border-radius: 50%;border: 1px solid #efefef;"><button id="infobox" style="border-radius: 50%;padding: 5px 10px;border: none;"><i class="fa fa-times" aria-hidden="true"></i></button></div>'+
                    '                <table width="100%">'+
                    '                    <thead style="background:#eee; padding:6px 10px;">'+
                    '                    <tr>'+
                    '                        <th></th>'+
                    '                        <th style="font-weight: 500;padding: 6px 10px;font-size: 14px;"> ' + '@' +d.Screen_Name +' <span style="display:block;padding:2px 0;font-size:10px;"></span></th>'+
                    '                        <th style="font-weight: 500;padding: 6px 10px;font-size: 14px;">' + '@' + main_user_array[0] +' <span style="display:block;padding:2px 0;font-size:10px;"></span></th>'+
                    '                    </tr>'+
                    '                    </thead >'+
                    '                    <tbody>'+
                    '                    <tr>'+
                    '                    <tr>'+
                    '                        <td  style="padding:6px 10px;font-weight: 500;">Location</td>'+
                    '                        <td style="padding:6px 10px;"> ' +d.Location+'</td>'+
                    '                        <td style="padding:6px 10px;">' +main_user_array[1]+'</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;">Category</td>'+
                    '                         <td style="padding:6px 10px;">' +'<span style="display: inline-block;background: #eeeeee;padding: 3px 8px;border-radius: 2px;">'+d.Category+'</span> '+'</td>'+
                    '                        <td style="padding:6px 10px;">' + '<span style="display: inline-block;background: #eeeeee;padding: 3px 8px;border-radius: 2px;">'+ main_user_array[2] +'</span> '+ '</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;">No of posts</td>'+
                    '                        <td style="padding:6px 10px;"> ' + d.Numpost + '</td>'+
                    '                        <td style="padding:6px 10px;">' +main_user_array[3] + '</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;">Total Interaction</td>'+
                    '                        <td style="padding:6px 10px;">' +d.Interaction + '</td>'+
                    '                        <td style="padding:6px 10px;">' +main_user_array[4] + '</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;"> Most Common Hashtag</td>'+
                    '                        <td style="padding:6px 10px;"> ' +d.hashtag[0] + '</td>'+
                    '                        <td style="padding:6px 10px;"> ' +main_user_array[5] + '</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;">Potential Reach</td>'+
                    '                        <td style="padding:6px 10px;"> '+d.Potential_Reach + '</td>'+
                    '                        <td style="padding:6px 10px;"> ' + main_user_array[6]+ ' </td>'+
                    '                    </tr>'+
                    '                    <tr style="border-top:1px solid #eee;">'+
                    '                        <td style="padding:8px 10px;font-weight: 500;"> Common Topics </td>'+
                    '                        <td  class="comtop" colspan="2" style="padding:8px 10px;"> Topic :  </td>'+
                    '                    </tr>'+
                    '                    </tbody>'+
                    '                </table>'+

                    '</div>'

                )
                for (  this.globei=0;this.globei<this.commontop.length;this.globei++){

                    console.log("inside for loop")
                    console.log(d3.select('.comtop'))
                             d3.select('.comtop').append("span").attr("class","topic-tags")
                               .html(this.commontop[this.globei]);
                }
             //       .attr('class' , 'tooltop')
                d3.select('#infobox').on('click', ()  =>{
                    this.closeinfo_box();
                });
            })




            .on('mouseout', function(d) {
                tooltip.transition()
                    .duration(500)
                    .style('opacity', 1);

            });


        this.showMyContainer=mycontainer
        console.log("the topics array is ")
        console.log(this.usertopics);

        function nodesize(d) {
            if (d.userScore >35) {
                return '45px';
            } else if (d.userScore < 35 && d.userScore >= 30) {
                return '30px';
            } else if (d.userScore < 30 && d.userScore >= 20) {
                return '20px';
            } else if (d.userScore < 20 && d.userScore >= 10) {
                return '13px';
            } else {
                return '5px';
            }
        }

        svg.selectAll('.domain')
            .style('display', 'none');

        svg.append('g')
            .attr('class', 'brush')
        //.call(brush);
        console.log('before svg brushend');



        //
        // function brushended() {
        //       const s = d3.event.selection;
        //       if (!s) {
        //           if (!idleTimeout) { return idleTimeout = setTimeout(idled, idleDelay); }
        //           xScale.domain([s[0][0], s[1][0]].map(xScale.invert, xScale));
        //           yScale.domain([s[1][1], s[0][1]].map(yScale.invert, yScale));
        //           svg.select('.brush').call(brush.move, null);
        //       } else {
        //           xScale.domain([s[0][0], s[1][0]].map(xScale.invert, xScale));
        //           yScale.domain([s[1][1], s[0][1]].map(yScale.invert, yScale));
        //           svg.select('.brush').call(brush.move, null);
        //       }
        //       zoom();
        //
        //   }

        // function idled() {
        //       idleTimeout = null;
        //   }

        // function zoom() {
        //
        //       const t = svg.transition().duration(750);
        //       //console.log(svg.select('.axis--x'));
        //       svg.select('#axis--x').transition(t).call(xAxis);
        //       svg.select('#axis--y').transition(t).call(yAxis);
        //       //console.log(xScale);
        //       //console.log(yScale);
        //       svg.selectAll('circle').transition(t)
        //           .attr('cx', function(d) { return xScale(d[0]); })
        //           .attr('cy', function(d) { return yScale(d[1]); });
        //   }


    }

    build_scatterGraph2( topicname): void {
        console.log('building the scatter graph');
        console.log('the this.graphresp1 is ');
        console.log(topicname);

        let main_user_array=[]
        //console.log(this.graphresp1);
        const d3: any = D3;

        const svg1 = d3.select('.scatter-graph');
        svg1.selectAll('svg').remove();

        const coloursd = ['#E82D4F', '#52AA8E', '#759ECC', '#CA7FA8', '#CEC07A', '#D14FC6', '#6A9C9C', '#63B142', '#465E73', '#823E45', '#682DE6'];
        // const svg: any = d3.select('.scatter-graph').append('svg');
        this.infobox1=d3.select('.tooltop');

        const margin = {
                top: 20,
                right: 80,
                bottom: 30,
                left: 50
            },
            width = 960 - margin.left - margin.right,
            height = 500 - margin.top - margin.bottom;

        //  var k = height / width
        // x0 = [-4.5, 4.5],
        // y0 = [-4.5 * k, 4.5 * k]
        // x = d3.scaleLinear().domain(x0).range([0, width]),
        // y = d3.scaleLinear().domain(y0).range([height, 0]),
        // z = d3.scaleOrdinal(d3.schemeCategory10);
        // let brush = d3.brush().on('end',
        //
        //
        //
        //       brushended),
        //       idleTimeout,
        //       idleDelay = 350;

        //

        // var parseDate = d3.time.fromat('%Y-%m-%dT%H:%M:%S%Z');
        const  xValue = function(d) {
            return  new Date(d.t_created).getTime(); }; // data -> value
        let xScale = d3.scaleLinear().range([0, width]); // value -> display
        const xMap = function(d) { return xScale(xValue(d)); }, // data -> display
            xAxis = d3.axisBottom().scale(xScale);

        // console.log('scale is');
        // console.log(xAxis);
        // console.log(xValue)


        // let yValue:any=[];
        const yValue = (d) => {
            //console.log(d["Screen_Name"])
            //console.log(this.screen_name)
            if (d.Screen_Name === this.screen_name) {
                main_user_array[0]=d.Screen_Name;
                this.compare_val[0]=main_user_array[0]
                main_user_array[1]=d.Location;
                this.compare_val[1]=main_user_array[1]
                main_user_array[2]=d.Category;
                this.compare_val[2]=main_user_array[2]
                main_user_array[3]=d.Numpost
                this.compare_val[3]=main_user_array[3]
                main_user_array[4]=d.Interaction
                this.compare_val[4]=main_user_array[4]
                main_user_array[5]=d.hashtag[0]
                this.compare_val[5]=main_user_array[5]
                main_user_array[6]=d.Potential_Reach
                this.compare_val[6]=main_user_array[6]
                main_user_array[7]=d.topicGroup
                this.compare_val[7]=main_user_array[7]
                console.log("in if")
                return 10;
            }
            else{
                //console.log("in else")
                return d.similarityscore;} };
        //console.log(this.graphresp1.similarityscore);
        // data -> value
        let yScale = d3.scaleLinear().range([height, 0]),
            yMap = function(d) { return yScale(yValue(d)); }, // data -> display
            yAxis = d3.axisLeft().scale(yScale);


        const cValue = function(d) { return d.Category; },
            color: any = d3.scaleOrdinal(coloursd);
        const svg = d3.select('.scatter-graph').append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
        const tooltip = d3.select('.scatter-graph').append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);
        xScale.domain([d3.min(this.graphresp1, xValue) - 1, d3.max(this.graphresp1, xValue) + 1]);
        yScale.domain([d3.min(this.graphresp1, yValue) - 1, d3.max(this.graphresp1, yValue) + 1]);
        // console.log("the y scale is ")


        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', 'translate(0,' + height + ')')
            .attr('id', 'axis-axis--x')
            .call(xAxis)
            .append('text')
            .attr('class', 'label')
            .attr('x', width)
            .attr('y', 0)
            .style('text-anchor', 'end')
            //.text('Date');

        // y-axis
        svg.append('g')
            .attr('class', 'y-axis')
            .attr('id', ' axis-axis--y')
            .call(yAxis)
            .append('text')
            .attr('class', 'label')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0)
            .attr('dy', '.71em')
            .style('text-anchor', 'end')
            //.text('User Score');
        // svg.selectAll("circle")
        //     .data(this.graphresp1)
        //     .enter().append("circle")
        //     .attr("cx", function(d) { return x(d[0]); })
        //     .attr("cy", function(d) { return y(d[1]); })
        //     .attr("r", 2.5)
        //     .attr("fill", function(d) { return z(d[2]); });
        console.log('before svg .dot');
        let mycontainer=this.showMyContainer
        // console.log(this.screen_name)

        svg.selectAll('.dot')
            .data(this.graphresp1)
            .enter().append('circle')
            .attr('class', 'dot')
            .attr('r', (d)  => {   // console.log(this.screen_name);
                // console.log(d['Screen_Name']);

                if (d.Screen_Name === this.screen_name) {
                    console.log(d.topicGroup)
                    this.usertopics = d.topicGroup;


                    //console.log('the user topics are ');
                    //console.log(this.usertopics);
                    // console.log("true") ;
                    return 0.5; } else { // console.log(d.Screen_Name);
                    return nodesize(d); }})
            .attr('cx', xMap)
            .attr('opacity', function(d){
                if(d.topicGroup.includes(topicname)){
                    console.log(topicname)
                    console.log((d.topicGroup))
                    console.log("inside opacity if ")
                    return 1;

                }
                else {
                    return 0.1;
                }
            })
            .attr('cy', yMap)
            .style('fill', function(d) {  return color(cValue(d)); })
            .on('click', (d)=> {
                console.log(mycontainer)
                mycontainer = !mycontainer  ;
                this.infobox1.transition()
                    .duration(200)
                    .style('opacity', 1);
                let commontopics=[];
                for(this.globei=0;this.globei<main_user_array[7].length;this.globei++){
                    if(d.topicGroup.includes(main_user_array[7][this.globei]))
                    {
                        commontopics.push(main_user_array[7][this.globei])
                    }
                }
                console.log(commontopics)
                this.commontop=commontopics

                // tooltip.html("User Screen Name:      "+      d.Screen_Name +"     " +main_user_array[0] + '<br/> ' +"   "+ d.Location + "     "+main_user_array[1] + '<br/> ' + "Category:     "+  d.Category +"    "+main_user_array[2]
                //      + '<br/> ' + "no of posts:      "+ d.Numpost  + "      "+   main_user_array[3]+'<br/> ' + "Total Interactions:    "+ d.Interaction  +"    " +main_user_array[4] +'<br/> '+ "hashtags used:    " + d.hashtag[0]+ "      "+main_user_array[5])
                //     .style('left', (d3.event.pageX ) + 'px')
                //     .style('top', (d3.event.pageY+50) + 'px');
                this.infobox1.html(
                    '<div style="background:#fff;position:absolute;top:50%;left:50%;transform:translate(-50%, -90%);width:auto;border:1px solid #eee">'+
                    '          <div class="close-tooltip"><button id="infobox"><i class="fa fa-times" aria-hidden="true"></i></button></div>'+
                    '                <table width="100%">'+
                    '                    <thead style="background:#eee; padding:6px 10px;">'+
                    '                    <tr>'+
                    '                        <th></th>'+
                    '                        <th style="font-weight: 500;padding: 6px 10px;font-size: 14px;"> ' + '@' +d.Screen_Name +' <span style="display:block;padding:2px 0;font-size:10px;"></span></th>'+
                    '                        <th style="font-weight: 500;padding: 6px 10px;font-size: 14px;">' + '@' + main_user_array[0] +' <span style="display:block;padding:2px 0;font-size:10px;"></span></th>'+
                    '                    </tr>'+
                    '                    </thead >'+
                    '                    <tbody>'+
                    '                    <tr>'+
                    '                    <tr>'+
                    '                        <td  style="padding:6px 10px;font-weight: 500;">Location</td>'+
                    '                        <td style="padding:6px 10px;"> ' +d.Location+'</td>'+
                    '                        <td style="padding:6px 10px;">' +main_user_array[1]+'</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;">Category</td>'+
                    '                         <td style="padding:6px 10px;">' +'<span style="display: inline-block;background: #eeeeee;padding: 3px 8px;border-radius: 2px;">'+d.Category+'</span> '+'</td>'+
                    '                        <td style="padding:6px 10px;">' + '<span style="display: inline-block;background: #eeeeee;padding: 3px 8px;border-radius: 2px;">'+ main_user_array[2] +'</span> '+ '</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;">No of posts</td>'+
                    '                        <td style="padding:6px 10px;"> ' + d.Numpost + '</td>'+
                    '                        <td style="padding:6px 10px;">' +main_user_array[3] + '</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;">Total Interaction</td>'+
                    '                        <td style="padding:6px 10px;">' +d.Interaction + '</td>'+
                    '                        <td style="padding:6px 10px;">' +main_user_array[4] + '</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;"> Most Common Hashtag</td>'+
                    '                        <td style="padding:6px 10px;"> ' +d.hashtag[0] + '</td>'+
                    '                        <td style="padding:6px 10px;"> ' +main_user_array[5] + '</td>'+
                    '                    </tr>'+
                    '                    <tr>'+
                    '                        <td style="padding:6px 10px;font-weight: 500;">Potential Reach</td>'+
                    '                        <td style="padding:6px 10px;"> '+d.Potential_Reach + '</td>'+
                    '                        <td style="padding:6px 10px;"> ' + main_user_array[6]+ ' </td>'+
                    '                    </tr>'+
                    '                   <tr style="border-top:1px solid #eee;">'+
                    '                        <td style="padding:8px 10px;font-weight: 500;"> Common Topics </td>'+
                    '                        <td  class="comtop" colspan="2" style="padding:8px 10px;"> Topic :  </td>'+
                    '                    </tr>'+
                    '                    </tbody>'+
                    '                </table>'+

                    '</div>'

                )
                for (  this.globei=0;this.globei<this.commontop.length;this.globei++){

                    console.log("inside for loop")
                    console.log(d3.select('.comtop'))
                    d3.select('.comtop').append("span").attr("class","topic-tags")
                        .html(this.commontop[this.globei]);
                }
                //       .attr('class' , 'tooltop')
                d3.select('#infobox').on('click', ()  =>{
                    this.closeinfo_box();
                });
            })
            .on('mouseout', function(d) {
                tooltip.transition()
                    .duration(500)
                    .style('opacity', 0);

            });
        this.showMyContainer=mycontainer
        //console.log("the topics array is ")
        //console.log(this.usertopics);

        function nodesize(d) {
            if (d.userScore >35) {
                return '45px';
            } else if (d.userScore < 35 && d.userScore >= 30) {
                return '30px';
            } else if (d.userScore < 30 && d.userScore >= 20) {
                return '20px';
            } else if (d.userScore < 20 && d.userScore >= 10) {
                return '13px';
            } else {
                return '5px';
            }
        }

        svg.selectAll('.domain')
            .style('display', 'none');

        svg.append('g')
            .attr('class', 'brush')
        //.call(brush);
        console.log('before svg brushend');



        //
        // function brushended() {
        //       const s = d3.event.selection;
        //       if (!s) {
        //           if (!idleTimeout) { return idleTimeout = setTimeout(idled, idleDelay); }
        //           xScale.domain([s[0][0], s[1][0]].map(xScale.invert, xScale));
        //           yScale.domain([s[1][1], s[0][1]].map(yScale.invert, yScale));
        //           svg.select('.brush').call(brush.move, null);
        //       } else {
        //           xScale.domain([s[0][0], s[1][0]].map(xScale.invert, xScale));
        //           yScale.domain([s[1][1], s[0][1]].map(yScale.invert, yScale));
        //           svg.select('.brush').call(brush.move, null);
        //       }
        //       zoom();
        //
        //   }

        // function idled() {
        //       idleTimeout = null;
        //   }

        // function zoom() {
        //
        //       const t = svg.transition().duration(750);
        //       //console.log(svg.select('.axis--x'));
        //       svg.select('#axis--x').transition(t).call(xAxis);
        //       svg.select('#axis--y').transition(t).call(yAxis);
        //       //console.log(xScale);
        //       //console.log(yScale);
        //       svg.selectAll('circle').transition(t)
        //           .attr('cx', function(d) { return xScale(d[0]); })
        //           .attr('cy', function(d) { return yScale(d[1]); });
        //   }



    }


    ngOnChanges() {

        // this.service.scatter_graph(this.graphresp1);
        console.log(' in onchanges');
        console.log(this.screen_name);
        console.log(this.graphresp1);
        this.build_scatterGraph(this.graphresp1, this.screen_name);



        // console.log('iso string check check');
        // console.log(typeof new Date(this.graphresp1[0].t_created).toISOString().getMonth === 'function');


    }

    ngOnInit() {

    }

}
