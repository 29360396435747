import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-rating-bar",
  templateUrl: "./rating-bar.component.html",
  styleUrls: ["./rating-bar.component.css"]
})
export class RatingBarComponent implements OnInit {
  cssRate: 2.5;

  constructor() { }

  ngOnInit() {
  }

}
