import {Injectable} from "@angular/core";
import {ApiService} from "../services/api.service";
import {SharedServiceService} from "../services/shared-service.service";

@Injectable({
  providedIn: "root"
})
export class SentimentsService {

  constructor(private apiService: ApiService,
              private myService: SharedServiceService) {
  }

  public getData(url:string, dates:any, filters?:any){
    let payload = {};
    let promise = new Promise((resolve, reject) =>  {
      if(filters.length ===1 && filters[0] === 'All channels'){
        filters = this.myService.sitesExcludingAllChannels;
      }
      payload['filters'] = filters;
      payload['start'] = dates[0];
      payload['end'] = dates[1];
      this.apiService.post(url, payload).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
    return promise;
  }
}
