import {Component, Input, OnInit, ViewChild, AfterViewInit, ViewChildren} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MessageModalComponent} from '../reusable/message-modal/message-modal.component';
import {GetUserChoiceComponent} from './get-user-choice/get-user-choice.component';
import {CustomTopicsService} from './custom-topics.service';
import {SharedServiceService} from '../services/shared-service.service';
import {response} from 'express';
import {HeaderComponent} from '../reusable/header/header.component';
import {AppComponent} from '../app.component';
import {HeaderDateChangedService} from '../reusable/header/header-date-changed.service';
import { ChartsModule } from 'ng2-charts';
import {EchartGraphsService} from '../services/echart-graphs.service';
import { GraphOptionsComponent } from './graph-options/graph-options.component';
import { Router} from '@angular/router';





import * as d3 from 'd3';
import * as c3 from 'c3';




@Component({
  selector: 'app-custom-topics',
  templateUrl: './custom-topics.component.html',
  styleUrls: ['./custom-topics.component.css'],

})
export class CustomTopicsComponent implements OnInit  {


  @Input() wordcloud;
  comparepie = 0;
  table = 0;
  publish = false;
  similarwords: any = [];
  pagename = 'Draft1';
  senddata = false;
  typeofgraph = 'piechart';
  typeofgraph2 = 'piechart';
  typeofgraph3 = 'piechart';
  typeofgraph4 = 'piechart';
  typeofgraph5 = 'piechart';


  msgdates = [];
  piechart = true;
  piechart1 = false;
  sectionNo = 1;
  section1Name = 'New Section';
showLoader=true;
publishnewpg=false;
fheValue=0;
mentionValue=0;

  section2Name = 'New Section';
  section3Name = 'New Section';
  section4Name = 'New Section';
  section5Name = 'New Section';


  insighshow = false;
  similarKeywords = {};
  editmode = true;
  renamepage = false;
  insight;
  bargraph1 = false;
  MajorGraphdata=[];
  graphdata3 = {};
  MajorGraphtype=[]
  presentDates=[]
  addInsight = true;
  dateforInsights={};
  backgroundColor=["#0074D9", "#FF4136", "#d69999", "#1d9090", "#2c5c6f", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA"]
  options: {
    scales: {

      gridLines: {
        display: false
      }

    }
  }
  DefaultInsights='Add Insights'


  showGraph = false;
  chartpie = {};
  section_name: 'New Section';
  PageName: 'Draft1';
  description = 'ADD DESCRIPTION';
  dataset = [];
  tabgrp1 = {};
  tabgrp2 = {};
  tabgrp3 = {};
  tabgrp4 = {};
  tabgrp5 = {};
  tabgrp6 = {};
  tabgrp7 = {};

  openmessagemodal = false;
  selectgrp = false;
  selectgrp2 = false;
  selectgrp3 = false;
  selectgrp4=false;
  selectgrp5=false;

  openInsites = false;
  toggleEditName: boolean;
  saveHeading = true;


  @ViewChild(MessageModalComponent) child: MessageModalComponent;


  constructor(private ngbModalService: NgbModal,
              private customservice: CustomTopicsService,
              private sharedservice: SharedServiceService,
              private header: HeaderComponent,
              public eChartGraphs: EchartGraphsService,
              private router: Router,
              private appcomp: AppComponent, public dateChangedService: HeaderDateChangedService) {
    dateChangedService.dateChanged$.subscribe(
    mission => {
          this.ngOnInit();


    });
    this.presentDates= this.header.getDateFromLS();
    console.log(this.presentDates);
    console.log(this.presentDates[0]);
  }

  public barChartOptions =[];
  public barHorizontalChartOptions =[];
  public  doublebarHorizontalChartOptions=[];

  public pieChartOptions =[];
  public  doublepieChartOptions1=[];
  public  doublepieChartOptions2=[];
  public  doublebarChartOptions=[];

  public doublebarChartLabels = {};
  public doublebarChartType = 'bar';
  public doublebarChartLegend = true;
  public doublebarChartData = {};
  public insightSectionName;

  public barChartLabels = {};
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = {};
  public va={};

  public pieChartLabels = {};
  public pieChartData = {};
  public pieChartType = 'pie';

  public comaprepieChartLabels = {};
  public comaprepieChartData1 = {};
  public comaprepieChartType = 'pie';

  public comaprepieChartData2 = {};
  public graphinsi = {};
  public sectionwiseInsight = [];
  public currentsection = 1;

  showInsights = false;

  showInsightSidebar(section) {


    this.showInsights = !this.showInsights;
    console.log(section);
    this.currentsection = section;
    if (section === 1) {
      console.log(this.section1Name);
      this.sectionwiseInsight = [];
      this.insightSectionName = this.section1Name;
    } else if (section === 2) {
      console.log(this.section2Name);
      this.sectionwiseInsight = [];
      this.insightSectionName = this.section2Name;

    } else if (section === 3) {
      console.log(this.section3Name);
      this.sectionwiseInsight = [];
      this.insightSectionName = this.section3Name;

    }
    else if (section === 4) {
      console.log(this.section4Name);
      this.sectionwiseInsight = [];
      this.insightSectionName = this.section4Name;

    }
    else if (section === 5) {
      console.log(this.section5Name);
      this.sectionwiseInsight = [];
      this.insightSectionName = this.section5Name;

    }

    this.va={};

    const m = section - 1;
    this.sectionwiseInsight = this.graphinsi[m];
console.log(this.sectionwiseInsight.length);
    for(let h=0;h<this.sectionwiseInsight.length;h++){
      console.log(this.sectionwiseInsight[h]);
      this.va[this.sectionwiseInsight[h]]=this.dateforInsights[m][h];

    }
    // this.sectionwiseInsight.reverse();
    console.log(this.va);

    console.log(this.insightSectionName);

  }
  deletesectionalert(section){
    console.log(section);
    if (confirm("DO YOU WANT TO DELETE THE SECTION!")) {
      this.deleteSection(section)
    } else {
      console.log(' not confirmed')
    }
  }
  deleteSection(section) {

    if (section === this.sectionNo) {

      this.sectionNo=section-1;
      this.MajorGraphdata[section-1]={};
      this.graphinsi[section-1]=[];
    } else {
      if (section === 4) {
        console.log("ins ection 4 deleting")
        console.log(this.MajorGraphdata[4])
        this.MajorGraphdata[3]={};
        this.MajorGraphdata[3] = this.MajorGraphdata[4];
        this.MajorGraphdata[4]={};

        //this.graphinsi[2]=[];
        if(this.sectionNo>4){
          console.log('inside second if')

         // this.MajorGraphdata[3]={};
          this.section4Name = this.section5Name;
          this.graphinsi[3] = [];
          this.graphinsi[3] = this.graphinsi[4];
          this.graphinsi[4]=[];
          console.log(this.typeofgraph5)


          this.typeofgraph4=this.typeofgraph5;
          console.log(this.typeofgraph);
          if(this.typeofgraph4==='piechart'){
            this.createGraphn(4);
          }
          else if(this.typeofgraph4==='bargraph1'){
            this.singleBarGraphn(4);
          }
          else if(this.typeofgraph4==='bargarph2'){
            this.bargraphn(4);
          }
          else if(this.typeofgraph4==='table'){
            this.tableforgroups4();
          }
          else if (this.typeofgraph4 === 'piechart2') {
            this.comaprePieChartn(4);
          }
          else if (this.typeofgraph4  === 'bargraph1horizontal') {
            console.log('in the table');
            this.HorizontalsingleBarGraphn(4);
          }

          else if (this.typeofgraph4  === 'bargarph2horizontal') {
            console.log('in the table');
            this.Horizontalbargraph4();
          }
        }
        this.sectionNo = this.sectionNo-1;


      }
      else if (section === 3) {
        this.MajorGraphdata[2] = this.MajorGraphdata[3];
        //this.graphinsi[2]=[];
        if(this.sectionNo>3){

          this.MajorGraphdata[3]={};
          this.section3Name = this.section4Name;
          this.graphinsi[2] = [];
          this.graphinsi[2] = this.graphinsi[3];
          this.graphinsi[3]=[];
          this.typeofgraph3=this.typeofgraph4;
          if(this.typeofgraph3==='piechart'){
            this.createGraphn(3);
          }
          else if(this.typeofgraph3==='bargraph1'){
            this.singleBarGraphn(3);
          }
          else if(this.typeofgraph3==='bargarph2'){
            this.bargraphn(3);
          }
          else if(this.typeofgraph3==='table'){
            this.tableforgroups3();
          }
          else if (this.typeofgraph3 === 'piechart2') {
            this.comaprePieChartn(3);
          }
          else if (this.typeofgraph3  === 'bargraph1horizontal') {
            console.log('in the table');
            this.HorizontalsingleBarGraphn(3);
          }

          else if (this.typeofgraph3  === 'bargarph2horizontal') {
            console.log('in the table');
            this.Horizontalbargraph3();
          }
        }

        if(this.sectionNo>4){

          this.MajorGraphdata[3]={};
          this.MajorGraphdata[3]=this.MajorGraphdata[4]
          this.MajorGraphdata[4]={};

          this.section4Name = this.section5Name;
          this.graphinsi[3] = [];
          this.graphinsi[3] = this.graphinsi[4];
          this.graphinsi[4]=[];
          this.typeofgraph4=this.typeofgraph5;
          if(this.typeofgraph4==='piechart'){
            this.createGraphn(4);
          }
          else if(this.typeofgraph4==='bargraph1'){
            this.singleBarGraphn(4);
          }
          else if(this.typeofgraph4==='bargarph2'){
            this.bargraphn(4);
          }
          else if(this.typeofgraph4==='table'){
            this.tableforgroups4();
          }
          else if (this.typeofgraph4 === 'piechart2') {
            this.comaprePieChartn(4);
          }
          else if (this.typeofgraph4  === 'bargraph1horizontal') {
            console.log('in the table');
            this.HorizontalsingleBarGraphn(4);
          }

          else if (this.typeofgraph4  === 'bargarph2horizontal') {
            console.log('in the table');
            this.Horizontalbargraph4();
          }
        }
        this.sectionNo = this.sectionNo-1;


      }
      else if (section === 2)
      {

        this.MajorGraphdata[1] = {};
        this.MajorGraphdata[1] = this.MajorGraphdata[2];
        this.MajorGraphdata[2]={};
        this.section2Name = this.section3Name;
        this.graphinsi[1] = [];
        this.graphinsi[1] = this.graphinsi[2];
        this.graphinsi[2]=[];
        this.typeofgraph2=this.typeofgraph3;
        if(this.typeofgraph2==='piechart'){
          this.createGraphn(2);
        }
        else if(this.typeofgraph2==='bargraph1'){
          this.singleBarGraphn(2);
        }
        else if(this.typeofgraph2==='bargarph2'){
          this.bargraphn(2);
        }
        else if(this.typeofgraph2==='table'){
          this.tableforgroups2();
        }
        else if (this.typeofgraph2 === 'piechart2') {
          this.comaprePieChartn(2);
        }
        else if (this.typeofgraph2  === 'bargraph1horizontal') {
          console.log('in the table');
          this.HorizontalsingleBarGraphn(2);
        }

        else if (this.typeofgraph2  === 'bargarph2horizontal') {
          console.log('in the table');
          this.Horizontalbargraph2();
        }
        if(this.sectionNo>3){
          this.MajorGraphdata[2] = this.MajorGraphdata[3];
          this.MajorGraphdata[3]={};
          this.section3Name = this.section4Name;
          this.graphinsi[2] = this.graphinsi[3];
          this.graphinsi[3]=[];
          this.typeofgraph3=this.typeofgraph4;
          console.log(this.typeofgraph3)
          if(this.typeofgraph3==='piechart'){
            this.createGraphn(3);
          }
          else if(this.typeofgraph3==='bargraph1'){
            this.singleBarGraphn(3);
          }
          else if(this.typeofgraph3==='bargarph2'){
            this.bargraphn(3);
          }
          else if(this.typeofgraph3==='table'){
            this.tableforgroups3();
          }
          else if (this.typeofgraph3 === 'piechart2') {
            this.comaprePieChartn(3);
          }
          else if (this.typeofgraph3  === 'bargraph1horizontal') {
            console.log('in the table');
            this.HorizontalsingleBarGraphn(3);
          }

          else if (this.typeofgraph3  === 'bargarph2horizontal') {
            console.log('in the table');
            this.Horizontalbargraph3();
          }

        }
        if(this.sectionNo>4){
          this.MajorGraphdata[3] = this.MajorGraphdata[4];
          this.MajorGraphdata[4]={};
          this.section4Name = this.section5Name;
          this.graphinsi[3] = this.graphinsi[4];
          this.graphinsi[4]=[];
          this.typeofgraph4=this.typeofgraph5;
          console.log(this.typeofgraph4)
          if(this.typeofgraph4==='piechart'){
            this.createGraphn(4);
          }
          else if(this.typeofgraph4==='bargraph1'){
            this.singleBarGraphn(4);
          }
          else if(this.typeofgraph4==='bargarph2'){
            this.bargraphn(4);
          }
          else if(this.typeofgraph4==='table'){
            this.tableforgroups4();
          }
          else if (this.typeofgraph4 === 'piechart2') {
            this.comaprePieChartn(4);
          }
          else if (this.typeofgraph4  === 'bargraph1horizontal') {
            console.log('in the table');
            this.HorizontalsingleBarGraphn(4);
          }

          else if (this.typeofgraph4  === 'bargarph2horizontal') {
            console.log('in the table');
            this.Horizontalbargraph4();
          }

        }



        this.sectionNo = this.sectionNo-1;


      }
    }
  }

  graphInsight(value) {
    console.log('in graphinsights');
    console.log(value);
    console.log(this.DefaultInsights)
    this.DefaultInsights='';

    console.log(this.DefaultInsights)
    // @ts-ignore
    document.getElementById('myTextarea').value='';

    const q = this.currentsection -   1;

    if (this.graphinsi[q] !== undefined) {
      this.graphinsi[q].push(value);
      this.dateforInsights[q].push(this.presentDates[0]);
    } else {
      this.graphinsi[q] = [];
      this.graphinsi[q].push(value);
      this.dateforInsights[q]=[];
      this.dateforInsights[q].push(this.presentDates[0]);



    }
    console.log(this.sectionwiseInsight);
    console.log(this.dateforInsights)
    this.sectionwiseInsight = this.graphinsi[q];
    this.sectionwiseInsight.reverse();
    console.log(this.graphinsi);


  }

  userGraphData() {
    //console.log(this.MajorGraphdata[0].length)
    console.log(this.wordcloud)

    if(this.MajorGraphdata[0]===undefined) {
      this.MajorGraphdata[0] = {};
    }
    const modalRef: NgbModalRef = this.ngbModalService.open(GetUserChoiceComponent, {windowClass: 'custom-modal'});
    modalRef.componentInstance.selectedkeywords = this.wordcloud;
    modalRef.componentInstance.newsection_name=this.section1Name

    console.log(this.MajorGraphdata[0])
    if (Object.keys(this.MajorGraphdata[0]).length !== 0) {
      modalRef.componentInstance.groupscompile = this.MajorGraphdata[0];
    }


    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log("after entry in 1")

      console.log(receivedEntry);
      modalRef.close();
      // this.graphdata = receivedEntry;
      this.MajorGraphdata[0] = receivedEntry
      // this.wordcloud=this.sharedservice.keywordsTopics
      this.senddata = true;
      if (this.typeofgraph === 'piechart') {
        this.createGraphn(1);
      } else if (this.typeofgraph === 'piechart2') {
        this.comaprePieChartn(1);
      } else if (this.typeofgraph === 'bargraph1') {
        this.singleBarGraphn(1);
      } else if (this.typeofgraph === 'bargarph2') {
        console.log('in the compare bar graph');
        this.bargraphn(1);
      } else if (this.typeofgraph === 'table') {
        console.log('in the table');
        this.tableforgroups();

      }
      else if (this.typeofgraph  === 'bargraph1horizontal') {
        console.log('in the table');
        this.HorizontalsingleBarGraphn(1);
      }

      else if (this.typeofgraph  === 'bargarph2horizontal') {
        console.log('in the table');
        this.Horizontalbargraph();
      }

      // this.createGraph();
    });

  }
  userGraphData2() {
    if(this.MajorGraphdata[1]===undefined) {
      this.MajorGraphdata[1] = {};
    }

    const modalRef: NgbModalRef = this.ngbModalService.open(GetUserChoiceComponent, {windowClass: 'custom-modal' });
    modalRef.componentInstance.selectedkeywords = this.wordcloud;
    modalRef.componentInstance.newsection_name=this.section2Name

    if (Object.keys(this.MajorGraphdata[1]).length !== 0) {
      modalRef.componentInstance.groupscompile = this.MajorGraphdata[1];
    }


    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log("after entry in 2")

      console.log(receivedEntry);
      modalRef.close();
      this.MajorGraphdata[1] = receivedEntry
      ///this.MajorGraphdata[1]=receivedEntry;
      console.log(Object.keys(this.MajorGraphdata[1]).length);
      // this.wordcloud=this.s/haredservice.keywordsTopics
      this.senddata = true;
      if (this.typeofgraph2 === 'piechart') {
        this.createGraphn(2);
      } else if (this.typeofgraph2 === 'piechart2') {
        this.comaprePieChartn(2);
      } else if (this.typeofgraph2 === 'bargraph1') {
        this.singleBarGraphn(2);
      } else if (this.typeofgraph2 === 'bargarph2') {
        console.log('in the compare bar graph');
        this.bargraphn(2);
      } else if (this.typeofgraph2 === 'table') {
        console.log('in the table');
        this.tableforgroups2();
      }
      else if (this.typeofgraph2  === 'bargraph1horizontal') {
        console.log('in the table');
        this.HorizontalsingleBarGraphn(2);
      }

      else if (this.typeofgraph2  === 'bargarph2horizontal') {
        console.log('in the table');
        this.Horizontalbargraph2();
      }

//      this.singleBarGraph2();
    });
  }
  userGraphData3() {
    if(this.MajorGraphdata[2]===undefined) {
      this.MajorGraphdata[2] = {};
    }
    console.log('in userGraphData3');
    console.log(this.wordcloud);
    const modalRef: NgbModalRef = this.ngbModalService.open(GetUserChoiceComponent, {windowClass: 'custom-modal' });
    modalRef.componentInstance.selectedkeywords = this.wordcloud;
    modalRef.componentInstance.newsection_name=this.section3Name

    if (Object.keys(this.MajorGraphdata[2]).length !== 0) {
      console.log('entry in 3')
      modalRef.componentInstance.groupscompile = this.MajorGraphdata[2];
    }
    console.log(Object.keys(this.MajorGraphdata[2]).length);


    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log("after entry in 3")
      console.log(receivedEntry);9
      modalRef.close();
      this.graphdata3 = receivedEntry;
      console.log(this.graphdata3)
      this.MajorGraphdata[2] = this.graphdata3
      console.log(Object.keys(this.MajorGraphdata[2]).length);
      // this.wordcloud=this.sharedservice.keywordsTopics
      this.senddata = true;
      if (this.typeofgraph3 === 'piechart') {
        this.createGraphn(3);
      } else if (this.typeofgraph3 === 'piechart2') {
        this.comaprePieChartn(3);
      } else if (this.typeofgraph3 === 'bargraph1') {
        this.singleBarGraphn(3);
      } else if (this.typeofgraph3 === 'bargarph2') {
        console.log('in the compare bar graph');
        this.bargraphn(3);
      } else if (this.typeofgraph3 === 'table') {
        console.log('in the table');
        this.tableforgroups3();
      }
      else if (this.typeofgraph3  === 'bargraph1horizontal') {
        console.log('in the table');
        this.HorizontalsingleBarGraphn(3);
      }

      else if (this.typeofgraph3  === 'bargarph2horizontal') {
        console.log('in the table');
        this.Horizontalbargraph3();
      }

    });
  }
  userGraphData4() {
    if(this.MajorGraphdata[3]===undefined) {
      this.MajorGraphdata[3] = {};
    }
    console.log('in userGraphData4');
    console.log(this.wordcloud);
    const modalRef: NgbModalRef = this.ngbModalService.open(GetUserChoiceComponent, {windowClass: 'custom-modal' });
    modalRef.componentInstance.selectedkeywords = this.wordcloud;
    modalRef.componentInstance.newsection_name=this.section4Name

    if (Object.keys(this.MajorGraphdata[3]).length !== 0) {
      console.log('entry in 3')
      modalRef.componentInstance.groupscompile = this.MajorGraphdata[3];
    }
    console.log(Object.keys(this.MajorGraphdata[3]).length);


    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log("after entry in 3")
      console.log(receivedEntry);
      modalRef.close();
      this.MajorGraphdata[3] = receivedEntry;
      console.log(Object.keys(this.MajorGraphdata[3]).length);
      // this.wordcloud=this.sharedservice.keywordsTopics
      this.senddata = true;
      if (this.typeofgraph4 === 'piechart') {
        this.createGraphn(4);
      } else if (this.typeofgraph4 === 'piechart2') {
        this.comaprePieChartn(4);
      } else if (this.typeofgraph4 === 'bargraph1') {
        this.singleBarGraphn(4);
      } else if (this.typeofgraph4 === 'bargarph2') {
        console.log('in the compare bar graph');
        this.bargraphn(4);
      } else if (this.typeofgraph4 === 'table') {
        console.log('in the table');
        this.tableforgroups4();
      }
      else if (this.typeofgraph4  === 'bargraph1horizontal') {
        console.log('in the table');
        this.HorizontalsingleBarGraphn(4);
      }

      else if (this.typeofgraph4  === 'bargarph2horizontal') {
        console.log('in the table');
        this.Horizontalbargraph4();
      }

    });
  }
  userGraphData5() {
    if(this.MajorGraphdata[4]===undefined) {
      this.MajorGraphdata[4] = {};
    }
    console.log('in userGraphData5');
    console.log(this.wordcloud);
    const modalRef: NgbModalRef = this.ngbModalService.open(GetUserChoiceComponent, {windowClass: 'custom-modal' });
    modalRef.componentInstance.selectedkeywords = this.wordcloud;
    modalRef.componentInstance.newsection_name=this.section5Name

    if (Object.keys(this.MajorGraphdata[4]).length !== 0) {
      console.log('entry in 5')
      modalRef.componentInstance.groupscompile = this.MajorGraphdata[4];
    }
    console.log(Object.keys(this.MajorGraphdata[4]).length);


    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log("after entry in 5")
      console.log(receivedEntry);
      modalRef.close();
      this.MajorGraphdata[4] = receivedEntry;
      console.log(Object.keys(this.MajorGraphdata[4]).length);
      // this.wordcloud=this.sharedservice.keywordsTopics
      this.senddata = true;
      if (this.typeofgraph5 === 'piechart') {
        this.createGraphn(5);
      } else if (this.typeofgraph5 === 'piechart2') {
        this.comaprePieChartn(5);
      } else if (this.typeofgraph5 === 'bargraph1') {
        this.singleBarGraphn(5);
      } else if (this.typeofgraph5 === 'bargarph2') {
        console.log('in the compare bar graph');
        this.bargraphn(5);
      } else if (this.typeofgraph5 === 'table') {
        console.log('in the table');
        this.tableforgroups5();
      }
      else if (this.typeofgraph5  === 'bargraph1horizontal') {
        console.log('in the table');
        this.HorizontalsingleBarGraphn(5);
      }

      else if (this.typeofgraph5  === 'bargarph2horizontal') {
        console.log('in the table');
        this.Horizontalbargraph5();
      }

    });
  }

  createGraphn(section) {
    console.log(section)

   if(section===1) {
      this.selectgrp = false
    }
   else if(section===2) {
     this.selectgrp2 = false
   }
   else if(section===3) {
     this.selectgrp3 = false
   }
   else if(section===4) {
     this.selectgrp4 = false
   }
   else if(section===5) {
     this.selectgrp5 = false
   }

    this.table = 0;
    const  group1 = [];
    const group2 = [];
    const group3 = [];
    const group4 = [];
    const group5 = [];
    const group6 = [];
    const group7 = [];
    const group8 = [];
    let sectionVaribale=section-1


    group1.push(this.MajorGraphdata[sectionVaribale][0].name);
    group2.push(this.MajorGraphdata[sectionVaribale][1].name);
    group3.push(this.MajorGraphdata[sectionVaribale][2].name);
    group4.push(this.MajorGraphdata[sectionVaribale][3].name);
    group5.push(this.MajorGraphdata[sectionVaribale][4].name);
    group6.push(this.MajorGraphdata[sectionVaribale][5].name);
    group7.push(this.MajorGraphdata[sectionVaribale][6].name);
    group8.push(this.MajorGraphdata[sectionVaribale][7].name);


    let grp1count = 0;
    let grp2count = 0;
    let grp3count = 0;
    let grp4count = 0;
    let grp5count = 0;
    let grp6count = 0;
    let grp7count = 0;
    let grp8count = 0;

    for (const keys in this.wordcloud) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][0].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][0].data[i]) {
          grp1count = this.wordcloud[keys] + grp1count;
        }

      }

    }

    for (const keys in this.wordcloud) {
      //   console.log(keys);
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][1].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][1].data[i]) {
          grp2count = this.wordcloud[keys] + grp2count;
        }

      }

    }
    for (const keys in this.wordcloud) {
      //   console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][2].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][2].data[i]) {
          grp3count = this.wordcloud[keys] + grp3count;
        }

      }

    }
    for (const keys in this.wordcloud) {
      //  console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][3].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][3].data[i]) {
          grp4count = this.wordcloud[keys] + grp4count;
        }

      }

    }
    for (const keys in this.wordcloud) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][4].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][4].data[i]) {
          grp5count = this.wordcloud[keys] + grp5count;
        }

      }

    }
    for (const keys in this.wordcloud) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][5].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][5].data[i]) {
          grp6count = this.wordcloud[keys] + grp6count;
        }

      }

    }

    for (const keys in this.wordcloud) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][6].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][6].data[i]) {
          grp7count = this.wordcloud[keys] + grp7count;
        }

      }

    }
    for (const keys in this.wordcloud) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][7].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][7].data[i]) {
          grp8count = this.wordcloud[keys] + grp8count;
        }

      }

    }


    group1.push(grp1count);
    group2.push(grp2count);
    group3.push(grp3count);
    group4.push(grp4count);
    group5.push(grp5count);
    group6.push(grp6count);
    group7.push(grp7count);

    group8.push(grp8count);


    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    const tempgroup1 = [];

    tempgroup1.push(group1);
    tempgroup1.push(group2);
    tempgroup1.push(group3);
    tempgroup1.push(group4);
    tempgroup1.push(group5);
    tempgroup1.push(group6);
    tempgroup1.push(group7);

    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[sectionVaribale].length;i++){
      console.log(this.MajorGraphdata[sectionVaribale][i].data.length)
      if(this.MajorGraphdata[sectionVaribale][i].data.length!==0){
        console.log("inside major grp")
        temparrgroup.push(this.MajorGraphdata[sectionVaribale][i].name)

        lenval=lenval+1;
      }
    }
    let seriesdata=[];
    for (let i=0;i<tempgroup1.length;i++){
      if(tempgroup1[i][1]!==0) {
        seriesdata.push({value: tempgroup1[i][1], name: tempgroup1[i][0]})
      }
    }
    console.log(this.MajorGraphdata[sectionVaribale])
    console.log(temparrgroup)

    console.log(seriesdata);
    let colorpallet= ["#c7683a", "#3D9970", "#d69999", "#1d9090", "#2c5c6f", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE"]


    this.pieChartOptions[sectionVaribale]={
      tooltip : {
        trigger: 'item',
        formatter: "<br/>{b} : {c} ({d}%)"
      },
      legend: {
        // type: 'scroll',
        orient: 'vertical',
        right: 25,
        top: 140,
        bottom: 20,
        data:temparrgroup
      },
      series: [
        {
          type: 'pie',
          radius: '55%',
          center: ['40%', '50%'],
          data:seriesdata,
          color:colorpallet,

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',

            }
          }
        }
      ]
    }



if(sectionVaribale===0) {
  // this.typeofgraph="piechart";
  this.typeofgraph = 'piechart';

  this.MajorGraphtype[0] = 'piechart'
}
 else if(sectionVaribale===1) {
      // this.typeofgraph="piechart";
      this.typeofgraph2 = 'piechart';

      this.MajorGraphtype[1] = 'piechart'
    }

else if(sectionVaribale===2) {
  // this.typeofgraph="piechart";
  this.typeofgraph3 = 'piechart';

  this.MajorGraphtype[2] = 'piechart'
}

else if(sectionVaribale===3) {
  // this.typeofgraph="piechart";
  this.typeofgraph4 = 'piechart';

  this.MajorGraphtype[3] = 'piechart'
}

else if(sectionVaribale===4) {
  // this.typeofgraph="piechart";
  this.typeofgraph5 = 'piechart';

  this.MajorGraphtype[4] = 'piechart'
}

    this.showGraph = true;



  }

  tableforgroups() {
    this.selectgrp = false
    this.tabgrp1 = {};
    this.tabgrp2 = {};
    this.tabgrp3 = {};
    this.tabgrp4 = {};
    this.tabgrp5 = {};
    this.tabgrp6 = {};
    this.tabgrp7 = {};

    const grp1 = {}; const grp2 = {};
    const grp3 = {};
    const grp4 = {};
    const grp5 = {};
    const grp6 = {};
    const grp7 = {};
    const grp8 = {};
    setTimeout(() => {
      console.log(d3.select('#chart'));
      d3.select('#chart3').style('display', 'none');
      d3.select('#chart2').style('display', 'none');
      d3.select('#chart4').style('display', 'none');
      d3.select('#chart5').style('display', 'none');
      d3.select('#chart').style('display', 'none');

    }, 300);
    console.log(this.MajorGraphdata[0]);
    grp1[this.MajorGraphdata[0][0].name] = this.MajorGraphdata[0][0].data;
    grp2[this.MajorGraphdata[0][1].name] = this.MajorGraphdata[0][1].data;
    grp3[this.MajorGraphdata[0][2].name] = this.MajorGraphdata[0][2].data;
    grp4[this.MajorGraphdata[0][3].name] = this.MajorGraphdata[0][3].data;
    grp5[this.MajorGraphdata[0][4].name] = this.MajorGraphdata[0][4].data;
    grp6[this.MajorGraphdata[0][5].name] = this.MajorGraphdata[0][5].data;
    grp7[this.MajorGraphdata[0][6].name] = this.MajorGraphdata[0][6].data;
    grp8[this.MajorGraphdata[0][7].name] = this.MajorGraphdata[0][7].data;
    grp1['NAME']=this.MajorGraphdata[0][0].name;
    grp2['NAME']=this.MajorGraphdata[0][1].name;
    grp3['NAME']=this.MajorGraphdata[0][2].name;
    grp4['NAME']=this.MajorGraphdata[0][3].name;
    grp5['NAME']=this.MajorGraphdata[0][4].name;
    grp6['NAME']=this.MajorGraphdata[0][5].name;
    grp7['NAME']=this.MajorGraphdata[0][6].name;
    grp8['NAME']=this.MajorGraphdata[0][7].name;
    console.log(grp2);
    console.log(this.similarwords);
    grp1['Dexcom']=[];
    grp2['Dexcom']=[];
    grp3['Dexcom']=[];
    grp4['Dexcom']=[];
    grp5['Dexcom']=[];
    grp6['Dexcom']=[];
    grp7['Dexcom']=[];
    grp1['ABBOTT']=[];
    grp2['ABBOTT']=[];
    grp3['ABBOTT']=[];
    grp4['ABBOTT']=[];
    grp5['ABBOTT']=[];
    grp6['ABBOTT']=[];
    grp7['ABBOTT']=[];
    grp1['ROCHE']=[];
    grp2['ROCHE']=[];
    grp3['ROCHE']=[];
    grp4['ROCHE']=[];
    grp5['ROCHE']=[];
    grp6['ROCHE']=[];
    grp7['ROCHE']=[];

    grp1['DexcomFreq']=0;
    grp2['DexcomFreq']=0;
    grp3['DexcomFreq']=0;
    grp4['DexcomFreq']=0;
    grp5['DexcomFreq']=0;
    grp6['DexcomFreq']=0;
    grp7['DexcomFreq']=0;
    grp1['ABBOTTFreq']=0;
    grp2['ABBOTTFreq']=0;
    grp3['ABBOTTFreq']=0;
    grp4['ABBOTTFreq']=0;
    grp5['ABBOTTFreq']=0;
    grp6['ABBOTTFreq']=0;
    grp7['ABBOTTFreq']=0;
    grp1['ROCHEFreq']=0;
    grp2['ROCHEFreq']=0;
    grp3['ROCHEFreq']=0;
    grp4['ROCHEFreq']=0;
    grp5['ROCHEFreq']=0;
    grp6['ROCHEFreq']=0;
    grp7['ROCHEFreq']=0;



    for(let keys in this.similarwords[0]){
      for( let i=0;i<grp1[this.MajorGraphdata[0][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[0][0].name][i]){


          grp1['Dexcom'].push(keys);
          grp1['DexcomFreq']=grp1['DexcomFreq']+this.similarwords[0][keys];
        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[0][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[0][1].name][i]){

          grp2['Dexcom'].push(keys);
          grp2['DexcomFreq']=grp2['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[0][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[0][2].name][i]){

          grp3['Dexcom'].push(keys);
          grp3['DexcomFreq']=grp3['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[0][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[0][3].name][i]){

          grp4['Dexcom'].push(keys);
          grp4['DexcomFreq']=grp4['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[0][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[0][4].name][i]){

          grp5['Dexcom'].push(keys);
          grp5['DexcomFreq']=grp5['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[0][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[0][5].name][i]){

          grp6['Dexcom'].push(keys);
          grp6['DexcomFreq']=grp6['DexcomFreq']+this.similarwords[0][keys];

        }
      }

    }

    for(let keys in this.similarwords[1]){
      for( let i=0;i<grp1[this.MajorGraphdata[0][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[0][0].name][i]){
          grp1['ABBOTT'].push(keys);
          grp1['ABBOTTFreq']=grp1['ABBOTTFreq']+this.similarwords[1][keys];

        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[0][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[0][1].name][i]){
          grp2['ABBOTT'].push(keys);
          grp2['ABBOTTFreq']=grp2['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[0][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[0][2].name][i]){
          grp3['ABBOTT'].push(keys);
          grp3['ABBOTTFreq']=grp3['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[0][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[0][3].name][i]){
          grp4['ABBOTT'].push(keys);
          grp4['ABBOTTFreq']=grp4['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[0][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[0][4].name][i]){
          grp5['ABBOTT'].push(keys);
          grp5['ABBOTTFreq']=grp5['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[0][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[0][5].name][i]){
          grp6['ABBOTT'].push(keys);
          grp6['ABBOTTFreq']=grp6['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }

    }
    for(let keys in this.similarwords[2]){
      for( let i=0;i<grp1[this.MajorGraphdata[0][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[0][0].name][i]){
          grp1['ROCHE'].push(keys);
          grp1['ROCHEFreq']=grp1['ROCHEFreq']+this.similarwords[2][keys];

        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[0][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[0][1].name][i]){
          grp2['ROCHE'].push(keys);
          grp2['ROCHEFreq']=grp2['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[0][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[0][2].name][i]){
          grp3['ROCHE'].push(keys);
          grp3['ROCHEFreq']=grp3['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[0][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[0][3].name][i]){
          grp4['ROCHE'].push(keys);
          grp4['ROCHEFreq']=grp4['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[0][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[0][4].name][i]){
          grp5['ROCHE'].push(keys);
          grp5['ROCHEFreq']=grp5['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[0][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[0][5].name][i]){
          grp6['ROCHE'].push(keys);
          grp6['ROCHEFreq']=grp6['ROCHEFreq']+this.similarwords[2][keys];

        }
      }

    }
    console.log(grp1);
    console.log(grp2);
    console.log(grp3);
    console.log(grp4);
    console.log(grp5);
    console.log(grp6);
    let lenval=0;
    let temparrgroup=[];
    for(let k=0;k<this.MajorGraphdata[0].length;k++){
      if(this.MajorGraphdata[0][k].data.length!==0){
        if(k===0){
          this.tabgrp1 = grp1;

        }
        else if(k===1){
          this.tabgrp2 = grp2;

        }
        else if(k===2){
          this.tabgrp3 = grp3;

        }
        else if(k===3){
          this.tabgrp4 = grp4;

        }
        else if(k===4){
          this.tabgrp5 = grp5;

        }
        else if(k===5){
          this.tabgrp6 = grp6;

        } else if(k===6){
          this.tabgrp7 = grp7;

        }


      }
    }
    console.log(this.tabgrp2)
    console.log(this.table);

    this.typeofgraph = 'table';
    this.MajorGraphtype[0]='table';


  }

  singleBarGraphn(section) {

    console.log(section)

    if(section===1) {
      this.selectgrp = false
    }
    else if(section===2) {
      this.selectgrp2 = false
    }
    else if(section===3) {
      this.selectgrp3 = false
    }
    else if(section===4) {
      this.selectgrp4 = false
    }
    else if(section===5) {
      this.selectgrp5 = false
    }

    this.table = 0;
    let sectionVaribale=section-1


    const  Doublebar = [];
    let grp1count = 0;
    let grp2count = 0;
    let grp3count = 0;
    let grp4count = 0;
    let grp5count = 0;
    let grp6count = 0;
    let grp7count = 0;
    let grp8count = 0;

    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][0].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][0].data[i]) {
          grp1count = this.wordcloud[keys] + grp1count;
        }

      }

    }

    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][1].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][1].data[i]) {
          grp2count = this.wordcloud[keys] + grp2count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][2].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][2].data[i]) {
          grp3count = this.wordcloud[keys] + grp3count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][3].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][3].data[i]) {
          grp4count = this.wordcloud[keys] + grp4count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][4].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][4].data[i]) {
          grp5count = this.wordcloud[keys] + grp5count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][5].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][5].data[i]) {
          grp6count = this.wordcloud[keys] + grp6count;
        }

      }

    }

    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][6].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][6].data[i]) {
          grp7count = this.wordcloud[keys] + grp7count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][7].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][7].data[i]) {
          grp8count = this.wordcloud[keys] + grp8count;
        }

      }

    }

  //  Doublebar.push('Groups');
    Doublebar.push(grp1count);
    Doublebar.push(grp2count);
    Doublebar.push(grp3count);
    Doublebar.push(grp4count);
    Doublebar.push(grp5count);
    Doublebar.push(grp6count);
    Doublebar.push(grp7count);

    const tempbar = [];



    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[sectionVaribale].length;i++){
      console.log(this.MajorGraphdata[sectionVaribale][i].data.length)
      if(this.MajorGraphdata[sectionVaribale][i].data.length!==0){
        console.log("inside major grp")
        temparrgroup.push(this.MajorGraphdata[sectionVaribale][i].name)
        console.log(temparrgroup)

        lenval=lenval+1;
      }
    }
    //
    // this.pieChartLabels[0] = temparrgroup;
    // console.log(this.pieChartLabels[0]);
    tempbar.push(Doublebar);
    console.log(tempbar[0]);
    // @ts-ignore
    const color = this.sharedservice.siteColor;


     this.barChartOptions[sectionVaribale] = {
      title: {
        text: ''
      },
      tooltip: {},
      legend: {
        data:['Charts']
      },
      xAxis: {
        data: temparrgroup
      },
      yAxis: {},
      series: [{
        itemStyle: {normal: {color: '#b1c1e4'}},
        type: 'bar',
        data: tempbar[0],
        // color: this.color;
      }]
    };
 if(sectionVaribale===0) {
   this.typeofgraph = 'bargraph1';
   this.MajorGraphtype[0] = 'bargraph1'
 }
 else if(sectionVaribale===1) {
   this.typeofgraph2 = 'bargraph1';
   this.MajorGraphtype[1] = 'bargraph1'
 }
 else if(sectionVaribale===2) {
   this.typeofgraph3 = 'bargraph1';
   this.MajorGraphtype[2] = 'bargraph1'
 }
 else if(sectionVaribale===3) {
   this.typeofgraph4 = 'bargraph1';
   this.MajorGraphtype[3] = 'bargraph1'
 }
 else if(sectionVaribale===4) {
   this.typeofgraph5 = 'bargraph1';
   this.MajorGraphtype[4] = 'bargraph1'
 }


  }

  HorizontalsingleBarGraphn(section) {

    console.log(section)

    if(section===1) {
      this.selectgrp = false
    }
    else if(section===2) {
      this.selectgrp2 = false
    }
    else if(section===3) {
      this.selectgrp3 = false
    }
    else if(section===4) {
      this.selectgrp4 = false
    }
    else if(section===5) {
      this.selectgrp5 = false
    }
    let sectionVaribale=section-1

    this.table = 0;

    const  Doublebar = [];
    let grp1count = 0;
    let grp2count = 0;
    let grp3count = 0;
    let grp4count = 0;
    let grp5count = 0;
    let grp6count = 0;
    let grp7count = 0;
    let grp8count = 0;

    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][0].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][0].data[i]) {
          grp1count = this.wordcloud[keys] + grp1count;
        }

      }

    }

    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][1].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][1].data[i]) {
          grp2count = this.wordcloud[keys] + grp2count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][2].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][2].data[i]) {
          grp3count = this.wordcloud[keys] + grp3count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][3].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][3].data[i]) {
          grp4count = this.wordcloud[keys] + grp4count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][4].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][4].data[i]) {
          grp5count = this.wordcloud[keys] + grp5count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][5].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][5].data[i]) {
          grp6count = this.wordcloud[keys] + grp6count;
        }

      }

    }

    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][6].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][6].data[i]) {
          grp7count = this.wordcloud[keys] + grp7count;
        }

      }

    }
    for (const keys in this.wordcloud) {

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][7].data.length; i++) {
        if (keys === this.MajorGraphdata[sectionVaribale][7].data[i]) {
          grp8count = this.wordcloud[keys] + grp8count;
        }

      }

    }

    //  Doublebar.push('Groups');
    Doublebar.push(grp1count);
    Doublebar.push(grp2count);
    Doublebar.push(grp3count);
    Doublebar.push(grp4count);
    Doublebar.push(grp5count);
    Doublebar.push(grp6count);
    Doublebar.push(grp7count);

    const tempbar = [];



    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[sectionVaribale].length;i++){
      console.log(this.MajorGraphdata[sectionVaribale][i].data.length)
      if(this.MajorGraphdata[sectionVaribale][i].data.length!==0){
        console.log("inside major grp")
        temparrgroup.push(this.MajorGraphdata[sectionVaribale][i].name)
        console.log(temparrgroup)

        lenval=lenval+1;
      }
    }
    //
    // this.pieChartLabels[0] = temparrgroup;
    // console.log(this.pieChartLabels[0]);
    tempbar.push(Doublebar);
    console.log(tempbar[0]);
    // @ts-ignore
    const color = this.sharedservice.siteColor;


    this.barHorizontalChartOptions[sectionVaribale] = {
      title: {
        text: ''
      },
      tooltip: {},
      legend: {
        data:['Charts']
      },
      xAxis: {},
      yAxis: {
        data: temparrgroup

      },
      series: [{
        itemStyle: {normal: {color: '#b1c1e4'}},

        type: 'bar',
        data: tempbar[0],
        // color: this.color;
      }]
    };
    console.log('in horizontal bar grah')



    if(sectionVaribale===0) {
      this.typeofgraph = 'bargraph1horizontal';
      this.MajorGraphtype[0] = 'bargraph1horizontal'
    }
    else if(sectionVaribale===1) {
      this.typeofgraph2 = 'bargraph1horizontal';
      this.MajorGraphtype[1] = 'bargraph1horizontal'
    }
    else if(sectionVaribale===2) {
      this.typeofgraph3 = 'bargraph1horizontal';
      this.MajorGraphtype[2] = 'bargraph1horizontal'
    }
    else if(sectionVaribale===3) {
      this.typeofgraph4 = 'bargraph1horizontal';
      this.MajorGraphtype[3] = 'bargraph1horizontal'
    }
    else if(sectionVaribale===4) {
      this.typeofgraph5 = 'bargraph1horizontal';
      this.MajorGraphtype[4] = 'bargraph1horizontal'
    }






  }

  comaprePieChartn(section) {
    if(section===1) {
      this.selectgrp = false
    }
    else if(section===2) {
      this.selectgrp2 = false
    }
    else if(section===3) {
      this.selectgrp3 = false
    }
    else if(section===4) {
      this.selectgrp4 = false
    }
    else if(section===5) {
      this.selectgrp5 = false
    }

    this.table = 0;
    let sectionVaribale=section-1


    console.log('in compare pie chart');

    const abbot = [];
    const dexcom = [];
    const roche = [];
    const metronic = [];


    let grop1 = 0;
    let grop2 = 0;
    let grop3 = 0;
    let grop4 = 0;
    let grop5 = 0;
    let grop6 = 0;
    let grop7 = 0;

    let Group1 = [];
    let Group2 = [];
    let Group3 = [];
    let Group4 = [];
    let Group5 = [];
    let Group6 = [];
    let Group7 = [];

    let Group8 = [];



    for (const keys in this.similarwords[0]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][0].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[0][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][1].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][2].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][3].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][4].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[0][keys];
        }

      }for (let i = 0; i < this.MajorGraphdata[sectionVaribale][5].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][6].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[0][keys];
        }

      }

    }
    Group1.push(this.MajorGraphdata[sectionVaribale][0].name);
    Group1.push(grop1);
    Group2.push(this.MajorGraphdata[sectionVaribale][1].name);
    Group2.push(grop2);
    Group3.push(this.MajorGraphdata[sectionVaribale][2].name);
    Group3.push(grop3);
    Group4.push(this.MajorGraphdata[sectionVaribale][3].name);
    Group4.push(grop4);
    Group5.push(this.MajorGraphdata[sectionVaribale][4].name);
    Group5.push(grop5);
    Group6.push(this.MajorGraphdata[sectionVaribale][5].name);
    Group6.push(grop6);
    Group7.push(this.MajorGraphdata[sectionVaribale][6].name);
    Group7.push(grop7);


    dexcom.push(Group1);
    dexcom.push(Group2);
    dexcom.push(Group3);
    dexcom.push(Group4);
    dexcom.push(Group5);
    dexcom.push(Group6);
    dexcom.push(Group7);



    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;

    Group1 = [];
    Group2 = [];
    Group3 = [];
    Group4 = [];
    Group5 = [];
    Group6 = [];
    Group7 = [];
    Group8 = [];

    for (const keys in this.similarwords[1]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][0].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[1][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][1].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][2].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][3].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][4].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][5].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][6].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[1][keys];
        }

      }
    }
    Group1.push(this.MajorGraphdata[sectionVaribale][0].name);
    Group1.push(grop1);
    Group2.push(this.MajorGraphdata[sectionVaribale][1].name);
    Group2.push(grop2);
    Group3.push(this.MajorGraphdata[sectionVaribale][2].name);
    Group3.push(grop3);
    Group4.push(this.MajorGraphdata[sectionVaribale][3].name);
    Group4.push(grop4);
    Group5.push(this.MajorGraphdata[sectionVaribale][4].name);
    Group5.push(grop5);
    Group6.push(this.MajorGraphdata[sectionVaribale][5].name);
    Group6.push(grop6);
    Group7.push(this.MajorGraphdata[sectionVaribale][6].name);
    Group7.push(grop7);

    abbot.push(Group1);
    abbot.push(Group2);
    abbot.push(Group3);
    abbot.push(Group4);
    abbot.push(Group5);
    abbot.push(Group6);
    abbot.push(Group7);

    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[sectionVaribale].length;i++){
      console.log(this.MajorGraphdata[sectionVaribale][i].data.length)
      if(this.MajorGraphdata[sectionVaribale][i].data.length!==0){
        console.log("inside major grp")
        temparrgroup.push(this.MajorGraphdata[sectionVaribale][i].name)
        console.log(temparrgroup)

        lenval=lenval+1;
      }
    }

    let seriesdata=[];
    for (let i=0;i<lenval;i++){


      seriesdata.push({value: dexcom[i][1], name: dexcom[i][0]})


    }
    let colorpallet= ["#c7683a", "#3D9970", "#d69999", "#1d9090", "#2c5c6f", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE"]


    this.doublepieChartOptions1[sectionVaribale]={
      tooltip : {
        trigger: 'item',
        formatter: "<br/>{b} : {c} ({d}%)"
      },
      title: {
        text: 'Dexcom',
        bottom: 0,
        right:230
      },
      legend: {
        // type: 'scroll',
        orient: 'horizontal',
        right: 100,
        top: 0,
        // bottom: 20,
        data:temparrgroup
      },
      series: [
        {
          type: 'pie',
          radius: '45%',
          center: ['40%', '50%'],
          data:seriesdata,
          color:colorpallet,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',

            }
          }
        }
      ]
    }

    seriesdata=[];
    for (let i=0;i<lenval;i++){


      seriesdata.push({value: abbot[i][1], name: abbot[i][0]})

    }

    this.doublepieChartOptions2[sectionVaribale]={
      tooltip : {
        trigger: 'item',
        formatter: "<br/>{b} : {c} ({d}%)"
      },
      title: {
        text: 'Abbott',
        bottom: 0,
        right:230
      },
      legend: {
        // type: 'scroll',
        orient: 'horizontal',
        right: 100,
        top: 0,
        data:temparrgroup
      },
      series: [
        {
          type: 'pie',
          radius: '45%',
          center: ['40%', '50%'],
          data:seriesdata,
          color:colorpallet,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',

            }
          }
        }
      ]
    }



    if(sectionVaribale===0) {
      this.typeofgraph = 'piechart2';
      this.MajorGraphtype[0] = 'piechart2'
    }
    else if(sectionVaribale===1) {
      this.typeofgraph2 = 'piechart2';
      this.MajorGraphtype[1] = 'piechart2'
    }
    else if(sectionVaribale===2) {
      this.typeofgraph3 = 'piechart2';
      this.MajorGraphtype[2] = 'piechart2'
    }
    else if(sectionVaribale===3) {
      this.typeofgraph4 = 'piechart2';
      this.MajorGraphtype[3] = 'piechart2'
    }
    else if(sectionVaribale===4) {
      this.typeofgraph5 = 'piechart2';
      this.MajorGraphtype[4] = 'piechart2'
    }

    this.comparepie = 1;



  }

  bargraphn(section) {
    if(section===1) {
      this.selectgrp = false
    }
    else if(section===2) {
      this.selectgrp2 = false
    }
    else if(section===3) {
      this.selectgrp3 = false
    }
    else if(section===4) {
      this.selectgrp4 = false
    }
    else if(section===5) {
      this.selectgrp5 = false
    }

    this.table = 0;
    let sectionVaribale=section-1

    console.log(this.similarwords);
    const abbot = [];
    const dexcom = [];
    const roche = [];
    const metronic = [];

    let grop1 = 0;
    let grop2 = 0;
    let grop3 = 0;
    let grop4 = 0;
    let grop5 = 0;
    let grop6 = 0;
    let grop7 = 0;



    for (const keys in this.similarwords[0]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][0].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[0][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][1].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][2].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][3].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][4].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][5].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][6].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[0][keys];
        }

      }

    }

    dexcom.push(grop1);
    dexcom.push(grop2);
    dexcom.push(grop3);
    dexcom.push(grop4);
    dexcom.push(grop5);
    dexcom.push(grop6);
    dexcom.push(grop7);


    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;
    for (const keys in this.similarwords[1]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][0].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[1][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][1].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][2].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][3].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][4].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[1][keys];
        }

      }for (let i = 0; i < this.MajorGraphdata[sectionVaribale][5].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][6].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[1][keys];
        }

      }


    }


    abbot.push(grop1);
    abbot.push(grop2);
    abbot.push(grop3);
    abbot.push(grop4);
    abbot.push(grop5);
    abbot.push(grop6);
    abbot.push(grop7);




    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;


    for (const keys in this.similarwords[2]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][0].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[2][keys];

        }

      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][1].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][2].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][3].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][4].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][5].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[2][keys];
        }
      }

      for (let i = 0; i < this.MajorGraphdata[sectionVaribale][6].data.length; i++) {
        if (this.MajorGraphdata[sectionVaribale][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[2][keys];
        }
      }

    }

    roche.push(grop1);
    roche.push(grop2);
    roche.push(grop3);
    roche.push(grop4);
    roche.push(grop5);
    roche.push(grop6);
    roche.push(grop7);

    const tempbar2 = [];
    tempbar2.push(dexcom);
    tempbar2.push(abbot);
    tempbar2.push(roche);
    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[sectionVaribale].length;i++){
      if(this.MajorGraphdata[sectionVaribale][i].data.length!==0){
        temparrgroup.push(this.MajorGraphdata[sectionVaribale][i].name)

        console.log(this.MajorGraphdata[sectionVaribale][i].data)

        lenval=lenval+1;
      }
    }


    let sourcearray=[];
    let temparr2=[];
    sourcearray.push(['product', 'Abbott', 'Dexcom', 'Roche']);
    for(let k=0;k<lenval;k++){
      temparr2=[];
      temparr2.push(temparrgroup[k])
      temparr2.push(tempbar2[1][k])//for abbott
      temparr2.push(tempbar2[0][k])//for dexcom
      temparr2.push(tempbar2[2][k])//for roche
      sourcearray.push(temparr2);

    }
    console.log(sourcearray);

    this.doublebarChartOptions[sectionVaribale] = {
      legend: {},
      tooltip: {},
      dataset: {
        source: sourcearray
      },
      xAxis: {type: 'category'},
      yAxis: {},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        {type: 'bar', color:'#42a6d3'},
        {type: 'bar', color:'#68a94d'},
        {type: 'bar', color:'#f1bc3e'}
      ]
    };

    if(sectionVaribale===0) {
      this.typeofgraph = 'bargarph2';
      this.MajorGraphtype[0] = 'bargarph2'
    }
    else if(sectionVaribale===1) {
      this.typeofgraph2 = 'bargarph2';
      this.MajorGraphtype[1] = 'bargarph2'
    }
    else if(sectionVaribale===2) {
      this.typeofgraph3 = 'bargarph2';
      this.MajorGraphtype[2] = 'bargarph2'
    }
    else if(sectionVaribale===3) {
      this.typeofgraph4 = 'bargarph2';
      this.MajorGraphtype[3] = 'bargarph2'
    }
    else if(sectionVaribale===4) {
      this.typeofgraph5 = 'bargarph2';
      this.MajorGraphtype[4] = 'bargarph2'
    }

  }

  Horizontalbargraph() {
    console.log("in horzontal bargraph")
    this.selectgrp = false

    this.table = 0;
    console.log(this.similarwords);
    const abbot = [];
    const dexcom = [];
    const roche = [];
    const metronic = [];
    console.log(this.MajorGraphdata[0][0]);

    let grop1 = 0;
    let grop2 = 0;
    let grop3 = 0;
    let grop4 = 0;
    let grop5 = 0;
    let grop6 = 0;
    let grop7 = 0;



    for (const keys in this.similarwords[0]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[0][0].data.length; i++) {
        if (this.MajorGraphdata[0][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[0][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][1].data.length; i++) {
        if (this.MajorGraphdata[0][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][2].data.length; i++) {
        if (this.MajorGraphdata[0][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][3].data.length; i++) {
        if (this.MajorGraphdata[0][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][4].data.length; i++) {
        if (this.MajorGraphdata[0][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][5].data.length; i++) {
        if (this.MajorGraphdata[0][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][6].data.length; i++) {
        if (this.MajorGraphdata[0][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[0][keys];
        }

      }

    }

    dexcom.push(grop1);
    dexcom.push(grop2);
    dexcom.push(grop3);
    dexcom.push(grop4);
    dexcom.push(grop5);
    dexcom.push(grop6);
    dexcom.push(grop7);


    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;
    for (const keys in this.similarwords[1]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[0][0].data.length; i++) {
        if (this.MajorGraphdata[0][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[1][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][1].data.length; i++) {
        if (this.MajorGraphdata[0][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][2].data.length; i++) {
        if (this.MajorGraphdata[0][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][3].data.length; i++) {
        if (this.MajorGraphdata[0][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][4].data.length; i++) {
        if (this.MajorGraphdata[0][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[1][keys];
        }

      }for (let i = 0; i < this.MajorGraphdata[0][5].data.length; i++) {
        if (this.MajorGraphdata[0][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][6].data.length; i++) {
        if (this.MajorGraphdata[0][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[1][keys];
        }

      }

    }


    abbot.push(grop1);
    abbot.push(grop2);
    abbot.push(grop3);
    abbot.push(grop4);
    abbot.push(grop5);
    abbot.push(grop6);
    abbot.push(grop7);




    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;


    for (const keys in this.similarwords[2]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[0][0].data.length; i++) {
        if (this.MajorGraphdata[0][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[2][keys];

        }

      }
      for (let i = 0; i < this.MajorGraphdata[0][1].data.length; i++) {
        if (this.MajorGraphdata[0][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[0][2].data.length; i++) {
        if (this.MajorGraphdata[0][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[0][3].data.length; i++) {
        if (this.MajorGraphdata[0][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[0][4].data.length; i++) {
        if (this.MajorGraphdata[0][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[0][5].data.length; i++) {
        if (this.MajorGraphdata[0][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[0][6].data.length; i++) {
        if (this.MajorGraphdata[0][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[2][keys];
        }
      }


    }

    roche.push(grop1);
    roche.push(grop2);
    roche.push(grop3);
    roche.push(grop4);
    roche.push(grop5);
    roche.push(grop6);
    roche.push(grop7);

    const tempbar2 = [];
    tempbar2.push(dexcom);
    tempbar2.push(abbot);
    tempbar2.push(roche);
    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[0].length;i++){
      if(this.MajorGraphdata[0][i].data.length!==0){
        temparrgroup.push(this.MajorGraphdata[0][i].name)

        console.log(this.MajorGraphdata[0][i].data)

        lenval=lenval+1;
      }
    }


    let sourcearray=[];
    let temparr2=[];
    sourcearray.push(['product', 'Abbott', 'Dexcom', 'Roche']);
    for(let k=0;k<lenval;k++){
      temparr2=[];
      temparr2.push(temparrgroup[k])
      temparr2.push(tempbar2[1][k])//for abbott
      temparr2.push(tempbar2[0][k])//for dexcom
      temparr2.push(tempbar2[2][k])//for roche
      sourcearray.push(temparr2);

    }
    console.log(sourcearray);

    this.doublebarHorizontalChartOptions[0] = {
      legend: {},
      tooltip: {},
      dataset: {
        source: sourcearray
      },
      xAxis: {},
      yAxis: {type: 'category'},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        {type: 'bar', color:'#42a6d3'},
        {type: 'bar', color:'#68a94d'},
        {type: 'bar', color:'#f1bc3e'}
      ]
    };

    this.typeofgraph = 'bargarph2horizontal';
    this.MajorGraphtype[0]='bargarph2'

  }

  ngOnInit() {

    setTimeout(() => {


      console.log('in ngonit in custom topics');
      this.wordcloud = this.sharedservice.keywordsTopics;

      this.similarwords = this.sharedservice.typeKeywords;

      let  temparray = [];
      if (Object.keys(this.wordcloud).length === 0) {
        console.log('the copy is ');
        let page = 'Draft1';
        console.log(this.sharedservice.fromheader);

        if (this.sharedservice.fromheader === 1) {
          console.log('inside shared one ');
          page = this.sharedservice.pagenamefromheader;
          this.editmode = false;
        }
        console.log(page);

        this.customservice.getdata(page).then((response: any) => {
          console.log(response);
          this.MajorGraphdata=response[0].Graphdata.graphdata
          //this.MajorGraphdata[0] = response[0].userdata;
          this.pagename = page;
          if(response[0].GraphInsights!==undefined){
            this.graphinsi =response[0].GraphInsights.GraphInsights.Insights;
            this.dateforInsights=response[0].GraphInsights.GraphInsights.dates;

          }
          console.log(response[0].Section)
          this.section1Name = response[0].Section;
          console.log(this.section1Name)
          this.description=response[0].description.Description

         temparray=Object.keys(response[0].WordCloud.WordCloud);
          console.log(temparray);


            const dates = this.header.getDateFromLS();
          // console.log(this.wordcloud)
          this.customservice.newfrequency(dates[0], dates[1], temparray).then((response2: any) => {
            console.log(temparray)
            let mentionArray={}
            mentionArray['keywords']=temparray;
            mentionArray['start_date']=dates[0];
            mentionArray['end_date']=dates[1];
            console.log(mentionArray);
            this.customservice.Mentions(mentionArray).then((response3: any) => {
              console.log(response3);
              this.fheValue=response3.response.fhe.total
              this.mentionValue=response3.response.mentions.total
              console.log(this.fheValue);




              console.log(response2.response);
            console.log(response[0]);


            let m=0;
            for(let k=0;k<response[0].Graphdata.graphdata.length;k++){
              console.log(response[0].Graphdata.graphdata[k].length)
              if(response[0].Graphdata.graphdata[k].length!==undefined){
                m=m+1;
              }
            }
            console.log(m);
            this.sectionNo=m;

            console.log(response[0].Graphdata.graphdata.length)
            if (m>=2) {
              console.log('in second section');

              //this.MajorGraphdata[1] = response[0].userdatasection2;
              setTimeout(() => {
                if (response[0].graphtypesection2.Graphtype2 === 'piechart') {
                  this.createGraphn(2);
                } else if (response[0].graphtypesection2.Graphtype2 === 'piechart2') {
                  this.comaprePieChartn(2);
                } else if (response[0].graphtypesection2.Graphtype2 === 'bargraph1') {
                  this.singleBarGraphn(2);
                } else if (response[0].graphtypesection2.Graphtype2 === 'bargarph2') {
                  console.log('in the compare bar graph');
                  this.bargraphn(2);
                } else if (response[0].graphtypesection2.Graphtype2 === 'table') {
                  console.log('in the table');
                  this.tableforgroups2();
                }
                else if (response[0].graphtypesection2.Graphtype2 === 'bargraph1horizontal') {
                    console.log('in the table');
                    this.HorizontalsingleBarGraphn(2);
                }
                else if (response[0].graphtypesection2.Graphtype2 === 'bargarph2horizontal') {
                    console.log('in the table');
                    this.Horizontalbargraph2();
                  }


                }, 400);
              this.section2Name = response[0].Section2.Section2;

            }

            if (m>=3) {
              //this.MajorGraphdata[1] = response[0].userdatasection2;
              setTimeout(() => {
                if (response[0].graphtypesection3.Graphtype3 === 'piechart') {
                  this.createGraphn(3);
                } else if (response[0].graphtypesection3.Graphtype3 === 'piechart2') {
                  this.comaprePieChartn(3);
                } else if (response[0].graphtypesection3.Graphtype3 === 'bargraph1') {
                  this.singleBarGraphn(3);
                } else if (response[0].graphtypesection3.Graphtype3 === 'bargarph2') {
                  console.log('in the compare bar graph');
                  this.bargraphn(3);
                } else if (response[0].graphtypesection3.Graphtype3 === 'table') {
                  console.log('in the table');
                  this.tableforgroups3();
                }
                else if (response[0].graphtypesection3.Graphtype3 === 'bargraph1horizontal') {
                  console.log('in the table');
                  this.HorizontalsingleBarGraphn(3);
                }

                else if (response[0].graphtypesection3.Graphtype3 === 'bargarph2horizontal') {
                  console.log('in the table');
                  this.Horizontalbargraph3();
                }

              }, 400);
              this.section3Name = response[0].Section3.Section3;

            }
            console.log(m);
            if (m>=4) {
              //this.MajorGraphdata[1] = response[0].userdatasection2;
              setTimeout(() => {
                if (response[0].graphtypesection4.Graphtype4=== 'piechart') {
                  this.createGraphn(4);
                } else if (response[0].graphtypesection4.Graphtype4 === 'piechart2') {
                  this.comaprePieChartn(4);
                } else if (response[0].graphtypesection4.Graphtype4 === 'bargraph1') {
                  this.singleBarGraphn(4);
                } else if (response[0].graphtypesection4.Graphtype4 === 'bargarph2') {
                  console.log('in the compare bar graph');
                  this.bargraphn(4);
                } else if (response[0].graphtypesection4.Graphtype4 === 'table') {
                  console.log('in the table');
                  this.tableforgroups4();
                }

                else if (response[0].graphtypesection4.Graphtype4 === 'bargraph1horizontal') {
                  console.log('in the table');
                  this.HorizontalsingleBarGraphn(4);
                }

                else if (response[0].graphtypesection4.Graphtype4 === 'bargarph2horizontal') {
                  console.log('in the table');
                  this.Horizontalbargraph4();
                }
              }, 400);
              this.section4Name = response[0].Section4.Section4;

            }

            if (m>=5) {
              //this.MajorGraphdata[1] = response[0].userdatasection2;
              setTimeout(() => {
                if (response[0].graphtypesection5.Graphtype5 === 'piechart') {
                  this.createGraphn(5);
                } else if (response[0].graphtypesection5.Graphtype5 === 'piechart2') {
                  this.comaprePieChartn(5);
                } else if (response[0].graphtypesection5.Graphtype5 === 'bargraph1') {
                  this.singleBarGraphn(5);
                } else if (response[0].graphtypesection5.Graphtype5 === 'bargarph2') {
                  console.log('in the compare bar graph');
                  this.bargraphn(5);
                } else if (response[0].graphtypesection5.Graphtype5 === 'table') {
                  console.log('in the table');
                  this.tableforgroups5();
                }
                else if (response[0].graphtypesection5.Graphtype5 === 'bargraph1horizontal') {
                  console.log('in the table');
                  this.HorizontalsingleBarGraphn(5);
                }

                else if (response[0].graphtypesection5.Graphtype5 === 'bargarph2horizontal') {
                  console.log('in the table');
                  this.Horizontalbargraph5();
                }

              }, 400);
              this.section5Name = response[0].Section5.Section5;

            }


            this.similarKeywords = response2.response;
            console.log(response[0].description.Description);
            this.description = response[0].description.Description;

            for (const i in response2.response) {


              this.wordcloud[i] = response2.response[i].total;


            }
            console.log(this.wordcloud);
            this.senddata = true;
            const dictvardex = {};
            const dictvarabbott = {};
            const dictvarroche = {};
            const dictvarmedt = {};

            console.log(this.similarKeywords);

            for (const keys in this.similarKeywords) {

              if (this.similarKeywords[keys].Dexcom !== undefined) {
                //  console.log(this.similarKeywords[keys][i].word.count.Dexcom)
                dictvardex[keys] = this.similarKeywords[keys].Dexcom;
              } else if (this.similarKeywords[keys]['Abbott FreeStyle'] !== undefined) {
                //  console.log(this.similarKeywords[keys][i].word.count.Dexcom)
                dictvarabbott[keys] = this.similarKeywords[keys]['Abbott FreeStyle'];
              } else if (this.similarKeywords[keys]['Roche Accu-Chek'] !== undefined) {
                //  console.log(this.similarKeywords[keys][i].word.count.Dexcom)
                dictvarroche[keys] = this.similarKeywords[keys]['Roche Accu-Chek'];
              } else if (this.similarKeywords[keys]['Medtronic Minimed'] !== undefined) {
                //  console.log(this.similarKeywords[keys][i].word.count.Dexcom)
                dictvarmedt[keys] = this.similarKeywords[keys]['Medtronic Minimed'];
              }

            }
            const all = [];
            all.push(dictvardex);
            all.push(dictvarabbott);
            all.push(dictvarroche);
            all.push(dictvarmedt);
            console.log(all);
            this.similarwords = all;
            console.log(response[0].Insights);
            if (response[0].Insights) {
              this.addinsight(response[0].Insights);
              this.openInsites = !this.openInsites;


            }
            // this.userGraphData();
            console.log(response[0].graphtype.Graphtype);
            setTimeout(() => {
              if (response[0].graphtype.Graphtype === 'piechart') {
                this.createGraphn(1);
              } else if (response[0].graphtype.Graphtype === 'piechart2') {
                this.comaprePieChartn(1);
              } else if (response[0].graphtype.Graphtype === 'bargraph1') {
                this.singleBarGraphn(1);
              } else if (response[0].graphtype.Graphtype === 'bargarph2') {
                console.log('in the compare bar graph');
                this.bargraphn(1);
              } else if (response[0].graphtype.Graphtype === 'table') {
                console.log('in the table');
                this.tableforgroups();
              }
              else if (response[0].graphtype.Graphtype === 'bargraph1horizontal') {
                console.log('in the table');
                this.HorizontalsingleBarGraphn(1);
              }

              else if (response[0].graphtype.Graphtype === 'bargarph2horizontal') {
                console.log('in the table');
                this.Horizontalbargraph();
              }
            }, 400);

          });
          });
        });
        console.log(this.sharedservice.pagenamefromheader);
      } else {
        const dates = this.header.getDateFromLS();

        let newarr=['device','supply', 'service','diet', 'lifestyle','customer','product']
        console.log(temparray)
        let mentionArray={}
        mentionArray['keywords']=newarr;
        mentionArray['start_date']=dates[0];
        mentionArray['end_date']=dates[1];
        console.log(mentionArray);

          this.customservice.Mentions(mentionArray).then((response3: any) => {
            this.fheValue=response3.response.fhe.total
            this.mentionValue=response3.response.mentions.total
            console.log(this.fheValue);

          this.userGraphData();


        });
      }
    }, 500);
    setTimeout(() => {
      this.showLoader=!this.showLoader;

    },2500)

  }

  selectgraphs() {
    this.selectgrp = !this.selectgrp;
    // const modalRef: NgbModalRef = this.ngbModalService.open(GraphOptionsComponent, {windowClass: 'custom-modal'});

  }
  selectgraphs2() {
    this.selectgrp2 = !this.selectgrp2;
    // const modalRef: NgbModalRef = this.ngbModalService.open(GraphOptionsComponent, {windowClass: 'custom-modal'});

  }
  selectgraphs3() {
    this.selectgrp3 = !this.selectgrp3;
    // const modalRef: NgbModalRef = this.ngbModalService.open(GraphOptionsComponent, {windowClass: 'custom-modal'});

  }
  selectgraphs4() {
    this.selectgrp4 = !this.selectgrp4;
    // const modalRef: NgbModalRef = this.ngbModalService.open(GraphOptionsComponent, {windowClass: 'custom-modal'});

  }
  selectgraphs5() {
    this.selectgrp5 = !this.selectgrp5;
    // const modalRef: NgbModalRef = this.ngbModalService.open(GraphOptionsComponent, {windowClass: 'custom-modal'});

  }

  addescription(title: string) {
    console.log(title);
    this.description = title;
    console.log(this.description);
  }

  tableforgroups2() {
    this.selectgrp2 = false
    this.tabgrp1 = {};
    this.tabgrp2 = {};
    this.tabgrp3 = {};
    this.tabgrp4 = {};
    this.tabgrp5 = {};
    this.tabgrp6 = {};
    this.tabgrp7 = {};

    const grp1 = {}; const grp2 = {};
    const grp3 = {};
    const grp4 = {};
    const grp5 = {};
    const grp6 = {};
    const grp7 = {};
    const grp8 = {};
    setTimeout(() => {
      console.log(d3.select('#chart'));
      d3.select('#chart3').style('display', 'none');
      d3.select('#chart2').style('display', 'none');
      d3.select('#chart4').style('display', 'none');
      d3.select('#chart5').style('display', 'none');
      d3.select('#chart').style('display', 'none');

    }, 300);
    console.log(this.MajorGraphdata[1]);
    grp1[this.MajorGraphdata[1][0].name] = this.MajorGraphdata[1][0].data;
    grp2[this.MajorGraphdata[1][1].name] = this.MajorGraphdata[1][1].data;
    grp3[this.MajorGraphdata[1][2].name] = this.MajorGraphdata[1][2].data;
    grp4[this.MajorGraphdata[1][3].name] = this.MajorGraphdata[1][3].data;
    grp5[this.MajorGraphdata[1][4].name] = this.MajorGraphdata[1][4].data;
    grp6[this.MajorGraphdata[1][5].name] = this.MajorGraphdata[1][5].data;
    grp7[this.MajorGraphdata[1][6].name] = this.MajorGraphdata[1][6].data;
    grp8[this.MajorGraphdata[1][7].name] = this.MajorGraphdata[1][7].data;


    grp1['NAME']=this.MajorGraphdata[1][0].name;
    grp2['NAME']=this.MajorGraphdata[1][1].name;
    grp3['NAME']=this.MajorGraphdata[1][2].name;
    grp4['NAME']=this.MajorGraphdata[1][3].name;
    grp5['NAME']=this.MajorGraphdata[1][4].name;
    grp6['NAME']=this.MajorGraphdata[1][5].name;
    grp7['NAME']=this.MajorGraphdata[1][6].name;
    grp8['NAME']=this.MajorGraphdata[1][7].name;
    console.log(grp2);
    console.log(this.similarwords);
    grp1['Dexcom']=[];
    grp2['Dexcom']=[];
    grp3['Dexcom']=[];
    grp4['Dexcom']=[];
    grp5['Dexcom']=[];
    grp6['Dexcom']=[];
    grp7['Dexcom']=[];
    grp1['ABBOTT']=[];
    grp2['ABBOTT']=[];
    grp3['ABBOTT']=[];
    grp4['ABBOTT']=[];
    grp5['ABBOTT']=[];
    grp6['ABBOTT']=[];
    grp7['ABBOTT']=[];
    grp1['ROCHE']=[];
    grp2['ROCHE']=[];
    grp3['ROCHE']=[];
    grp4['ROCHE']=[];
    grp5['ROCHE']=[];
    grp6['ROCHE']=[];
    grp7['ROCHE']=[];


    grp1['DexcomFreq']=0;
    grp2['DexcomFreq']=0;
    grp3['DexcomFreq']=0;
    grp4['DexcomFreq']=0;
    grp5['DexcomFreq']=0;
    grp6['DexcomFreq']=0;
    grp7['DexcomFreq']=0;
    grp1['ABBOTTFreq']=0;
    grp2['ABBOTTFreq']=0;
    grp3['ABBOTTFreq']=0;
    grp4['ABBOTTFreq']=0;
    grp5['ABBOTTFreq']=0;
    grp6['ABBOTTFreq']=0;
    grp7['ABBOTTFreq']=0;
    grp1['ROCHEFreq']=0;
    grp2['ROCHEFreq']=0;
    grp3['ROCHEFreq']=0;
    grp4['ROCHEFreq']=0;
    grp5['ROCHEFreq']=0;
    grp6['ROCHEFreq']=0;
    grp7['ROCHEFreq']=0;





    for(let keys in this.similarwords[0]){
      for( let i=0;i<grp1[this.MajorGraphdata[1][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[1][0].name][i]){
          grp1['Dexcom'].push(keys);
          grp1['DexcomFreq']=grp1['DexcomFreq']+this.similarwords[0][keys];


        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[1][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[1][1].name][i]){
          grp2['Dexcom'].push(keys);
          grp2['DexcomFreq']=grp2['DexcomFreq']+this.similarwords[0][keys];


        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[1][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[1][2].name][i]){
          grp3['Dexcom'].push(keys);
          grp3['DexcomFreq']=grp3['DexcomFreq']+this.similarwords[0][keys];


        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[1][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[1][3].name][i]){
          grp4['Dexcom'].push(keys);
          grp4['DexcomFreq']=grp4['DexcomFreq']+this.similarwords[0][keys];


        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[1][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[1][4].name][i]){
          grp5['Dexcom'].push(keys);
          grp5['DexcomFreq']=grp5['DexcomFreq']+this.similarwords[0][keys];


        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[1][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[1][5].name][i]){
          grp6['Dexcom'].push(keys);
          grp6['DexcomFreq']=grp6['DexcomFreq']+this.similarwords[0][keys];


        }
      }

    }
    for(let keys in this.similarwords[1]){
      for( let i=0;i<grp1[this.MajorGraphdata[1][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[1][0].name][i]){
          grp1['ABBOTT'].push(keys);
          grp1['ABBOTTFreq']=grp1['ABBOTTFreq']+this.similarwords[1][keys];



        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[1][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[1][1].name][i]){
          grp2['ABBOTT'].push(keys);
          grp2['ABBOTTFreq']=grp2['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[1][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[1][2].name][i]){
          grp3['ABBOTT'].push(keys);
          grp3['ABBOTTFreq']=grp3['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[1][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[1][3].name][i]){
          grp4['ABBOTT'].push(keys);
          grp4['ABBOTTFreq']=grp4['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[1][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[1][4].name][i]){
          grp5['ABBOTT'].push(keys);
          grp5['ABBOTTFreq']=grp5['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[1][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[1][5].name][i]){
          grp6['ABBOTT'].push(keys);
          grp6['ABBOTTFreq']=grp6['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }

    }


    for(let keys in this.similarwords[2]){
      for( let i=0;i<grp1[this.MajorGraphdata[1][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[1][0].name][i]){
          grp1['ROCHE'].push(keys);
          grp1['ROCHEFreq']=grp1['ROCHEFreq']+this.similarwords[2][keys];


        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[1][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[1][1].name][i]){
          grp2['ROCHE'].push(keys);
          grp2['ROCHEFreq']=grp2['ROCHEFreq']+this.similarwords[2][keys];


        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[1][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[1][2].name][i]){
          grp3['ROCHE'].push(keys);
          grp3['ROCHEFreq']=grp3['ROCHEFreq']+this.similarwords[2][keys];


        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[1][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[1][3].name][i]){
          grp4['ROCHE'].push(keys);
          grp4['ROCHEFreq']=grp4['ROCHEFreq']+this.similarwords[2][keys];


        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[1][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[1][4].name][i]){
          grp5['ROCHE'].push(keys);
          grp5['ROCHEFreq']=grp5['ROCHEFreq']+this.similarwords[2][keys];


        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[1][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[1][5].name][i]){
          grp6['ROCHE'].push(keys);
          grp6['ROCHEFreq']=grp6['ROCHEFreq']+this.similarwords[2][keys];


        }
      }

    }

    console.log(grp1);
    console.log(grp2);
    console.log(grp3);
    console.log(grp4);
    console.log(grp5);
    console.log(grp6);

    let lenval=0;
    let temparrgroup=[];
    for(let k=0;k<this.MajorGraphdata[1].length;k++){
      if(this.MajorGraphdata[1][k].data.length!==0){
        if(k===0){
          this.tabgrp1 = grp1;

        }
        else if(k===1){
          this.tabgrp2 = grp2;

        }
        else if(k===2){
          this.tabgrp3 = grp3;

        }
        else if(k===3){
          this.tabgrp4 = grp4;

        }
        else if(k===4){
          this.tabgrp5 = grp5;

        }
        else if(k===5){
          this.tabgrp6 = grp6;

        } else if(k===6){
          this.tabgrp7 = grp7;

        }


      }
    }


    console.log(this.table);
    // this.table=1;
    this.typeofgraph2 = 'table';
    this.MajorGraphtype[1]='table'



  }

  Horizontalbargraph2() {
    this.selectgrp2 = false;

    this.table = 0;
    console.log(this.similarwords);
    const abbot = [];
    const dexcom = [];
    const roche = [];
    const metronic = [];
    console.log(this.MajorGraphdata[1][0]);
    let grop1 = 0;
    let grop2 = 0;
    let grop3 = 0;
    let grop4 = 0;
    let grop5 = 0;
    let grop6 = 0;
    let grop7 = 0;



    for (const keys in this.similarwords[0]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[1][0].data.length; i++) {
        if (this.MajorGraphdata[1][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[0][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][1].data.length; i++) {
        if (this.MajorGraphdata[1][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][2].data.length; i++) {
        if (this.MajorGraphdata[1][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][3].data.length; i++) {
        if (this.MajorGraphdata[1][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][4].data.length; i++) {
        if (this.MajorGraphdata[1][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][5].data.length; i++) {
        if (this.MajorGraphdata[1][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][6].data.length; i++) {
        if (this.MajorGraphdata[1][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[0][keys];
        }

      }

    }

    dexcom.push(grop1);
    dexcom.push(grop2);
    dexcom.push(grop3);
    dexcom.push(grop4);
    dexcom.push(grop5);
    dexcom.push(grop6);
    dexcom.push(grop7);


    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;
    for (const keys in this.similarwords[1]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[1][0].data.length; i++) {
        if (this.MajorGraphdata[1][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[1][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][1].data.length; i++) {
        if (this.MajorGraphdata[1][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][2].data.length; i++) {
        if (this.MajorGraphdata[1][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][3].data.length; i++) {
        if (this.MajorGraphdata[1][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][4].data.length; i++) {
        if (this.MajorGraphdata[1][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[1][keys];
        }

      }for (let i = 0; i < this.MajorGraphdata[1][5].data.length; i++) {
        if (this.MajorGraphdata[1][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][6].data.length; i++) {
        if (this.MajorGraphdata[1][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[1][keys];
        }

      }


    }

    abbot.push(grop1);
    abbot.push(grop2);
    abbot.push(grop3);
    abbot.push(grop4);
    abbot.push(grop5);
    abbot.push(grop6);
    abbot.push(grop7);



    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;

    for (const keys in this.similarwords[2]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[1][0].data.length; i++) {
        if (this.MajorGraphdata[1][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[2][keys];

        }

      }
      for (let i = 0; i < this.MajorGraphdata[1][1].data.length; i++) {
        if (this.MajorGraphdata[1][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[1][2].data.length; i++) {
        if (this.MajorGraphdata[1][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[1][3].data.length; i++) {
        if (this.MajorGraphdata[1][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[1][4].data.length; i++) {
        if (this.MajorGraphdata[1][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[1][5].data.length; i++) {
        if (this.MajorGraphdata[1][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[1][6].data.length; i++) {
        if (this.MajorGraphdata[1][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[2][keys];
        }
      }

    }
    roche.push(grop1);
    roche.push(grop2);
    roche.push(grop3);
    roche.push(grop4);
    roche.push(grop5);
    roche.push(grop6);
    roche.push(grop7);

    const tempbar2 = [];
    tempbar2.push(dexcom);
    tempbar2.push(abbot);
    tempbar2.push(roche);
    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[1].length;i++){
      if(this.MajorGraphdata[1][i].data.length!==0){
        temparrgroup.push(this.MajorGraphdata[1][i].name)

        console.log(this.MajorGraphdata[1][i].data)

        lenval=lenval+1;
      }
    }


    let sourcearray=[];
    let temparr2=[];
    sourcearray.push(['product', 'Abbott', 'Dexcom', 'Roche']);
    for(let k=0;k<lenval;k++){
      temparr2=[];
      temparr2.push(temparrgroup[k])
      temparr2.push(tempbar2[1][k])//for abbott
      temparr2.push(tempbar2[0][k])//for dexcom
      temparr2.push(tempbar2[2][k])//for roche
      sourcearray.push(temparr2);

    }
    console.log(sourcearray);

    this.doublebarHorizontalChartOptions[1] = {
      legend: {},
      tooltip: {},
      dataset: {
        source: sourcearray
      },
      xAxis: {},
      yAxis: {type: 'category'},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        {type: 'bar', color:'#42a6d3'},
        {type: 'bar', color:'#68a94d'},
        {type: 'bar', color:'#f1bc3e'}
      ]
    };



    setTimeout(() => {

      d3.select('#chart3sec2').style('display', 'none');
      d3.select('#chartsec2').style('display', 'none');
      d3.select('#chart4sec2').style('display', 'none');
      d3.select('#chart5sec2').style('display', 'none');
      d3.select('#chart2sec2').style('display', 'block');

    }, 400);
    this.typeofgraph2 = 'bargarph2horizontal';
    this.MajorGraphtype[1]='bargarph2'






  }

  tableforgroups3() {
    this.tabgrp1 = {};
    this.tabgrp2 = {};
    this.tabgrp3 = {};
    this.tabgrp4 = {};
    this.tabgrp5 = {};
    this.tabgrp6 = {};
    this.tabgrp7 = {};
    this.selectgrp3 = false

    const grp1 = {}; const grp2 = {};
    const grp3 = {};
    const grp4 = {};
    const grp5 = {};
    const grp6 = {};
    const grp7 = {};
    const grp8 = {};
    setTimeout(() => {
      console.log(d3.select('#chart'));
      d3.select('#chart3').style('display', 'none');
      d3.select('#chart2').style('display', 'none');
      d3.select('#chart4').style('display', 'none');
      d3.select('#chart5').style('display', 'none');
      d3.select('#chart').style('display', 'none');

    }, 300);
    console.log(this.MajorGraphdata[2]);
    grp1[this.MajorGraphdata[2][0].name] = this.MajorGraphdata[2][0].data;
    grp2[this.MajorGraphdata[2][1].name] = this.MajorGraphdata[2][1].data;
    grp3[this.MajorGraphdata[2][2].name] = this.MajorGraphdata[2][2].data;
    grp4[this.MajorGraphdata[2][3].name] = this.MajorGraphdata[2][3].data;
    grp5[this.MajorGraphdata[2][4].name] = this.MajorGraphdata[2][4].data;
    grp6[this.MajorGraphdata[2][5].name] = this.MajorGraphdata[2][5].data;
    grp7[this.MajorGraphdata[2][6].name] = this.MajorGraphdata[2][6].data;
    grp8[this.MajorGraphdata[2][7].name] = this.MajorGraphdata[2][7].data;


    grp1['NAME']=this.MajorGraphdata[2][0].name;
    grp2['NAME']=this.MajorGraphdata[2][1].name;
    grp3['NAME']=this.MajorGraphdata[2][2].name;
    grp4['NAME']=this.MajorGraphdata[2][3].name;
    grp5['NAME']=this.MajorGraphdata[2][4].name;
    grp6['NAME']=this.MajorGraphdata[2][5].name;
    grp7['NAME']=this.MajorGraphdata[2][6].name;
    grp8['NAME']=this.MajorGraphdata[2][7].name;
    console.log(grp2);
    console.log(this.similarwords);
    grp1['Dexcom']=[];
    grp2['Dexcom']=[];
    grp3['Dexcom']=[];
    grp4['Dexcom']=[];
    grp5['Dexcom']=[];
    grp6['Dexcom']=[];
    grp7['Dexcom']=[];
    grp1['ABBOTT']=[];
    grp2['ABBOTT']=[];
    grp3['ABBOTT']=[];
    grp4['ABBOTT']=[];
    grp5['ABBOTT']=[];
    grp6['ABBOTT']=[];
    grp7['ABBOTT']=[];
    grp1['ROCHE']=[];
    grp2['ROCHE']=[];
    grp3['ROCHE']=[];
    grp4['ROCHE']=[];
    grp5['ROCHE']=[];
    grp6['ROCHE']=[];
    grp7['ROCHE']=[];



    grp1['DexcomFreq']=0;
    grp2['DexcomFreq']=0;
    grp3['DexcomFreq']=0;
    grp4['DexcomFreq']=0;
    grp5['DexcomFreq']=0;
    grp6['DexcomFreq']=0;
    grp7['DexcomFreq']=0;
    grp1['ABBOTTFreq']=0;
    grp2['ABBOTTFreq']=0;
    grp3['ABBOTTFreq']=0;
    grp4['ABBOTTFreq']=0;
    grp5['ABBOTTFreq']=0;
    grp6['ABBOTTFreq']=0;
    grp7['ABBOTTFreq']=0;
    grp1['ROCHEFreq']=0;
    grp2['ROCHEFreq']=0;
    grp3['ROCHEFreq']=0;
    grp4['ROCHEFreq']=0;
    grp5['ROCHEFreq']=0;
    grp6['ROCHEFreq']=0;
    grp7['ROCHEFreq']=0;





    for(let keys in this.similarwords[0]){
      for( let i=0;i<grp1[this.MajorGraphdata[2][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[2][0].name][i]){
          grp1['Dexcom'].push(keys);
          grp1['DexcomFreq']=grp1['DexcomFreq']+this.similarwords[0][keys];

        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[2][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[2][1].name][i]){
          grp2['Dexcom'].push(keys);
          grp2['DexcomFreq']=grp2['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[2][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[2][2].name][i]){
          grp3['Dexcom'].push(keys);
          grp3['DexcomFreq']=grp3['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[2][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[2][3].name][i]){
          grp4['Dexcom'].push(keys);
          grp4['DexcomFreq']=grp4['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[2][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[2][4].name][i]){
          grp5['Dexcom'].push(keys);
          grp5['DexcomFreq']=grp5['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[2][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[2][5].name][i]){
          grp6['Dexcom'].push(keys);
          grp6['DexcomFreq']=grp6['DexcomFreq']+this.similarwords[0][keys];

        }
      }

    }
    for(let keys in this.similarwords[1]){
      for( let i=0;i<grp1[this.MajorGraphdata[2][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[2][0].name][i]){
          grp1['ABBOTT'].push(keys);
          grp1['ABBOTTFreq']=grp1['ABBOTTFreq']+this.similarwords[1][keys];

        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[2][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[2][1].name][i]){
          grp2['ABBOTT'].push(keys);
          grp2['ABBOTTFreq']=grp2['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[2][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[2][2].name][i]){
          grp3['ABBOTT'].push(keys);
          grp3['ABBOTTFreq']=grp3['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[2][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[2][3].name][i]){
          grp4['ABBOTT'].push(keys);
          grp4['ABBOTTFreq']=grp4['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[2][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[2][4].name][i]){
          grp5['ABBOTT'].push(keys);
          grp5['ABBOTTFreq']=grp5['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[2][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[2][5].name][i]){
          grp6['ABBOTT'].push(keys);
          grp6['ABBOTTFreq']=grp6['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }

    }


    for(let keys in this.similarwords[2]){
      for( let i=0;i<grp1[this.MajorGraphdata[2][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[2][0].name][i]){
          grp1['ROCHE'].push(keys);
          grp1['ROCHEFreq']=grp1['ROCHEFreq']+this.similarwords[2][keys];

        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[2][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[2][1].name][i]){
          grp2['ROCHE'].push(keys);
          grp2['ROCHEFreq']=grp2['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[2][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[2][2].name][i]){
          grp3['ROCHE'].push(keys);
          grp3['ROCHEFreq']=grp3['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[2][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[2][3].name][i]){
          grp4['ROCHE'].push(keys);
          grp4['ROCHEFreq']=grp4['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[2][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[2][4].name][i]){
          grp5['ROCHE'].push(keys);
          grp5['ROCHEFreq']=grp5['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[2][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[2][5].name][i]){
          grp6['ROCHE'].push(keys);
          grp6['ROCHEFreq']=grp6['ROCHEFreq']+this.similarwords[2][keys];

        }
      }

    }

    console.log(grp1);
    console.log(grp2);
    console.log(grp3);
    console.log(grp4);
    console.log(grp5);
    console.log(grp6);
    let lenval=0;
    let temparrgroup=[];
    for(let k=0;k<this.MajorGraphdata[2].length;k++){
      if(this.MajorGraphdata[2][k].data.length!==0){
        if(k===0){
          this.tabgrp1 = grp1;

        }
        else if(k===1){
          this.tabgrp2 = grp2;

        }
        else if(k===2){
          this.tabgrp3 = grp3;

        }
        else if(k===3){
          this.tabgrp4 = grp4;

        }
        else if(k===4){
          this.tabgrp5 = grp5;

        }
        else if(k===5){
          this.tabgrp6 = grp6;

        } else if(k===6){
          this.tabgrp7 = grp7;

        }


      }
    }

    console.log(this.table);
    // this.table=1;
    this.typeofgraph3 = 'table';
    this.MajorGraphtype[2]='table'




  }

  Horizontalbargraph3() {
    this.selectgrp3 = false

    this.table = 0;
    console.log(this.similarwords);
    const abbot = [];
    const dexcom = [];
    const roche = [];
    const metronic = [];
    console.log(this.MajorGraphdata[2][0]);

    let grop1 = 0;
    let grop2 = 0;
    let grop3 = 0;
    let grop4 = 0;
    let grop5 = 0;
    let grop6 = 0;
    let grop7 = 0;



    for (const keys in this.similarwords[0]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[2][0].data.length; i++) {
        if (this.MajorGraphdata[2][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[0][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][1].data.length; i++) {
        if (this.MajorGraphdata[2][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][2].data.length; i++) {
        if (this.MajorGraphdata[2][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][3].data.length; i++) {
        if (this.MajorGraphdata[2][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][4].data.length; i++) {
        if (this.MajorGraphdata[2][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][5].data.length; i++) {
        if (this.MajorGraphdata[2][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][6].data.length; i++) {
        if (this.MajorGraphdata[2][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[0][keys];
        }

      }

    }

    dexcom.push(grop1);
    dexcom.push(grop2);
    dexcom.push(grop3);
    dexcom.push(grop4);
    dexcom.push(grop5);
    dexcom.push(grop6);
    dexcom.push(grop7);


    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;
    for (const keys in this.similarwords[1]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[2][0].data.length; i++) {
        if (this.MajorGraphdata[2][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[1][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][1].data.length; i++) {
        if (this.MajorGraphdata[2][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][2].data.length; i++) {
        if (this.MajorGraphdata[2][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][3].data.length; i++) {
        if (this.MajorGraphdata[2][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][4].data.length; i++) {
        if (this.MajorGraphdata[2][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[1][keys];
        }

      }for (let i = 0; i < this.MajorGraphdata[2][5].data.length; i++) {
        if (this.MajorGraphdata[2][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[1][keys];
        }

      }


    }

    abbot.push(grop1);
    abbot.push(grop2);
    abbot.push(grop3);
    abbot.push(grop4);
    abbot.push(grop5);
    abbot.push(grop6);



    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;

    for (const keys in this.similarwords[2]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[2][0].data.length; i++) {
        if (this.MajorGraphdata[2][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[2][keys];

        }

      }
      for (let i = 0; i < this.MajorGraphdata[2][1].data.length; i++) {
        if (this.MajorGraphdata[2][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[2][2].data.length; i++) {
        if (this.MajorGraphdata[2][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[2][3].data.length; i++) {
        if (this.MajorGraphdata[2][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[2][4].data.length; i++) {
        if (this.MajorGraphdata[2][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[2][5].data.length; i++) {
        if (this.MajorGraphdata[2][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[2][keys];
        }
      }

    }
    roche.push(grop1);
    roche.push(grop2);
    roche.push(grop3);
    roche.push(grop4);
    roche.push(grop5);
    roche.push(grop6);
    const tempbar2 = [];
    tempbar2.push(dexcom);
    tempbar2.push(abbot);
    tempbar2.push(roche);
    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[2].length;i++){
      if(this.MajorGraphdata[2][i].data.length!==0){
        temparrgroup.push(this.MajorGraphdata[2][i].name)

        console.log(this.MajorGraphdata[2][i].data)

        lenval=lenval+1;
      }
    }


    let sourcearray=[];
    let temparr2=[];
    sourcearray.push(['product', 'Abbott', 'Dexcom', 'Roche']);
    for(let k=0;k<lenval;k++){
      temparr2=[];
      temparr2.push(temparrgroup[k])
      temparr2.push(tempbar2[1][k])//for abbott
      temparr2.push(tempbar2[0][k])//for dexcom
      temparr2.push(tempbar2[2][k])//for roche
      sourcearray.push(temparr2);

    }
    console.log(sourcearray);

    this.doublebarHorizontalChartOptions[2] = {
      legend: {},
      tooltip: {},
      dataset: {
        source: sourcearray
      },
      xAxis: {},
      yAxis: {type: 'category'},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        {type: 'bar', color:'#42a6d3'},
        {type: 'bar', color:'#68a94d'},
        {type: 'bar', color:'#f1bc3e'}
      ]
    };


    setTimeout(() => {

      d3.select('#chart3sec2').style('display', 'none');
      d3.select('#chartsec2').style('display', 'none');
      d3.select('#chart4sec2').style('display', 'none');
      d3.select('#chart5sec2').style('display', 'none');
      d3.select('#chart2sec2').style('display', 'block');

    }, 400);
    this.typeofgraph3 = 'bargarph2horizontal';
    this.MajorGraphtype[2]='bargarph2'

  }

  tableforgroups4() {
    this.selectgrp4 = false
    this.tabgrp1 = {};
    this.tabgrp2 = {};
    this.tabgrp3 = {};
    this.tabgrp4 = {};
    this.tabgrp5 = {};
    this.tabgrp6 = {};
    this.tabgrp7 = {};

    const grp1 = {}; const grp2 = {};
    const grp3 = {};
    const grp4 = {};
    const grp5 = {};
    const grp6 = {};
    const grp7 = {};
    const grp8 = {};
    setTimeout(() => {
      console.log(d3.select('#chart'));
      d3.select('#chart3').style('display', 'none');
      d3.select('#chart2').style('display', 'none');
      d3.select('#chart4').style('display', 'none');
      d3.select('#chart5').style('display', 'none');
      d3.select('#chart').style('display', 'none');

    }, 300);
    console.log(this.MajorGraphdata[3]);
    grp1[this.MajorGraphdata[3][0].name] = this.MajorGraphdata[3][0].data;
    grp2[this.MajorGraphdata[3][1].name] = this.MajorGraphdata[3][1].data;
    grp3[this.MajorGraphdata[3][2].name] = this.MajorGraphdata[3][2].data;
    grp4[this.MajorGraphdata[3][3].name] = this.MajorGraphdata[3][3].data;
    grp5[this.MajorGraphdata[3][4].name] = this.MajorGraphdata[3][4].data;
    grp6[this.MajorGraphdata[3][5].name] = this.MajorGraphdata[3][5].data;
    grp7[this.MajorGraphdata[3][6].name] = this.MajorGraphdata[3][6].data;
    grp8[this.MajorGraphdata[3][7].name] = this.MajorGraphdata[3][7].data;
    grp1['NAME']=this.MajorGraphdata[3][0].name;
    grp2['NAME']=this.MajorGraphdata[3][1].name;
    grp3['NAME']=this.MajorGraphdata[3][2].name;
    grp4['NAME']=this.MajorGraphdata[3][3].name;
    grp5['NAME']=this.MajorGraphdata[3][4].name;
    grp6['NAME']=this.MajorGraphdata[3][5].name;
    grp7['NAME']=this.MajorGraphdata[3][6].name;
    grp8['NAME']=this.MajorGraphdata[3][7].name;
    console.log(grp2);
    console.log(this.similarwords);
    grp1['Dexcom']=[];
    grp2['Dexcom']=[];
    grp3['Dexcom']=[];
    grp4['Dexcom']=[];
    grp5['Dexcom']=[];
    grp6['Dexcom']=[];
    grp7['Dexcom']=[];
    grp1['ABBOTT']=[];
    grp2['ABBOTT']=[];
    grp3['ABBOTT']=[];
    grp4['ABBOTT']=[];
    grp5['ABBOTT']=[];
    grp6['ABBOTT']=[];
    grp7['ABBOTT']=[];
    grp1['ROCHE']=[];
    grp2['ROCHE']=[];
    grp3['ROCHE']=[];
    grp4['ROCHE']=[];
    grp5['ROCHE']=[];
    grp6['ROCHE']=[];
    grp7['ROCHE']=[];

    grp1['DexcomFreq']=0;
    grp2['DexcomFreq']=0;
    grp3['DexcomFreq']=0;
    grp4['DexcomFreq']=0;
    grp5['DexcomFreq']=0;
    grp6['DexcomFreq']=0;
    grp7['DexcomFreq']=0;
    grp1['ABBOTTFreq']=0;
    grp2['ABBOTTFreq']=0;
    grp3['ABBOTTFreq']=0;
    grp4['ABBOTTFreq']=0;
    grp5['ABBOTTFreq']=0;
    grp6['ABBOTTFreq']=0;
    grp7['ABBOTTFreq']=0;
    grp1['ROCHEFreq']=0;
    grp2['ROCHEFreq']=0;
    grp3['ROCHEFreq']=0;
    grp4['ROCHEFreq']=0;
    grp5['ROCHEFreq']=0;
    grp6['ROCHEFreq']=0;
    grp7['ROCHEFreq']=0;



    for(let keys in this.similarwords[0]){
      for( let i=0;i<grp1[this.MajorGraphdata[3][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[3][0].name][i]){


          grp1['Dexcom'].push(keys);
          grp1['DexcomFreq']=grp1['DexcomFreq']+this.similarwords[0][keys];
        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[3][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[3][1].name][i]){

          grp2['Dexcom'].push(keys);
          grp2['DexcomFreq']=grp2['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[3][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[3][2].name][i]){

          grp3['Dexcom'].push(keys);
          grp3['DexcomFreq']=grp3['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[3][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[3][3].name][i]){

          grp4['Dexcom'].push(keys);
          grp4['DexcomFreq']=grp4['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[3][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[3][4].name][i]){

          grp5['Dexcom'].push(keys);
          grp5['DexcomFreq']=grp5['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[3][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[3][5].name][i]){

          grp6['Dexcom'].push(keys);
          grp6['DexcomFreq']=grp6['DexcomFreq']+this.similarwords[0][keys];

        }
      }

    }

    for(let keys in this.similarwords[1]){
      for( let i=0;i<grp1[this.MajorGraphdata[3][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[3][0].name][i]){
          grp1['ABBOTT'].push(keys);
          grp1['ABBOTTFreq']=grp1['ABBOTTFreq']+this.similarwords[1][keys];

        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[3][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[3][1].name][i]){
          grp2['ABBOTT'].push(keys);
          grp2['ABBOTTFreq']=grp2['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[3][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[3][2].name][i]){
          grp3['ABBOTT'].push(keys);
          grp3['ABBOTTFreq']=grp3['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[3][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[3][3].name][i]){
          grp4['ABBOTT'].push(keys);
          grp4['ABBOTTFreq']=grp4['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[3][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[3][4].name][i]){
          grp5['ABBOTT'].push(keys);
          grp5['ABBOTTFreq']=grp5['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[3][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[3][5].name][i]){
          grp6['ABBOTT'].push(keys);
          grp6['ABBOTTFreq']=grp6['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }

    }
    console.log(grp1);
    console.log(grp2);
    console.log(grp3);
    console.log(grp4);
    console.log(grp5);
    console.log(grp6);
    let lenval=0;
    let temparrgroup=[];
    for(let k=0;k<this.MajorGraphdata[3].length;k++){
      if(this.MajorGraphdata[3][k].data.length!==0){
        if(k===0){
          this.tabgrp1 = grp1;

        }
        else if(k===1){
          this.tabgrp2 = grp2;

        }
        else if(k===2){
          this.tabgrp3 = grp3;

        }
        else if(k===3){
          this.tabgrp4 = grp4;

        }
        else if(k===4){
          this.tabgrp5 = grp5;

        }
        else if(k===5){
          this.tabgrp6 = grp6;

        } else if(k===6){
          this.tabgrp7 = grp7;

        }


      }
    }
    console.log(this.tabgrp2)
    console.log(this.table);

    this.typeofgraph4 = 'table';
    this.MajorGraphtype[3]='table';


  }

  Horizontalbargraph4() {
    this.selectgrp4 = false

    this.table = 0;
    console.log(this.similarwords);
    const abbot = [];
    const dexcom = [];
    const roche = [];
    const metronic = [];
    console.log(this.MajorGraphdata[3][0]);

    let grop1 = 0;
    let grop2 = 0;
    let grop3 = 0;
    let grop4 = 0;
    let grop5 = 0;
    let grop6 = 0;
    let grop7 = 0;



    for (const keys in this.similarwords[0]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[3][0].data.length; i++) {
        if (this.MajorGraphdata[3][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[0][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][1].data.length; i++) {
        if (this.MajorGraphdata[3][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][2].data.length; i++) {
        if (this.MajorGraphdata[3][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][3].data.length; i++) {
        if (this.MajorGraphdata[3][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][4].data.length; i++) {
        if (this.MajorGraphdata[3][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][5].data.length; i++) {
        if (this.MajorGraphdata[3][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][6].data.length; i++) {
        if (this.MajorGraphdata[3][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[0][keys];
        }

      }

    }

    dexcom.push(grop1);
    dexcom.push(grop2);
    dexcom.push(grop3);
    dexcom.push(grop4);
    dexcom.push(grop5);
    dexcom.push(grop6);
    dexcom.push(grop7);


    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;
    for (const keys in this.similarwords[1]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[3][0].data.length; i++) {
        if (this.MajorGraphdata[3][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[1][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][1].data.length; i++) {
        if (this.MajorGraphdata[3][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][2].data.length; i++) {
        if (this.MajorGraphdata[3][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][3].data.length; i++) {
        if (this.MajorGraphdata[3][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][4].data.length; i++) {
        if (this.MajorGraphdata[3][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[1][keys];
        }

      }for (let i = 0; i < this.MajorGraphdata[3][5].data.length; i++) {
        if (this.MajorGraphdata[3][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[1][keys];
        }

      }


    }

    abbot.push(grop1);
    abbot.push(grop2);
    abbot.push(grop3);
    abbot.push(grop4);
    abbot.push(grop5);
    abbot.push(grop6);



    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;

    for (const keys in this.similarwords[2]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[3][0].data.length; i++) {
        if (this.MajorGraphdata[3][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[2][keys];

        }

      }
      for (let i = 0; i < this.MajorGraphdata[3][1].data.length; i++) {
        if (this.MajorGraphdata[3][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[3][2].data.length; i++) {
        if (this.MajorGraphdata[3][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[3][3].data.length; i++) {
        if (this.MajorGraphdata[3][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[3][4].data.length; i++) {
        if (this.MajorGraphdata[3][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[3][5].data.length; i++) {
        if (this.MajorGraphdata[3][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[2][keys];
        }
      }

    }
    roche.push(grop1);
    roche.push(grop2);
    roche.push(grop3);
    roche.push(grop4);
    roche.push(grop5);
    roche.push(grop6);
    const tempbar2 = [];
    tempbar2.push(dexcom);
    tempbar2.push(abbot);
    tempbar2.push(roche);
    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[3].length;i++){
      if(this.MajorGraphdata[3][i].data.length!==0){
        temparrgroup.push(this.MajorGraphdata[3][i].name)

        console.log(this.MajorGraphdata[3][i].data)

        lenval=lenval+1;
      }
    }


    let sourcearray=[];
    let temparr2=[];
    sourcearray.push(['product', 'Abbott', 'Dexcom', 'Roche']);
    for(let k=0;k<lenval;k++){
      temparr2=[];
      temparr2.push(temparrgroup[k])
      temparr2.push(tempbar2[1][k])//for abbott
      temparr2.push(tempbar2[0][k])//for dexcom
      temparr2.push(tempbar2[2][k])//for roche
      sourcearray.push(temparr2);

    }
    console.log(sourcearray);

    this.doublebarHorizontalChartOptions[3] = {
      legend: {},
      tooltip: {},
      dataset: {
        source: sourcearray
      },
      xAxis: {},
      yAxis: {type: 'category'},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        {type: 'bar', color:'#42a6d3'},
        {type: 'bar', color:'#68a94d'},
        {type: 'bar', color:'#f1bc3e'}
      ]
    };


    setTimeout(() => {

      d3.select('#chart3sec2').style('display', 'none');
      d3.select('#chartsec2').style('display', 'none');
      d3.select('#chart4sec2').style('display', 'none');
      d3.select('#chart5sec2').style('display', 'none');
      d3.select('#chart2sec2').style('display', 'block');

    }, 400);
    this.typeofgraph4 = 'bargarph2horizontal';
    this.MajorGraphtype[2]='bargarph2'

  }

  tableforgroups5() {
    this.selectgrp5 = false
    this.tabgrp1 = {};
    this.tabgrp2 = {};
    this.tabgrp3 = {};
    this.tabgrp4 = {};
    this.tabgrp5 = {};
    this.tabgrp6 = {};
    this.tabgrp7 = {};

    const grp1 = {}; const grp2 = {};
    const grp3 = {};
    const grp4 = {};
    const grp5 = {};
    const grp6 = {};
    const grp7 = {};
    const grp8 = {};
    setTimeout(() => {
      console.log(d3.select('#chart'));
      d3.select('#chart3').style('display', 'none');
      d3.select('#chart2').style('display', 'none');
      d3.select('#chart4').style('display', 'none');
      d3.select('#chart5').style('display', 'none');
      d3.select('#chart').style('display', 'none');

    }, 300);
    console.log(this.MajorGraphdata[4]);
    grp1[this.MajorGraphdata[4][0].name] = this.MajorGraphdata[0][0].data;
    grp2[this.MajorGraphdata[4][1].name] = this.MajorGraphdata[4][1].data;
    grp3[this.MajorGraphdata[4][2].name] = this.MajorGraphdata[4][2].data;
    grp4[this.MajorGraphdata[4][3].name] = this.MajorGraphdata[4][3].data;
    grp5[this.MajorGraphdata[4][4].name] = this.MajorGraphdata[4][4].data;
    grp6[this.MajorGraphdata[4][5].name] = this.MajorGraphdata[4][5].data;
    grp7[this.MajorGraphdata[4][6].name] = this.MajorGraphdata[4][6].data;
    grp8[this.MajorGraphdata[4][7].name] = this.MajorGraphdata[4][7].data;
    grp1['NAME']=this.MajorGraphdata[4][0].name;
    grp2['NAME']=this.MajorGraphdata[4][1].name;
    grp3['NAME']=this.MajorGraphdata[4][2].name;
    grp4['NAME']=this.MajorGraphdata[4][3].name;
    grp5['NAME']=this.MajorGraphdata[4][4].name;
    grp6['NAME']=this.MajorGraphdata[4][5].name;
    grp7['NAME']=this.MajorGraphdata[4][6].name;
    grp8['NAME']=this.MajorGraphdata[4][7].name;
    console.log(grp2);
    console.log(this.similarwords);
    grp1['Dexcom']=[];
    grp2['Dexcom']=[];
    grp3['Dexcom']=[];
    grp4['Dexcom']=[];
    grp5['Dexcom']=[];
    grp6['Dexcom']=[];
    grp7['Dexcom']=[];
    grp1['ABBOTT']=[];
    grp2['ABBOTT']=[];
    grp3['ABBOTT']=[];
    grp4['ABBOTT']=[];
    grp5['ABBOTT']=[];
    grp6['ABBOTT']=[];
    grp7['ABBOTT']=[];
    grp1['ROCHE']=[];
    grp2['ROCHE']=[];
    grp3['ROCHE']=[];
    grp4['ROCHE']=[];
    grp5['ROCHE']=[];
    grp6['ROCHE']=[];
    grp7['ROCHE']=[];

    grp1['DexcomFreq']=0;
    grp2['DexcomFreq']=0;
    grp3['DexcomFreq']=0;
    grp4['DexcomFreq']=0;
    grp5['DexcomFreq']=0;
    grp6['DexcomFreq']=0;
    grp7['DexcomFreq']=0;
    grp1['ABBOTTFreq']=0;
    grp2['ABBOTTFreq']=0;
    grp3['ABBOTTFreq']=0;
    grp4['ABBOTTFreq']=0;
    grp5['ABBOTTFreq']=0;
    grp6['ABBOTTFreq']=0;
    grp7['ABBOTTFreq']=0;
    grp1['ROCHEFreq']=0;
    grp2['ROCHEFreq']=0;
    grp3['ROCHEFreq']=0;
    grp4['ROCHEFreq']=0;
    grp5['ROCHEFreq']=0;
    grp6['ROCHEFreq']=0;
    grp7['ROCHEFreq']=0;



    for(let keys in this.similarwords[0]){
      for( let i=0;i<grp1[this.MajorGraphdata[4][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[4][0].name][i]){


          grp1['Dexcom'].push(keys);
          grp1['DexcomFreq']=grp1['DexcomFreq']+this.similarwords[0][keys];
        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[4][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[4][1].name][i]){

          grp2['Dexcom'].push(keys);
          grp2['DexcomFreq']=grp2['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[4][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[4][2].name][i]){

          grp3['Dexcom'].push(keys);
          grp3['DexcomFreq']=grp3['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[4][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[4][3].name][i]){

          grp4['Dexcom'].push(keys);
          grp4['DexcomFreq']=grp4['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[4][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[4][4].name][i]){

          grp5['Dexcom'].push(keys);
          grp5['DexcomFreq']=grp5['DexcomFreq']+this.similarwords[0][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[4][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[4][5].name][i]){

          grp6['Dexcom'].push(keys);
          grp6['DexcomFreq']=grp6['DexcomFreq']+this.similarwords[0][keys];

        }
      }

    }

    for(let keys in this.similarwords[1]){
      for( let i=0;i<grp1[this.MajorGraphdata[4][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[4][0].name][i]){
          grp1['ABBOTT'].push(keys);
          grp1['ABBOTTFreq']=grp1['ABBOTTFreq']+this.similarwords[1][keys];

        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[4][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[4][1].name][i]){
          grp2['ABBOTT'].push(keys);
          grp2['ABBOTTFreq']=grp2['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[4][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[4][2].name][i]){
          grp3['ABBOTT'].push(keys);
          grp3['ABBOTTFreq']=grp3['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[4][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[4][3].name][i]){
          grp4['ABBOTT'].push(keys);
          grp4['ABBOTTFreq']=grp4['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[4][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[4][4].name][i]){
          grp5['ABBOTT'].push(keys);
          grp5['ABBOTTFreq']=grp5['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[4][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[4][5].name][i]){
          grp6['ABBOTT'].push(keys);
          grp6['ABBOTTFreq']=grp6['ABBOTTFreq']+this.similarwords[1][keys];

        }
      }

    }


    for(let keys in this.similarwords[2]){
      for( let i=0;i<grp1[this.MajorGraphdata[4][0].name].length;i++){
        if(keys===grp1[this.MajorGraphdata[4][0].name][i]){


          grp1['ROCHE'].push(keys);
          grp1['ROCHEFreq']=grp1['ROCHEFreq']+this.similarwords[2][keys];
        }

      }
      for( let i=0;i<grp2[this.MajorGraphdata[4][1].name].length;i++){
        if(keys===grp2[this.MajorGraphdata[4][1].name][i]){

          grp2['ROCHE'].push(keys);
          grp2['ROCHEFreq']=grp2['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp3[this.MajorGraphdata[4][2].name].length;i++){
        if(keys===grp3[this.MajorGraphdata[4][2].name][i]){

          grp3['ROCHE'].push(keys);
          grp3['ROCHEFreq']=grp3['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp4[this.MajorGraphdata[4][3].name].length;i++){
        if(keys===grp4[this.MajorGraphdata[4][3].name][i]){

          grp4['ROCHE'].push(keys);
          grp4['ROCHEFreq']=grp4['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp5[this.MajorGraphdata[4][4].name].length;i++){
        if(keys===grp5[this.MajorGraphdata[4][4].name][i]){

          grp5['ROCHE'].push(keys);
          grp5['ROCHEFreq']=grp5['ROCHEFreq']+this.similarwords[2][keys];

        }
      }
      for( let i=0;i<grp6[this.MajorGraphdata[4][5].name].length;i++){
        if(keys===grp6[this.MajorGraphdata[4][5].name][i]){

          grp6['ROCHE'].push(keys);
          grp6['ROCHEFreq']=grp6['ROCHEFreq']+this.similarwords[2][keys];

        }
      }

    }



    console.log(grp1);
    console.log(grp2);
    console.log(grp3);
    console.log(grp4);
    console.log(grp5);
    console.log(grp6);
    let lenval=0;
    let temparrgroup=[];
    for(let k=0;k<this.MajorGraphdata[4].length;k++){
      if(this.MajorGraphdata[4][k].data.length!==0){
        if(k===0){
          this.tabgrp1 = grp1;

        }
        else if(k===1){
          this.tabgrp2 = grp2;

        }
        else if(k===2){
          this.tabgrp3 = grp3;

        }
        else if(k===3){
          this.tabgrp4 = grp4;

        }
        else if(k===4){
          this.tabgrp5 = grp5;

        }
        else if(k===5){
          this.tabgrp6 = grp6;

        } else if(k===6){
          this.tabgrp7 = grp7;

        }


      }
    }
    console.log(this.tabgrp2)
    console.log(this.table);

    this.typeofgraph5 = 'table';
    this.MajorGraphtype[0]='table';


  }

  Horizontalbargraph5() {
    console.log("in horzontal bargraph")
    this.selectgrp5 = false

    this.table = 0;
    console.log(this.similarwords);
    const abbot = [];
    const dexcom = [];
    const roche = [];
    const metronic = [];
    console.log(this.MajorGraphdata[4][0]);

    let grop1 = 0;
    let grop2 = 0;
    let grop3 = 0;
    let grop4 = 0;
    let grop5 = 0;
    let grop6 = 0;
    let grop7 = 0;



    for (const keys in this.similarwords[0]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[4][0].data.length; i++) {
        if (this.MajorGraphdata[4][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[0][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][1].data.length; i++) {
        if (this.MajorGraphdata[4][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][2].data.length; i++) {
        if (this.MajorGraphdata[4][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][3].data.length; i++) {
        if (this.MajorGraphdata[4][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][4].data.length; i++) {
        if (this.MajorGraphdata[4][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][5].data.length; i++) {
        if (this.MajorGraphdata[4][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[0][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][6].data.length; i++) {
        if (this.MajorGraphdata[4][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[0][keys];
        }

      }

    }

    dexcom.push(grop1);
    dexcom.push(grop2);
    dexcom.push(grop3);
    dexcom.push(grop4);
    dexcom.push(grop5);
    dexcom.push(grop6);
    dexcom.push(grop7);


    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;
    for (const keys in this.similarwords[1]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[4][0].data.length; i++) {
        if (this.MajorGraphdata[4][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[1][keys];


        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][1].data.length; i++) {
        if (this.MajorGraphdata[4][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][2].data.length; i++) {
        if (this.MajorGraphdata[4][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][3].data.length; i++) {
        if (this.MajorGraphdata[4][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][4].data.length; i++) {
        if (this.MajorGraphdata[4][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[1][keys];
        }

      }for (let i = 0; i < this.MajorGraphdata[4][5].data.length; i++) {
        if (this.MajorGraphdata[4][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[1][keys];
        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][6].data.length; i++) {
        if (this.MajorGraphdata[4][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[1][keys];
        }

      }

    }


    abbot.push(grop1);
    abbot.push(grop2);
    abbot.push(grop3);
    abbot.push(grop4);
    abbot.push(grop5);
    abbot.push(grop6);
    abbot.push(grop7);




    grop1 = 0;
    grop2 = 0;
    grop3 = 0;
    grop4 = 0;
    grop5 = 0;
    grop6 = 0;
    grop7 = 0;


    for (const keys in this.similarwords[2]) {
      // console.log(keys)
      for (let i = 0; i < this.MajorGraphdata[4][0].data.length; i++) {
        if (this.MajorGraphdata[4][0].data[i] === keys) {
          grop1 = grop1 + this.similarwords[2][keys];

        }

      }
      for (let i = 0; i < this.MajorGraphdata[4][1].data.length; i++) {
        if (this.MajorGraphdata[4][1].data[i] === keys) {
          grop2 = grop2 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[4][2].data.length; i++) {
        if (this.MajorGraphdata[4][2].data[i] === keys) {
          grop3 = grop3 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[4][3].data.length; i++) {
        if (this.MajorGraphdata[4][3].data[i] === keys) {
          grop4 = grop4 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[4][4].data.length; i++) {
        if (this.MajorGraphdata[4][4].data[i] === keys) {
          grop5 = grop5 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[4][5].data.length; i++) {
        if (this.MajorGraphdata[4][5].data[i] === keys) {
          grop6 = grop6 + this.similarwords[2][keys];
        }
      }
      for (let i = 0; i < this.MajorGraphdata[4][6].data.length; i++) {
        if (this.MajorGraphdata[4][6].data[i] === keys) {
          grop7 = grop7 + this.similarwords[2][keys];
        }
      }


    }

    roche.push(grop1);
    roche.push(grop2);
    roche.push(grop3);
    roche.push(grop4);
    roche.push(grop5);
    roche.push(grop6);
    roche.push(grop7);

    const tempbar2 = [];
    tempbar2.push(dexcom);
    tempbar2.push(abbot);
    tempbar2.push(roche);
    let lenval=0;
    let temparrgroup=[];
    for( let i=0;i<this.MajorGraphdata[4].length;i++){
      if(this.MajorGraphdata[4][i].data.length!==0){
        temparrgroup.push(this.MajorGraphdata[4][i].name)

        console.log(this.MajorGraphdata[4][i].data)

        lenval=lenval+1;
      }
    }


    let sourcearray=[];
    let temparr2=[];
    sourcearray.push(['product', 'Abbott', 'Dexcom', 'Roche']);
    for(let k=0;k<lenval;k++){
      temparr2=[];
      temparr2.push(temparrgroup[k])
      temparr2.push(tempbar2[1][k])//for abbott
      temparr2.push(tempbar2[0][k])//for dexcom
      temparr2.push(tempbar2[2][k])//for roche
      sourcearray.push(temparr2);

    }
    console.log(sourcearray);

    this.doublebarHorizontalChartOptions[4] = {
      legend: {},
      tooltip: {},
      dataset: {
        source: sourcearray
      },
      xAxis: {},
      yAxis: {type: 'category'},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        {type: 'bar', color:'#42a6d3'},
        {type: 'bar', color:'#68a94d'},
        {type: 'bar', color:'#f1bc3e'}
      ]
    };

    this.typeofgraph5 = 'bargarph2horizontal';
    this.MajorGraphtype[4]='bargarph2'

  }

  saveLabel() {
    this.saveHeading = true;
    this.toggleEditName = false;
  }

  editLabelName() {
    this.toggleEditName = true;
    this.saveHeading = false;
  }
  openmodal() {
    console.log('in open modal');

    console.log(this.openmessagemodal);
    const dates = this.header.getDateFromLS();
    this.msgdates = dates;
    console.log(dates);
    console.log(this.saveHeading);
    this.openmessagemodal = !this.openmessagemodal;
    //  this.child.fetchMessages2(dates);
    this.sharedservice.tofetchmessages2(dates);
  }
  savedata() {
    this.publish=false;
    this.showLoader=true;

    let m={};

    for(let i in this.wordcloud){
      console.log(i);
      m[i]=this.wordcloud[i];
    }
    console.log(m)
    if (this.pagename !== 'Draft1') {
      const userpagedata = [];

      userpagedata.push({'graphdata':this.MajorGraphdata})

      userpagedata.push({'Graphtype': this.typeofgraph});
      userpagedata.push({'Description': this.description});
      userpagedata.push({'Section': this.section1Name});
      userpagedata.push({'PageName': this.pagename});
      userpagedata.push({'WordCloud': m});
      console.log(this.graphinsi);
      userpagedata.push({'GraphInsights': {Insights:this.graphinsi,dates:this.dateforInsights}});

      console.log(this.sectionNo);

      if (this.sectionNo >= 2) {
        userpagedata.push({'Graphtype2': this.typeofgraph2});
        userpagedata.push({'Section2': this.section2Name});

      }
      if (this.sectionNo >= 3) {
        userpagedata.push({'Graphtype3': this.typeofgraph3});
        userpagedata.push({'Section3': this.section3Name});

      }

      if (this.sectionNo >= 4) {
        userpagedata.push({'Graphtype4': this.typeofgraph4});
        userpagedata.push({'Section4': this.section4Name});

      }
      if (this.sectionNo >= 5) {
        userpagedata.push({'Graphtype5': this.typeofgraph5});
        userpagedata.push({'Section5': this.section5Name});

      }
      
      this.customservice.savepage(userpagedata).then((response: any) => {
        if (response.error) {
          throw response.error;
        } else {
          console.log(response);

        }
      });
    } else {
      const userpagedata = [];
      console.log(this.PageName);
      let m={};

      for(let i in this.wordcloud){
        console.log(i);
        m[i]=this.wordcloud[i];
      }
      console.log(m)
      userpagedata.push({'graphdata':this.MajorGraphdata})

      userpagedata.push({'Graphtype': this.typeofgraph});
      userpagedata.push({'Description': this.description});
      userpagedata.push({'Section': this.section1Name});
      userpagedata.push({'PageName': 'Draft1'});
      userpagedata.push({'WordCloud': m});
      console.log(this.graphinsi);
      userpagedata.push({'GraphInsights': {Insights:this.graphinsi,dates:this.dateforInsights}});

      console.log(this.sectionNo);

      if (this.sectionNo >= 2) {
        userpagedata.push({'Graphtype2': this.typeofgraph2});
        userpagedata.push({'Section2': this.section2Name});

      }
      if (this.sectionNo >= 3) {
        userpagedata.push({'Graphtype3': this.typeofgraph3});
        userpagedata.push({'Section3': this.section3Name});

      }
      if (this.sectionNo >= 4) {
        userpagedata.push({'Graphtype4': this.typeofgraph4});
        userpagedata.push({'Section4': this.section4Name});

      }
      if (this.sectionNo >= 5) {
        userpagedata.push({'Graphtype5': this.typeofgraph5});
        userpagedata.push({'Section5': this.section5Name});

      }






      this.customservice.savepage(userpagedata).then((response: any) => {
        if (response.error) {
          throw response.error;
        } else {
          console.log(response);

        }

      });

    }
    setTimeout(() => {
      this.showLoader=!this.showLoader;
    },500)

this.editmode=!this.editmode;

  }

  publishclose() {
    this.publish = false;
this.publishnewpg=false;
  }
  publishh() {
    this.publish = true;
    console.log(this.PageName);
    if(this.pagename==="Draft1"){
      this.publishnewpg=true;
    }
  }
  publish2() {
    this.publish = false;
    console.log(this.description);
    console.log(this.PageName);
    const userpagedata = [];
    console.log(this.PageName);
    let m={};

    for(let i in this.wordcloud){
      console.log(i);
      m[i]=this.wordcloud[i];
    }
    userpagedata.push({'graphdata':this.MajorGraphdata})

    userpagedata.push({'Graphtype': this.typeofgraph});
    userpagedata.push({'Description': this.description});
    userpagedata.push({'Section': this.section1Name});
    userpagedata.push({'PageName': this.PageName});
    userpagedata.push({'WordCloud': m});
    console.log(this.graphinsi);
    userpagedata.push({'GraphInsights': {Insights:this.graphinsi,dates:this.dateforInsights}});

    console.log(this.sectionNo);

    if (this.sectionNo >= 2) {
      userpagedata.push({'Graphtype2': this.typeofgraph2});
      userpagedata.push({'Section2': this.section2Name});

    }
    if (this.sectionNo >= 3) {
      userpagedata.push({'Graphtype3': this.typeofgraph3});
      userpagedata.push({'Section3': this.section3Name});

    }
    if (this.sectionNo >= 4) {
      userpagedata.push({'Graphtype4': this.typeofgraph4});
      userpagedata.push({'Section4': this.section4Name});

    }
    if (this.sectionNo >= 5) {
      userpagedata.push({'Graphtype5': this.typeofgraph5});
      userpagedata.push({'Section5': this.section5Name});

    }


    console.log(userpagedata);

    this.customservice.savepage(userpagedata).then((response: any) => {

    });
    this.sharedservice.keywordsTopics = {};
    this.appcomp.addlinksinHeader2(this.PageName);
    this.sharedservice.pagenamefromheader = this.PageName;
    this.header.pageis(this.PageName);

  }
  publishnewpage(){
    this.publishnewpg=true;

  }
  preview(){
    this.editmode=!this.editmode
  }
  openInsite() {
    this.openInsites = !this.openInsites;
  }
  openOtherSection() {
    this.sectionNo = this.sectionNo + 1;
    console.log(this.sectionNo);
    console.log('in other section');
    if (this.sectionNo === 2) {
      this.userGraphData2();
    }
    if (this.sectionNo === 3) {
      this.userGraphData3();
    }
    if (this.sectionNo === 4) {
      this.userGraphData4();
    }
    if (this.sectionNo === 5) {
      this.userGraphData5();
    }

  }
  addinsight(value) {
    this.openInsites = !this.openInsites;
    console.log(value);
    this.insight = value;
    this.insighshow = true;
  }
  switchedit() {
    this.editmode = true;
  }
  deletepageconfirm() {
    console.log('in delete page');
    this.renamepage = true;
    this.customservice.deletepage(this.pagename);
    this.router.navigate(['/seek-and-discover']);
  }
  deletepage(){
    if (confirm("Do You Want To Delete The Page !")) {
    this.deletepageconfirm()
    } else {
    console.log('confirmed')
    }

  }
  cancelInsight() {
    console.log("in cancel insigts")
    this.DefaultInsights='';
    console.log(this.DefaultInsights);
    // $('#myform1').value='';
  }
  deleteinsight(insight) {
    this.removeItemOnce(this.graphinsi[this.currentsection - 1], insight);
  }

  editInsight(insight) {
    this.removeItemOnce(this.graphinsi[this.currentsection - 1], insight);
    this.DefaultInsights=insight;

  }
  removeItemOnce(arr, value) {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

}
