import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ForceDirectedGraphService} from './force-directed-graph.service';
import {TwitterService} from '../../../twitter/twitter.service';
import {EllipseForceService} from '../ellipse-force/ellipse-force.service';
// import {ScatterGraphService} from '../../../twitter/scatter-graph/scatter-graph.service';
import {ScatterGraphComponent} from '../../../twitter/scatter-graph/scatter-graph.component';
import {HeaderComponent} from '../../header/header.component';

import * as d3 from 'd3';
import {color, keys} from 'd3';
import {LeftPannelComponent} from '../../left-pannel/left-pannel.component';
import {transform} from 'async';

@Component({
    selector: 'app-force-directed-graph',
    templateUrl: './force-directed-graph.component.html',
    styleUrls: ['./force-directed-graph.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ForceDirectedGraphComponent implements OnInit {

    constructor(private service: ForceDirectedGraphService,
                private twitterService: TwitterService,
                private ellipseForceService: EllipseForceService,
                // private scattergraphservice: ScatterGraphService,
                private header: HeaderComponent,
                private scatterGraph: ScatterGraphComponent) {

    }
    @Input () graphresp: any = [];
    @Input () graphresp1: any = [];
    @Input() showlabel:any
    @Input() servicelinks:any
    @Input() servicelinks2: any
    public graphresp2: any = [];
    public typegraphcont =true;
    public typegraphmen=true;
    public transcript : any =[];
    public screen_name: any;
    public activeTab: any;
    public trans : any
    public showloader=false

    public sidebox: any
    public slideron: any=0;
    searchWord: string
    searchWord2: string;
    public delWord:string;
    searchwordarr:any=[]
    public reload_search: boolean=true;
    public graphcount: any=-1
    public sliderval=0
    public  status1 = false;
    public  status2: boolean = false;
    public  status3:boolean = false;
    public  status4: boolean = false;
    public  status5 : boolean= false;
    public  status6: boolean = false;
    public  status7 :boolean= false;
    public  status8:boolean = false;
    public  status9:boolean = false;
    public  status10:boolean = false;
    public  status11:boolean = false;


    // public resetvar: any =  this.buildGraph();
    @Input () val;
    catelist: any = ['Doctor', 'Patient', 'Bloggers', 'Caregiver', 'HCP', 'reseracher', 'Patient_advocate', 'journalist/media', ' Org Med Devices', 'organization/NGO', 'others'];
    catkeys: any = ['Doctor', 'Patient', 'Bloggers', 'Caregiver', 'HCP', 'reseracher', 'Patient_advocate', 'journalist/media', ' Org Med Devices', 'organization/NGO', 'others'];
    filters: any = [];
    showLegendLine: any = {'Doctor': true, 'Patient': true, 'Bloggers': true, 'Caregiver': true, 'HCP': true, 'reseracher': true, 'Patient_advocate': true, 'journalist/media': true, 'Org Med Devices': true, 'organization/NGO': true, 'others': true};
    topicsarr: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    Filtername: any = {};
    public checkedFilters: any = {};
    verifiedStatus: any = ['True', 'False'];


    showFilters = false;


    public testFunction() {
        // console.log('in test function');
        // console.log(this.catkeys);
        this.checkedFilters.Category = this.filters;
        this.checkedFilters.Verified = this.verifiedStatus;
        // console.log(this.val);
        if (this.val === 'true') {
            this.twitterService.getNetworkGraph(this.header.getDateFromLS(), this.checkedFilters).then((response: any) => {
                // console.log('inside force');
                this.graphresp = response;
                const svg = d3.select('svg');
                svg.selectAll('*').remove();

                this.service.graph(this.graphresp);

                this.buildGraph2();

            }).catch((error: any) => {
                throw error;
            });
        } else {
            this.twitterService.getNetworkGraph2(this.header.getDateFromLS(), this.checkedFilters).then((response: any) => {
                console.log('inside graph keys ');
                this.graphresp1 = response;
                console.log(this.graphresp1);
                const svg = d3.select('svg');
                svg.selectAll('*').remove();
                this.service.graph2(this.graphresp1);
                this.buildGraph();
            }).catch((error: any) => {
                throw error;
            });
        }

    }
    public testFunction2() {
        // console.log('in test function');
        // console.log(this.catkeys);
        this.status1 = false;
        this.status2= false;
        this.status3 = false;
        this.status4 = false;
        this.status5 = false;
        this.status6 = false;
        this.status7 = false;
        this.status8 = false;
        this.status9 = false;
        this.status10 = false;
        this.status11 = false;


        // console.log(this.val);
        if (this.val === 'true') {
            this.twitterService.getNetworkGraph(this.header.getDateFromLS()).then((response: any) => {
                // console.log('inside force');
                this.graphresp = response;
                const svg = d3.select('svg');
                svg.selectAll('*').remove();

                this.service.graph(this.graphresp);

                this.buildGraph2();

            }).catch((error: any) => {
                throw error;
            });
        } else {
            this.twitterService.getNetworkGraph2(this.header.getDateFromLS()).then((response: any) => {
                console.log('inside graph keys ');
                this.graphresp1 = response;
                console.log(this.graphresp1);
                const svg = d3.select('svg');
                svg.selectAll('*').remove();
                this.service.graph2(this.graphresp1);
                this.buildGraph();
            }).catch((error: any) => {
                throw error;
            });
        }

    }


    public checkarray1(filtername1: any ) {
        // console.log(this.catkeys);
        console.log('in checkarray');
        if(this.filters.includes(filtername1)){
            this.filters.splice(this.filters.indexOf(filtername1), 1);
        }
        else{


            this.filters.push(filtername1);
        }
        console.log('the added filters is');
        console.log(this.filters);
        // console.log(this.catkeys);

        // console.log(this.catkeys);
    }
    public demofun()
    {
        console.log("in demo function for check")
    }
    public userinteraction(end_date: any, name : any )
    {
        console.log("in interaction")
        const username = name;
        this.activeTab = true;
        this.trans=true

        const headerDates = this.header.getDateFromLS();
        const modifiedDates = [headerDates[0], end_date];
        // this.showFilters=false
        this.header.getDateFromLS()[1];
        this.twitterService.getuserTranscript(this.header.getDateFromLS(), {'username': username}).then((response: any) => {
            // console.log('inside graph keys ');
            this.transcript = response;

        }).catch((error: any) => {
            throw error;
        });

    }

    public clickname(end_date: any, name: any ) {
        console.log(" in clickname")

        const headerDates = this.header.getDateFromLS();
        const modifiedDates = [headerDates[0], end_date];
        const username = name;
        this.activeTab = true;
        this.screen_name = username;
        // this.showFilters=false
        this.header.getDateFromLS()[1];


        // this.header.getDateFromLS().end= end_date
        this.twitterService.getscatterGraph(modifiedDates, {'username': username}).then((response: any) => {
            // console.log('inside graph keys ');
            this.graphresp2 = response;

        }).catch((error: any) => {
            throw error;
        });


    }
    public checkarray2(filtername1: any ) {

        if (this.verifiedStatus.indexOf(filtername1) > -1) {
            this.verifiedStatus.splice(this.verifiedStatus.indexOf(filtername1), 1);
            // console.log(this.verifiedStatus);
        } else {
            this.verifiedStatus.push(filtername1);
        }
        // console.log(this.verifiedStatus);
    }
    public formatLabel() {
        this.slideron=1;
        console.log("format label")
        console.log(this.val)

        console.log(this.sliderval)
        if (this.val === 'true') {
            let temparr: any=[]
            this.service.graph(this.graphresp);

            console.log(this.service.links2.length)
            let newl=this.service.links2.length
            for (let i = newl-1; i >  0; i--) {
                //console.log(this.service.links)
                console.log(i)
                console.log(this.service.links2[i].value)
                console.log(this.sliderval)
                if (this.service.links2[i].value >= this.sliderval) {
                    console.log("the strength is ")
                    let q=[];
                    console.log(this.service.links2[i].link_strength)
                    console.log(i)
                    //console.log(this.service.links.splice(i,1))
                    q = this.service.links2.splice(i,1)
                    console.log(q[0]);
                    temparr.push(q[0]);

                    // console.log(this.service.links[i].link_strength)
                    // console.log(this.service.links[i])
                    //  temparr=this.service.links.splice(i,1);
                }
                else{


                }

            }
            // console.log(temparr)
            this.service.links2=temparr
            console.log(this.service.links2)
            this.buildGraph2();
        }
        else{
            let temparr: any=[]
            this.service.graph2(this.graphresp1);

            console.log(this.service.links.length)
            let newl=this.service.links.length
            for (let i = newl-1; i >  0; i--) {
                //console.log(this.service.links)
                console.log(i)
                if (this.service.links[i].link_strength !<= this.sliderval) {
                    console.log("the strength is ")
                    console.log(this.service.links[i].link_strength)
                    //this.service.links.splice(i,1)
                    console.log(this.service.links.splice(i,1))
                }
                else{
                    console.log("in else ")
                    console.log(i)
                    console.log(this.service.links[i])
                }

            }

            this.buildGraph();
            console.log(this.service.links)

        }
        console.log(this.service.links.length)


    }
    public alllinks(){
        this.slideron=0;
        this.sliderval=0

        this.ngOnChanges()




    }
    public delkeyword(delword){
        this.reload_search=false;
        this.reload_search=true;
        console.log("the words are ")
        console.log(this.searchwordarr)

        console.log(delword)
        for(let i=0;i<this.searchwordarr.length;i++) {
            if (this.searchwordarr.includes(delword)) {
                console.log("inside if ")
                this.searchwordarr.splice(i,1)
                this.searchWord2=''


            }
        }
        console.log(this.searchwordarr)
        this.checkedFilters.keywords = this.searchwordarr;

        if (this.val === 'true') {
            this.twitterService.getNetworkGraph(this.header.getDateFromLS(), this.checkedFilters).then((response: any) => {
                // console.log('inside force');
                this.graphresp = response;
                const svg = d3.select('svg');
                svg.selectAll('*').remove();

                this.service.graph(this.graphresp);

                this.buildGraph2();

            }).catch((error: any) => {
                throw error;
            });
        } else {
            this.twitterService.getNetworkGraph2(this.header.getDateFromLS(), this.checkedFilters).then((response: any) => {
                console.log('inside graph keys ');
                this.graphresp1 = response;
                console.log(this.graphresp1);
                const svg = d3.select('svg');
                svg.selectAll('*').remove();
                this.service.graph2(this.graphresp1);
                this.buildGraph();
            }).catch((error: any) => {
                throw error;
            });
        }
    }

    public keywordserach(){

        var x = this.searchWord
        this.searchWord2=this.searchWord
        this.searchWord='';
        console.log("the searched word is ")
        console.log(x[0])


        this.searchwordarr.push(x);
        if(x[0]==="#"){
            this.checkedFilters.hashtags = this.searchwordarr;
        }
        else {
            this.checkedFilters.keywords = this.searchwordarr;
        }
        if (this.val === 'true') {
            this.twitterService.getNetworkGraph(this.header.getDateFromLS(), this.checkedFilters).then((response: any) => {
                // console.log('inside force');
                this.graphresp = response;
                const svg = d3.select('svg');
                svg.selectAll('*').remove();

                this.service.graph(this.graphresp);

                this.buildGraph2();

            }).catch((error: any) => {
                throw error;
            });
        } else {
            this.twitterService.getNetworkGraph2(this.header.getDateFromLS(), this.checkedFilters).then((response: any) => {
                console.log('inside graph keys ');
                this.graphresp1 = response;
                console.log(this.graphresp1);
                const svg = d3.select('svg');
                svg.selectAll('*').remove();
                this.service.graph2(this.graphresp1);
                this.buildGraph();
            }).catch((error: any) => {
                throw error;
            });
        }
    }
    public colour_code( prof) {
        if (prof === 'Doctor') {
            return '#E82D4F'; }
        else if (prof === 'Patient') {
            return '#52AA8E';
        } else if (prof === 'Bloggers') {
            return '#759ECC';
        } else if (prof === 'Caregivers') {
            return '#CA7FA8';
        } else if (prof === 'HCP') {
            return  '#CEC07A';
        } else if (prof === 'researcher') {
            return '#D14FC6';
        } else if (prof === 'Patient_advocate') {
            return '#6A9C9C';
        } else if (prof === 'journalist/media') {
            return '#63B142';
        } else if (prof === 'Org Med Devices') {
            return  '#465E73';
        } else if (prof === 'organisation/NGO') {
            return '#823E45';
        } else if (prof === 'others') {
            return '#682DE6';
        }
    }


    public buildGraph() {
        let selectednode: any=[]
        this.showloader=true;

        this.graphcount=this.graphcount+1
        this.typegraphcont=true;
        this.showFilters = true;
        this.typegraphmen=false;
        console.log('in build graph');


        const keys = ['Doctor', 'Patient', 'Bloggers', 'Caregiver', 'HCP', 'reseracher', 'Patient_advocate', 'journalist/media', ' Org Med Devices', 'organization/NGO', 'others'];

        const svg1 = d3.select('.network-graph');
        svg1.selectAll('svg').remove();
        const tooltip = d3.select('body')
            .append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);
        const tooltip1 = d3.select('body')
            .append('div')
            .attr('class', 'tooltipnew')
            .style('opacity', 0);

        // const colourcode=[F7D92B,ED1C24,EC5E36,ED0B70,BCCD30,0690CF,6154A4,A967AA,A64D2C,CD912D, 4C4CCC ]
        const coloursd = ['#E82D4F', '#52AA8E', '#759ECC', '#CA7FA8', '#CEC07A', '#D14FC6', '#6A9C9C', '#63B142', '#465E73', '#823E45', '#682DE6'];
        const svg: any = d3.select('.network-graph').append('svg');
        const width: any = +svg.attr('width');
        const height: any = +svg.attr('height');
        const color: any = d3.scaleOrdinal(coloursd);
        console.log("hieht is")
        console.log(height)
        if(this.slideron===0||this.slideron===2) {

            this.service.graph2(this.graphresp1);
        }



        const simulation: any = d3.forceSimulation()

            .force('link', d3.forceLink().id((d: any) => d.id)
                .distance(60).strength(0.1)
            )
            .force('charge', d3.forceManyBody())
            .force('collide', d3.forceCollide().radius((d: any) => {
                return +d.value + 150;
            }))

            .force('center', d3.forceCenter(width / 2, height / 2));
        console.log("the length of graph is ")


        if(this.slideron===2){

            this.service.graph2(this.graphresp1);}
        createbackg(svg,width,625,this.service.nodes,this.service.links);

        // add encompassing group for the zoom
        const g = svg.append('g')
        //  .attr('class', 'everything2')
            .attr('transform', 'translate(405,315) scale(0.2) !important');


        const link: any = g.append('g')
            .attr('class', 'links')
            .selectAll('path')
            .data(this.service.links)


            .enter().append('path')

            .attr('stroke-width', (d: any) => {

                return (d.value * 0.9);
            });
        this.sidebox=d3.select('.tooltip-wrapper1');
        link
            .attr('class', 'link')


        // console.log("in opacity link")
        //   tooltip.transition()
        //       .duration(200)
        //       .style('opacity', 1)
        //   tooltip.html('Source:' + d.source.id +
        //       '<p/>Target:' + d.target.id +
        //       '<p/>Link Strength:' + d.link_strength +
        //       '<p/>Strength:'  + d.value)
        //       .style('left', (d3.event.pageX) + 'px')
        //       .style('top', (d3.event.pageY + 10) + 'px');
        // })
        // .on('mouseout.tooltip', function() {
        //     tooltip.transition()
        //         .duration(100)
        //         .style('opacity', 0);
        // })
        // .on('mouseout.fade', fade(1))
        // .on('mousemove', function() {
        //     tooltip.style('left', (d3.event.pageX) + 'px')
        //         .style('top', (d3.event.pageY + 10) + 'px');
        // });
        const node: any = g.append('g')
            .attr('class', 'nodes')
            .selectAll('g')
            .data(this.service.nodes)
            // .on("mouseout", fade(1))
            .enter().append('g');

        // .on('click', (d: any, i: number) => {

        // this.clickname(d.date, d.id);
        // }
        const valbool = false;




        const circles: any =  node.append('svg:circle')
            .attr('r', 5)
            .attr('fill', function(d: any) {
                //console.log(d)
                // console.log(this.filters.length)
                // if(this.filters.includes[d.group[0]]){
                //     console.log(d.group[0])
                // }
                // else if(this.filters.includes[d.group[1]]){
                //     let x=d.group[0];
                //     d.group[0]=d.group[1];
                //     d.group[1]=x
                // }
                // else if(this.filters.includes[d.group[2]]){
                //     let x=d.group[0];
                //     d.group[0]=d.group[2];
                //     d.group[2]=x
                // }
                if (d.group === 'Doctor') {
                    return coloursd[0];
                } else if (d.group[0] === "Patient") {
                    return coloursd[1];
                } else if (d.group[0] === "Bloggers") {
                    return coloursd[2];
                } else if (d.group[0] === "Caregiver") {
                    return coloursd[3];
                } else if (d.group[0] === "HCP") {
                    return coloursd[4];
                } else if (d.group[0] === "reseacher") {
                    return coloursd[5];
                } else if (d.group [0]=== "Patient_advocate") {
                    return coloursd[6];
                } else if (d.group [0]=== "journalist/media") {
                    return coloursd[7];
                } else if (d.group[0] === "Org Med Devices") {
                    return coloursd[8];
                } else if (d.group[0] ===  "organization/NGO") {
                    return coloursd[9];
                } else {
                    return coloursd[10];
                }
            })
            .attr('transform',(d:any)=>{return 'translate('+d.x,d.y+') !important' ;})

            .attr('r', (d: any) => nodesize(d) )


            .on('click', (d) => {
                selectednode = d
                console.log("the selectednode is")
                console.log(selectednode)

                console.log('in box console');
                console.log(d);
                console.log(d.Profession);
                console.log(d.Score);
                // console.log("in opacity circle")
                this.sidebox.transition()
                //tooltip1.transition()
                    .duration(100)
                    .style('opacity', 1);

                this.sidebox.html('<div  xmlns="http://www.w3.org/1999/html"> ' +
                    '   <div style="display: flex;justify-content: flex-start;align-items: center;" class="graph-detial-tooltip-header"> ' +
                    '   <div class="tool-tip-img" style="margin-right: 15px;">  ' +
                    ' <img src="../../../../assets/images/profile-img.svg" alt="img" width="40px"> ' +
                    '   </div> ' +
                    '   <div class="tool-tip-ctgry-name" style="display: flex;flex-direction: column;width: 90%;text-align: left;"> ' +
                    ' <p><b>' + d.Author_Name + '</b> <br> <a> @' + d.id + '</a></p> ' +
                    '<p><span class="graph-area" style="margin-right: 40px;">' + d.Location + '</span></p> ' +
                    '</div> ' +
                    '</div> ' +
                    ' <div class="category-type" style="background-color: ' + this.colour_code(d.Profession[0]) + '"  style="color:white">' + d.Profession[0] + '</div>  ' +

                    '<div class="row-flex"> ' +
                    '<div class="col-flex"><strong>Influencer Score</strong><span> ' + d.Score + ' </span></div> ' +
                    '<div class="col-flex"><strong> No of  Tweets</strong><span> ' + d.Nopost + '  </span></div> ' +
                    '</div> ' +
                    '<div class="row-flex"> ' +
                    '<div class="col-flex"><strong>Followers</strong><span> ' + d.follower + '  </span></div> ' +
                    '<div class="col-flex"><strong>Interactions</strong><span> ' + d.Interaction + '  </span></div>    ' +
                    '</div> ' +
                    '<div class="tooltip-footer" style="border-top:1px solid #ddd;padding: 18px 0 0;display: flex;justify-content: space-evenly;">'+
                    '<div class="row-flex">'+
                    '<div class="col-flex tags"><strong>Common Hashtags used</strong><span>'+ d.hashtags[0]+'</span><span>'+d.hashtags[1]+'</span><span>'+d.hashtags[2]+'</span></div> '+
                    '</div>'+
                    '</div>'+
                    '<div class="tooltip-footer" style="border-top:1px solid #ddd;padding: 18px 0 0;display: flex;justify-content: space-evenly;">'+
                    '<div class="row-flex">'+
                    '<div class="col-flex tags"><strong>Common Keywords </strong><span> ' + d.Keywords[0]+ '  </span><span>'+d.Keywords[1]+'</span><span>'+d.Keywords[2]+'</span></div> '+
                    '</div>'+
                    '</div>'+

                    '<div class="tooltip-footer" style="border-top:1px solid #ddd;padding: 18px 0 0;display: flex;justify-content: space-evenly;"> ' +
                    //              '<a style="border: 1px solid #ddd;border-radius: 5px;font-size: 12px;padding: 5px 10px;" (click)="'+ this.clickname(d.date,d.id)+'" >Influencer Map</a> '+
                    '<button  id="influencermap" >Influencer Map</button> ' +
                    '<button  id="interaction" >Transcript</button> ' +
                    '</div> ' +
                    '</div> '

                )

                    .style('right',  24 + 'px')
                    .style('top', 356 + 'px');
                // @ts-ignore
                d3.select('#influencermap').on('click', ()  =>{
                    this.clickname(d.date,d.id);
                });
                // @ts-ignore
                d3.select('#interaction').on('click', ()  =>{
                    this.userinteraction(d.date,d.id);
                });

            })

            // .on('click', (d: any, i: number) => {

            // this.clickname(d.date, d.id);
            // });
            .on('mouseover.fade', fade(0.1))
            // .on('mouseout.tooltip', function() {
            //     tooltip.transition()
            //         .duration(100)
            //         .style('opacity', 1);
            // })
            // .on('mouseout.fade', fade(1))
            .on('mouseover', function() {
                tooltip.style('left', (d3.event.pageX) + 'px')
                    .style('top', (d3.event.pageY + 10) + 'px');

            });
        d3.select('.hello' )
            .attr('class', 'color-square-1')
            .on('click', (d: any, i: number) => {
                console.log('in hello');
                // this.clickname(d.date, d.id);
            });

        // var images = node.append("svg:image")
        //     .attr("xlink:href",  function(d) {
        //       return ("http://twitter.com/"+d.id+"/photo");})
        //     .attr("x", function(d) { return -25;})
        //     .attr("y", function(d) { return -25;})
        //     .attr("height", 50)
        //     .attr("width", 50);

        const lables: any = node.append('text')
            .attr('x', 0)
            .attr('dy', '.35em')
            .style('font-size', (d: any) => wordsize(d))

            .text(function(d) {
                return d.id;
            })
            .attr('x', 56)
            .attr('y', 0);
        function wordsize(d) {
            if (d.value === 35) {
                return '65px';
            } else if (d.value < 35 && d.value >= 30) {
                return '60px';
            } else if (d.value < 30 && d.value >= 20) {
                return '50px';
            } else if (d.value < 20 && d.value >= 10) {
                return '43px';
            } else {
                return '35px';
            }
        }

        function nodesize(d) {
            if (d.value === 35) {
                return '65px';
            } else if (d.value < 35 && d.value >= 30) {
                return '60px';
            } else if (d.value < 30 && d.value >= 20) {
                return '50px';
            } else if (d.value < 20 && d.value >= 10) {
                return '43px';
            } else {
                return '35px';
            }
        }
        // node.append("title")
        // // tslint:disable-next-line:only-arrow-functions
        //     .text(function(d) {
        //         return ("Rank "+ d.ranks);
        //     });



        simulation

            .nodes(this.service.nodes)


            .on('tick', ticked);
        function linkDistance(d) {
            let m = 0;
            if (d.value === 1 || d.value === 2) {
                m = 100;
            } else if (d.value > 2 && d.value < 5 ) {
                m = 80;
            } else if (d.value > 5 && d.value <= 10) {
                m = 60;
            } else if (d.value > 10 && d.value <= 20) {
                m = 50;
            } else if (d.value > 20 && d.value <= 40) {
                m = 30;
            } else if (d.value > 40) {
                m = 10;
            }

            return m;
        }
        const linkedByIndex = {};
        this.service.links.forEach(d => {
            console.log(d.source)
            console.log(d.target)
            linkedByIndex[d.source + ',' + d.target] = 1;
            // linkedByIndex[d.target.index + ',' + d.source.index] = 1;

        });

        function createbackg(tempsvg, width,height,nodes, links){

            console.log("inside createbackg")
            console.log(selectednode)
            tempsvg.append('g').append('rect')
                .attr('class','network-graph2')
                .attr('width', '100%')
                .attr('height', height)
                // .on('click', function() {
                //     console.log("inside on click")
                //      tooltip.transition()
                //          .duration(100)
                //         .style('opacity', 1);
                //  })
                .on('click', fadeoff(1));



        }

        function isConnected(a, b) {

            console.log(linkedByIndex[a.id + ',' + b.id])

            return linkedByIndex[a.id + ',' + b.id] || linkedByIndex[b.id + ',' + a.id] || a.id === b.id;
        }


        function fade(opacity) {
            console.log("inside fade")
            return d => {
                console.log("the fade is ")
                console.log(node)


                node.style('stroke-opacity', function(o) {

                    const thisOpacity = isConnected(d, o) ? 1 : opacity;
                    // console.log(d.id)
                    // console.log(thisOpacity)
                    this.setAttribute('fill-opacity', thisOpacity);
                    return thisOpacity;
                });

                link.style('stroke-opacity', o => (o.source === d || o.target === d ? 1 : opacity))
                link.style('stroke-width', o => (o.source === d || o.target === d ? 7 : 0.85))

            };
        }
        function fadeoff(opacity)  {
            console.log("inside fade")
            return d => {
                console.log("the fade is ")
                console.log(node)
                console.log(selectednode)


                node.style('stroke-opacity', function(o) {

                    const thisOpacity = isConnected(selectednode, o) ? 1 : opacity;
                    // console.log(d.id)
                    // console.log(thisOpacity)
                    this.setAttribute('fill-opacity', thisOpacity);
                    return thisOpacity;
                });

                link.style('stroke-opacity', o => (o.source === selectednode || o.target ===selectednode ? 1 : opacity))
                link.style('stroke-width', o => (o.source === selectednode || o.target === selectednode ? 7 : 0.85))

            };
        }



        function ticked() {



            node
                .attr('transform', (d: any) => {

                    return 'translate(' + d.x + ',' + d.y + ')';
                });
            link.attr('d', function(d) {


                const dx = d.target.x - d.source.x,
                    dy = d.target.y - d.source.y,
                    dr = Math.sqrt(dx * dx + dy * dy);


                return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + dr + ' 0 0,1 ' + d.target.x + ',' + d.target.y;
            });



            // restart();
            //
            // function restart() {
            //
            //     node.enter().append("g")
            //         .attr('class', 'nodes')
            //         .selectAll('g')
            //         .data(this.service.nodes)
            //     node.append("circle")
            //         .attr("r", 10)
            //         .on("mousedown", onClick);
            //     node.append("text")
            //         .attr("dx", 12)
            //         .attr("dy", ".35em")
            //         .text(d => d.name);
            //     node.exit().remove();
            //
            //     link = link.data(links);
            //
            //     force.start();


        }

        simulation.force('link')
            .links(this.service.links);

        // add drag capabilities
        const dragHandler = d3.drag()
            .on('start', dragStarted)
            .on('drag', dragged)
            .on('end', dragEnded);

        dragHandler(node);

        function dragStarted(d: any) {
            // simulation.force.stop()

            if (!d3.event.active) {
                simulation.alphaTarget(0.2).restart();
            }
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(d: any) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        function dragEnded(d: any) {
            //if (!d3.event.active) {
            simulation.alphaTarget(0);
            //}
            //d.fx = null;
            // d.fy = null;
        }
        let i=0;
        function reset() {
            console.log('in reset')
            i=0
            g
                .attr('class', 'everything1')
                .attr('transform', 'translate(405,315) scale(0.3)');
            console.log(g);

        }
        function reset2() {
            console.log('in reset')
            i=0
            g
                .attr('class', 'everything1')
                .attr('transform', 'translate(405,315) scale(0.3)' );
            console.log(g);

        }
        function createZoomableGraph2(svg: any, scaleExtent: [number, number], zoomActions: any): void {

            const zoomHandler = d3.zoom().scaleExtent(scaleExtent).on('zoom', zoomActions);
            svg.call(zoomHandler).on('dblclick.zoom', null);
            console.log(d3.zoomIdentity)
            svg.call(zoomHandler.transform, d3.zoomIdentity);
            console.log(svg.transition())
            zoomHandler.scaleTo(svg.transition(), 0);

        }

        const zoomHandler = d3.zoom()
            .on('zoom', zoomActions);

        // zoomHandler(svg);
        //this.ellipseForceService.createZoomableGraph(svg, [0.3, 4], () => {
        createZoomableGraph2(svg, [0.3, 4], () => {



            g.attr('transform', d3.event.transform);
            reset2();




        });



        // Zoom functions
        function zoomActions() {
            g.attr('transform', d3.event.transform);
        }

        let var2=0.3

        function zoomin(){
            i=i+1
            console.log(" in zoom in ")
            if(i==0){
                g.attr('transform', ' translate(405,315) scale(0.4)');
            }
            else if (i===1){
                g.attr('transform', 'translate(405,315) scale(0.5)');
            }
            else if (i===2){
                g.attr('transform', 'translate(405,315) scale(0.6)');
            }
            else if (i===3){
                g.attr('transform', 'translate(405,315) scale(0.7)');
            }
            else if (i===4){
                g.attr('transform', 'translate(405,315) scale(0.8)');
            }
            else if (i===5){
                g.attr('transform', 'translate(405,315) scale(0.9)');
            }
            else if (i===6){
                g.attr('transform', 'translate(405,315) scale(1)');
            }
            else if (i===7){
                g.attr('transform', 'translate(405,315) scale(1.1)');
            }
            else if (i===8){
                g.attr('transform', 'translate(405,315) scale(1.2)');
            }
            else if (i===9){
                g.attr('transform', 'translate(405,315) scale(1.3)');
            }
            else if (i===10){
                g.attr('transform', 'translate(405,315) scale(1.4)');
            }
            else if (i===11){
                g.attr('transform', 'translate(405,315) scale(1.5)');
            }
            else{
                g.attr('transform', 'translate(405,315) scale(1.5)');
            }

        }
        function zoomout(){

            if(i===0){
                g.attr('transform', 'translate(405,315) scale(0.3)')
            }
            else if (i===1){
                g.attr('transform', 'translate(405,315) scale(0.3)');
            }
            else if (i===2){
                g.attr('transform', 'translate(405,315) scale(0.4)');
            }
            else if (i===3){
                g.attr('transform', 'translate(405,315) scale(0.5)');
            }
            else if (i===4){
                g.attr('transform', 'translate(405,315) scale(0.6)');
            }
            else if (i===5){
                g.attr('transform', 'translate(405,315) scale(0.7)');
            }
            else if (i===6){
                g.attr('transform', 'translate(405,315) scale(0.8)');
            }
            else if (i===7){
                g.attr('transform', 'translate(405,315) scale(0.9)');
            }
            else if (i===8){
                g.attr('transform', 'translate(405,315) scale(1.0)');
            }
            else if (i===9){
                g.attr('transform', 'translate(405,315) scale(1.1)');
            }
            else if (i===10){
                g.attr('transform', 'translate(405,315) scale(1.2)');
            }
            else if (i===11){
                g.attr('transform', 'translate(405,315) scale(1.3)');
            }
            else if (i===12){
                g.attr('transform', 'translate(405,315) scale(1.4)');
            }
            i=i-1



        }

        // @ts-ignore
        this.buildGraph.reset = reset;
        // @ts-ignore
        this.buildGraph.zoomin = zoomin;
        // @ts-ignore
        this.buildGraph.zoomout = zoomout;
        // @ts-ignore
        let x=true;
        setTimeout(()=>{
            this.showloader=false
        },5000);
        console.log(this.showloader)
        //     this.showloader=x



    }

    buildGraph2(): void {
        let selectednode: any=[]
        this.showloader=true;
        this.showFilters = true;
        this.typegraphcont=false;
        this.typegraphmen=true;

        // console.log('build graph 2');

        const svg2 = d3.select('.network-graph');
        svg2.selectAll('svg').remove();
        const keys = ['Doctor', 'Patient', 'Bloggers', 'Caregiver', 'HCP', 'reseracher', 'Patient_advocate', 'journalist/media', ' Org Med Devices', 'organization/NGO', 'others'];


        const tooltip = d3.select('body')
            .append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);

        //   this.service.graph(this.graphresp)

        //  this.service.graph(this.graphresp);
        // const coloursd = ['red', 'blue', 'green', 'yellow', 'black', 'violet', 'orange', 'brown', 'pink', 'grey', 'white'];
        const coloursd = ['#E82D4F', '#52AA8E', '#759ECC', '#CA7FA8', '#CEC07A', '#D14FC6', '#6A9C9C', '#63B142', '#465E73', '#823E45', '#682DE6'];
        const svg: any = d3.select('.network-graph').append('svg');
        const width: any = +svg.attr('width');
        const height: any = +svg.attr('height');
        const color: any = d3.scaleOrdinal(coloursd);

        const simulation: any = d3.forceSimulation()
            .force('link', d3.forceLink().id((d: any) => d.id)
                .distance((d: any ) => linkDistance(d)).strength(0.1)
            )
            .force('charge', d3.forceManyBody())
            .force('collide', d3.forceCollide().radius((d: any) => {
                return +d.value + 90;
            }))

            .force('center', d3.forceCenter(width / 2, height / 2));

        const g = svg.append('g')
        //   .attr('class', 'everything1')
            .attr('transform', 'translate(405,300) scale(0.3)');

        g
        // .attr('class', 'everything1')
            .attr('transform', 'translate(405,315) scale(0.3)');


        const link: any = g.append('g')
            .attr('class', 'links')
            .selectAll('path')
            .data(this.service.links2)
            .enter().append('path')

            .attr('stroke-width', (d: any) => {
                //     console.log(d.value)

                return (d.value * 0.9);
            });
        link
            .attr('class', 'link')
            .on('mouseover.tooltip', function(d) {
                tooltip.transition()
                    .duration(300)
                    .style('opacity', .8);
                tooltip.html('Source:' + d.source.id +
                    '<p/>Target:' + d.target.id +
                    '<p/>Strength:'  + d.value)
                    .style('left', (d3.event.pageX) + 'px')
                    .style('top', (d3.event.pageY + 10) + 'px');
            })
            .on('mouseout.tooltip', function() {
                tooltip.transition()
                    .duration(100)
                    .style('opacity', 0);
            })
            .on('mouseout.fade', fade(1))
            .on('mousemove', function() {
                tooltip.style('left', (d3.event.pageX) + 'px')
                    .style('top', (d3.event.pageY + 10) + 'px');
            });


        const node: any = g.append('g')
            .attr('class', 'nodes')
            .selectAll('g')
            .data(this.service.nodes2)
            // .on("mouseout", fade(1))
            .enter().append('g');
        this.sidebox=d3.select('.tooltip-wrapper1');

        const circles: any = node.append('svg:circle')
            .attr('r', 5)
            .attr('fill', (d: any)=> {

                console.log(this.showFilters)
                if (d.group[0] === 1) {
                    return coloursd[0];
                } else if (d.group [0]=== 2) {
                    return coloursd[1];
                } else if (d.group[0] === 3) {
                    return coloursd[2];
                } else if (d.group[0] === 4) {
                    return coloursd[3];
                } else if (d.group [0]=== 5) {
                    return coloursd[4];
                } else if (d.group [0]=== 6) {
                    return coloursd[5];
                } else if (d.group [0]=== 7) {
                    return coloursd[6];
                } else if (d.group[0] === 8) {
                    return coloursd[7];
                } else if (d.group[0] === 9) {
                    return coloursd[8];
                } else if (d.group [0]=== 10) {
                    return coloursd[9];
                } else {
                    return coloursd[10];
                }
            })
            .attr('r', (d: any) => nodesize(d) )
            .on('click', (d) => {
                selectednode = d
                console.log("the selectednode is")
                console.log(selectednode)


                console.log('in box console');
                console.log(d);
                console.log(d.Profession);
                console.log(d.Score);
                console.log(d.Nopost)
                // console.log("in opacity circle")
                this.sidebox.transition()
                //tooltip1.transition()
                    .duration(100)
                    .style('opacity', 1);

                this.sidebox.html('<div  xmlns="http://www.w3.org/1999/html"> ' +
                    '   <div style="display: flex;justify-content: flex-start;align-items: center;" class="graph-detial-tooltip-header"> ' +
                    '   <div class="tool-tip-img" style="margin-right: 15px;">  ' +
                    ' <img src="../../../../assets/images/profile-img.svg" alt="img" width="40px"> ' +
                    '   </div> ' +
                    '   <div class="tool-tip-ctgry-name" style="display: flex;flex-direction: column;width: 90%;text-align: left;"> ' +
                    ' <p><b>' + d.Author_Name + '</b> <br> <a> @' + d.id + '</a></p> ' +
                    '<p><span class="graph-area" style="margin-right: 40px;">' + d.Location + '</span></p> ' +
                    '</div> ' +
                    '</div> ' +
                    ' <div class="category-type" style="background-color: ' + this.colour_code(d.Profession[0]) + '"  style="color:white">' + d.Profession[0] + '</div>  ' +
                    ' <div class="category-type" style="background-color: ' + this.colour_code(d.Profession[1]) + '"  style="color:white">' + d.Profession[1] + '</div>  ' +

                    '<div class="row-flex"> ' +
                    '<div class="col-flex"><strong>Influencer Score</strong><span> ' + d.Score + ' </span></div> ' +
                    '<div class="col-flex"><strong> No of  Tweets</strong><span> ' + d.Nopost + '  </span></div> ' +
                    '</div> ' +
                    '<div class="row-flex"> ' +
                    '<div class="col-flex"><strong>Followers</strong><span> ' + d.follower + '  </span></div> ' +
                    '<div class="col-flex"><strong>Interactions</strong><span> ' + d.Interaction + '  </span></div>    ' +
                    '</div> ' +

                    '<div class="tooltip-footer" style="border-top:1px solid #ddd;padding: 18px 0 0;display: flex;justify-content: space-evenly;">'+

                    '</div>'+

                    //              '<a style="border: 1px solid #ddd;border-radius: 5px;font-size: 12px;padding: 5px 10px;" (click)="'+ this.clickname(d.date,d.id)+'" >Influencer Map</a> '+

                    '</div> ' +
                    '</div> '

                )

                    .style('right',  24 + 'px')
                    .style('top', 380 + 'px');
                // @ts-ignore

            })
            // .on('mouseout.fade', fade(1))
            .on('mouseover.fade', fade(0.1))
            .on('mouseout.tooltip', function() {
                tooltip.transition()
                    .duration(100)
                    .style('opacity', 0);
            })
            .on('mouseout.fade', fade(1))
            .on('mouseover', function() {
                tooltip.style('left', (d3.event.pageX) + 'px')
                    .style('top', (d3.event.pageY + 10) + 'px');
            });
        // var imges = node.append("svg:image")
        //     .attr("xlink:href",  function(d) {
        //         return ("http://twitter.com/"+d.id+"/photo");})
        //     .attr("x", function(d) { return -25;})
        //     .attr("y", function(d) { return -25;})
        //     .attr("height", 50)
        //     .attr("width", 50);

        const lables: any = node.append('text')

            .attr('x', 0)
            .attr('dy', '.35em')
            .style('font-size', (d: any) => wordsize(d))

            .text(function(d) {

                return d.id;
            })

            .attr('x', 42)
            .attr('y', 3);
        function wordsize(d) {
            if (d.value === 35) {
                return '65px';
            } else if (d.value < 35 && d.value >= 30) {
                return '60px';
            } else if (d.value < 30 && d.value >= 20) {
                return '50px';
            } else if (d.value < 20 && d.value >= 10) {
                return '43px';
            } else {
                return '35px';
            }
        }

        function nodesize(d) {
            if (d.value === 35) {
                return '65px';
            } else if (d.value < 35 && d.value >= 30) {
                return '60px';
            } else if (d.value < 30 && d.value >= 20) {
                return '50px';
            } else if (d.value < 20 && d.value >= 10) {
                return '43px';
            } else {
                return '35px';
            }
        }

        simulation
            .nodes(this.service.nodes2)

            .on('tick', ticked);
        function linkDistance(d) {
            let m = 0;
            if (d.value === 1 || d.value === 2) {
                m = 100;
            } else if (d.value > 2 && d.value < 5 ) {
                m = 80;
            } else if (d.value > 5 && d.value <= 10) {
                m = 60;
            } else if (d.value > 10 && d.value <= 20) {
                m = 50;
            } else if (d.value > 20 && d.value <= 40) {
                m = 30;
            } else if (d.value > 40) {
                m = 10;
            }
            return m;
        }

        const linkedByIndex = {};
        this.service.links2.forEach(d => {

            linkedByIndex[d.source + ',' + d.target] = 1;
            //console.log(linkedByIndex)
            // linkedByIndex[d.target.index + ',' + d.source.index] = 1;
        });



        function isConnected(a, b) {



            return linkedByIndex[a.id + ',' + b.id] || linkedByIndex[b.id + ',' + a.id] || a.id === b.id;
        }


        function fade(opacity) {

            return d => {

                console.log(node)


                node.style('stroke-opacity', function(o) {

                    const thisOpacity = isConnected(d, o) ? 1 : opacity;
                    // console.log(d.id)
                    // console.log(thisOpacity)
                    this.setAttribute('fill-opacity', thisOpacity);
                    return thisOpacity;
                });

                link.style('stroke-opacity', o => (o.source === d || o.target === d ? 1 : opacity))
                //link.style('stroke-width', o => (o.source === d || o.target === d ? 7 : 0.85))

            };
        }
        function fadeoff(opacity)  {
            console.log("inside fade")
            return d => {
                console.log("the fade is ")
                console.log(node)
                console.log(selectednode)


                node.style('stroke-opacity', function(o) {

                    const thisOpacity = isConnected(selectednode, o) ? 1 : opacity;
                    // console.log(d.id)
                    // console.log(thisOpacity)
                    this.setAttribute('fill-opacity', thisOpacity);
                    return thisOpacity;
                });

                link.style('stroke-opacity', o => (o.source === selectednode || o.target ===selectednode ? 1 : opacity))
                link.style('stroke-width', o => (o.source === selectednode || o.target === selectednode ? 7 : 0.85))

            };
        }



        function ticked() {


            node
                .attr('transform', (d: any) => {

                    return 'translate(' + d.x + ',' + d.y + ')';
                });
            link.attr('d', function (d) {


                const dx = d.target.x - d.source.x,
                    dy = d.target.y - d.source.y,
                    dr = Math.sqrt(dx * dx + dy * dy);


                return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + dr + ' 0 0,1 ' + d.target.x + ',' + d.target.y;
            });
        }

        simulation.force('link')
            .links(this.service.links2);


        // add drag capabilities

        const dragHandler = d3.drag()
            .on('start', dragStarted)
            .on('drag', dragged)
            .on('end', dragEnded);

        dragHandler(node);

        function dragStarted(d: any) {
            if (!d3.event.active) {

                simulation.alphaTarget(0.2).restart();
            }
            d.fx = d.x;
            d.fy = d.y;
        }


        function dragged(d: any) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        function dragEnded(d: any) {
            // if (!d3.event.active) {
            simulation.alphaTarget(0);
            //}
            // d.fx = null;
            // d.fy = null;
        }


        let i=0;
        function reset() {
            console.log('in reset')
            i=0
            g
                .attr('class', 'everything1')
                .attr('transform', 'translate(405,315) scale(0.3)');
            // console.log(g);

        }

        const zoomHandler = d3.zoom()
            .on('zoom', zoomActions);

        // zoomHandler(svg);
        this.ellipseForceService.createZoomableGraph(svg, [0.3, 4], () => {

            g.attr('transform', d3.event.transform);
            reset();
        });


        // Zoom functions
        function zoomActions() {
            g.attr('transform', d3.event.transform);
        }

        let var2=0.3
        function zoomin(){
            i=i+1
            console.log(" in zoom in ")
            if(i==0){
                g.attr('transform', ' translate(405,315) scale(0.4)');
            }
            else if (i===1){
                g.attr('transform', 'translate(405,315) scale(0.5)');
            }
            else if (i===2){
                g.attr('transform', 'translate(405,315) scale(0.6)');
            }
            else if (i===3){
                g.attr('transform', 'translate(405,315) scale(0.7)');
            }
            else if (i===4){
                g.attr('transform', 'translate(405,315) scale(0.8)');
            }
            else if (i===5){
                g.attr('transform', 'translate(405,315) scale(0.9)');
            }
            else if (i===6){
                g.attr('transform', 'translate(405,315) scale(1)');
            }
            else if (i===7){
                g.attr('transform', 'translate(405,315) scale(1.1)');
            }
            else if (i===8){
                g.attr('transform', 'translate(405,315) scale(1.2)');
            }
            else if (i===9){
                g.attr('transform', 'translate(405,315) scale(1.3)');
            }
            else if (i===10){
                g.attr('transform', 'translate(405,315) scale(1.4)');
            }
            else if (i===11){
                g.attr('transform', 'translate(405,315) scale(1.5)');
            }
            else{
                alert("Maximum zoom-in level reached.");
            }

        }
        function zoomout(){

            if(i===0){
                g.attr('transform', 'translate(405,315) scale(0.3)');
            }
            else if (i===1){

            }
            else if (i===2){
                g.attr('transform', 'translate(405,315) scale(0.4)');
            }
            else if (i===3){
                g.attr('transform', 'translate(405,315) scale(0.5)');
            }
            else if (i===4){
                g.attr('transform', 'translate(405,315) scale(0.6)');
            }
            else if (i===5){
                g.attr('transform', 'translate(405,315) scale(0.7)');
            }
            else if (i===6){
                g.attr('transform', 'translate(405,315) scale(0.8)');
            }
            else if (i===7){
                g.attr('transform', 'translate(405,315) scale(0.9)');
            }
            else if (i===8){
                g.attr('transform', 'translate(405,315) scale(1.0)');
            }
            else if (i===9){
                g.attr('transform', 'translate(405,315) scale(1.1)');
            }
            else if (i===10){
                g.attr('transform', 'translate(405,315) scale(1.2)');
            }
            else if (i===11){
                g.attr('transform', 'translate(405,315) scale(1.3)');
            }
            else if (i===12){
                g.attr('transform', 'translate(405,315) scale(1.4)');
            }
            i=i-1



        }
        setTimeout(()=>{
            this.showloader=false
        },5000);

        // @ts-ignore
        this.buildGraph2.reset = reset;
        // @ts-ignore
        this.buildGraph2.zoomin = zoomin;
        // @ts-ignore
        this.buildGraph2.zoomout = zoomout;



    }


    ngOnChanges() {

        if (this.val === 'true') {

            this.service.graph(this.graphresp)
            this.buildGraph2()


        } else {
            this.service.graph2(this.graphresp1)



            this.buildGraph();


        }



    }

    ngOnInit() {

    }



    // tslint:disable-next-line:use-life-cycle-interface




}
