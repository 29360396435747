import {Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input} from "@angular/core";
import {SharedServiceService} from "../../services/shared-service.service";

@Component({
  selector: "app-sitefilters",
  templateUrl: "./sitefilters.component.html",
  styleUrls: ["./sitefilters.component.css"]
})
export class SitefiltersComponent implements OnInit {

  public sites: any[] = [];
  @Output() filterChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() checkedSiteFilters: any = [];

  constructor(private myservice: SharedServiceService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  public updateCheckedSiteFilters(site: string, event) {
    if (event.target.checked) {
      if (site === "All channels") {
        this.checkedSiteFilters = ["All channels"];
      }
      else {
        const allChannelsIndex = this.checkedSiteFilters.indexOf("All channels")
        if (allChannelsIndex > -1) {
          this.checkedSiteFilters.splice(allChannelsIndex, 1);
        }
        const index = this.checkedSiteFilters.indexOf(site);
        if (index <= -1) {
          this.checkedSiteFilters.push(site);
          // TODO: not the right approach to call detect Changes manually
          this.changeDetectorRef.detectChanges();
        }
        if ((this.checkedSiteFilters.length) === 5) {
          this.checkedSiteFilters = [];
          this.checkedSiteFilters.push("All channels");
        }
      }
    }
    else {
      const index = this.checkedSiteFilters.indexOf(site);
      if (index > -1) {
        this.checkedSiteFilters.splice(index, 1);
      }
      if (this.checkedSiteFilters.length === 0) {
        this.checkedSiteFilters.push("All channels");
      }
    }
    this.filterChanged.emit(this.checkedSiteFilters);
  }

  public removeCheckedFilter(site: string) {
    const index = this.checkedSiteFilters.indexOf(site);
    if (index > -1) {
      this.checkedSiteFilters.splice(index, 1);
    }
    if (this.checkedSiteFilters.length === 0) {
      this.checkedSiteFilters.push("All channels");
    }
    this.filterChanged.emit(this.checkedSiteFilters);
  }

  ngOnInit() {
    this.sites = this.myservice.sites;
  }

}
