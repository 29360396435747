import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as $ from 'jquery';
import * as moment from 'moment';
import {HeaderDateChangedService} from './header-date-changed.service';
import {WorkspaceService} from '../../workspace/workspace.service';
import {  NgZone } from '@angular/core';
import {SharedServiceService} from "../../services/shared-service.service";
import {CustomTopicsService} from "../../custom-topics/custom-topics.service";
import { Router,ActivatedRoute} from '@angular/router';
import {AppRoutingModule} from '../../app-routing.module';
import {CustomTopicsComponent} from "../../custom-topics/custom-topics.component";
import {HomeComponent} from "../../home/home.component";
import {AuthGuard} from "../../auth.guard";
import {MessageModalComponent} from "../message-modal/message-modal.component";
import { forwardRef } from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']

})
export class HeaderComponent implements OnInit {
  @Input() activenewlink;
  @Input() newlink = 'false';
  @Input() topicspage: any=[];
  // @ViewChild (CustomTopicsComponent) child: CustomTopicsComponent


  public appRoutingModule: AppRoutingModule
  //const newval=CustomTopicsComponent;


  constructor( private route: ActivatedRoute,private router: Router, private customtopic: CustomTopicsService  ,private sharedser:SharedServiceService,private dateChangedService: HeaderDateChangedService, private wsService: WorkspaceService,private ngZone: NgZone) {
  }
  public openpage=false;
   public Routingname=false;

  public title: string = environment.title;
  public daterange: any = {};
  public minDate: any = moment('2018-09-01', 'YYYY-MM-DD');
  public maxDate: any = moment('2019-04-30', 'YYYY-MM-DD');
  public initialStartDate: any = moment('2019-01-01', 'YYYY-MM-DD');
  public todaysDate: any = moment();
  public dateDisplay: any;
  public topicsActive: boolean = false;
  public flagtype=0;


  public options: any = {
    ranges: {
      'Last 30 Days': [moment(this.todaysDate).subtract(30, 'days'), moment(this.maxDate)],
      'Last quarter': [moment(this.todaysDate).subtract(90, 'days'), moment(this.maxDate)]
    },
    startDate: this.minDate,
    locale: {format: 'YYYY-MM-DD'},
    alwaysShowCalendars: true,
    showCustomRangeLabel: true,
    minDate: this.minDate,
    maxDate: this.maxDate
  };

  public selectedDate(value: any, datepicker?: any) {
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.dateDisplay = value.start.format('Do MMM YY') + ' - ' + value.end.format('Do MMM YY');
    this.storeDateInLS(datepicker.start, datepicker.end);
    this.dateChangedService.announceDate();
  }

  /*
      Storing date in localstorage
  */
  public addlinksinHeader(draft){
    this.newlink = 'true';
    this.activenewlink = draft;
    console.log(this.activenewlink);
    console.log(this.newlink)
  }

  public storeDateInLS(start: any, end: any) {
    const datearr: string[] = [];
    datearr.push(start.format('YYYY-MM-DD'));
    datearr.push(end.format('YYYY-MM-DD'));
    localStorage.setItem('dates', JSON.stringify(datearr));
  }

  public checkIfDateIsInLS() {
    if (!localStorage.getItem('dates')) {
      return false;
    } else {
      return true;
    }
  }

  public getDateFromLS() {
    if (localStorage.getItem('dates') !== null) {
      const start = JSON.parse(localStorage.getItem('dates'))[0];
      const end = JSON.parse(localStorage.getItem('dates'))[1];
      return [start, end];
    } else {
      // TODO: A little repetitive else block can be improved
      this.options.startDate = this.minDate;
      this.options.endDate = this.maxDate;
      this.dateDisplay = this.minDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
      this.storeDateInLS(this.minDate, this.maxDate);
      return [this.minDate.format('YYYY-MM-DD'), this.maxDate.format('YYYY-MM-DD')];
    }
  }

  public headerNavBar() {
    $('nav ul li').children('.inner-menu').toggle('fast');
  }

  // public headerNavBar2() {
  //   $('.inner-menu ul li').children('.sub-inner-menu').toggle('fast');
  // }
  //
  // public headerNavBar2() {
  //   $('nav ul li').children('.sub-inner-menu').toggle('fast');
  // }

  public logout() {
    this.deleteCookie('credentials');
  }

  public deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  public pageis(name){
    console.log("in pageis")
    this.sharedser.fromheader=1;
    this.sharedser.pagenamefromheader=name;
    this.openpage =true;
    console.log(name);
    console.log(this.sharedser.headerval)
//this.appRoutingModule.addToRoutes();
    console.log(this.route.snapshot);
      this.Routingname=true;
    //this.router.config.push({path: name, component: CustomTopicsComponent})
     this.sharedser.approut2();
     let flag=0;
    console.log(this.router.config);

console.log(name);
    if(this.sharedser.headerval===0) {
    this.router.config[29].path = name;
  this.sharedser.headerval=1;
}
else{
    this.router.config[30].path = name;
  this.sharedser.headerval=0;
}
    console.log(this.router.config);

console.log(this.sharedser.headerval)



    //  console.log(this.child);

    // this.router.events.subscribe(event =>{
    //   if (event instanceof CustomTopicsComponent){
    //
    //   }
    // })
    this.sharedser.keywordsTopics=[];
    console.log(this.router.config);
    this.router.navigate(['/'+name]);
      console.log("after navigation")

  }
  public newtest(){
    console.log("in nwtest");
  }

  ngOnInit() {
    console.log("in ngonit")
    this.sharedser.keywordsTopics={};

    console.log(this.topicspage)
    if(this.activenewlink){
    this.sharedser.fromheader=1

    }

      // Todo: Click outside directive should be used instead of jquery.
      document.addEventListener('click', (evt) => {
        if ($('.inner-menu ul li').is(':visible')) {
          const flyoutElement = document.getElementsByClassName('topics');
          let targetElement = evt.target; // clicked element
          do {
            if ($(targetElement).is('.topics')) {
              return;
            }
            targetElement = (<HTMLElement>(<HTMLElement>targetElement).parentNode);
          } while (targetElement);
          $('.inner-menu').hide();
        }
      });

      this.wsService.getActiveWorkspace().then((activeWS: any) => {
        if (activeWS && activeWS.from && activeWS.to) {
          this.storeDateInLS(moment(activeWS.from), moment(activeWS.to));
          this.options.startDate = moment(activeWS.from);
          this.options.endDate = moment(activeWS.to);
          this.dateDisplay = moment(activeWS.from).format('Do MMM YY') + ' - ' + moment(activeWS.to).format('Do MMM YY');
        } else {
          this.options.startDate = this.initialStartDate;
          this.options.endDate = this.maxDate;
          this.dateDisplay = this.initialStartDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
          this.storeDateInLS(this.initialStartDate, this.maxDate);
        }
      }).catch((err: any) => console.error(err));

      // Todo: Add tag if date contains 'st' or 'th'

  }
}
