import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class InnerModalMessagesService {

  constructor(
      private apiService: ApiService
  ) {
  }

  public getMessagesModal(start: any, end: any, phrase: string): Promise<any> {
    let url = '/messages/?start=' + start + '&end=' + end + '&phrase=' + phrase;

    return new Promise((resolve, reject) => {
      this.apiService.put(url, null).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }
}
