import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as d3 from 'd3';
import {EllipseForceService} from '../reusable/d3/ellipse-force/ellipse-force.service';
import {WordCloudService} from '../reusable/d3/word-cloud/word-cloud.service';
import {MessageService} from './message.service';
import {HeaderDateChangedService} from '../reusable/header/header-date-changed.service';
import {HeaderComponent} from '../reusable/header/header.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from './modal/modal.component';
import {ModalService} from './modal/modal.service';
import {WorkspaceService} from '../workspace/workspace.service';
import {ToastrService} from 'ngx-toastr';
import {SubClusterService} from './sub-cluster/sub-cluster.service';
import {MatCardModule} from "@angular/material/card";
import {MatSliderModule} from "@angular/material/slider";

const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MessagesComponent implements OnInit {

  public groupLabel: string = '';
  public selectedGroup: number = 0;
  public showLoader = false;
  public groupLabels: any = {};
  public isSubClusterTabActive: boolean = false;
  public clickBoxButtonClicked: string;
  svgSelectors: any = {
    main: '.main-svg',
    compare: '.compare-svg',
    sentiments: '.sentiment-svg'
  };
  showSVG: string = ''; // available values from this.svgSelectors
  color: string[] = [
    '#9D7C10',
    '#1ec1c7',
    '#468f61',
    '#009975',
    '#de752a',
    '#8f33b9',
    '#332fa3',
    '#fd6683',
    '#e04d2f',
    '#fd6683',
    '#1f67db'
  ]; // color: any = d3.scaleOrdinal(d3.schemeCategory10);
  public brands: string[] = ['Abbott FreeStyle', 'Dexcom', 'Roche Accu-Chek', 'Medtronic Minimed'];

  compare1: string = this.brands[0];
  compare2: string = this.brands[1];
  parentGroup: number = 5;
  nodes: any[] = [];
  links: any[] = [];
  wordCloudNodesMap: any;
  nodeTextMap: any;
  nodeGroups: any;
  nodeLinksMap: any;
  rawLinks: any;
  subClusterKeywords: any[];
  showSubClusterView = false;
  selectedNodes: any = {};
  selectedLinks: any = {};
  selectedWords: any = {};
  clickedWords: string[] = [];
  public wordsOccurrence: any[] = [];
  excludeWords: string[];
  shortListedWords: string[];
  groupKeywordsCount: any = {};
  initialTick = true;
  nodePositions: any = {};
  clickBox: any;
  isVariablesLoaded: boolean = false;
  autoTicks = false;
  disabled = false;
  invert = false;
  max = 40;
  min = 15;
  showTicks = false;
  step = 5;
  thumbLabel = true;
  slideLimit = 15;
  vertical = false;
  tickInterval = 5;

  constructor(
      private messageService: MessageService,
      private ellipseForceService: EllipseForceService,
      private wordCloudService: WordCloudService,
      public header: HeaderComponent,
      public dateChangedService: HeaderDateChangedService,
      private modalService: ModalService,
      private toastr: ToastrService,
      private workspaceService: WorkspaceService,
      private subClusterService: SubClusterService,
      private ngbModalService: NgbModal) {
    // Initialize values
    Object.values(this.svgSelectors).forEach((selector: string) => {
      this.selectedNodes[selector] = [];
      this.selectedLinks[selector] = [];
      this.selectedWords[selector] = {};
    });
    dateChangedService.dateChanged$.subscribe(
        mission => {
          const dates: any[] = this.header.getDateFromLS();
          this.workspaceService.resetWorkspace(workspace, {start: dates[0], end: dates[1]}).then(() => {
            if (location.pathname.includes('messages')) {
              this.showLoader = true;
              location.reload();
            }
          }).catch((error) => {
            console.error(error);
          });
        });
  }



  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

  public saveLabel(label: any): void {
    // This is duplicate, original implementation is in modal.component, remove it while refactoring.
    // this.messageService.saveLabel({label: label, group: this.selectedGroup}).then((resp: any) => {
    //   this.groupLabels[this.selectedGroup] = label;
    // }).catch((ex) => console.error(ex));
  }

  public compareBrands(brands: any): void {
    /*this.showLoader = true;*/
    if (brands) {
      this.compare1 = brands[0];
      this.compare2 = brands[1];
      this.showComparisonGraph();
    } else {
      this.showMainGraph();
    }
    /*this.showLoader = false;*/
  }

  public toggleSentiments(show: any): void {
    if (show) {
      this.showSentimentGraph();
    } else {
      this.showMainGraph();
    }
  }

  public wordSelectedInLeftComponent(word: any): void {
    this.clickedWords = [word];
  }

  private initSVGSelector(): any {
    let svgSelector: any;
    const allSVGs: any = d3.selectAll('svg');
    allSVGs.append('g');
    allSVGs.selectAll('*').remove(); // Clear existing SVGs contents.
    Object.values(this.svgSelectors).forEach((selector: string) => {
      let selection: any = d3.select(selector);
      if (selector !== this.showSVG) {
        selection.style('display', 'none');
      } else {
        selection.style('display', 'block');
        svgSelector = selection;
      }
    });
    return svgSelector;
  }

  private appendRect(targetSVG: any, selector: any, dimensions: any, datum: any): void {

    targetSVG.select(selector).append('rect')
        .datum(datum)
        .attr('class', 'rect-node')
        .attr('fill', '#fcfcfc')
        .attr('stroke', '#616161')
        .attr('stroke-width', 0.5)

        .attr('width', dimensions.rectWidth)
        .attr('height', dimensions.rectHeight);
  }

  private highlightNodes(targetSVG: any, nodesToBeHighlighted: number[]): void {
    targetSVG.selectAll('.node')
        .attr('opacity', (d: any) => {
          return nodesToBeHighlighted.includes(d.id) ? 1 : 0.3;
        });
    targetSVG.selectAll('.rect-node')
        .attr('stroke-width', (d: any) => {
          return this.selectedNodes[this.showSVG].includes(d.id) ? 5 : 0.5;
        });
  }

  private highlightLinks(targetSVG: any, linksToBeHighlighted: number[]): void {
    this.selectedLinks[this.showSVG] = linksToBeHighlighted;
    targetSVG.selectAll('.link')
        .attr('stroke', (d: any, i: number) => {
          return linksToBeHighlighted.includes(i) ? '#71A365' : '#b7b5b5';
        })
        .attr('opacity', (d: any, i: number) => {
          return linksToBeHighlighted.includes(i) ? 1 : 0.3;
        });
  }

  private highlightNodeConnections(targetSVG: any, id: number, links: any[]): void {
    let nodesToBeHighlighted: number[] = [id];
    let linksToBeHighlighted: number[] = [];
    links.forEach((link: any, index: number) => {
      if (link.target.id === id) {
        if (!nodesToBeHighlighted.includes(link.source.id)) {
          nodesToBeHighlighted.push(link.source.id);
        }
        linksToBeHighlighted.push(index);
      } else if (link.source.id === id) {
        if (!nodesToBeHighlighted.includes(link.target.id)) {
          nodesToBeHighlighted.push(link.target.id);
        }
        linksToBeHighlighted.push(index);
      }
    });
    this.selectedNodes[this.showSVG] = [id];
    this.highlightNodes(targetSVG, nodesToBeHighlighted);
    this.highlightLinks(targetSVG, linksToBeHighlighted);
  }

  private highlightLinkConnections(targetSVG: any, index: number, links: any[]): void {
    this.selectedNodes[this.showSVG] = [links[index].source.id, links[index].target.id];
    this.highlightNodes(targetSVG, [links[index].source.id, links[index].target.id]);
    this.highlightLinks(targetSVG, [index]);
  }

  private highlightCoOccurredWordNodes(text: string, group: number, targetSVG: any, links: any[]): void {
    const textGroupNode: any = this.wordCloudNodesMap[group];
    const linkedWords: string[] = this.nodeLinksMap[text] || [];
    linkedWords.push(text);
    const linkedGroups: number[] = [];
    linkedWords.forEach((word: string) => {
      const wordData: any = this.nodeTextMap[word];
      if (wordData) {
        const wordGroupNode: any = this.wordCloudNodesMap[wordData.group];
        if (!linkedGroups.includes(wordGroupNode.id)) {
          linkedGroups.push(wordGroupNode.id);
        }
      }
    });
    const groupLinks: any[] = [];
    if (links) {
      links.forEach((link: any, index: number) => {
        if (link.target.id === textGroupNode.id && linkedGroups.includes(link.source.id)) {
          groupLinks.push(index);
        } else if (link.source.id === textGroupNode.id && linkedGroups.includes(link.target.id)) {
          groupLinks.push(index);
        }
      });
    }
    this.highlightNodes(targetSVG, linkedGroups);
    this.highlightLinks(targetSVG, groupLinks);
  }

  private createBackground(targetSVG: any, width: number, height: number, nodes: any[], links: any[]): void {
    const _this = this;
    // For background color and other functionality.
    targetSVG.append('g').append('rect')
        .attr('class', 'graph-transparent')
        .attr('width', '100%')
        .attr('height', height)
        .on('mouseover', function (d: any) {
          d3.select(this).style('cursor', 'all-scroll');
        })
        .on('click', () => {
          targetSVG.selectAll('.node').attr('opacity', 1);
          targetSVG.selectAll('.rect-node').attr('stroke-width', 0.5);
          targetSVG.selectAll('.link').attr('stroke', '#b7b5b5').attr('opacity', 1);
          this.selectedNodes[this.showSVG].forEach((id: any) => {
            targetSVG.select('.cluster-' + id).on('click', function (d: any) {
              _this.clickNode(d, this, targetSVG, nodes, links);
            });
          });
          this.selectedNodes[this.showSVG] = [];
          this.selectedLinks[this.showSVG] = [];
          this.resetWordsSelection(targetSVG);
          this.clickBox.transition()
              .duration(100)
              .style('opacity', 0);
        });
  }

  private createLinks(targetSVG: any, parentElem: any, links: any[]): any {
    const _this = this;
    const link: any = parentElem.append('g')
        .attr('class', 'links')
        .selectAll('path')
        .data(links)
        .enter().append('svg:path')
        .attr('class', 'link')
        .style('fill', 'none')
        .attr('stroke', '#b7b5b5')
        .attr('stroke-width', (d: any) => d.rank)
        .on('mouseover', function (d: any, i: number) {
          const elem: any = d3.select(this).style('cursor', 'pointer');
          if (!_this.selectedLinks[_this.showSVG].includes(i)) {
            elem.attr('stroke', '#71A365');
          }
        })
        .on('mouseout', function (d: any, i: number) {
          const elem: any = d3.select(this).style('cursor', 'normal');
          if (!_this.selectedLinks[_this.showSVG].includes(i)) {
            elem.attr('stroke', '#b7b5b5');
          }
        })
        .on('click', (d: any, i: number) => {
          this.highlightLinkConnections(targetSVG, i, links);
        });
    link.append('title').text((d: any) => d.value);
    return link;
  }

  private removeClickBoxContent(): void {
    this.clickBox.select("div").remove();
  }

  private clickNode(d: any, elemRef: any, targetSVG: any, nodes: any[], links: any[]): void {
    this.initialTick = true;
    this.removeClickBoxContent();
    const _this = this;
    this.clickBox.transition()
        .duration(100)
        .style('opacity', 1)

    this.clickBox.html(this.createClickBoxHtml(d))
        .style('left', (d3.event.pageX) + 'px')
        .style('top', (d3.event.pageY + 10) + 'px');

    this.selectedNodes[this.showSVG].forEach((id: any) => {
      targetSVG.select('.cluster-' + id).on('click', function (d: any) {
        _this.clickNode(d, this, targetSVG, nodes, links);
      });
    });
    this.selectedNodes[this.showSVG].length = 0;
    this.highlightNodeConnections(targetSVG, d.id, links);
    this.selectedGroup = d.group;
    this.wordsOccurrence = this.messageService.getWordCloudData(d.group, 1, null,
        {topKeyWords: false}, this.nodeGroups, this.slideLimit ).data;
    const words: string[] = this.wordsOccurrence.map((d: any) => d.text);
    this.groupLabel = this.groupLabels[this.selectedGroup] || 'Group - ' + this.selectedGroup;
    this.clickedWords = words;
    d3.select('#highlevelstats-clickbox').on('click', ()  =>{

      this.clickBoxButtonClicked = "statistics";
      this.expandWordsDiv(targetSVG, d, links);

      //this.clickname(d.date,d.id);
    });
    d3.select('#subcluster-clickbox').on('click', ()  =>{
      //this.clickname(d.date,d.id);
      this.getSubClusters(d.group);
      //switch tab
      this.isSubClusterTabActive = true;
      //this.showSubClusterView = true;
    });
    d3.select('#cloudview-clickbox').on('click', ()  =>{
      this.clickBoxButtonClicked = "home";
      this.expandWordsDiv(targetSVG, d, links);

      //this.clickname(d.date,d.id);
    });
    d3.select('#listview-clickbox').on('click', ()  =>{
      this.clickBoxButtonClicked = "menu1";
      this.expandWordsDiv(targetSVG, d, links);
      //this.clickname(d.date,d.id);
    });
    d3.select('#cross-click-box').on('click', ()  =>{
      //this.clickBoxButtonClicked = "menu1";
      //this.expandWordsDiv(targetSVG, d, links);
      //this.clickname(d.date,d.id);
      this.removeClickBoxContent();
    });

    d3.select(elemRef).on('click', null);
  }

  private createClickBoxHtml(d): any {
    let div = '<div class="tooltip-wrapper"> ' +
        '<div class="arrow-tooltip" style="background: ' + this.color[Math.floor(d.group)] + '" ></div>' +
        '   <div style="display: flex;justify-content: flex-start;align-items: center;" class="graph-detial-tooltip-header"> ' +
        '   <div class="tool-tip-ctgry-name" style="display: flex;flex-direction: column;width: 90%;text-align: left;"> ' +
        '</div> ' +
        '</div> ' +

        '<div class="row-flex group-row" style="background: ' + this.color[Math.floor(d.group)] + '"  > ' +
        '<span>' +
        'Group Name ' + d.group + '<br>' +
        '</span>' +
        '<div>' +
        '<strong>Words : </strong>' + this.groupKeywordsCount[d.group] +
        '</div>' +
        '</div> ' +
        '<div class="row-flex"> ' +
        '</div> ' +
        '<div class="tooltip-footer"> ' +
        //              '<a style="border: 1px solid #ddd;border-radius: 5px;font-size: 12px;padding: 5px 10px;" (click)="'+ this.clickname(d.date,d.id)+'" >Influencer Map</a> '+
        '<button  id="highlevelstats-clickbox">' +
        '   <i class="fa fa-bar-chart  " aria-hidden="true"></i>  Statistics' +
        '<span class="btn-tooltip"> View sentiment or device breakup </span>' +
        '</button> ' +
        '<button  id="subcluster-clickbox">' +
        '   <i class="fa fa-search-plus" aria-hidden="true"></i> Subclusters' +
        '<span class="btn-tooltip"> View further breakdown of ' + d.group+' </span>' +
        '</button> ' +
        '<button  id="listview-clickbox">' +
        '   <i class="fa fa-list" aria-hidden="true"></i>  List view' +
        '<span class="btn-tooltip"> View prominent phrase/messages </span>' +
        '</button> ' +
        '<button  id="cloudview-clickbox">' +
        '   <i class="fa fa-cloud" aria-hidden="true"></i>  Cloud view' +
        '<span class="btn-tooltip"> View exact position coordinates </span>' +
        '</button> ' +
        '</div> ' +
        '<span id="cross-click-box">' +
        '<i class="fa fa-times" aria-hidden="true"></i>' +
        '</span>' +
        '</div> ';
    return div;
  }

  private createNodes(parentElem: any, nodes: any[]): any {
    const _this = this;
    const node: any = parentElem.append('g')
        .attr('class', 'nodes')
        .selectAll('g')
        .data(nodes)
        .enter().append('g')
        .attr('class', (d: any) => {
          return 'node node-' + d.id;
        })
        .on('mouseover', function (d: any) {
          const elem: any = d3.select(this).style('cursor', 'pointer');
          if (!_this.selectedNodes[_this.showSVG].includes(d.id)) {
            elem.select('rect').attr('stroke-width', 5);
          }
        })
        .on('mouseout', function (d: any) {
          const elem: any = d3.select(this).style('cursor', 'normal');
          if (!_this.selectedNodes[_this.showSVG].includes(d.id)) {
            elem.select('rect').attr('stroke-width', 0.5);
          }
        });
    node.append('title');
    return node;
  }

  private resetWordsSelection(targetSVG: any): void {
    targetSVG.selectAll('.highlighted-text')
        .classed('highlighted-text', false)
        .style('fill', (d: any) => {
          return this.color[Math.floor(this.selectedWords[this.showSVG][d.text].group)];
        });
    this.selectedWords[this.showSVG] = {};
  }

  private placeWords(targetSVG: any, selector: any, node: any, words: any[], bBoxList: any, links: any[]): any {
    targetSVG.select(selector).select('.wordcloud').remove();
    const _this = this;
    const wordCloud: any = targetSVG.select(selector)
        .append('g').attr('class', 'wordcloud');
    wordCloud.selectAll('text')
        .data(words)
        .enter().append('text')
        .attr('class', 'wc-txt')
        .attr('text-anchor', 'middle')
        .style('font-size', (d: any) =>{
          //   console.log(d.rank)
          if(d.rank>150){
            return 150;
          }
          else{
            return d.rank;
          }
        })
        .style('fill', this.color[Math.floor(node.group)])
        .text((d: any) => d.text)
        .attr('transform', (d: any) => {
          return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
        })
        .on('mouseover', function (d: any) {
          if (!_this.selectedWords[_this.showSVG][d.text] && _this.selectedNodes[_this.showSVG].includes(node.id)) {
            d3.select(this).classed('highlight-text', true);
            d3.select(this).style('fill', '#333333');
          }
        })
        .on('mouseout', function (d: any) {
          if (!_this.selectedWords[_this.showSVG][d.text] && _this.selectedNodes[_this.showSVG].includes(node.id)) {
            d3.select(this).classed('highlight-text', false);
            d3.select(this).style('fill', _this.color[Math.floor(node.group)]);
          }
        })
        .on('click', function (d: any) {
          if (_this.selectedNodes[_this.showSVG].includes(node.id)) {
            _this.resetWordsSelection(targetSVG);
            _this.selectedWords[_this.showSVG][d.text] = {group: node.group};
            d3.select(this).classed('highlighted-text', true);
            d3.select(this).style('fill', '#333333');
            _this.groupLabel = _this.groupLabels[_this.selectedGroup] || 'Group - ' + _this.selectedGroup;
            _this.clickedWords = [d.text];
            _this.highlightCoOccurredWordNodes(d.text, node.group, targetSVG, links);
          }
        });
    wordCloud.each(function (d: any) {
      bBoxList[d.group] = this.getBBox();
    });
  }

  private fillWordCloudInNodes(targetSVG: any, nodes: any[], sizeFactor: number, device: string, links: any[]): any {
    const _this = this;
    const bBoxList: any = {};

    nodes.forEach((node: any) => {
      const wordCloudData: any = this.messageService.getWordCloudData(node.group, sizeFactor, device,
          {topKeyWords: true, excludeWords: this.excludeWords, shortListedWords: this.shortListedWords},
          this.nodeGroups, this.slideLimit);

      // Creating cluster object, which will contain rect and wordcloud.
      targetSVG.select('.node-' + node.id).append('g')
          .datum(node)
          .attr('class', 'cluster cluster-' + node.id)
          .on('click', function (d: any) {
            _this.clickNode(d, this, targetSVG, nodes, links);
          });

      // Append rectangular background for word-cloud.
      this.appendRect(targetSVG, '.cluster-' + node.id, wordCloudData, node);
      // let wid;
      // let heig;

      // Append word cloud in each node
      // width: wordCloudData.rectWidth,
      //         height: wordCloudData.rectHeight,
      if(wordCloudData.rectWidth>2000){
        wordCloudData.rectWidth=1500
      }
      if(wordCloudData.rectHeight>1000){
        wordCloudData.rectHeight=1000
      }

      this.wordCloudService.create(wordCloudData.data, {

        width: wordCloudData.rectWidth,
        height:wordCloudData.rectHeight,
        padding: 10
      }, (words: any[]) => {
        this.placeWords(targetSVG, '.cluster-' + node.id, node, words, bBoxList, links);
      });
    });
    return bBoxList;
  }

  private adjustWordCloudBGDimensions(targetSVG: any, bBoxList: any, transform: boolean): void {
    const rectNode: any = targetSVG.selectAll('.rect-node')
        .attr('width', (d: any) => bBoxList[d.group].width + 40)
        .attr('height', (d: any) => bBoxList[d.group].height + 20)
        .attr('rx', (d: any) => ((bBoxList[d.group].height + 20) * 15) / 100)
        .attr('ry', (d: any) => ((bBoxList[d.group].height + 20) * 15) / 100);
    if (transform) {
      rectNode.attr('transform', (d: any) => 'translate(' + (bBoxList[d.group].x - 20)
          + ',' + (bBoxList[d.group].y - 10) + ')');
    }
  }

  private appendNodeLabels(targetSVG: any, selector: any, bBoxList: any, links: any[]): void {
    //targetSVG.select(selector).select('title').text((d: any) => this.groupKeywordsCount[d.group] + ' words');
    targetSVG.select(selector).select('.node-label').remove();
    const label: any = targetSVG.select(selector).append('g').attr('class', 'node-label');
    console.log(this.groupLabels)
    label.append('text')
        .attr('class', 'label')
        .text((d: any) =>{  console.log(d); return this.groupLabels[d.group] || 'Group - ' + d.group})
        .attr('transform', (d: any) => 'translate(' + (bBoxList[d.group].x - 10)
            + ',' + (bBoxList[d.group].y - 20) + ')');
    /*label.append('text')
        .attr('class', 'fa fa-refresh add-label')
        .text('\uf021')
        .attr('transform', (d: any) => 'translate(' + (bBoxList[d.group].x + bBoxList[d.group].width - 100)
            + ',' + (bBoxList[d.group].y - 20) + ')')
        .on('click', (d: any) => {
          this.getSubClusters(d.group);
        });
    label.append('text')
        .attr('class', 'icon-fullscreen add-label')
        .text('\uf0b2')
        .attr('transform', (d: any) => 'translate(' + (bBoxList[d.group].x + bBoxList[d.group].width - 40)
            + ',' + (bBoxList[d.group].y - 20) + ')')
        .on('click', (d: any) => {
          this.expandWordsDiv(targetSVG, d, links, bBoxList);
        });*/
  }

  buildGraph(targetSVG: any, dimensions: any, nodes: any[], links: any[], device?: string): void {
    // Create force simulation.
    const simulation: any = this.ellipseForceService.createForceSimulation({length: 1000, strength: 0.15},
        -6000, {padding: 30, ir: 0.5, or: 700},
        {width: dimensions.width / 2, height: dimensions.height / 2});

    // For background color and other functionality.
    this.createBackground(targetSVG, dimensions.width, dimensions.height, nodes, links);

    // add encompassing group for the zoom
    const g = targetSVG.append('g').attr('class', 'everything');

    // Creating node links and defining their behaviours.
    const link: any = this.createLinks(targetSVG, g, links);
    // Creating nodes and defining their behaviours.
    const node: any = this.createNodes(g, nodes);

    // Fill word-cloud in each node and return their html bBox dimensions.
    const bBoxList: any = this.fillWordCloudInNodes(targetSVG, nodes, 1, device, links);
    // Adjust dimensions of word-cloud's background rect.
    this.adjustWordCloudBGDimensions(targetSVG, bBoxList, true);
    // Append node labels based on node dimensions.
    nodes.forEach((d: any) => {
      this.appendNodeLabels(targetSVG, '.node-' + d.id, bBoxList, links);
    });
    //Add tooltip box
    this.clickBox = d3.select('.click-box');
    // Define tick behaviour.
    simulation.nodes(nodes).on('tick', () => {
      this.ellipseForceService.tickListener(node, link);
    }).on('end', () => {
      if (!this.initialTick) {
        let coordinatesReq: any[] = nodes.map((d: any) => {
          console.log(d)
          return {
            x: d.x,
            y: d.y,
            vx: d.vx,
            vy: d.vy,
            fx: d.fx,
            fy: d.fy,
            group: d.group,
            workspace: workspace
          };
        });
        this.messageService.saveClusterInfo(coordinatesReq).then((res: any) => {
          this.toastr.success('Graph coordinates saved.', 'Success', {timeOut: 3000});
        }).catch((ex: any) => this.toastr.error('Error saving coordinates.', 'Error', {timeOut: 3000}));
      } else {
        this.initialTick = false;
      }
    });
    // Define link force behaviour.
    simulation.force('link').links(links);

    // Add drag capabilities.
    this.ellipseForceService.createDraggableNodes(node, simulation);

    // Add zoom capabilities.
    this.ellipseForceService.createZoomableGraph(targetSVG, [1 / 5, 4], () => {
      g.attr('transform', d3.event.transform);
    });
    setTimeout(() => {
      this.isVariablesLoaded = true;
    }, 1000);
  }

  showMainGraph(): void {
    this.showSVG = this.svgSelectors.main;
    const selector: any = this.initSVGSelector(); // Fetch parent div of required SVG
    const width: any = +window.innerWidth;
    const height: any = 600;
    const targetSVG: any = selector.select('svg').attr('width', '100%').attr('height', height);
    this.buildGraph(targetSVG, {width: width, height: height}, this.nodes, this.links);
  }

  showComparisonGraph(): void {
    this.showSVG = this.svgSelectors.compare;
    const selector: any = this.initSVGSelector(); // Fetch parent div of required SVGs
    /*const width: any = +window.innerWidth;*/
    const width: any = 400;
    const height: any = 600;
    const targetSVG1: any = selector.select('.compare-svg1 svg').attr('width', '100%').attr('height', height);
    const targetSVG2: any = selector.select('.compare-svg2 svg').attr('width', '100%').attr('height', height);
    this.buildGraph(targetSVG1, {width: width, height: height}, this.nodes, this.links,
        this.messageService.formatDeviceName(this.compare1));
    this.buildGraph(targetSVG2, {width: width, height: height}, this.nodes, this.links,
        this.messageService.formatDeviceName(this.compare2));
  }

  showSentimentGraph(): void {
    this.showSVG = this.svgSelectors.sentiments;
    const selector: any = this.initSVGSelector(); // Fetch parent div of required SVGs
    const width: any = +window.innerWidth;
    const height: any = 6000;
    const targetSVG: any = selector.select('svg').attr('width', width).attr('height', height);
    const nodes: any[] = this.nodes;

    // Creating definitions for percentage bar.
    const defs: any = targetSVG.append('defs');

    // For background color and other functionality.
    targetSVG.append('g').append('rect')
        .attr('width', width)
        .attr('height', height)
        .attr('fill', '#000000');

    // add encompassing group for the zoom.
    const g = targetSVG.append('g').attr('class', 'everything')
        .attr('transform', 'translate(100,50)');

    // Creating nodes and defining their behaviours.
    const node: any = g.append('g')
        .attr('class', 'nodes')
        .selectAll('g')
        .data(nodes)
        .enter().append('g')
        .attr('class', (d: any) => {
          return 'node node-' + d.id;
        });
    node.append('title').text((d: any) => this.nodeGroups[d.group].length + ' words');

    // Fill word-cloud in each node and return their html bBox dimensions.
    const bBoxList: any = this.fillWordCloudInNodes(targetSVG, nodes, 6, null, null);
    // Adjust dimensions of word-cloud's background rect.
    this.adjustWordCloudBGDimensions(targetSVG, bBoxList, false);

    const maxWidth: number = +d3.max(Object.values(bBoxList), (d: any) => d.width);

    const yScale: any = {};
    let yAxisStart: number = 0, yAxisEnd: number = 0;

    // Creating Percentage bar.
    const bars: any = g.append('g')
        .attr('class', 'bars');
    let start = 0;
    nodes.forEach((node: any, index: number) => {
      let selector: string = '.node-' + node.id;
      const rectNode: any = targetSVG.select(selector).select('.rect-node');
      yScale[node.id] = {start: start, end: start + +rectNode.attr('height')};
      targetSVG.select(selector).attr('transform', 'translate('
          + (maxWidth - +rectNode.attr('width')) + ',' + start + ')');

      targetSVG.select(selector).select('.wordcloud').attr('transform', 'translate('
          + (+rectNode.attr('width') / 2) + ',' + (+rectNode.attr('height') / 2) + ')');

      const sentiments: any = this.messageService.getGroupSentiments(node.group, this.nodeGroups);
      const positivePercentage: number =
          Math.ceil((sentiments.positive.count / (sentiments.positive.count + sentiments.negative.count)) * 100);
      const negativePercentage: number = 100 - positivePercentage;

      const linearGradient: any = defs.append('linearGradient').attr('id', 'gradient' + node.id);
      linearGradient.append('stop').attr('class', 'stop1')
          .attr('offset', '0%').attr('stop-color', '#719571');
      linearGradient.append('stop').attr('class', 'stop2')
          .attr('offset', positivePercentage + '%').attr('stop-color', '#719571');
      linearGradient.append('stop').attr('class', 'stop3')
          .attr('offset', positivePercentage + '%').attr('stop-color', '#A07070');
      linearGradient.append('stop').attr('class', 'stop4')
          .attr('offset', '100%').attr('stop-color', '#A07070');

      const bar: any = bars.append('g');

      bar.append('rect')
          .attr('class', 'bar bar-' + node.id)
          .attr('width', 500)
          .attr('height', 30)
          .attr('fill', 'url(#' + 'gradient' + node.id + ')')
          .attr('transform', 'translate('
              + (maxWidth + 30) + ',' + (((yScale[node.id].start + yScale[node.id].end) / 2) - 15) + ')');

      // Tag percentage bar.
      const barSeparator: number = Math.ceil(maxWidth + ((500 * positivePercentage) / 100) + 30);
      // Positive percentage text.
      bar.append('text').style('fill', 'black').style('font-size', 10)
          .text(positivePercentage + '%')
          .attr('transform', 'translate('
              + (barSeparator - 25) + ',' + (((yScale[node.id].start + yScale[node.id].end) / 2) + 5) + ')');
      // Negative percentage text.
      bar.append('text').style('fill', 'black').style('font-size', 10)
          .text(negativePercentage + '%')
          .attr('transform', 'translate('
              + (barSeparator + 2) + ',' + (((yScale[node.id].start + yScale[node.id].end) / 2) + 5) + ')');
      // No of sentiments text.
      const sentimentText: any = sentiments.positive.count + sentiments.negative.count;
      bar.append('text').style('fill', 'white').style('font-size', 10)
          .text(() => {
            return index > 0 ? sentimentText : sentimentText + ' instances of sentiments mention for this cluster';
          })
          .attr('transform', () => {
            const translateX = index > 0 ? barSeparator - 17 : maxWidth + 230;
            return 'translate(' + translateX + ',' + (((yScale[node.id].start + yScale[node.id].end) / 2) - 18) + ')';
          });
      yAxisEnd = yScale[node.id].end;
      start = yScale[node.id].end + 20;
    });

    // Create yAxis line.
    const yAxis: any = g.append('g')
        .attr('class', 'yAxis')
        .append('line')
        .attr('stroke', 'white')
        .attr('stroke-width', '0.3px')
        .attr('x1', maxWidth + 29)
        .attr('y1', yAxisStart)
        .attr('x2', maxWidth + 29)
        .attr('y2', yAxisEnd);
  }

  setGroupLabels(labels: any[]): void {
    console.log("in set Group Labels")
    this.groupLabels = {};
    console.log(labels)
    this.nodePositions = {};
    let q=1
    ;    if (labels && labels.length) {
      labels.forEach((item: any) => {
        console.log(item)
        console.log(item.group)
        this.groupLabels[q] = 'Group -' + item.group;
        q=q+1;
        if (item.x && item.y) {
          this.nodePositions[item.group] = item;
        }
      });
    }
    console.log(this.groupLabels)
  }

  updateMainGraph(data): void {
    this.showLoader = true;
    location.reload();
  }

  refreshMainGraphData(): void {
    this.initialTick = true;
    this.showLoader = true;
    this.subClusterKeywords = [];
    this.showSubClusterView = false;
    this.parentGroup = null;
    const dates: any[] = this.header.getDateFromLS();
    Promise.all([

      this.messageService.getClusterData(dates[0], dates[1], workspace),
      this.subClusterService.getSubClusterKeywords()
    ]).then((resp: any[]) => {
      console.log(resp[0].group_mapping)
      let flag=0;
      if(resp[0].group_mapping!==undefined){
        flag=1;
        this.messageService.saveLabellist(resp[0].group_mapping);
      }
      else{
        let flag=0;
        console.log("in else")
      }
      if(flag===1){
        this.messageService.getLabels(workspace).then((clusters: any[]) => {
           console.log(clusters)
          for(let s=0;s<clusters.length;s++){
            clusters[s].group=resp[0].group_mapping[s];
          }
          // console.log(clusters)
          this.setGroupLabels(clusters);
          console.log(this.nodePositions)
          const clusterData: any = this.messageService.processClusterData(resp[0], this.nodePositions);
          this.nodes = clusterData.nodes;
          this.links = clusterData.links;
          this.wordCloudNodesMap = clusterData.wordCloudNodesMap;
          this.nodeTextMap = clusterData.nodeTextMap;
          this.nodeGroups = clusterData.nodeGroups;
          this.nodeLinksMap = clusterData.nodeLinksMap;
          this.rawLinks = resp[0].cooccurence;
          this.refreshGroupKeywordsCount();
          this.processKeyWords(resp[0].Cluster);
          this.showMainGraph();
          if (resp[1] && resp[1].length) {
            this.subClusterKeywords = resp[1];
            this.showSubClusterView = true;
            this.isSubClusterTabActive = false;
            this.parentGroup = resp[1][0].group;
          }
          this.showLoader = false;
        }).catch((error: any) => console.error(error));
      }
      else{
        console.log("inside else")
        this.messageService.labellistfor().then((varp:any)=>{
          //  console.log(varp[0]['0']);


          this.messageService.getLabels(workspace).then((clusters: any[]) => {
            console.log(clusters)
            for(let s=0;s<clusters.length;s++){
              // console.log(varp[0]['0'][s])
              clusters[s].group=varp[0]['0'][s];
            }
            this.setGroupLabels(clusters);

            const clusterData: any = this.messageService.processClusterData(resp[0], this.nodePositions);
            this.nodes = clusterData.nodes
            this.links = clusterData.links;
            this.wordCloudNodesMap = clusterData.wordCloudNodesMap;
            this.nodeTextMap = clusterData.nodeTextMap;
            this.nodeGroups = clusterData.nodeGroups;
            this.nodeLinksMap = clusterData.nodeLinksMap;
            this.rawLinks = resp[0].cooccurence;
            this.refreshGroupKeywordsCount();
            this.processKeyWords(resp[0].Cluster);
            this.showMainGraph();
            if (resp[1] && resp[1].length) {
              this.subClusterKeywords = resp[1];
              this.showSubClusterView = true;
              this.isSubClusterTabActive = false;
              this.parentGroup = resp[1][0].group;
            }
            this.showLoader = false;
          }).catch((error: any) => console.error(error));
        }).catch((error: any) => console.error(error));
      }
    }).catch((error: any) => console.error(error));
  }

  refreshGroupKeywordsCount(): void {
    for (let group in this.nodeGroups) {
      this.groupKeywordsCount[group] = this.nodeGroups[group].length;
    }
  }

  processKeyWords(keywords: any[]): void {
    let excludeWords: string[] = [];
    let shortListedWords: string[] = [];
    keywords.forEach((item: any) => {
      if (item.deleted) {
        excludeWords.push(item.name);
        this.groupKeywordsCount[item.group]--;
      } else if (item.shortListed) {
        shortListedWords.push(item.name);
      }
    });
    this.excludeWords = excludeWords;
    this.shortListedWords = shortListedWords;
  }

  showHighLevelStats(): void {
    const modalRef: NgbModalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.selectedWords = this.clickedWords;
    modalRef.componentInstance.parentWordsOccurrence = this.wordsOccurrence;
    modalRef.componentInstance.groupLabel = this.groupLabel;
    modalRef.componentInstance.activeTabString = this.clickBoxButtonClicked;
  }

  expandWordsDiv(targetSVG: any, d: any, links: any[], bBoxList?: any): void {
    const modalRef: NgbModalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.group = d.group;
    modalRef.componentInstance.groupColor = this.color[d.group];
    modalRef.componentInstance.groupLabel = this.groupLabels[d.group] || 'Group - ' + d.group;
    modalRef.componentInstance.nodeGroups = this.nodeGroups;
    modalRef.componentInstance.activeTabString = this.clickBoxButtonClicked;
    modalRef.componentInstance.selectedWords = this.clickedWords;
    modalRef.componentInstance.parentWordsOccurrence = this.wordsOccurrence;

    modalRef.result.then((rs: any) => {
      if (rs === 'saveWords') {
        this.refreshMainGraphData();
      }
    }).catch((err: any) => {
      this.showLoader = true;
      this.messageService.getLabels(workspace).then((labels: any[]) => {
        console.log("line 975");
        console.log(labels);
        this.setGroupLabels(labels);
        this.removeClickBoxContent();
        //this.appendNodeLabels(targetSVG, '.node-' + d.id, bBoxList, links);
        this.showLoader = false;
      }).catch((ex) => {
        this.showLoader = false;
        this.toastr.error('Error updating label.', 'Error', {timeOut: 3000});
      });
    });
  }

  getSubClusters(parentGroup: number): void {
    if (!this.showSubClusterView) {
      this.showLoader = true;
      this.parentGroup = parentGroup;
      let wordFreqMap: any = {};
      this.nodeGroups[parentGroup].forEach((item: any) => {
        if (!item.deleted) {
          wordFreqMap[item.name] = item.value;
        }
      });
      this.subClusterService.getSubClusters(this.parentGroup, wordFreqMap).then((data: any[]) => {
        this.subClusterKeywords = data;
        this.showSubClusterView = true;
        this.showLoader = false;
      }).catch((err: any) => console.error(err));
    }
  }

  ngOnInit() {
    this.refreshMainGraphData();
    // this.showMainGraph();
    // this.showComparisonGraph();
    // this.showSentimentGraph();
  }

}
