import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import {DataService} from '../reusable/d3/ellipse-force/data.service';
import * as d3 from 'd3';

const sentimentTypes: string[] = ['positive', 'negative', 'neutral'];
const deviceTypes: string[] = ['abbottfreestyle', 'dexcom', 'rocheaccuchek', 'medtronicminimed'];

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
      private dataService: DataService,
      private apiService: ApiService
  ) {
  }
   flag=0;

  public processClusterData(data: any, nodePositions?: any): any {
    let rawNodes: any[] = data.Cluster;
    let rawLinks: any = data.cooccurence;
    let excludeWords: string[] = [];
    rawNodes.forEach((d: any) => {
      if (d.deleted) {
        excludeWords.push(d.name);
      }
    });

    let [wordCloudNodesMap, nodeTextMap, nodeGroups] = this.manageNodes(rawNodes, nodePositions);
    let [nodeLinksMap, processedLinks, nodeMap] = this.formatData(rawLinks, nodeTextMap, excludeWords);
    let links: any[] = this.finalizeData(processedLinks, nodeMap, wordCloudNodesMap);
    // Define ranks of link values here
    const maxValue: number = d3.max(links, (d: any) => d.value || 0);
    const minValue: number = d3.min(links, (d: any) => d.value || 0);
    links.forEach((link: any) => {
      link.rank = this.calculateSizeRank(link.value, maxValue, minValue, 50) + 20;
    });

    return {
      nodes: Object.values(wordCloudNodesMap) || [],
      links: links,
      wordCloudNodesMap: wordCloudNodesMap,
      nodeTextMap: nodeTextMap,
      nodeGroups: nodeGroups,
      nodeLinksMap: nodeLinksMap
    };
  }

  public getClusterData(startDate: any, endDate: any, workspace: string): Promise<any> {
    const url = '/clusters';
    const payload: any = {
      'start_date': startDate,
      'end_date': endDate,
      'is_reshuffling': 'False',
      'filter': {
        'device_names': [
          'Dexcom'
        ]
      },
      'device': '',
      'website': ''
    };
    // const headers: any = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json'
    // };
    return new Promise((resolve, reject) => {
      // uncomment below piece of code to get static data
      /*resolve({
        Cluster: this.dataService.nodes,
        cooccurence: this.dataService.links
      });*/
      this.apiService.put(url + '?workspace=' + workspace, null).subscribe((response: any) => {
        if (response && response.Cluster.length && response.cooccurence) {
          resolve(response);
        } else {
          this.apiService.put(url, payload).subscribe((response) => {
            resolve(response);
          }, (error: any) => {
            reject(error);
          });
        }
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getMessages(start: any, end: any, words: string[], lastMsgId: string): Promise<any> {
    let url = '/messages/?start=' + start + '&end=' + end;
    if (lastMsgId) {
      url += '&lastMsgId=' + lastMsgId;
    }
    return new Promise((resolve, reject) => {
      this.apiService.put(url, words).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getMsgStats(start: any, end: any, words: string[]): Promise<any> {
    let url = '/messages/stats/?start=' + start + '&end=' + end;
    return new Promise((resolve, reject) => {
      this.apiService.put(url, words).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public saveLabel(label: any): Promise<any> {
    console.log(label)
    let url = '/clusters/label';
    return new Promise((resolve, reject) => {
      this.apiService.put(url, label).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }
  public saveLabellist(label: any): Promise<any> {
    console.log("in message service labels")
    let url = '/clusters/labellist';
    return new Promise((resolve, reject) => {
      this.apiService.post(url, label).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public saveClusterInfo(clusters: any[]): Promise<any> {
    let url = '/clusters/info';
    return new Promise((resolve, reject) => {
      this.apiService.put(url, clusters).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getLabels(workspace: string): Promise<any[]> {
    let url = '/clusters/label?workspace=' + workspace;
    return new Promise((resolve, reject) => {
      this.apiService.get(url).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public labellistfor(): Promise<any[]> {
    console.log("in service")
    let url = '/clusters/labellistfor';
    return new Promise((resolve, reject) => {
      this.apiService.get(url).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }


  formatDeviceName(device: string): string {
    const fDevice: string = device ? device.toLowerCase().trim() : '';
    return fDevice ? fDevice.replace(/ /g, '').replace(/-/g, '') : '';
  }

  getGroupSentiments(group: number, nodeGroups: any): any {
    let sentiments: any = {
      positive: {count: 0},
      negative: {count: 0},
      neutral: {count: 0}
    };
    let items: any[] = this.sliceNodes(nodeGroups[group], 15);
    items.forEach((item: any) => {
      sentimentTypes.forEach((sType: string) => {
        const sentiment: any = item.stats.Sentiment[sType];
        sentiments[sType].count += sentiment.count;
        for (let device in sentiment.bifurcation) {
          const fDevice: string = this.formatDeviceName(device);
          if (deviceTypes.includes(fDevice)) {
            if (!sentiments[sType][fDevice]) {
              sentiments[sType][fDevice] = 0;
            }
            sentiments[sType][fDevice] += sentiment.bifurcation[device];
          }
        }
      });
    });
    return sentiments;
  }

  appendDefaultNodeValues(node: any, nodePositions: any): void {
    node.rx = 300; // Default values
    node.ry = 200; // Default values

    if (nodePositions && nodePositions[node.group]) {

      node.x = nodePositions[node.group].x;
      node.y = nodePositions[node.group].y;
      node.vx = nodePositions[node.group].vx;
      node.vy = nodePositions[node.group].vy;
      node.fx = nodePositions[node.group].fx;
      node.fy = nodePositions[node.group].fy;
    }
    else{
      //console.log(this.flag+1)

if(this.flag===0) {
  node.x = nodePositions['Accuracy'].x;
  node.y = nodePositions['Accuracy'].y;
  node.vx = nodePositions['Accuracy'].vx;
  node.vy = nodePositions['Accuracy'].vy;
  node.fx = nodePositions['Accuracy'].fx;
  node.fy = nodePositions['Accuracy'].fy;
}
else if(this.flag===1) {
  node.x = nodePositions['DiabetesManagement'].x;
  node.y = nodePositions['DiabetesManagement'].y;
  node.vx = nodePositions['DiabetesManagement'].vx;
  node.vy = nodePositions['DiabetesManagement'].vy;
  node.fx = nodePositions['DiabetesManagement'].fx;
  node.fy = nodePositions['DiabetesManagement'].fy;
}
else if(this.flag===2) {
  node.x = nodePositions['Diet&Lifestyle'].x;
  node.y = nodePositions['Diet&Lifestyle'].y;
  node.vx = nodePositions['Diet&Lifestyle'].vx;
  node.vy = nodePositions['Diet&Lifestyle'].vy;
  node.fx = nodePositions['Diet&Lifestyle'].fx;
  node.fy = nodePositions['Diet&Lifestyle'].fy;
}
else if(this.flag===3) {
  node.x = nodePositions['Wearable'].x;
  node.y = nodePositions['Wearable'].y;
  node.vx = nodePositions['Wearable'].vx;
  node.vy = nodePositions['Wearable'].vy;
  node.fx = nodePositions['Wearable'].fx;
  node.fy = nodePositions['Wearable'].fy;
}




      this.flag=this.flag+1
    }
    this.flag=this.flag+1
  }

  calculateSizeRank(size: number, maxValue: number, minValue: number, limit: number): number {
    let rank: number = ((size - minValue) / (maxValue - minValue));
    return Math.ceil(rank * limit); // range from 0 to limit.
  }

  getWordCloudData(group: number, sizeFactor: number, device: string, pref: any, nodeGroups: any, limit:number): any {
    let data: any[] = [];
    let items: any[] = nodeGroups[group].filter((d: any) => {
      return !pref.excludeWords || !pref.excludeWords.includes(d.name);
    });
    const groupSize: number = items.length < 100 ? 100 : items.length;
    const shortListedItems: any[] = [];
    const remainingItems: any[] = [];
    items.forEach((d: any) => {
      if (pref.shortListedWords && pref.shortListedWords.includes(d.name)) {
        shortListedItems.push(d);
      } else {
        remainingItems.push(d);
      }
    });
    items = shortListedItems.concat(remainingItems);
    const extent: number[] = d3.extent(items, (d: any) => device ? (d.devices[device] || 0) : (d.value || 0));
    let limitGroup: number = groupSize / 10;
    if (limitGroup < 15) {
      limitGroup = 15;
    }
    if (limitGroup > 30) {
      limitGroup = 30;
    }
    if(limitGroup === 30){
      if(limit < 30){
        limit = 30;
      }
    }
    if (pref.topKeyWords) {
      items = this.sliceNodes(items, limit);
    }
    items.forEach((item: any) => {
      const size: number = device ? (item.devices[device] || 0) : (item.value || 0);
      item.rank = this.calculateSizeRank(size, extent[1], extent[0], groupSize / 2) + 20;
      data.push({
        text: item.name,
        size: size,
        rank: item.rank / sizeFactor
      });
      // fontWeight += (item.name.length * Math.sqrt(item.rank / sizeFactor));
    });
    return {
      data: data,
      rectWidth: (groupSize * 600) / 100,
      rectHeight: (groupSize * 400) / 100
    };
  }

  createNode(nodeMap: any, group: number, nodePositions: any, opts: any): void {
    if (!nodeMap[group]) {
      // Creating group leader.
      nodeMap[group] = {
        id: ++opts.lastNodeId,
        group: group
      };

      this.appendDefaultNodeValues(nodeMap[group], nodePositions);
    }
  }

  sortNodes(nodes: any[]): void {
    // Sort nodes in descending order based on their frequencies.
    nodes.sort((a: any, b: any) => {
      return (b.value - a.value);
    });
  }

  sliceNodes(nodes: any[], limit: number): any {
    return nodes.slice(0, limit);
  }

  private buildNodeLinksMap(nodeLinksMap: any, source: string, target: string): void {
    if (!nodeLinksMap[source]) {
      nodeLinksMap[source] = []; // Init links array
    }
    if (!nodeLinksMap[target]) {
      nodeLinksMap[target] = []; // Init links array
    }

    if (!nodeLinksMap[source].includes(target)) {
      nodeLinksMap[source].push(target);
    }
    if (!nodeLinksMap[target].includes(source)) {
      nodeLinksMap[target].push(source);
    }
  }

  manageNodes(rawNodes: any[], nodePositions: any): any[] {

    let createNodeOpts: any = {lastNodeId: rawNodes[rawNodes.length - 1].id};
    let groups: any = {};
    let wordCloudNodesMap: any = {};
    let nodeMap: any = {};

    rawNodes.forEach((node: any) => {
      //console.log(node.group)
      node.name = node.name.toLowerCase().trim();
      if (node.name && node.value > 1) {
        if (!node.deleted) {
     //     console.log(nodePositions)
          this.createNode(wordCloudNodesMap, node.group, nodePositions, createNodeOpts); // Word cloud node
        }
        node.devices = {};
        const sentiments: any = node.stats.Sentiment;
        for (let sentiment in sentiments) {
          const bifurcation: any = sentiments[sentiment].bifurcation;
          for (let device in bifurcation) {
            const fDevice: string = this.formatDeviceName(device);
            if (deviceTypes.includes(fDevice)) {
              if (!node.devices[fDevice]) {
                node.devices[fDevice] = 0;
              }
              node.devices[fDevice] += bifurcation[device];
            }
          }
        }
        if (!groups[node.group]) {
          groups[node.group] = [];
        }
        groups[node.group].push(node);
        nodeMap[node.name] = node;
      }
    });
    for (let group in groups) {
      this.sortNodes(groups[group]);
    }
    return [wordCloudNodesMap, nodeMap, groups];
  }

  formatData(rawLinks: any, nodeTextMap: any, excludeWords: string[]): any[] {
    let nodeMap: any = {};
    let linkMap: any = {};
    let nodeLinksMap: any = {};
    for (let source in rawLinks) {
      source = source.toLowerCase().trim() || '';
      if (source && !excludeWords.includes(source)) {
        for (let target in rawLinks[source]) {
          target = target.toLowerCase().trim() || '';
          if (target && !excludeWords.includes(target)) {
            this.buildNodeLinksMap(nodeLinksMap, source, target);
            let sourceNode: any = nodeTextMap[source];
            let targetNode: any = nodeTextMap[target];
            const linkFreq: number = rawLinks[source][target];
            if (sourceNode && targetNode && linkFreq) {
              let link = {
                source: sourceNode.id,
                target: targetNode.id,
                value: linkFreq,
                group: sourceNode.group
              };
              if (!(linkMap[link.source + ',' + link.target] || linkMap[link.target + ',' + link.source])) {
                linkMap[link.source + ',' + link.target] = link;
              }
              if (!nodeMap[sourceNode.id]) {
                nodeMap[sourceNode.id] = sourceNode;
              }
              if (!nodeMap[targetNode.id]) {
                nodeMap[targetNode.id] = targetNode;
              }
            }
          }
        }
      }
    }
    return [nodeLinksMap, Object.values(linkMap), nodeMap];
  }

  finalizeData(links: any[], nodeMap: any, wordCloudNodesMap: any): any[] {
    let groupLinksMap: any = {};
    links.forEach((link: any) => {
      let targetNode: any = nodeMap[link.target];
      let sourceNode: any = nodeMap[link.source];
      let targetNodeGroup: any = wordCloudNodesMap[targetNode.group];
      let sourceNodeGroup: any = wordCloudNodesMap[sourceNode.group];
      if (sourceNode.group !== targetNode.group && targetNodeGroup && sourceNodeGroup) {
        let groupLinkMapKey = targetNode.group < sourceNode.group ?
            targetNode.group + ',' + sourceNode.group : sourceNode.group + ',' + targetNode.group;
        if (!groupLinksMap[groupLinkMapKey]) {
          groupLinksMap[groupLinkMapKey] = {
            source: sourceNodeGroup.id,
            target: targetNodeGroup.id,
            value: link.value
          };
        } else {
          groupLinksMap[groupLinkMapKey].value += link.value;
        }
      }
    });
    return Object.values(groupLinksMap) || [];
  }


}
