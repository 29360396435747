import {Component, OnInit} from "@angular/core";
import * as d3 from "d3";

@Component({
  selector: "app-line-graph",
  templateUrl: "./line-graph.component.html",
  styleUrls: ["./line-graph.component.css"]
})
export class LineGraphComponent implements OnInit {

  constructor() {
  }

  drawGraph(): void {
    const tagCoordinates: any = {
      graph: {
        start: {
          x: 0,
          y: 0
        },
        end: {
          x: 0,
          y: 0
        }
      },
      svg: {
        start: {
          x: 0,
          y: 0
        },
        end: {
          x: 0,
          y: 0
        }
      }
    };
    const rawData1: any[] = [
      {year: 2006, population: 40},
      {year: 2008, population: 45},
      {year: 2010, population: 48},
      {year: 2012, population: 51},
      {year: 2014, population: 53},
      {year: 2016, population: 57},
      {year: 2017, population: 62}
    ];
    const rawData2: any[] = [
      {year: 2006, population: 20},
      {year: 2008, population: 25},
      {year: 2010, population: 28},
      {year: 2012, population: 31},
      {year: 2014, population: 33},
      {year: 2016, population: 37},
      {year: 2017, population: 42}
    ];
    const margin: any = {top: 20, right: 20, bottom: 30, left: 50};
    const width: number = 960 - margin.left - margin.right;
    const height: number = 500 - margin.top - margin.bottom;

    // set the ranges
    const x: any = d3.scaleLinear().range([0, width]);
    const y: any = d3.scaleLinear().range([height, 0]);

    rawData1.forEach((d: any) => {
      d.year = d.year;
      d.population = +d.population;
    });

    const svg: any = d3.select(".lineGraph").append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g").attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    // This allows to find the closest X index of the mouse:
    const bisect: any = d3.bisector((d: any) => d.year).left;

    // Create the circle that travels along the curve of chart
    const focus: any = svg
        .append("g")
        .append("circle")
        .style("fill", "none")
        .attr("stroke", "black")
        .attr("r", 8.5)
        .style("opacity", 0);

    // Create the text that travels along the curve of chart
    const focusText: any = svg
        .append("g")
        .append("text")
        .style("opacity", 0)
        .attr("text-anchor", "left")
        .attr("alignment-baseline", "middle");

    // Create a rect on top of the svg area: this rectangle recovers mouse position
    svg.append("rect")
        .style("fill", "none")
        .style("pointer-events", "all")
        .attr("width", width)
        .attr("height", height)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", mouseout)
        .on("mousedown", mousedown)
        .on("mouseup", mouseup);

    const valueLine: any = d3.line()
        .x((d: any) => x(d.year))
        .y((d: any) => y(d.population));

    const svgMetadata: any = {
      x: {
        min: d3.min(rawData1, (d: any) => d.year),
        max: d3.max(rawData1, (d: any) => d.year)
      },
      y: {
        min: 0,
        max: d3.max(rawData1, (d: any) => d.population)
      }
    };

    x.domain([svgMetadata.x.min, svgMetadata.x.max]);
    y.domain([svgMetadata.y.min, svgMetadata.y.max]);

    svgMetadata.heightPerUnit = height / (svgMetadata.y.max - svgMetadata.y.min);
    svgMetadata.widthPerUnit = width / (svgMetadata.x.max - svgMetadata.x.min);

    // Add the valueLine path.
    svg.append("path")
        .datum(rawData1)
        .attr("fill", "none")
        .attr("stroke", "green")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", "1.5px")
        .attr("d", valueLine);

    svg.append("path")
        .datum(rawData2)
        .attr("fill", "none")
        .attr("stroke", "blue")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", "1.5px")
        .attr("d", valueLine);

    // Add the X Axis
    svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));

    // Add the Y Axis
    svg.append("g")
        .call(d3.axisLeft(y));

    // What happens when the mouse move -> show the annotations at the right positions.
    function mouseover() {
      focus.style("opacity", 1);
      focusText.style("opacity", 1);
    }

    function mousemove() {
      // recover coordinate we need
      const mousePointer: any = d3.mouse(this)[0];
      const x0: any = x.invert(mousePointer);
      const dataIndex: any = bisect(rawData1, x0);
      const selectedData: any = rawData1[dataIndex - 1];
      focus.attr("cx", x(selectedData.year))
          .attr("cy", y(selectedData.population));
      focusText.html("x:" + selectedData.year + "  -  " + "y:" + selectedData.population)
          .attr("x", x(selectedData.year) + 15)
          .attr("y", y(selectedData.population));
    }

    function mouseout() {
      focus.style("opacity", 0);
      focusText.style("opacity", 0);
    }

    function mousedown() {
      console.log("Tagging started...!!!");
      // recover coordinate we need
      const mousePointer: any = d3.mouse(this);
      const x0: any = x.invert(mousePointer[0]);
      const y0: any = y.invert(mousePointer[1]);
      tagCoordinates.graph.start.x = x0;
      tagCoordinates.graph.start.y = y0;
      tagCoordinates.svg.start.x = mousePointer[0];
      tagCoordinates.svg.start.y = mousePointer[1];
    }

    function mouseup() {
      console.log("Tagging finished...!!!");
      // recover coordinate we need
      const mousePointer: any = d3.mouse(this);
      const x0: any = x.invert(mousePointer[0]);
      const y0: any = y.invert(mousePointer[1]);
      tagCoordinates.graph.end.x = x0;
      tagCoordinates.graph.end.y = y0;
      tagCoordinates.svg.end.x = mousePointer[0];
      tagCoordinates.svg.end.y = mousePointer[1];
      console.log("tag coordinates -", tagCoordinates);

      const rectWidth: any = tagCoordinates.graph.end.x - tagCoordinates.graph.start.x;
      let rectHeight: any = tagCoordinates.graph.start.y;
      if (tagCoordinates.graph.end.y > tagCoordinates.graph.start.y) {
        rectHeight = tagCoordinates.graph.end.y;
        tagCoordinates.svg.start.y = tagCoordinates.svg.end.y;
      }

      svg.append("rect")
          .style("fill", "black")
          .style("opacity", 0.3)
          .attr("x", tagCoordinates.svg.start.x)
          .attr("y", tagCoordinates.svg.start.y)
          .attr("width", rectWidth * svgMetadata.widthPerUnit)
          .attr("height", rectHeight * svgMetadata.heightPerUnit);
    }
  }

  ngOnInit() {
    this.drawGraph();
  }

}
