import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api.service';

const workspace: string = '5e1c7cf6debc6afe2e6c7eb6';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  /*testCoordinates = {
    'dexcom': {
      'coordinates': [-0.4088144898414612, -3.345777988433838],
      'group': 0
    },
    'sensor': {
      'coordinates': [-15.450915336608887, 1.7106623649597168],
      'group': 0
    },
    'pump': {
      'coordinates': [0.5093113780021667, -6.132572174072266],
      'group': 0
    },
    'phone': {
      'coordinates': [-11.639776229858398, 3.40057635307312],
      'group': 0
    },
    'transmitter': {
      'coordinates': [-13.96583366394043, 0.992038905620575],
      'group': 0
    },
    'watch': {
      'coordinates': [-9.030553817749023, 5.383457183837891],
      'group': 0
    },
    'alarm': {
      'coordinates': [-14.023679733276367, 6.099376678466797],
      'group': 0
    },
    'message': {
      'coordinates': [-12.436409950256348, -5.123884677886963],
      'group': 0
    },
    'iphone': {
      'coordinates': [-9.989493370056152, 3.4122254848480225],
      'group': 0
    },
    'wifi': {
      'coordinates': [-7.230395793914795, -1.1719210147857666],
      'group': 0
    },
    'receiver': {
      'coordinates': [-12.401100158691406, 1.435772180557251],
      'group': 0
    },
    'xdrip': {
      'coordinates': [-6.21328592300415, 3.3691234588623047],
      'group': 0
    },
    'sound': {
      'coordinates': [-8.84911060333252, -3.494784355163574],
      'group': 0
    },
    'connection': {
      'coordinates': [-9.612028121948242, -1.7204673290252686],
      'group': 0
    },
    'display': {
      'coordinates': [-7.635500907897949, -6.151024341583252],
      'group': 0
    },
    'touch': {
      'coordinates': [-5.247304439544678, -9.885689735412598],
      'group': 0
    },
    'notification': {
      'coordinates': [-10.986775398254395, -4.261998176574707],
      'group': 0
    },
    'dex': {
      'coordinates': [0.9959338903427124, -3.46323823928833],
      'group': 0
    },
    'sugarmate': {
      'coordinates': [-7.125345706939697, 4.5497589111328125],
      'group': 0
    },
    'button': {
      'coordinates': [-9.76679515838623, -8.47790241241455],
      'group': 0
    },
    'combo': {
      'coordinates': [-3.47851300239563, -8.734672546386719],
      'group': 0
    },
    'screen': {
      'coordinates': [-9.119550704956055, -7.245556354522705],
      'group': 0
    },
    'basal': {
      'coordinates': [-2.4857046604156494, -0.10237294435501099],
      'group': 0
    },
    'nfc': {
      'coordinates': [-8.146794319152832, 1.8644709587097168],
      'group': 0
    },
    'tslim': {
      'coordinates': [-1.527356743812561, -4.882994651794434],
      'group': 0
    },
    'accuchek': {
      'coordinates': [-4.51017951965332, -12.986149787902832],
      'group': 0
    },
    'battery': {
      'coordinates': [-14.07553482055664, -1.0475870370864868],
      'group': 0
    },
    'bluetooth': {
      'coordinates': [-8.8701753616333, 0.6635604500770569],
      'group': 0
    },
    'signal': {
      'coordinates': [-11.438858985900879, -1.5679947137832642],
      'group': 0
    },
    'clock': {
      'coordinates': [-4.83730936050415, -1.9001398086547852],
      'group': 0
    },
    'vibe': {
      'coordinates': [-2.532191514968872, -6.1656317710876465],
      'group': 0
    },
    'router': {
      'coordinates': [-6.643385887145996, -2.4274230003356934],
      'group': 0
    },
    'ping': {
      'coordinates': [-3.445847511291504, -7.072345733642578],
      'group': 0
    },
    'pdm': {
      'coordinates': [-0.8293336033821106, -7.531299591064453],
      'group': 0
    },
    'meter': {
      'coordinates': [-3.2118079662323, -15.15835189819336],
      'group': 1
    },
    'strip': {
      'coordinates': [-0.5582458972930908, -15.520201683044434],
      'group': 1
    },
    'site': {
      'coordinates': [3.8586764335632324, -7.962701797485352],
      'group': 1
    },
    'replacement': {
      'coordinates': [4.340518951416016, -16.898679733276367],
      'group': 1
    },
    'box': {
      'coordinates': [6.7665910720825195, -16.926851272583008],
      'group': 1
    },
    'cannula': {
      'coordinates': [6.390908718109131, -9.571343421936035],
      'group': 1
    },
    'pocket': {
      'coordinates': [6.286520481109619, -19.69602394104004],
      'group': 1
    },
    'glucometer': {
      'coordinates': [-2.9093949794769287, -13.803251266479492],
      'group': 1
    },
    'insert': {
      'coordinates': [6.167796611785889, -5.363915920257568],
      'group': 1
    },
    'needle': {
      'coordinates': [8.02085018157959, -9.554475784301758],
      'group': 1
    },
    'machine': {
      'coordinates': [1.2258780002593994, -12.758890151977539],
      'group': 1
    },
    'pod': {
      'coordinates': [2.931126594543457, -6.610367298126221],
      'group': 1
    },
    'lancet': {
      'coordinates': [0.9532715082168579, -14.891117095947266],
      'group': 1
    },
    'clip': {
      'coordinates': [5.514321327209473, -12.610289573669434],
      'group': 1
    },
    'warranty': {
      'coordinates': [2.528486728668213, -20.42812728881836],
      'group': 1
    },
    'tube': {
      'coordinates': [2.8454995155334473, -11.060345649719238],
      'group': 1
    },
    'belt': {
      'coordinates': [4.868207931518555, -13.55727481842041],
      'group': 1
    },
    'reservoir': {
      'coordinates': [6.939652919769287, -11.177080154418945],
      'group': 1
    },
    'plastic': {
      'coordinates': [8.931955337524414, -13.051301956176758],
      'group': 1
    },
    'batch': {
      'coordinates': [7.934305667877197, -16.916093826293945],
      'group': 1
    },
    'tablet': {
      'coordinates': [-1.2546383142471313, -10.877535820007324],
      'group': 1
    },
    'cassette': {
      'coordinates': [-0.16990451514720917, -12.733083724975586],
      'group': 1
    },
    'tubing': {
      'coordinates': [5.340850830078125, -10.657849311828613],
      'group': 1
    },
    'port': {
      'coordinates': [0.6438165903091431, -9.688582420349121],
      'group': 1
    },
    'catheter': {
      'coordinates': [5.8415327072143555, -8.020964622497559],
      'group': 1
    },
    'issue': {
      'coordinates': [13.571477890014648, 2.6080987453460693],
      'group': 2
    },
    'problem': {
      'coordinates': [13.610687255859375, 1.7978014945983887],
      'group': 2
    },
    'patch': {
      'coordinates': [10.40841007232666, -7.792619705200195],
      'group': 2
    },
    'restart': {
      'coordinates': [7.3150529861450195, 0.9723585844039917],
      'group': 2
    },
    'irritation': {
      'coordinates': [13.296571731567383, -2.238823175430298],
      'group': 2
    },
    'rash': {
      'coordinates': [14.18702220916748, -2.89290189743042],
      'group': 2
    },
    'barrier': {
      'coordinates': [11.337787628173828, -3.7726657390594482],
      'group': 2
    },
    'paracetamol': {
      'coordinates': [11.509261131286621, -1.7622997760772705],
      'group': 2
    },
    'mri': {
      'coordinates': [8.62657356262207, -2.0957863330841064],
      'group': 2
    },
    'inserter': {
      'coordinates': [7.959353446960449, -7.294847011566162],
      'group': 2
    },
    'insertion': {
      'coordinates': [7.600388050079346, -5.43222188949585],
      'group': 2
    },
    'stick': {
      'coordinates': [12.89198112487793, -14.282052993774414],
      'group': 3
    },
    'tape': {
      'coordinates': [13.100830078125, -8.986327171325684],
      'group': 3
    },
    'adhesive': {
      'coordinates': [12.889162063598633, -6.990084648132324],
      'group': 3
    },
    'spray': {
      'coordinates': [15.767939567565918, -8.972037315368652],
      'group': 3
    },
    'sticker': {
      'coordinates': [10.771539688110352, -10.598067283630371],
      'group': 3
    },
    'glue': {
      'coordinates': [14.289363861083984, -6.691713333129883],
      'group': 3
    },
    'tac': {
      'coordinates': [15.565386772155762, -10.600568771362305],
      'group': 3
    },
    'tegaderm': {
      'coordinates': [14.236888885498047, -8.391551971435547],
      'group': 3
    },
    'shell': {
      'coordinates': [11.140707969665527, -12.687682151794434],
      'group': 3
    },
    'libre': {
      'coordinates': [4.604650020599365, -0.889155924320221],
      'group': 4
    },
    'reading': {
      'coordinates': [0.4059434235095978, 7.64988374710083],
      'group': 4
    },
    'reader': {
      'coordinates': [-4.42142915725708, 6.684484004974365],
      'group': 4
    },
    'error': {
      'coordinates': [2.3360071182250977, 3.2706027030944824],
      'group': 4
    },
    'finger': {
      'coordinates': [7.047869682312012, 8.904341697692871],
      'group': 4
    },
    'arrow': {
      'coordinates': [-1.1489242315292358, 10.687607765197754],
      'group': 4
    },
    'miaomiao': {
      'coordinates': [-6.9990363121032715, 8.833028793334961],
      'group': 4
    },
    'glimp': {
      'coordinates': [-4.993503570556641, 3.978511333465576],
      'group': 4
    },
    'calibration': {
      'coordinates': [3.827979803085327, 5.643343925476074],
      'group': 4
    },
    'mine': {
      'coordinates': [2.5002925395965576, -3.10900616645813],
      'group': 4
    },
    'mode': {
      'coordinates': [9.496493339538574, 5.535308361053467],
      'group': 4
    },
    'graph': {
      'coordinates': [-0.9166175127029419, 9.366363525390625],
      'group': 4
    },
    'input': {
      'coordinates': [-0.6425766944885254, 3.5416438579559326],
      'group': 4
    },
    'libra': {
      'coordinates': [2.9504806995391846, -0.3922763466835022],
      'group': 4
    },
    'fingerstick': {
      'coordinates': [4.8976826667785645, 6.901259899139404],
      'group': 4
    },
    'miao': {
      'coordinates': [-7.892212390899658, 10.7496976852417],
      'group': 4
    },
    'delay': {
      'coordinates': [3.863518238067627, 2.6055102348327637],
      'group': 4
    },
    'scanning': {
      'coordinates': [0.6826862096786499, 5.883360385894775],
      'group': 4
    },
    'scanner': {
      'coordinates': [-2.9595730304718018, 5.817061424255371],
      'group': 4
    },
    'enlite': {
      'coordinates': [0.15517792105674744, -1.1850202083587646],
      'group': 4
    },
    'deviation': {
      'coordinates': [4.184128761291504, 9.16530704498291],
      'group': 4
    },
    'fsl': {
      'coordinates': [2.565004587173462, 0.7819986343383789],
      'group': 4
    },
    'mmol': {
      'coordinates': [1.981683611869812, 10.930449485778809],
      'group': 4
    },
    'blucon': {
      'coordinates': [-6.936513423919678, 8.789285659790039],
      'group': 4
    },
    'fingerprick': {
      'coordinates': [4.4433393478393555, 8.506065368652344],
      'group': 4
    },
    'warmup': {
      'coordinates': [6.983272552490234, 2.6726245880126953],
      'group': 4
    },
    'arm': {
      'coordinates': [19.376623153686523, -3.8631434440612793],
      'group': 5
    },
    'skin': {
      'coordinates': [16.8502140045166, -5.452212333679199],
      'group': 5
    },
    'stomach': {
      'coordinates': [19.06187629699707, -5.955167293548584],
      'group': 5
    },
    'thigh': {
      'coordinates': [20.67105484008789, -4.826722621917725],
      'group': 5
    },
    'belly': {
      'coordinates': [19.076021194458008, -7.31903076171875],
      'group': 5
    },
    'tissue': {
      'coordinates': [19.94476318359375, -11.072575569152832],
      'group': 5
    },
    'abdomen': {
      'coordinates': [20.592702865600586, -6.088016986846924],
      'group': 5
    },
    'fluid': {
      'coordinates': [20.63229751586914, -12.18917465209961],
      'group': 5
    }
  };*/

  constructor(private apiService: ApiService) {
  }

  public getKeywords(options?: any): Promise<any[]> {
    options = options || {};
    let url = '/keywords?workspace=' + workspace;
    if (options.group) {
      url += '&group=' + options.group;
    }
    if (options.subGroup) {
      url += '&subGroup=' + options.subGroup;
    }
    console.log(url)
    return new Promise((resolve, reject) => {
      this.apiService.get(url).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });

  }

  public upsertKeywords(keywords: any[]): Promise<any> {
    let url = '/keywords?workspace=' + workspace;
    return new Promise((resolve, reject) => {
      this.apiService.put(url, keywords).subscribe((response) => {
        resolve(response);

      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getPhrases(start: any, end: any, keyWord: string): Promise<any[]> {
    let url = '/messages/phrases?start=' + start + '&end=' + end + '&keyWord=' + keyWord;
    return new Promise((resolve, reject) => {
      this.apiService.get(url).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  public getCoordinates(words: string[]): Promise<any> {
    const url = '/clusters/coordinates';
    return new Promise((resolve, reject) => {
      // resolve(this.testCoordinates);
      this.apiService.put(url, words).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }
}
